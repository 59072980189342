/* REAL - DASH - USERS - ALL (long)*/
/* File: /src/pages/Dashboard/DashboardPages/Users-All/ManageUser.js  */
import "./ManageUser.css";
import React, 
       { useContext, 
         useEffect, 
         useState, 
         useCallback 
       }                                            from "react";
import { useNavigate }                              from "react-router-dom";
import { IoCloseSharp }                             from "react-icons/io5";

// REACT-ICONS
import { BiSolidPencil }                            from "react-icons/bi";
import { MdDelete }                                 from "react-icons/md";
import { LiaPhoneVolumeSolid }                      from "react-icons/lia";
import { TbPasswordUser }                           from "react-icons/tb";
import { MdOutlineMarkEmailRead }                   from "react-icons/md";
import { ImProfile }                                from "react-icons/im";
import { SiAuthentik }                              from "react-icons/si";
import { TbMailCode }                               from "react-icons/tb";
import { FaRegEye, 
        FaRegEyeSlash, 
        FaUserPlus
        }                                           from "react-icons/fa";
import { RiContactsBook2Line }                      from "react-icons/ri";
import { GiSettingsKnobs }                          from "react-icons/gi";
import { FcViewDetails }                            from "react-icons/fc";
import { MdPersonOff }                              from "react-icons/md";

import { toast }                                    from "react-toastify";
import { Button, Col, Form, Modal, Row,
         Tooltip, OverlayTrigger,
         Dropdown, DropdownButton,
       }                                            from "react-bootstrap";
import   BootstrapTable                             from "react-bootstrap-table-next";
import   filterFactory,
       { textFilter, 
       }                                            from "react-bootstrap-table2-filter";
import   paginationFactory                          from "react-bootstrap-table2-paginator";

import { UserContext }                              from "../../../../App";
import { staggerVariants,
         tileVariants 
       }                                            from "../../../../utils/FmVariants";
import { SVGImages }                                from "../../../../config/images";
import {
         FormButton,
         FormCheckField,
         FormControlField,
         FormLabelText,
         ShowHidePassField,
         SignInForm,
       }                                            from "../../../Auth/SignUp/signUpStyle";

// API SERVICES
import { 
         useInternetListPackagesQuery,
       }                                            from "../../../../services/svcInternetApi";
import { 
         useGetAllCoursesQuery,
       }                                            from "../../../../services/svcLearningApi";
import { 
        useGetAllRemittancesQuery,
      }                                             from "../../../../services/svcRemittanceApi";
import { 
        useGetAllTranscriptsQuery,
      }                                             from "../../../../services/svcTranscriptApi";
import { 
        useGetAllMarketingsQuery,
      }                                             from "../../../../services/svcMarketingApi";
import { 
        useGetAllVehiclePartsQuery, 
        }                                           from "../../../../services/svcVehiclePartsApi";
import {
        useListServicesQuery,
        }                                           from "../../../../services/svcServiceStoreApi";
import {
         useGetAllUsersQuery,
         useCreateMutation,
         useUpdateUsersMutation,
         useUsersDeleteMutation,
        }                                           from "../../../../services/userUserApi";
import { 
         useGetSubscriptionsMutation,
         useUpdateSubscriptionsMutation,
       }                                            from "../../../../services/userUserStoreApi";

/* import moment                                       from "moment"; */ 
/* import ModalBody                                    from "../../../../components/Modal/Modal"; */

const initialFormData = {
    firstName: '',
    lastName: '',
    email: 'Enter',
    password: '',
    phoneNo: '',
    role: 'User'
};

const ManageUser = ({ show, handleClose, page = 1, sizePerPage = 25, lastPageText = ">>", firstPageText = "<<", nextPageText = ">", prePageText = "<" }) => {
    const Source_Name           = "MANAGE - USERS";
    const STG_Min_Password_Length           = 8;
    const STG_passwordRegex                 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    const STG_phoneNumberRegex              = /[^+\d\s()-]/g;
    
    const navigate                          = useNavigate();
    const { user, refetch, setRefetch }     = useContext(UserContext);
    const { data }                          = useGetAllUsersQuery({ source: Source_Name });
    const { data: internetLists }           = useInternetListPackagesQuery({ source: Source_Name });
    const { data: courseLists }             = useGetAllCoursesQuery({ source: Source_Name });
    const { data: remittanceLists }         = useGetAllRemittancesQuery({ source: Source_Name });
    const { data: transcriptLists }         = useGetAllTranscriptsQuery({ source: Source_Name });
    const { data: marketingLists }          = useGetAllMarketingsQuery({ source: Source_Name });
    const { data: vehiclePartLists }        = useGetAllVehiclePartsQuery({ source: Source_Name });
    const { data: Services_List}            = useListServicesQuery({source: Source_Name});
    const isAuthorized                      = ["admin", "power"].includes(user?.role);
    const roles                             = ["Admin", "Affiliate", "Audit", "Billing", "Power", "Support", "User"]; 
    const booleanFormatter                  = cell   => (  <span>   {cell ? "Yes" : "No"}      </span>    );
    const sliceIdFormatter                  = (cell) => (  <span>   {cell?.toString().slice(cell.length - 7)}</span>  );
    const defaultSorted                     = [{    dataField: 'updatedAt', order: 'desc'   }];
    

    /* ***** Mutations ***** */
    const [Auth_UpdateUsers]                = useUpdateUsersMutation();
    const [Auth_UsersDelete]                = useUsersDeleteMutation();
    const [Auth_Create]                     = useCreateMutation();
    const [UserStore_UpdateSubscriptions]   = useUpdateSubscriptionsMutation();


    /* ***** Selection for Buttons ***** */
    const [selectedInternetServiceCode,     setSelectedInternetServiceCode]         = useState('');
    const [selectedLearningServiceCode,     setSelectedLearningServiceCode]         = useState('');
    const [selectedRemittancesServiceCode,  setSelectedRemittancesServiceCode]      = useState('');
    const [selectedTranscriptsServiceCode,  setSelectedTranscriptsServiceCode]      = useState('');
    const [selectedMarketingsServiceCode,   setSelectedMarketingsServiceCode]       = useState('');
    const [selectedVehiclePartsServiceCode, setSelectedVehiclePartsServiceCode]     = useState("VPART-01");

    const [selectedInternetProvider, setSelectedInternetProvider] = useState("");
    const [internetAccountNumber, setInternetAccountNumber] = useState('');

    const [selectedValidatePhone,           setSelectedValidatePhone]               = useState(null);
    const [selectedValidateEmail,           setSelectedValidateEmail]               = useState(null);
    const [selectedInactiveStatus,          setSelectedInactiveStatus]              = useState(null);
    const [selectedTwoFaEmailStatus,        setSelectedTwoFaEmailStatus]            = useState(null);
    const [selectedTwoFaPhoneStatus,        setSelectedTwoFaPhoneStatus]            = useState(null);
    const [selectedTwoFaAuthStatus,         setSelectedTwoFaAuthStatus]             = useState(null);
    const [selectedPassword,                setSelectedPassword]                    = useState("");
    const [selectedUserRole,                setSelectedUserRole]                    = useState("SelectRole");
    const [selectedNewUserRole,             setSelectedNewUserRole]                 = useState("User");
    const [selectedInternetStatus,          setSelectedInternetStatus]              = useState('-?-');
    const [selectedLearningStatus,          setSelectedLearningStatus]              = useState('-?-');
    const [selectedRemittanceStatus,        setSelectedRemittanceStatus]            = useState('-?-');
    const [selectedTranscriptStatus,        setSelectedTranscriptStatus]            = useState('-?-');
    const [selectedMarketingStatus,         setSelectedMarketingStatus]             = useState('-?-');
    const [selectedVehiclePartsStatus,      setSelectedVehiclePartsStatus]          = useState('-?-');

    
    /* ***** Modal Forms FOR SERVICES ***** */
    const [subscribeInternet_Modal,      setSubscribeInternet_Modal]        = useState(false);
    const [subscribeCourse_Modal,        setSubscribeCourse_Modal]          = useState(false);
    const [subscribeRemittance_Modal,    setSubscribeRemittance_Modal]      = useState(false);
    const [subscribeTranscript_Modal,    setSubscribeTranscript_Modal]      = useState(false);
    const [subscribeMarketing_Modal,     setSubscribeMarketing_Modal]       = useState(false);
    const [subscribeAutoParts_Modal,     setSubscribeAutoParts_Modal]       = useState(false);

    /* ***** Modal Forms FOR SETTINGS ***** */
    const [validatedPhone_Modal,         setValidatedPhone_Modal]           = useState(false);
    const [validatedEmail_Modal,         setValidatedEmail_Modal]           = useState(false);
    const [twoFaEmail_Modal,             setTwoFaEmail_Modal]               = useState(false);
    const [twoFaPhone_Modal,             setTwoFaPhone_Modal]               = useState(false);
    const [twoFaAuth_Modal,              setTwoFaAuth_Modal]                = useState(false);
    const [resetPass_Modal,              setResetPass_Modal]                = useState(false);
    const [role_Modal,                   setRole_Modal]                     = useState(false);
    const [inactive_Modal,               setInactive_Modal]                 = useState(false);

    const [delete_Modal,                 setDelete_Modal]                   = useState(false);
    const [create_Modal,                 setCreate_Modal]                   = useState(false);    


    const [error,               setError]                 = useState("");
    const [phone,               setPhone]                 = useState("");
    const [selected,            setSelected]              = useState({});
    const [twoFaEmailUsers,     setTwoFaEmailUsers]       = useState([]);
    const [selectedUsers,       setSelectedUsers]         = useState([]);
    const [subscriptionsToShow, setSubscriptionsToShow]   = useState(null);
    const [showPass,            setShowPass]              = useState(false);
    const [showResetPass,       setShowResetPass]         = useState(false);
    const [subscribe,           setSubscribe]             = useState(false);
    const [error1,              setError1]                = useState(false);
    const [showAdvanced,        setShowAdvanced]          = useState(false);
    const [showFlags,           setShowFlags]             = useState(false);
    const [showContact,         setShowContact]           = useState(false);
    const [formData,            setFormData]              = useState(initialFormData);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const noUsersSelected                                 = selectedUsers.length === 0;

    const getUserDetails = (userId) => {
        return data?.data?.find(user => user._id === userId);
    };
    
    const showSubscriptions = (userSubscriptions) => {
        setSubscriptionsToShow(userSubscriptions);
    };


    /* Section 01.0 - function formatDateTime(dateTimeString) */
    function formatDateTime(dateTimeString) {
        if (!dateTimeString) {  return <span>---</span>;    }
        const date      = new Date(dateTimeString);
    
        const year      = date.getFullYear();
        const month     = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day       = String(date.getDate()).padStart(2, '0');
    
        const hours     = String(date.getHours()).padStart(2, '0');
        const minutes   = String(date.getMinutes()).padStart(2, '0');
        const seconds   = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
    }

    /* Section 02.0 - const useFetchSubscriptions */
    const useFetchSubscriptions = (selectedUsers) => {
        const [subscriptions, setSubscriptions] = useState({});
        const [fetchSubscriptions, { isLoading, error }] = useGetSubscriptionsMutation();

        const fetchAllSubscriptions = useCallback(async () => { // Use useCallback to memoize the function
            if (selectedUsers.length > 0) {
                try {
                    const data = {
                        userIds: selectedUsers, 
                    };
                    const response = await fetchSubscriptions({ data, source: Source_Name }).unwrap();
                    if (response && response.data) {
                        const subs = response.data.reduce((acc, sub) => {
                            acc[sub.userId] = sub.subscriptions;
                            return acc;
                        }, {});
                        setSubscriptions(subs);
                    } else {
                        console.error("Invalid response structure:", response);
                    }
                } catch (error) {
                    console.error("Error fetching subscriptions:", error);
                }
            }
        }, [selectedUsers, fetchSubscriptions]); // Add dependencies here

        useEffect(() => {
            fetchAllSubscriptions();
        }, [selectedUsers, fetchAllSubscriptions]); // Include fetchAllSubscriptions in dependency array

        return { subscriptions, isLoading, error, refetch: fetchAllSubscriptions }; // Return refetch
    };
    // Usage with refetch
    const { subscriptions, isLoading: subsLoading, error: subsError, refetch: fetchSubscriptions } = useFetchSubscriptions(selectedUsers);

    /* Section 03.0 - useEffect - twoFaEmailUsers */
    useEffect(() => {   
        console.log(twoFaEmailUsers);   
    }, [twoFaEmailUsers]);
  
    /* Section 04.0 - useEffect - refetch */
    useEffect(() => {
        const refetchUsers = async () => {
            try {
                await refetch(); // Refetch the user data
            } catch (error) {
                console.error("Error refetching users:", error);
            }
        };
        refetchUsers();
    }, [refetch]);
    
    /* Section 04.5 - useEffect - Services_List */
    useEffect(() => {
        // Check if there's only one provider for the "internet" service
        const internetService = Services_List?.data?.find(service => service.serviceName === 'internet');
        if (internetService?.providers.length === 1) {
            setSelectedInternetProvider(internetService.providers[0].providerName); // Set the default selected provider to the provider's name
        }
    }, [Services_List]);
    


    /* Section 05.0 - const columns - COLUMNS FOR THE MAIN TABLE */
    const columns = [
        {               dataField: "_id",                   text: "Mod",        sort: true,
                                                                                formatter: () => (  <Button>    {" "}   <BiSolidPencil />   </Button>   ),
            events: {   onClick: (e, column, columnIndex, row, rowIndex) => {   navigate(`/dashboard/sudo-profile/${row?._id}`);  },  },
        },
                        { dataField: "firstName",           text: "First",      sort: true, filter: textFilter() },
                        { dataField: "lastName",            text: "Last",       sort: true, filter: textFilter() },
        showContact &&  { dataField: "phoneNo",             text: "Phone:",     sort: true, filter: textFilter() },
        showContact &&  { dataField: "address",             text: "Address",    sort: true, filter: textFilter() },
                        { dataField: "email",               text: "Email",      sort: true, filter: textFilter(), formatter: cell => <span>{cell}</span> },
        showFlags &&    { dataField: "phoneVerified",       text: "PVr",        sort: true, formatter: booleanFormatter },
        showFlags &&    { dataField: "emailVerified",       text: "EVr",        sort: true, formatter: booleanFormatter },
        showFlags &&    { dataField: "twoFaEmailEnabled",   text: "E2F",        sort: true, formatter: booleanFormatter },
        showFlags &&    { dataField: "twoFaAuthEnabled",    text: "A2F",        sort: true, formatter: booleanFormatter },
        showFlags &&    { dataField: "forcePassReset",      text: "PwdRst",     sort: true, formatter: booleanFormatter },
                        { dataField: "role",                text: "Role",       sort: true, filter: textFilter() },
        showAdvanced && { dataField: "inactive",            text: "Inactive",   sort: true, formatter: booleanFormatter },
        showAdvanced && { dataField: "lastLoginDate",       text: "LastLogin",  sort: true, formatter: (cell) => formatDateTime(cell),  headerStyle: {  fontSize: '0.8rem'  },  style: {  fontSize: '0.8rem'  } },
        showAdvanced && { dataField: "_id",                 text: "ID",         sort: true, formatter: sliceIdFormatter,                headerStyle: { fontSize: '0.8rem' }, style: { fontSize: '0.8rem' } },
                        { dataField: "updatedAt",           text: "Updated",    sort: true, formatter: (cell) => formatDateTime(cell),  headerStyle: { fontSize: '0.8rem' }, style: { fontSize: '0.8rem' } },
        showAdvanced && { dataField: "createdAt",           text: "Created",    sort: true, formatter: (cell) => formatDateTime(cell),  headerStyle: {  fontSize: '0.8rem'  },  style: {  fontSize: '0.8rem'  } },
    ].filter(Boolean);

    /* Section 06.0 - const paginationOptions  MAIN TABLE PAGE OPTIONS */
    const paginationOptions = {
        page,                sizePerPage,            showTotal: true,        alwaysShowAllBtns: true,
        lastPageText,     firstPageText,        nextPageText,      prePageText,
        onPageChange:        function (page, sizePerPage) {    console.log("page", page);      console.log("size per page", sizePerPage);  },
        onSizePerPageChange: function (page, sizePerPage) {    console.log("page", page);      console.log("size per page", sizePerPage);  },
    };

    /* Section 07.0 - const handle_InputChange  Handle Change to firstName, lastName, email, password */
    const handle_InputChange = event => {
        const isPasswordInvalid = event.target.name === "password" && !STG_passwordRegex.test(event.target.value);
        setError1(isPasswordInvalid);
        setFormData({ ...formData,  [event.target.name]: event.target.value, });
    };

    /* Section 08.0 - const handle_PhoneNoChange */
    const handle_PhoneNoChange = event => {
        const inputValue = event.target.value;
        const sanitizedValue = inputValue.replace(STG_phoneNumberRegex, "");
        console.log(sanitizedValue);
        setPhone(sanitizedValue.length > 0 ? sanitizedValue : "");
    };

    /* Section 09.0 - const handle_CreateNewUser */
    const handle_CreateNewUser = () => {
        if (formData.email && formData.firstName && formData.password && formData.lastName) {
            const token             = localStorage.getItem("token");
            const data = {
                firstName:      formData.firstName,
                lastName:       formData.lastName,
                email:          formData.email,
                role:           selectedNewUserRole,
                password:       formData.password,
                phoneNo:        formData.phoneNo,
                address:        formData.address,
                emailVerified:  true,
                referrerId:     'sa0000001',
                subscribe:      subscribe,
                token:          token,
            };
            if (data && STG_passwordRegex.test(formData.password)) {
                Auth_Create({data, source: Source_Name}).then(res => {
                    if (res?.data?.status) {
                        setError("");
                        setCreate_Modal(false);
                        toast.success("User created Successfully");
                    } else {
                        setError(res?.data?.message);
                        setError1(false);
                    }
                });
            } else {
                setError1(!STG_passwordRegex.test(formData.password));
                setError("");
            }            
        } else {
            setError("Please fill out the required information!");
            setError1(false);
        }
    };

    /* Section 10.0 - const handle_CreateCancel */
    const handle_CreateCancel = () => {
        setFormData({
            ...formData,
            email: '',
            password: ''
        });
        setCreate_Modal(false);
    };

    /* Section 11.0 - const handle_DeleteUser */
    const handle_DeleteUser = async () => {
        try {
            const res = await Auth_UsersDelete(selectedUsers);
            console.log("RES", res);
            if (res?.data?.status) {
                toast.success(res.data.message);
                setSelectedUsers([]); // Reset selectedUsers after delete
                setRefetch(refetch + 1); // Trigger refetch to update the data
            } else {
                toast.error(res.data.message);
            }
            setDelete_Modal(false);
        } catch (error) {
            toast.error("STATEMENT");
            toast.error(error);
        }
    };
    
    /* Section 12.0 - const handle_OpenCreateModal */
    const handle_OpenCreateModal = () => {
        setFormData({
            ...formData,
            email: "Enter",
        });
        
        setCreate_Modal(true);
    };    
    
    /* Section 13.0 - const handle_UpdUsers */
    const handle_UpdUsers = (Full_Data, setModal, setSelectedState) => {
        /* 
        [Log]  {ids: ["6678468c7ae0cc4149ffd2e7"], data: {role: "billing"}} 
      */
     console.log("DATA:", Full_Data);
        Auth_UpdateUsers(Full_Data).then(res => {
            if (res?.data?.status) {
                setRefetch(refetch + 1);
                toast.success(res?.data?.message);
                if (setSelectedState) {
                    setSelectedState(true);
                }
            } else {
                toast.error(res?.data?.message);
            }
            setModal(false);
        });
    };

    /* Section 14.0 - const handle_RowSelect */
    const handle_RowSelect = (row, isSelect, rowIndex, e) => {
        const twoFaEmailData = row.twoFaEmail ? row.twoFaEmail : row.email;

        const updateSelectedUsers   = include => {    setSelectedUsers(  prev => (include ? [...prev, row._id] : prev.filter(id => id !== row._id)));        };
    
        const updateTwoFaEmailUsers = (include) => {  setTwoFaEmailUsers(prev => include ? [...prev, { id: row._id, data: { twoFaEmail: twoFaEmailData } }]       : prev.filter(user => user.id !== row._id)  );  };

        const userIsSelected = selectedUsers.includes(row._id);
        if (isSelect && !userIsSelected) {
            updateSelectedUsers(true);              updateTwoFaEmailUsers(true);
        } else {
            updateSelectedUsers(false);             updateTwoFaEmailUsers(false);
        }
        
        
    };
    const selectRow = { mode: "checkbox",        clickToSelect: true,        onSelect: handle_RowSelect,    };

    /* Section 15.0 - const handle_ActionClick */
    const handle_ActionClick = useCallback(
        (modalSetter) => {
        if (isAuthorized && !noUsersSelected) {
            modalSetter(true);
            setSelected(data);
        } else {
            toast.error("Please select at least one User!");
        }
        },
        [isAuthorized, noUsersSelected, setSelected, data]
    );

    /* Section 16.0 - const handle_DeleteClick */
    const handle_DeleteClick = useCallback(() => {
        if (isAuthorized && !noUsersSelected) {
        setDelete_Modal(true);
        setSelected(data);
        } else {
        toast.error("Please select at least one User!");
        }
    }, [isAuthorized, noUsersSelected, setDelete_Modal, setSelected, data]);

    /* Section 17.0 - const handle_UpdSubs */
    const handle_UpdSubs = (Full_Data, setModal) => {
        /* 
        [Log]  {ids: ["6678468c7ae0cc4149ffd2e7"], data: {role: "billing"}} 
      */
     console.log("DATA:", Full_Data);
     UserStore_UpdateSubscriptions(Full_Data).then(res => {
            if (res?.data?.status) {
                setRefetch(refetch + 1);
                toast.success(res?.data?.message);
            } else {
                toast.error(res?.data?.message);
            }
            setModal(false);
        });
    };

    /* Section 18.0 - const actions - Array of actions for reusability and scalability */
    const actions = [
        { label: <TbPasswordUser />,            modalSetter: setResetPass_Modal },           // * Reset Password 
        { label: <ImProfile />,                 modalSetter: setRole_Modal }                 // * Assign Role 
        // { label: 'Advanced',        modalSetter: setAdvancedModal }
    ];

    const handleAction = (actionType) => {
        switch (actionType) {
            case "MKT":                setSubscribeMarketing_Modal(true);                break;
            case "Int":                setSubscribeInternet_Modal(true);                 break;
            case "Crs":                setSubscribeCourse_Modal(true);                   break;
            case "Prt":                setSubscribeAutoParts_Modal(true);                break;
            case "Rmt":                setSubscribeRemittance_Modal(true);               break;
            case "Tcr":                setSubscribeTranscript_Modal(true);               break;
            case "validateEmail":      setValidatedEmail_Modal(true);                    break;
            case "validatePhone":      setValidatedPhone_Modal(true);                    break;
            case "twoFaAuth":          setTwoFaAuth_Modal(true);                         break;
            case "twoFaEmail":         setTwoFaEmail_Modal(true);                        break;
            case "twoFaPhone":         setTwoFaPhone_Modal(true);                        break;
            case "inactive":           setInactive_Modal(true);                          break;
            default:                   break;
        }
    };


    const createAuditAction = (actionDescription) => ({
        agentId: user?._id,
        actionDescription,
        timestamp: new Date()
    });
    
    
    const handleSubscribe = (serviceType, serviceCode, serviceStatus, setModalFunction, resetServiceCodeFunction, selectedInternetProvider, internetAccountNumber) => {
        handle_UpdSubs(  { 
            ids: selectedUsers, 
            data: { 
                subscriptions: serviceType, serviceStatus, serviceCode, providerName: selectedInternetProvider, providerAccNb: internetAccountNumber,
                auditActions: createAuditAction(`Updated subscription serviceStatus to ${serviceStatus}`)
            },
            source: Source_Name,  
        },  setModalFunction ); 

        resetServiceCodeFunction('');
        fetchSubscriptions(); 
    };

                                               
    

    const handle_ValidatePhone  = () => {     handle_UpdUsers(  { ids: selectedUsers, data: { phoneVerified:     selectedValidatePhone },    task: "phoneVerified"      },  setValidatedPhone_Modal,    setSelectedValidatePhone     ); };
    const handle_ValidateEmail  = () => {     handle_UpdUsers(  { ids: selectedUsers, data: { emailVerified:     selectedValidateEmail },    task: "emailVerified"      },  setValidatedEmail_Modal,    setSelectedValidateEmail     ); };
    const handle_Inactive       = () => {     handle_UpdUsers(  { ids: selectedUsers, data: { inactive:          selectedInactiveStatus },   task: "inactiveEnabled"    },  setInactive_Modal,          setSelectedInactiveStatus    ); };
    const handle_TwoFaAuth      = () => {     handle_UpdUsers(  { ids: selectedUsers, data: { twoFaAuthEnabled:  selectedTwoFaAuthStatus },  task: "twoFaAuthEnabled"   },  setTwoFaAuth_Modal,         setSelectedTwoFaAuthStatus   ); };
    const handle_TwoFaEmail     = () => {     handle_UpdUsers(  { ids: selectedUsers, data: { twoFaEmailEnabled: selectedTwoFaEmailStatus }, task: "twoFaEmailEnabled"  },  setTwoFaEmail_Modal,        setSelectedTwoFaEmailStatus  ); };
    const handle_TwoFaPhone     = () => {     handle_UpdUsers(  { ids: selectedUsers, data: { twoFaPhoneEnabled: selectedTwoFaPhoneStatus }, task: "twoFaPhoneEnabled"  },  setTwoFaPhone_Modal,        setSelectedTwoFaPhoneStatus  ); };
    const handle_PassReset      = () => {     handle_UpdUsers(  { ids: selectedUsers, data: { password: selectedPassword, forcePassReset: true }, task: "resetPass"     },  setResetPass_Modal,         setSelectedPassword          ); };
    const handle_Role           = (role) => { handle_UpdUsers(  { ids: selectedUsers, data: { role:              role },                     task: "role"               },  setRole_Modal,              setSelectedUserRole          ); };
    
    const handle_Phone_SelectChange         = (e) => { const value = e.target.value;   setSelectedValidatePhone(       value === 'SelectStatus' ? null : value === 'true');   };
    const handle_Email_SelectChange         = (e) => { const value = e.target.value;   setSelectedValidateEmail(       value === 'SelectStatus' ? null : value === 'true');   };
    const handle_Inactive_SelectChange      = (e) => { const value = e.target.value;   setSelectedInactiveStatus(      value === 'SelectStatus' ? null : value === 'true');   };
    const handle_TwoFaAuth_SelectChange     = (e) => { const value = e.target.value;   setSelectedTwoFaAuthStatus(     value === 'SelectStatus' ? null : value === 'true');   };
    const handle_TwoFaEmail_SelectChange    = (e) => { const value = e.target.value;   setSelectedTwoFaEmailStatus(    value === 'SelectStatus' ? null : value === 'true');   };
    const handle_TwoFaPhone_SelectChange    = (e) => { const value = e.target.value;   setSelectedTwoFaPhoneStatus(    value === 'SelectStatus' ? null : value === 'true');   };

    

    return (
        <div>
            {/* Buttons Row  */}
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-2 mb-3">
                <h4>Manage Users ({data?.data?.length || 0})</h4>

                <> {/* 4 Buttons Row ============================ */}
                    {/* User Create */}
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Add User</Tooltip>}       >
                        <Button onClick={handle_OpenCreateModal} className="btn btn-primary"><FaUserPlus /></Button>
                    </OverlayTrigger>

                    {/* User Contact Info */}
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">User Contact Info</Tooltip>}       >
                        <Button onClick={() => setShowContact(!showContact)}>
                            {showContact ? ( <span><FaRegEyeSlash /><RiContactsBook2Line /></span> )
                                        : (<span><FaRegEye /><RiContactsBook2Line /></span> )  }
                        </Button>
                    </OverlayTrigger>

                    {/* User Flags */}
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">User Flags</Tooltip>}       >
                        <Button onClick={() => setShowFlags(!showFlags)}>
                            {showFlags ? ( <span><FaRegEyeSlash /><GiSettingsKnobs /></span> )
                                        : (<span><FaRegEye /><GiSettingsKnobs /></span> )  }
                        </Button>
                    </OverlayTrigger>

                    {/* Advanced Details */}
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Advanced Details</Tooltip>}       >
                        <Button onClick={() => setShowAdvanced(!showAdvanced)}>
                            {showAdvanced ? ( <span><FaRegEyeSlash /><FcViewDetails /></span> )
                                        : (<span><FaRegEye /><FcViewDetails /></span> )  }
                        </Button> 
                    </OverlayTrigger>
                </>             

                <div className="d-flex align-items-center justify-content-md-center justify-content-sm-start flex-wrap gx-4">
                    {/* Reset Password  &  Assign Role */}
                    {actions.map((action, index) => (
                        <span   key={index} onClick={() => handle_ActionClick(action.modalSetter)}   
                                className={`action-icon ${isAuthorized && !noUsersSelected ? '' : 'disabled'}`} >
                            <span className="ms-2">{action.label}</span>
                        </span>
                    ))}

                    {/* Services Dropdown */}
                    <DropdownButton id="dropdown-basic-button" title="Services" variant="primary" className="me-2">
                        <Dropdown.Item onClick={() => handleAction("MKT")}>MKT</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("Int")}>Int</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("Crs")}>Crs</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("Prt")}>Prt</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("Rmt")}>Rmt</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("Tcr")}>Tcr</Dropdown.Item>
                    </DropdownButton>

                    {/* Status Dropdown */}
                    <DropdownButton id="dropdown-status" title="Status" variant="primary" className="me-2">
                        <Dropdown.Item onClick={() => handleAction("validateEmail")}><MdOutlineMarkEmailRead /> Verify Email</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("validatePhone")}><LiaPhoneVolumeSolid /> Verify Phone</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("twoFaAuth")}><SiAuthentik /> Authenticator Status</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("twoFaEmail")}><TbMailCode /> Email 2FA Status</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("twoFaPhone")}><LiaPhoneVolumeSolid /> Phone 2FA Status</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAction("inactive")}><MdPersonOff /> Inactive Status</Dropdown.Item>
                    </DropdownButton>

                    {/* Delete User */}
                    <div className="d-flex align-items-center justify-content-center gx-4">
                        <span               onClick={handle_DeleteClick}                             className={`action-icon delete ${isAuthorized && !noUsersSelected ? "" : "disabled"}`} >
                        <MdDelete />
                        </span>
                    </div>
                </div>
            </div>


            {/* table BootStrap configuration */}
            {data?.data?.length > 0 && (
                <div className="table-responsive">
                    <BootstrapTable
                        bootstrap4
                        keyField="_id"
                        data={data?.data}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        striped
                        hover
                        condensed
                        responsive
                        scrollable
                        pagination={paginationFactory(paginationOptions)}
                        selectRow={selectRow}
                        noDataIndication="No data found!"
                        filter={filterFactory()}
                        filterPosition="top"
                    />
                </div>
            )}

            <Modal show={subscriptionsToShow !== null} onHide={() => setSubscriptionsToShow(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>User Subscriptions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(subscriptionsToShow, null, 2)}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSubscriptionsToShow(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* SHOW DELETE CONFIRMATION Modal *******************************************/}
            <Modal 
                show={showDeleteConfirmation} 
                onHide={() => setShowDeleteConfirmation(false)} 
                backdrop="static" 
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this subscription? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button 
                        variant="danger" 
                        onClick={() => {
                            handleSubscribe(
                                "internet", 
                                selectedInternetServiceCode, 
                                selectedInternetStatus, 
                                setSubscribeInternet_Modal, 
                                setSelectedInternetServiceCode,
                                selectedInternetProvider,
                                internetAccountNumber,
                            );
                            setShowDeleteConfirmation(false);
                        }}
                    >
                        Confirm Deletion
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* SUBSCRIBE INTERNET Modal *******************************************/}
            <Modal show={subscribeInternet_Modal} onHide={() => setSubscribeInternet_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>      <Modal.Title>Internet Subscription #users:  ({selectedUsers.length})</Modal.Title>        </Modal.Header>
                <Modal.Body>
                    {/* List of Users and their Subscriptions */}
                    {subsLoading ? (
                        <p>Loading subscriptions...</p>
                    ) : subsError ? (
                        <p>Error loading subscriptions: {subsError.message}</p>
                    ) : (
                        <>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <strong style={{ color: 'blue' }}>Internet Provider</strong><br/>
                                    <select 
                                        id="providerDropdown" 
                                        value={selectedInternetProvider} 
                                        onChange={(e) => setSelectedInternetProvider(e.target.value)}
                                        disabled={Services_List?.data?.filter(service => service.serviceName === 'internet')[0]?.providers.length === 1} // Disable if only one provider for 'internet'
                                    >
                                        {Services_List?.data?.filter(service => service.serviceName === 'internet')[0]?.providers.length === 1 ? (
                                            <option value={Services_List.data.filter(service => service.serviceName === 'internet')[0].providers[0].providerName}>
                                                {Services_List.data.filter(service => service.serviceName === 'internet')[0].providers[0].providerName}
                                            </option>
                                        ) : (
                                            <>
                                                <option value="" disabled>Select a Provider</option>
                                                {Services_List?.data?.filter(service => service.serviceName === 'internet').map(service =>
                                                    service.providers.map(provider => (
                                                        <option key={provider.providerName} value={provider.providerName}>
                                                            {provider.providerName}
                                                        </option>
                                                    ))
                                                )}
                                            </>
                                        )}
                                    </select>
                                </div>
                                {selectedInternetStatus !== 'requested' && (
                                    <div>
                                        <strong style={{ color: 'blue' }}>Account Nb:</strong><br/>
                                        <input 
                                            type="text" 
                                            id="internetAccountNumber" 
                                            placeholder="Account Number" 
                                            value={internetAccountNumber} 
                                            onChange={(e) => setInternetAccountNumber(e.target.value)} 
                                        />
                                    </div>
                                )}
                            </div>

                            <div style={{ height: '18px' }}></div>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <strong style={{ color: 'blue' }}>Internet Package</strong><br/>
                                    <select onChange={(e) => setSelectedInternetServiceCode(e.target.value)}>
                                        <option value="">Select Package</option>
                                        {internetLists && [...internetLists.data]  // Create a shallow copy of the array
                                            .sort((a, b) => a.serviceCode.localeCompare(b.serviceCode))  // Sort the copied array
                                            .map((internet, idx) => (
                                                <option key={idx} value={internet.serviceCode}>
                                                    {internet.serviceCode}/{internet.uploadSpeed} -- ( {internet.price}$ )
                                                </option>
                                            ))}
                                    </select>


                                </div>
                                <div>
                                    <strong style={{ color: 'blue' }}>&nbsp; Status</strong> <br/>
                                    <select onChange={(e) => setSelectedInternetStatus(e.target.value)} style={{ marginLeft: '10px' }}>
                                        <option value="active">-?-</option>
                                        <option value="active">active</option>
                                        <option value="inactive">inactive</option>
                                        <option value="requested">requested</option>
                                        <option value="suspended">suspended</option>
                                        <option value="delete">delete</option>
                                    </select>
                                </div>
                            </div>

                            <div style={{ height: '18px' }}></div>
                            
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {selectedUsers.map((userId) => {
                                    const user = getUserDetails(userId);
                                    const userSubscriptions = subscriptions[userId] || [];

                                    return (
                                        <li key={userId}>
                                            <div>
                                                <button onClick={() => showSubscriptions(userSubscriptions)} style={{ marginRight: '5px' }}><FaRegEye /> </button>
                                                {Object.keys(userSubscriptions).length > 0 ? (
                                                    (() => {
                                                        const internetServiceCodes = [];

                                                        Object.keys(userSubscriptions).forEach((serviceType) => {
                                                            const serviceDetails = userSubscriptions[serviceType];
                                                            if (Array.isArray(serviceDetails) && serviceType === 'internet' && serviceDetails.some(detail => detail.serviceCode)) {
                                                                const serviceCodes = serviceDetails.filter(detail => detail.serviceCode).map(detail => detail.serviceCode);
                                                                internetServiceCodes.push(...serviceCodes);
                                                            };
                                                        });

                                                        // Sort service codes in ascending order
                                                        internetServiceCodes.sort();

                                                        return (
                                                            <span>
                                                                <strong>{user.firstName} </strong>: 
                                                                {internetServiceCodes.length > 0 
                                                                    ? internetServiceCodes.map((serviceCode, index) => {
                                                                        const serviceStatus = userSubscriptions.internet.find(detail => detail.serviceCode === serviceCode)?.serviceStatus || "N/A";
                                                                        return (
                                                                            <span key={index}>
                                                                                {serviceCode} <small>({serviceStatus})</small>{index < internetServiceCodes.length - 1 && ', '}
                                                                            </span>
                                                                        );
                                                                    })
                                                                    : ''}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>  <strong>{user.firstName} </strong>  </span>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>




                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="success-button" variant="danger" onClick={() => setSubscribeInternet_Modal(false)}>Cancel</Button>
                    <Button 
                        className="danger-btn" 
                        onClick={() => {
                            if (selectedInternetStatus === 'delete') {
                                setShowDeleteConfirmation(true);
                            } else {
                                handleSubscribe(
                                    "internet", 
                                    selectedInternetServiceCode, 
                                    selectedInternetStatus, 
                                    setSubscribeInternet_Modal, 
                                    setSelectedInternetServiceCode,
                                    selectedInternetProvider,
                                    internetAccountNumber,
                                );
                            }
                        }}
                        disabled={selectedInternetServiceCode === '' || selectedInternetStatus === '-?-' || selectedUsers === null}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* SUBSCRIBE INTERNET Modal ************************* */}


            {/* SUBSCRIBE VEHICLE PARTS Modal *******************************************/}
            <Modal show={subscribeAutoParts_Modal} onHide={() => setSubscribeAutoParts_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>              <Modal.Title>Vehicle Parts Subscription #users: ({selectedUsers.length})</Modal.Title>        </Modal.Header>
                <Modal.Body>
                    {/* List of Users and their Subscriptions */}
                    {subsLoading ? (
                        <p>Loading subscriptions...</p>
                    ) : subsError ? (
                        <p>Error loading subscriptions: {subsError.message}</p>
                    ) : (
                        <>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {selectedUsers.map((userId) => {
                                    const user = getUserDetails(userId);
                                    const userSubscriptions = subscriptions[userId] || [];

                                    return (
                                        <li key={userId}>
                                            <div>
                                                <button onClick={() => showSubscriptions(userSubscriptions)}><FaRegEye /> </button>
                                                {Object.keys(userSubscriptions).length > 0 ? (
                                                    (() => {
                                                        const VehiclePartsServiceCodes = [];
                                    
                                                        Object.keys(userSubscriptions).forEach((serviceType) => {
                                                            const serviceDetails = userSubscriptions[serviceType];
                                                            if (Array.isArray(serviceDetails) && serviceType === 'vehicleParts' && serviceDetails.some(detail => detail.serviceCode)) {
                                                                const serviceCodes = serviceDetails.filter(detail => detail.serviceCode).map(detail => detail.serviceCode);
                                                                VehiclePartsServiceCodes.push(...serviceCodes);
                                                            };
                                                        });
                                    
                                                        return (
                                                            <span>
                                                                <strong>{user.firstName} </strong>: {VehiclePartsServiceCodes.length > 0 ? VehiclePartsServiceCodes.join(', ') : ''}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>  <strong>{user.firstName} </strong>  </span>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <strong>AutoParts Package to Assign</strong><br/>
                                    <select onChange={(e) => setSelectedVehiclePartsServiceCode(e.target.value)}>
                                        <option value="">Select Package</option>
                                        {vehiclePartLists && vehiclePartLists.data.map((vehicleParts, idx) => (
                                            <option key={idx} value={vehicleParts.serviceCode}>
                                                Svc: {vehicleParts.serviceCode} -- ( {vehicleParts.price}$ ) -- {vehicleParts.description}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <strong>&nbsp; Status</strong> <br/>
                                    <select onChange={(e) => setSelectedVehiclePartsStatus(e.target.value)} style={{ marginLeft: '10px' }}>
                                        <option value="active">-?-</option>
                                        <option value="active">active</option>
                                        <option value="inactive">inactive</option>
                                        <option value="requested">requested</option>
                                        <option value="suspended">suspended</option>
                                        <option value="delete">delete</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setSubscribeAutoParts_Modal(false)}>Cancel</Button>
                    <Button className="success-button" onClick={() => handleSubscribe("vehicleParts", selectedVehiclePartsServiceCode, selectedVehiclePartsStatus, setSubscribeAutoParts_Modal, setSelectedVehiclePartsServiceCode)} 
                                                        disabled={selectedVehiclePartsServiceCode === '' || selectedVehiclePartsStatus === '-?-' || selectedUsers === null}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            {/* SUBSCRIBE VEHICLE PARTS Modal ************************* */}



            {/* SUBSCRIBE LEARNING Modal *******************************************/}
            <Modal show={subscribeCourse_Modal} onHide={() => setSubscribeCourse_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Course Subscription #users: ({selectedUsers.length})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* List of Users and their Subscriptions */}
                    {subsLoading ? (
                        <p>Loading subscriptions...</p>
                    ) : subsError ? (
                        <p>Error loading subscriptions: {subsError.message}</p>
                    ) : (
                        <>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {selectedUsers.map((userId) => {
                                    const user = getUserDetails(userId);
                                    const userSubscriptions = subscriptions[userId] || [];
                                    
                                    return (
                                        <li key={userId}>
                                            <div>
                                                <button onClick={() => showSubscriptions(userSubscriptions)}><FaRegEye /> </button>
                                                {Object.keys(userSubscriptions).length > 0 ? (
                                                    (() => {
                                                        const coursesServiceCodes = [];
                                                        Object.keys(userSubscriptions).forEach((serviceType) => {
                                                            const serviceDetails = userSubscriptions[serviceType];
                                                            if (Array.isArray(serviceDetails) && serviceType === 'courses' && serviceDetails.some(detail => detail.serviceCode)) {
                                                                const serviceCodes = serviceDetails.filter(detail => detail.serviceCode).map(detail => detail.serviceCode);
                                                                coursesServiceCodes.push(...serviceCodes);
                                                            };
                                                        });
                                                        return (
                                                            <span>
                                                                <strong>{user.firstName} </strong>: {coursesServiceCodes.length > 0 ? coursesServiceCodes.join(', ') : ''}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>  <strong>{user.firstName} </strong>  </span>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <strong>Course Package to Assign</strong><br/>
                                    <select onChange={(e) => setSelectedLearningServiceCode(e.target.value)}>
                                        <option value="">Select Package</option>
                                        {courseLists && courseLists.data.map((course, idx) => (
                                            <option key={idx} value={course.serviceCode}>
                                                Code: {course.serviceCode} -- {course.courseName} -- ( {course.price}$ )
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <strong>&nbsp; Status</strong> <br/>
                                    <select onChange={(e) => setSelectedLearningStatus(e.target.value)} style={{ marginLeft: '10px' }}>
                                        <option value="-?-">-?-</option>
                                        <option value="active">active</option>
                                        <option value="inactive">inactive</option>
                                        <option value="requested">requested</option>
                                        <option value="suspended">suspended</option>
                                        <option value="delete">delete</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setSubscribeCourse_Modal(false)}>Cancel</Button>
                    <Button className="success-button" onClick={() => handleSubscribe("learning", selectedLearningServiceCode, selectedLearningStatus, setSubscribeCourse_Modal, setSelectedLearningServiceCode)}
                                                            disabled={selectedLearningServiceCode === '' || selectedLearningStatus === '-?-' || selectedUsers === null}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            {/* SUBSCRIBE LEARNING Modal ************************* */}



            {/* SUBSCRIBE REMITTANCE Modal *******************************************/}
            <Modal show={subscribeRemittance_Modal} onHide={() => setSubscribeRemittance_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Remittance Subscription #users: ({selectedUsers.length})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* List of Users and their Subscriptions */}
                    {subsLoading ? (
                        <p>Loading subscriptions...</p>
                    ) : subsError ? (
                        <p>Error loading subscriptions: {subsError.message}</p>
                    ) : (
                        <>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {selectedUsers.map((userId) => {
                                    const user = getUserDetails(userId);
                                    const userSubscriptions = subscriptions[userId] || [];
                                    
                                    return (
                                        <li key={userId}>
                                            <div>
                                                <button onClick={() => showSubscriptions(userSubscriptions)}><FaRegEye /> </button>
                                                {Object.keys(userSubscriptions).length > 0 ? (
                                                    (() => {
                                                        const remittanceServiceCodes = [];
                                                        Object.keys(userSubscriptions).forEach((serviceType) => {
                                                            const serviceDetails = userSubscriptions[serviceType];
                                                            if (Array.isArray(serviceDetails) && serviceType === 'remittances' && serviceDetails.some(detail => detail.serviceCode)) {
                                                                const serviceCodes = serviceDetails.filter(detail => detail.serviceCode).map(detail => detail.serviceCode);
                                                                remittanceServiceCodes.push(...serviceCodes);
                                                            };
                                                        });
                                                        return (
                                                            <span>
                                                                <strong>{user.firstName} </strong>: {remittanceServiceCodes.length > 0 ? remittanceServiceCodes.join(', ') : ''}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>  <strong>{user.firstName} </strong>  </span>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <strong>Remittance Package to Assign</strong><br/>
                                    <select onChange={(e) => setSelectedRemittancesServiceCode(e.target.value)}>
                                        <option value="">Select Package</option>
                                        {remittanceLists && remittanceLists.data.map((remittance, idx) => (
                                            <option key={idx} value={remittance.serviceCode}>
                                                Code: {remittance.serviceCode} -- {remittance.remittanceName} -- ( {remittance.price}$ )
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <strong>&nbsp; Status</strong> <br/>
                                    <select onChange={(e) => setSelectedRemittanceStatus(e.target.value)} style={{ marginLeft: '10px' }}>
                                        <option value="-?-">-?-</option>
                                        <option value="active">active</option>
                                        <option value="inactive">inactive</option>
                                        <option value="requested">requested</option>
                                        <option value="suspended">suspended</option>
                                        <option value="delete">delete</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setSubscribeRemittance_Modal(false)}>Cancel</Button>
                    <Button className="success-button" onClick={() => handleSubscribe("remittances", selectedRemittancesServiceCode, selectedRemittanceStatus, setSubscribeRemittance_Modal, setSelectedRemittancesServiceCode)}
                                                        disabled={selectedRemittancesServiceCode === '' || selectedRemittanceStatus === '-?-' || selectedUsers === null}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            {/* SUBSCRIBE REMITTANCE Modal ************************* */}


            {/* SUBSCRIBE TRANSCRIPT Modal *******************************************/}
            <Modal show={subscribeTranscript_Modal} onHide={() => setSubscribeTranscript_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Transcript Subscription #users: ({selectedUsers.length})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* List of Users and their Subscriptions */}
                    {subsLoading ? (
                        <p>Loading subscriptions...</p>
                    ) : subsError ? (
                        <p>Error loading subscriptions: {subsError.message}</p>
                    ) : (
                        <>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {selectedUsers.map((userId) => {
                                    const user = getUserDetails(userId);
                                    const userSubscriptions = subscriptions[userId] || [];
                                    
                                    return (
                                        <li key={userId}>
                                            <div>
                                                <button onClick={() => showSubscriptions(userSubscriptions)}><FaRegEye /> </button>
                                                {Object.keys(userSubscriptions).length > 0 ? (
                                                    (() => {
                                                        const transcriptServiceCodes = [];
                                                        Object.keys(userSubscriptions).forEach((serviceType) => {
                                                            const serviceDetails = userSubscriptions[serviceType];
                                                            if (Array.isArray(serviceDetails) && serviceType === 'transcripts' && serviceDetails.some(detail => detail.serviceCode)) {
                                                                const serviceCodes = serviceDetails.filter(detail => detail.serviceCode).map(detail => detail.serviceCode);
                                                                transcriptServiceCodes.push(...serviceCodes);
                                                            };
                                                        });
                                                        return (
                                                            <span>
                                                                <strong>{user.firstName} </strong>: {transcriptServiceCodes.length > 0 ? transcriptServiceCodes.join(', ') : ''}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>  <strong>{user.firstName} </strong>  </span>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <strong>Transcript Package to Assign</strong><br/>
                                    <select onChange={(e) => setSelectedTranscriptsServiceCode(e.target.value)}>
                                        <option value="">Select Package</option>
                                        {transcriptLists && transcriptLists.data.map((transcript, idx) => (
                                            <option key={idx} value={transcript.serviceCode}>
                                                Code: {transcript.serviceCode} -- {transcript.transcriptName} -- ( {transcript.price}$ )
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <strong>&nbsp; Status</strong> <br/>
                                    <select onChange={(e) => setSelectedTranscriptStatus(e.target.value)} style={{ marginLeft: '10px' }}>
                                        <option value="-?-">-?-</option>
                                        <option value="active">active</option>
                                        <option value="inactive">inactive</option>
                                        <option value="requested">requested</option>
                                        <option value="suspended">suspended</option>
                                        <option value="delete">delete</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setSubscribeTranscript_Modal(false)}>Cancel</Button>
                    <Button className="success-button" onClick={() => handleSubscribe("transcripts", selectedTranscriptsServiceCode, selectedTranscriptStatus, setSubscribeTranscript_Modal, setSelectedTranscriptsServiceCode)}
                                                        disabled={selectedTranscriptsServiceCode === '' || selectedTranscriptStatus === '-?-' || selectedUsers === null}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            {/* SUBSCRIBE TRANSCRIPT Modal ************************* */}


            {/* SUBSCRIBE MARKETING Modal *******************************************/}
            <Modal show={subscribeMarketing_Modal} onHide={() => setSubscribeMarketing_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Marketing Subscription #users: ({selectedUsers.length})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* List of Users and their Subscriptions */}
                    {subsLoading ? (
                        <p>Loading subscriptions...</p>
                    ) : subsError ? (
                        <p>Error loading subscriptions: {subsError.message}</p>
                    ) : (
                        <>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {selectedUsers.map((userId) => {
                                    const user = getUserDetails(userId);
                                    const userSubscriptions = subscriptions[userId] || [];
                                    
                                    return (
                                        <li key={userId}>
                                            <div>
                                                <button onClick={() => showSubscriptions(userSubscriptions)}><FaRegEye /> </button>
                                                {Object.keys(userSubscriptions).length > 0 ? (
                                                    (() => {
                                                        const marketingServiceCodes = [];
                                                        Object.keys(userSubscriptions).forEach((serviceType) => {
                                                            const serviceDetails = userSubscriptions[serviceType];
                                                            if (Array.isArray(serviceDetails) && serviceType === 'marketings' && serviceDetails.some(detail => detail.serviceCode)) {
                                                                const serviceCodes = serviceDetails.filter(detail => detail.serviceCode).map(detail => detail.serviceCode);
                                                                marketingServiceCodes.push(...serviceCodes);
                                                            };
                                                        });
                                                        return (
                                                            <span>
                                                                <strong>{user.firstName} </strong>: {marketingServiceCodes.length > 0 ? marketingServiceCodes.join(', ') : ''}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>  <strong>{user.firstName} </strong>  </span>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <strong>Marketing Package to Assign</strong><br/>
                                    <select onChange={(e) => setSelectedMarketingsServiceCode(e.target.value)}>
                                        <option value="">Select Package</option>
                                        {marketingLists && marketingLists.data.map((marketing, idx) => (
                                            <option key={idx} value={marketing.serviceCode}>
                                                Code: {marketing.serviceCode} -- {marketing.marketingName} -- ( {marketing.price}$ )
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <strong>&nbsp; Status</strong> <br/>
                                    <select onChange={(e) => setSelectedMarketingStatus(e.target.value)} style={{ marginLeft: '10px' }}>
                                        <option value="-?-">-?-</option>
                                        <option value="active">active</option>
                                        <option value="inactive">inactive</option>
                                        <option value="requested">requested</option>
                                        <option value="suspended">suspended</option>
                                        <option value="delete">delete</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setSubscribeMarketing_Modal(false)}>Cancel</Button>
                    <Button className="success-button" onClick={() => handleSubscribe("marketings", selectedMarketingsServiceCode, selectedMarketingStatus, setSubscribeMarketing_Modal, setSelectedMarketingsServiceCode)}
                                                        disabled={selectedMarketingsServiceCode === '' || selectedMarketingStatus === '-?-' || selectedUsers === null}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            {/* SUBSCRIBE MARKETING Modal ************************* */}


            {/* <ModalBody show={show} handleClose={handleClose} /> */}
            {/* ROLE Update *******************************************/}
            <Modal show={role_Modal} onHide={() => setRole_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>       <Modal.Title>Assign Role for these users? ({selectedUsers.length})</Modal.Title>     </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ] - ${user.role}` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>  
                    <div className="modal-form">
                        <Form.Select    className="shadow-none"     aria-label="Select user role"       value={selectedUserRole}    onChange={e => setSelectedUserRole(e.target.value)} >
                            <option value="SelectRole">Select a Role</option>
                            <option value="user">     User</option>
                            <option value="admin">    Admin</option>
                            <option value="billing">  Billing</option>
                            <option value="affiliate">Affiliate</option>
                            <option value="support">  Support</option>
                            <option value="audit">    Audit</option>
                            <option value="power">    Power</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setRole_Modal(false)}>                        Close           </Button>
                    <Button className="success-button" disabled={selectedUserRole === 'SelectRole'} onClick={() => handle_Role(selectedUserRole)} variant="success">   Update  </Button>
                </Modal.Footer>
            </Modal>        {/* update role ************************* */}



            {/* DELETE modal *******************************************/}
            <Modal show={delete_Modal} onHide={() => setDelete_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>        <Modal.Title>Delete User these users? ({selectedUsers.length})</Modal.Title>     </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ]` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>  
                    <div className="modal-form">                        <h5>Do you really want to delete these users?</h5>                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setDelete_Modal(false)}>                      Cancel          </Button>
                    <Button className="success-button" onClick={() => handle_DeleteUser(selected?._id)}>                         Delete          </Button>
                </Modal.Footer>
            </Modal>        {/* delete modal ************************* */}



            {/* PHONE Verify Modal *******************************************/}
            <Modal show={validatedPhone_Modal} onHide={() => setValidatedPhone_Modal(false)} backdrop="static" keyboard={false} dialogClassName="custom-modal-width">
                <Modal.Header closeButton>        <Modal.Title>Update Verify Phone for these users? ({selectedUsers.length})</Modal.Title>     </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ]` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>                    
                    <div className="modal-form">
                        <h5>Set Phone Verification Status to:</h5>
                        <Form.Select    className="shadow-none"         defaultValue="SelectStatus"     aria-label="Default select example"     onChange={handle_Phone_SelectChange}  >
                            <option value="SelectStatus">Select a Status</option>
                            <option value={true}>Verified</option>
                            <option value={false}>Unverified</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn"      variant="danger"                onClick={() => setValidatedPhone_Modal(false)}>              Cancel      </Button>
                    <Button className="success-button"  onClick={handle_ValidatePhone}   disabled={selectedValidatePhone === null}>     Confirm     </Button>
                </Modal.Footer>
            </Modal>        {/* Phone Verify Modal ************************* */}



            {/* EMAIL Verify Modal *******************************************/}
            <Modal show={validatedEmail_Modal} onHide={() => setValidatedEmail_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>        <Modal.Title>Update Verify Email for these users? ({selectedUsers.length})</Modal.Title>     </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ]` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>                    
                    <div className="modal-form">
                        <h5>Set Verify Email Status to:</h5>
                        <Form.Select    className="shadow-none"     defaultValue="SelectStatus"     aria-label="Default select example"     onChange={handle_Email_SelectChange } >
                            <option value="SelectStatus">Select a Status</option>
                            <option value={true}>Verified</option>
                            <option value={false}>Unverified</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn"      variant="danger"                onClick={() => setValidatedEmail_Modal(false)}>                Cancel        </Button>
                    <Button className="success-button"  onClick={handle_ValidateEmail}   disabled={selectedValidateEmail === null}>       Confirm       </Button>
                </Modal.Footer>
            </Modal>        {/* Email Verify Modal ************************* */}



            {/* TWOFA-AUTH Modal *******************************************/}
            <Modal show={twoFaAuth_Modal} onHide={() => setTwoFaAuth_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>        <Modal.Title>Update Authenticator Status for these users? ({selectedUsers.length})</Modal.Title>      </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ]` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>                    
                    <div className="modal-form">
                        <h5>Set Authenticator Status to:</h5>
                        <Form.Select    className="shadow-none"     defaultValue="SelectStatus"     aria-label="Default select example"     onChange={handle_TwoFaAuth_SelectChange}  >
                            <option value="SelectStatus">Select a Status</option>
                            <option value={true}>ON</option>
                            <option value={false}>OFF</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setTwoFaAuth_Modal(false)}>                        Cancel         </Button>
                    <Button className="success-button" onClick={handle_TwoFaAuth} disabled={selectedTwoFaAuthStatus === null}> Confirm      </Button>
                </Modal.Footer>
            </Modal>        {/* Authenticator Modal ************************* */}



            {/* TWOFA-EMAIL mmodal *******************************************/}
            <Modal show={twoFaEmail_Modal} onHide={() => setTwoFaEmail_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>        <Modal.Title>Update Email 2FA Status for these users? ({selectedUsers.length})</Modal.Title>       </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ]` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>                    
                    <div className="modal-form">
                        <h5>Set Email 2FA Status to:</h5>
                        <Form.Select
                            className="shadow-none"         defaultValue="SelectStatus"  aria-label="Default select example"        onChange={handle_TwoFaEmail_SelectChange}
                        >
                            <option value="SelectStatus">Select a Status</option>
                            <option value={true}>ON</option>
                            <option value={false}>OFF</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setTwoFaEmail_Modal(false)}>                        Cancel        </Button>
                    <Button className="success-button" onClick={handle_TwoFaEmail} disabled={selectedTwoFaEmailStatus === null}>       Confirm       </Button>
                </Modal.Footer>
            </Modal>        {/* two 2fa Modal************************* */}



            {/* TWOFA-PHONE mmodal *******************************************/}
            <Modal show={twoFaPhone_Modal} onHide={() => setTwoFaPhone_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>        <Modal.Title>Update Phone 2FA Status for these users? ({selectedUsers.length})</Modal.Title>       </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ]` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>                    
                    <div className="modal-form">
                        <h5>Set Phone 2FA Status to:</h5>
                        <Form.Select
                            className="shadow-none"         defaultValue="SelectStatus"  aria-label="Default select example"        onChange={handle_TwoFaPhone_SelectChange}
                        >
                            <option value="SelectStatus">Select a Status</option>
                            <option value={true}>ON</option>
                            <option value={false}>OFF</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setTwoFaPhone_Modal(false)}>                        Cancel        </Button>
                    <Button className="success-button" onClick={handle_TwoFaPhone} disabled={selectedTwoFaPhoneStatus === null}>       Confirm       </Button>
                </Modal.Footer>
            </Modal>        {/* two PHONE 2fa Modal************************* */}

            


            {/* PASSWORD  Reset Modal *******************************************/}
            <Modal show={resetPass_Modal} onHide={() => setResetPass_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>     <Modal.Title>Reset password for these users? ({selectedUsers.length})</Modal.Title>     </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ]` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>
                    <div className="modal-form">
                        <div className="input-wrapper">
                            <label htmlFor="password">New Password</label>
                            <div className="d-flex">
                                <input
                                    id="password"                   type={showResetPass ? "text" : "password"}     value={selectedPassword}
                                    placeholder="New Password"      onChange={e => setSelectedPassword(e.target.value)}
                                />
                                <Button
                                    id="button-addon2"              variant="outline-secondary"                     className="ms-2"
                                    onClick={() => setShowResetPass(!showResetPass)}
                                >
                                    {showResetPass ? <SVGImages.EyeOnIcon /> : <SVGImages.EyeOffIcon />}
                                </Button>
                            </div>
                        </div>
                        {selectedPassword.length > 0 && selectedPassword.length < STG_Min_Password_Length && (
                            <div className="error-message" style={{ color: 'red' }}>    Minimum Password Length: {STG_Min_Password_Length}. </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <Button className="danger-btn"      variant="danger"        onClick={() => setResetPass_Modal(false)}>                              Cancel       </Button>
                    <Button className="success-button"  onClick={handle_PassReset}   disabled={selectedPassword.length < STG_Min_Password_Length}> Confirm      </Button>
                </Modal.Footer>
            </Modal>        {/* reset Password************************* */}

            {/* INACTIVE Modal *******************************************/}
            <Modal show={inactive_Modal} onHide={() => setInactive_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>    <Modal.Title>Update Inactive Status for these users? ({selectedUsers.length})</Modal.Title>       </Modal.Header>
                <Modal.Body>
                    {/* List of Users */}
                    <div className="user-list">
                        {selectedUsers.map((userId, index) => {
                            const user = getUserDetails(userId);
                            return (
                                <div key={userId} className="user-item">
                                    {user ? `${String(index + 1).padStart(2, '0')} - ${user.firstName} ${user.lastName} - [ ${user.email} ]` : "User not found"}
                                </div>
                            );
                        })}
                        <div style={{ marginBottom: '10px' }}></div>
                    </div>                    
                    <div className="modal-form">
                        <h5>Set Inactive Status to:</h5>
                        <Form.Select
                            className="shadow-none"         defaultValue="SelectStatus"  aria-label="Default select example"        onChange={handle_Inactive_SelectChange}
                        >
                            <option value="SelectStatus">Select a Status</option>
                            <option value={true}>ON</option>
                            <option value={false}>OFF</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setInactive_Modal(false)}>                        Cancel        </Button>
                    <Button className="success-button" onClick={handle_Inactive} disabled={selectedInactiveStatus === null}>       Confirm       </Button>
                </Modal.Footer>
            </Modal>        {/* Inactive mmodal************************* */}



            {/* CREATE User *******************************************/}
            <Modal show={create_Modal} onHide={() => setCreate_Modal(false)} backdrop="static" keyboard={false}>
                <div className="create-user">
                    <IoCloseSharp onClick={() => setCreate_Modal(false)} className="close-btn-icon" />
                    <h3 className="text-center mb-3"   style={{ color: "black" }}>           Create New User By Admin            </h3>
                    <SignInForm
                        variants={staggerVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.1 }}>

                        <Row>               {/*  First Name*  &  Last Name*  */}
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3"            controlId="exampleForm.ControlInput1">
                                    <FormLabelText className="dark"     variants={tileVariants}>    First Name* </FormLabelText>
                                    <FormControlField
                                        required        type="text"     name="firstName"    value={formData.firstName}  variants={tileVariants}                                        
                                        autocomplete="off"              placeholder="New User First Name"               onChange={handle_InputChange}
                                        className="dark"
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3"            controlId="exampleForm.ControlInput2">
                                    <FormLabelText className="dark"     variants={tileVariants}>    Last Name*  </FormLabelText>
                                    <FormControlField
                                        required        type="text"     name="lastName"     value={formData.lastName}   variants={tileVariants}                                        
                                        autocomplete="off"              placeholder="New User Last Name"                onChange={handle_InputChange}
                                        className="dark"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>               {/*  Email (Auto Verified)*  */}
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3"            controlId="exampleForm.ControlInput3">
                                    <FormLabelText className="dark"     variants={tileVariants}>Email (Auto Verified)*</FormLabelText>
                                    <FormControlField
                                        required        type="email"    name="email"        value={formData.email}      variants={tileVariants}
                                        autocomplete="off"              placeholder="New User Email"                    onChange={handle_InputChange}
                                        className="dark"
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3"            controlId="exampleForm.ControlInput4">
                                    <FormLabelText className="dark"     variants={tileVariants}>    Password*   </FormLabelText>
                                    <ShowHidePassField variants={tileVariants}>
                                        <FormControlField
                                            required    type={showPass ? "text" : "password"}   name="password"         value={formData.password}
                                                                        placeholder="Something not easily guessed"      onChange={handle_InputChange}
                                            className="dark"            aria-describedby="basic-addon2"
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => setShowPass(!showPass)}>
                                            {showPass ? <SVGImages.EyeOnIcon /> : <SVGImages.EyeOffIcon />}
                                        </Button>
                                    </ShowHidePassField>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>               {/*  Mobile Number*   &  Subscribe to events */}
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3"            controlId="exampleForm.ControlInput5">
                                    <FormLabelText className="dark"     variants={tileVariants}>    Mobile Number*  </FormLabelText>
                                    <FormControlField
                                        required        type="text"     name="phoneNo"      value={phone}               variants={tileVariants}
                                        autocomplete="off"              placeholder="Enter User mobile no"              onChange={handle_PhoneNoChange}
                                        className="dark"
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <FormCheckField variants={tileVariants} className="mb-3">
                                    <FormLabelText className="dark" variants={tileVariants}>    &nbsp;      </FormLabelText>
                                    <Form.Check // prettier-ignore
                                        type="checkbox" variants={tileVariants}
                                        className="dark"
                                        id={`default-checkbox`}
                                        label={`Subscribe to ATA2GO important events and promotional email, User can unsubscribe anytime!`}
                                        checked={subscribe ? true : false}
                                        onChange={e => setSubscribe(e.target.checked)}
                                    />
                                </FormCheckField>
                            </Col>
                        </Row>


                        <Row>               {/*  Role  */}
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
                                    <FormLabelText className="dark" variants={tileVariants}>Role*</FormLabelText>
                                    <Form.Control
                                        as="select"
                                        value={selectedNewUserRole}
                                        onChange={e => setSelectedNewUserRole(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled>Select Role</option>
                                        {roles.map((role, index) => (
                                            <option key={index} value={role}>{role}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>               {/*  Error Message  */}
                            <Col md={12}>
                                <div className="d-flex flex-column align-items-center">
                                    {error && <p className="error-message">{error}</p>}
                                    {error1 && (
                                        <div className="d-flex justify-content-center flex-column align-items-center">
                                            <p className="error-message mb-0"><b>Password must be:</b></p>
                                            <ul className="pass-err-list">
                                                <li className="pass-error">- At least 8 characters</li>
                                                <li className="pass-error">- One uppercase</li>
                                                <li className="pass-error">- One Lowercase</li>
                                                <li className="pass-error">- One special character</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                        <>
                                        <FormButton
                                            variants={tileVariants} variant="primary" className="sm-width rightBtn"
                                            onClick={handle_CreateNewUser}>
                                            <span>Create</span>
                                        </FormButton>

                                        <Button className="danger-btn" variant="danger" onClick={handle_CreateCancel}>    Cancel    </Button>

                                        </>
                                </div>
                            </Col>
                        </Row>


                    </SignInForm>
                </div>
            </Modal>        {/* Create User************************* */}



        </div>
    );
};

export default ManageUser;
