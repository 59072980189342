/* REAL - MODAL - Learning Request Package */ 
import React, { useState }              from "react";
import { Modal, Button, Form }          from "react-bootstrap";
import { toast }                        from "react-toastify";
import { Images }                       from "../../config/images";
import { useSendSupportMailMutation }       from "../../services/userUserApi";
import { 
        useUpdateSubscriptionsMutation,
        }                               from "../../services/userUserStoreApi";
import "./ModalStyle.css";

const SvcLearningModal = ({ showModal, handleCloseModal, user, data }) => {
    const Source_Name           = "MODAL - Learning Request Package"; 
    const [contactMethod,   setContactMethod]   = useState("email");
    const [selectedPackage, setSelectedPackage] = useState("Select Package");

    const [SendLearningRequest]                 = useSendSupportMailMutation();
    const [UserStore_UpdateSubscriptions]       = useUpdateSubscriptionsMutation();
    

    const handleContactMethodChange = (e) => setContactMethod(e.target.value);

    const handlePackageChange = (e) => {
        setSelectedPackage(e.target.value);
        console.log("selectedPackage", selectedPackage);
    };

    const createAuditAction = (actionDescription) => ({
        agentId: user?._id,
        actionDescription,
        timestamp: new Date()
    });

    const handleSubscribeLearning = (selectedPackageDetails) => {
        const serviceType = "learning";      
        const serviceStatus = "requested";
        console.log("selectedPackageDetails", selectedPackageDetails);
        const serviceCode = selectedPackageDetails.serviceCode;
        
        UserStore_UpdateSubscriptions({ 
            ids: Array.isArray(user?._id) ? user?._id : [user?._id],
            data: { 
                subscriptions: serviceType, 
                serviceStatus, 
                serviceCode,
                auditActions: createAuditAction(`Requesting Package: ${serviceCode}`)  // Wrap in an array
            },
            source: Source_Name, 
        }).then(res => {
            if (res?.data?.status) {
        //        setRefetch(refetch + 1);
                toast.success(res?.data?.message);
            } else {
                toast.error(res?.data?.message);
            }
        //    setModal(false);
        });
       
      
      //  fetchSubscriptions(); 
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();

        

        const selectedPackageDetails = data?.data?.find(pkg => pkg._id === selectedPackage);
        console.log("selectedPackage", selectedPackage);
        console.log("selectedPackageDetails", selectedPackageDetails);
        handleSubscribeLearning(selectedPackageDetails);

        const emailData = {
            name: `${user?.lastName}, ${user?.firstName}`,
            email: user?.email,
            subject: 'Learning Service Request',
            text: `My Postal Code is: ${user?.postCode || "Not Provided"}
            <br/><br/>Learning Package:
            <br/> ${selectedPackageDetails ? `Level: ${selectedPackageDetails.courseLevel} / ${selectedPackageDetails.description} Price: ${selectedPackageDetails.price} $` : "Not Provided"}
            <br/><br/>User's phone number:
            <br/> ${user?.phoneNo || "Not Provided"}`,
        };

        handleCloseModal();
        try {
            console.log("emailData", emailData);
            SendLearningRequest(emailData).then((res) => {
                console.log("res", res);
                if (res?.data?.status) {
                    toast.success(res?.data?.message);
                    setContactMethod("email");
                } else {
                    toast.warning("SEND FAILED: Please send an email to support@ata2go.com");
                }
            });
        } catch (error) {
            console.log(error.message);
        }
    };



    const modalContentStyle = {
      //  backgroundImage: `url(${Images.wwwInternet})`,
      color: '#000', // Ensure the text is black, which contrasts well with a light background
      backgroundColor: '#fff', // Optional: Set a white background for good contrast
    };


    return (
        <Modal 
        show={showModal} 
        onHide={handleCloseModal} 
        backdrop="static" 
        keyboard={false}
    >

        <div className="modal-content modal-content-background" style={modalContentStyle}>
            <Modal.Header closeButton>
                <img src={Images.wwwInternet} alt="Learning Logo" className="header-image" />
                <Modal.Title>Learning Service Request</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{user?.firstName}, thank you for your Interests.</p>
                <p>
                    To better serve you, a representative will contact you to discuss your learning requirements such as your actual starting level, start date,
                    any special needs and customs requests you may have.
                </p>
                <Form>
                    <Form.Group controlId="postalCode">
                        <Form.Label>Please confirm your postal code so we can locate direct you.</Form.Label>
                        <Form.Control 
                            type="text" 
                            defaultValue={user?.postCode || ""} 
                            placeholder={!user?.postCode ? "Enter your postal code" : ""}
                        />
                    </Form.Group>

                    {/* Dropdown for selecting learning package */}
                    <Form.Group controlId="learningPackage">
                        <Form.Label>Select a Learning Package:</Form.Label>
                        <Form.Control 
                            as="select" 
                            className="form-select" 
                            value={selectedPackage} // Bind to the state variable
                            onChange={handlePackageChange}
                        >
                            <option value="SelectPackage">Select Package</option> {/* Default option */}
                            {data?.data?.map((learnpack) => (
                                <option key={learnpack._id} value={learnpack._id}>
                                    {` \u00A0\u00A0\u00A0\u00A0 ${learnpack.price} $ - ${learnpack.courseLevel} / ${learnpack.description}`}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="contactMethod">
                        <br />
                        <Form.Label>For this request, contact me by:</Form.Label>
                        <Form.Check
                            type="radio"
                            name="contactMethod"
                            checked={contactMethod === "email"}
                            value="email"
                            onChange={handleContactMethodChange}
                            disabled={!user?.email}
                            label={`Email: ${user?.email || "Not Provided"}`}
                        />
                        <Form.Check
                            type="radio"
                            name="contactMethod"
                            checked={contactMethod === "phone"}
                            value="phone"
                            onChange={handleContactMethodChange}
                            disabled={!user?.phoneNo}
                            label={`Phone: ${user?.phoneNo || "Not Provided"}`}
                        />
                        <Form.Label>Adjust your Contact Details in Settings if the above is incorrect.</Form.Label>
                    </Form.Group>
                </Form>
                <br />
                <p>We will follow up with you shortly.</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}                                                  >Close</Button>
                <Button variant="primary"   onClick={handleSendMessage} disabled={selectedPackage === "Select Package"}  >Confirm</Button>
            </Modal.Footer>
            </div>
        </Modal>
    );
};

export default SvcLearningModal;
