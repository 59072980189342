/* REAL - SETTINGS - DOCUMENTS */
/* File: /src/pages/Settings/Documents/index.jsx  */
import { useTranslation 
}                                               from 'react-i18next';
import { Button, 
         Col, 
         Form, 
         Row,
         Modal,
         Tooltip, 
         OverlayTrigger, 
        }                                           from "react-bootstrap";
import { useContext, 
         useEffect, 
         useState }                                 from "react";
import { toast }                                    from "react-toastify";
import { useNavigate }                              from "react-router-dom";
import { FormControlField, 
         FormLabelText, 
        }                                           from "./DocumentsStyle";
import { TabContentContainer }                      from "../../Tabs/tabContentStyle";
import { 
         useGetUserByIdQuery, 
        }                                           from "../../../services/userUserApi";
import {  
         useUpdateDocumentMutation,
         useDeleteDocumentMutation,
         useRetrieveDocumentListByUserIdMutation, 
        }                                           from "../../../services/userDocumentApi";
import { UserContext }                              from "../../../App";
import { staggerVariants, 
         titleVariants }                            from "../../../utils/FmVariants";
import { Images,          
        }                                           from "../../../config/images";
import "./DocumentsStyle.css";


const ModuleDocumentInfo = ({ user }) => {
    const { t } = useTranslation('settingsDocuments');
    const Source_Name                                           = "SETTINGS - DOCUMENTS";
    const navigate                                              = useNavigate();
    console.log("[ModuleDocumentInfo] - Here", user);
    const { refetch,                setRefetch }                = useContext(UserContext);
    const { data }                                              = useGetUserByIdQuery(user?._id);
    const [retrieveDocumentListByUserId]                        = useRetrieveDocumentListByUserIdMutation();
    const [Document_updateDocuments]                            = useUpdateDocumentMutation();
    const [Document_deleteDocument]                             = useDeleteDocumentMutation();
    const [firstName,               setFirstName]               = useState("");
    const [lastName,                setLastName]                = useState("");
    const [docNote,                 setDocNote]                 = useState("");
    const [onUpdate,                setOnUpdate]                = useState(false);
    const [showConfirmationModal,   setShowConfirmationModal]   = useState(false);  // State for showing modal
    const [fileToRemove,            setFileToRemove]            = useState(null);  // State to track which file is to be removed
    const [shouldTriggerUpdate,     setShouldTriggerUpdate]     = useState(false);




    /* Section 01.00 - [selectedFiles, setSelectedFiles]  - Each type of document can have its own selected file state */
    const [selectedFiles, setSelectedFiles] = useState({
        "driver-license": [],
        "health-card": [],
        "passport": [],
        "residence-permit": [],
        "national-id": []
    });

    /* Section 02.00 - [docStatus, setDocStatus]  */
    const [docStatus, setDocStatus] = useState({
        "driver-license":   "Submitted",
        "health-card":      "Submitted",
        "passport":         "Submitted",
        "residence-permit": "Submitted",
        "national-id":      "Submitted"
    });

    /* Section 03.00 - [docExpiry, setDocExpiry]  */
    const [docExpiry, setDocExpiry] = useState({
        "driver-license": "",
        "health-card": "",
        "passport": "",
        "residence-permit": "",
        "national-id": ""
    });

    /* Section 04.00 - handleUpdate  */
    const handleUpdate = () => {
        // Create a FormData object to hold the files
        const formData = new FormData();
    
        // Append the user ID
        formData.append('userId', user?._id);

        console.log("IDS=", user?._id);
        console.log("selectedFiles=", selectedFiles);
    
        // Append each file type and its corresponding files
        Object.keys(selectedFiles).forEach(key => {
            selectedFiles[key].forEach(file => {
                formData.append(key, file);
                formData.append(`${key}_docStatus`, docStatus[key]); // Pass docStatus for each file type
                formData.append(`${key}_docExpiry`, docExpiry[key] || ''); // Pass docExpiry for each file type
            });
        });

        /* Debugging: Log the FormData entries
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
        */

        // Pass the FormData to the mutation
        Document_updateDocuments({ userId: [user?._id], data: formData }).then(res => {
            console.log("...Back from update...");
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setOnUpdate(false);
                setRefetch(refetch + 1);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };
    
    /* Section 05.00 - handleFileChange  */
    const handleFileChange = (e, type) => {
        const updatedFiles = {
            ...selectedFiles,
            [type]: Array.from(e.target.files)
        };
        setSelectedFiles(updatedFiles);

        // Set shouldTriggerUpdate to true to trigger the useEffect
        setShouldTriggerUpdate(true);
    };



    /* Section 06.00 - handleRemoveFile  */
    const handleRemoveFile = (key) => {
        setFileToRemove(key);  // Set the file to be removed
        setShowConfirmationModal(true);  // Show the confirmation modal
    };

    /* Section 07.00 - confirmRemoveFile  */
    const confirmRemoveFile = () => {
        const docType = fileToRemove;
        const docToDelete = selectedFiles[docType][0]; // Assuming there's only one file per docType

        if (docToDelete && docToDelete.id) {
            
            // Call the deleteDocument mutation with the document's ID
            Document_deleteDocument({ data: { id: docToDelete.id, source: Source_Name } }).then(response => {
                if (response?.data?.status) {
                    toast.success(t('messages.document_deleted_success'));

                    // Remove the file from the state after successful deletion
                    setSelectedFiles(prevFiles => ({
                        ...prevFiles,
                        [docType]: []
                    }));
                } else {
                    toast.error(t('messages.document_deleted_failure'));
                }
            }).catch(error => {
                toast.error(t('messages.error_deleting_document'));
            });
        } else {
            // If there's no ID, just remove the file locally
            setSelectedFiles(prevFiles => ({
                ...prevFiles,
                [docType]: []
            }));
        }

        setShowConfirmationModal(false);  // Hide the modal
        setFileToRemove(null);  // Reset fileToRemove state
    };


    /* Section 08.00 - useEffect - [data] */
    useEffect(() => {
        setFirstName(       data?.result?.firstName);
        setLastName(        data?.result?.lastName);
    }, [data]);

    /* Section 09.00 - useEffect - [user?._id, retrieveDocumentListByUserId] */
    useEffect(() => {
        if (user?._id) {
            retrieveDocumentListByUserId(user?._id).then(res => {
                if (res?.data?.status && res?.data?.documentList) {
                    const newSelectedFiles = { ...selectedFiles };
                    const newDocExpiry = { ...docExpiry };
                    const newDocStatus = { ...docStatus };
                    const newDocNote = { ...docNote };

                    res.data.documentList.forEach(doc => {
                        if (doc.docType && newSelectedFiles[doc.docType]) {
                            // Log the document to ensure correct data
                            console.log("Document from backend:", doc);

                            newSelectedFiles[doc.docType] = [{
                                id: doc._id,  // Ensure the ID is stored
                                name: doc.fileName,
                                type: doc.contentType,
                                timestamp: new Date(doc.uploadDate).toLocaleString('en-CA', { hour12: false })
                            }];
                            newDocExpiry[doc.docType] = doc.docExpiry ? new Date(doc.docExpiry).toISOString().split('T')[0] : "";
                            newDocStatus[doc.docType] = doc.docStatus || "New Status";
                            newDocNote[doc.docType] = doc.docNote || "";
                        }
                    });

                    console.log("Updated selectedFiles:", newSelectedFiles);

                    setSelectedFiles(newSelectedFiles);
                    setDocExpiry(newDocExpiry);
                    setDocStatus(newDocStatus);
                    setDocNote(newDocNote);
                } else {
                    toast.error(t('messages.document_updated_failure'));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?._id, retrieveDocumentListByUserId]);



    /* Section 10.00 - useEffect - [shouldTriggerUpdate, selectedFiles] */
    useEffect(() => {
        if (shouldTriggerUpdate) {
            handleUpdate();
            setShouldTriggerUpdate(false); // Reset after triggering update
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldTriggerUpdate]);


    
return (
    <TabContentContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>

        {/*  HEADER USER IDENTIFICATION DOCUMENTS */}
        <div className="d-flex justify-content-between align-items-center">
            <div className="mt-4">
                <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('titles.user_identification_documents')}</h5>
            </div>
            <div className="d-flex">
                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t('buttons.modify_contact')}</Tooltip>}     >
                    <Button variant="primary" className="ms-2" onClick={() => navigate("/settings?tab=ContactInfo")}>
                        {t('buttons.modify_contact')}
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t('buttons.remittance')}</Tooltip>}     >
                    <Button variant="primary" className="ms-2" onClick={() => navigate("/subscriptions?tab=remittance")}>
                        {t('buttons.remittance')}
                    </Button>
                </OverlayTrigger>
            </div>
        </div>

        <hr className="mt-1" />

        <div className="row">
            {/*  FIRST AND LAST NAME  */}
            <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('titles.verify_name_exact_spelling')}</h5>
                <h6 className="mb-0 pb-0" style={{ color: 'black' }}>{t('descriptions.name_must_match')}</h6>
                <span><br /></span>
                <Row>
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput2">
                            <FormLabelText variants={titleVariants}>{t('placeholders.first_name')}</FormLabelText>
                            <FormControlField
                                required                    disabled={!onUpdate}              type="text"            variants={titleVariants}       
                                value={firstName}           placeholder={t('placeholders.first_name')}          onChange={e => setFirstName(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput3">
                            <FormLabelText variants={titleVariants}>{t('placeholders.last_name')}</FormLabelText>
                            <FormControlField
                                required                    disabled={!onUpdate}                type="text"            variants={titleVariants}       
                                value={lastName}            placeholder={t('placeholders.last_name')}             onChange={e => setLastName(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>
                </Row>
            </div>


            <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t('titles.document_id_management')}</h5>
            <h6 className="mb-0 pb-0" style={{ color: 'black' }}>{t('descriptions.documents_encrypted_note')}</h6>
            <h6 className="mb-0 pb-0" style={{ color: 'black' }}>{t('descriptions.confirm_identity')}</h6>
            <span><br /></span>

            {/* Table Section */}
            <div className="col-12">
                <table className="table table-bordered">
                    {/* TABLE HEADER - FIRST ROW */}
                    <thead className="custom-header">
                        <tr>
                            <th>{t('table_headers.id_type')}    <br />
                                <span style={{ fontSize: 'smaller' }}>Select At least one</span>
                            </th>
                            <th>{t('table_headers.document')}    <br />
                                <span style={{ fontSize: 'smaller' }}>PDF or Images</span>
                            </th>
                            <th>{t('table_headers.uploaded')}    <br />
                                <span style={{ fontSize: 'smaller' }}>TimeStamp</span>
                            </th>
                            <th>{t('table_headers.status')}      <br />
                                <span style={{ fontSize: 'smaller' }}>Verification</span>
                            </th>
                            <th>{t('table_headers.note')}      <br />
                                <span style={{ fontSize: 'smaller' }}>Note</span>
                            </th>
                            <th>{t('table_headers.expiry')}      <br />
                                <span style={{ fontSize: 'smaller' }}>Date</span>
                            </th>  
                        </tr>
                    </thead>

                    {/* TABLE DATA */}
                    <tbody>

                        {/* DOCUMENT QUEBEC DRIVERS LICENSE **************************/}
                        <tr>
                            {/* ID Type Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={Images.DocIdQCDriversLicense}  alt="Driver's License" style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
                                    <span>Driver's License</span>
                                </div>
                            </td>

                            {/* Document Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor="file-driver-license" className="btn btn-link">
                                        {selectedFiles["driver-license"] && selectedFiles["driver-license"].length > 0 ? 'Update' : 'Choose'}
                                    </label>
                                    <input 
                                        type="file" 
                                        id="file-driver-license" 
                                        name="promoDoc" 
                                        accept=".pdf,.jpg,.jpeg,.png,.webp" 
                                        multiple 
                                        style={{ display: 'none' }} 
                                        onChange={(e) => {
                                            handleFileChange(e, "driver-license");
                                        }}
                                    />
                                    {selectedFiles["driver-license"] && selectedFiles["driver-license"].length > 0 && (
                                        <button 
                                            className="btn btn-link text-danger ms-2"
                                            onClick={() => handleRemoveFile("driver-license")}
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                                <div className="file-list custom-input">
                                    {selectedFiles["driver-license"] && selectedFiles["driver-license"].length > 0 ? (
                                        selectedFiles["driver-license"].map((file, index) => (
                                            <div key={index} className="file-item custom-input">{file.name}</div>
                                        ))
                                    ) : (
                                        <div className="file-item custom-input"></div>
                                    )}
                                </div>
                            </td>

                            {/* Uploaded Column */}
                            <td>
                                {selectedFiles["driver-license"] && selectedFiles["driver-license"].length > 0 ? (
                                    <>
                                        {new Date().toLocaleDateString('en-CA')} <br />
                                        {new Date().toLocaleTimeString('en-CA', { hour12: false })}.{new Date().getMilliseconds()}
                                    </>
                                ) : '---'}
                            </td>

                            {/* Status Column */}
                            <td>
                                {selectedFiles["driver-license"] && selectedFiles["driver-license"].length > 0 ? 
                                    docStatus["driver-license"] : 
                                    '---'
                                }
                            </td>


                            {/* Note Column */}
                            <td>
                                {selectedFiles["driver-license"] && selectedFiles["driver-license"].length > 0 ? 
                                    docNote["driver-license"] : 
                                    '---'
                                }
                            </td>

                            {/* Expiry Column */}
                            <td>
                                <FormControlField
                                    type="date"
                                    disabled={!onUpdate} 
                                    value={docExpiry["driver-license"]} // Example for driver's license
                                    onChange={(e) => setDocExpiry({
                                        ...docExpiry,
                                        "driver-license": e.target.value // Update the expiry date for this document type
                                    })}
                                />
                            </td>
                        </tr>

                        {/* DOCUMENT QUEBEC HEALTH CARD *************************/}
                        <tr>
                            {/* ID Type Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={Images.DocIdRAMQ} alt="Health Insurance Card" style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
                                    <div>
                                        <span>Quebec Issued</span><br />
                                        <span>Health Insurance Card</span>
                                    </div>
                                </div>
                            </td>

                            {/* Document Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor="file-health-card" className="btn btn-link">
                                        {selectedFiles["health-card"] && selectedFiles["health-card"].length > 0 ? 'Update' : 'Choose'}
                                    </label>
                                    <input 
                                        type="file" 
                                        id="file-health-card" 
                                        name="promoDoc" 
                                        accept=".pdf,.jpg,.jpeg,.png,.webp" 
                                        multiple 
                                        style={{ display: 'none' }} 
                                        onChange={(e) => {
                                            handleFileChange(e, "health-card");
                                        }}
                                    />
                                    {selectedFiles["health-card"] && selectedFiles["health-card"].length > 0 && (
                                        <button 
                                            className="btn btn-link text-danger ms-2"
                                            onClick={() => handleRemoveFile("health-card")}
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                                <div className="file-list custom-input">
                                    {selectedFiles["health-card"] && selectedFiles["health-card"].length > 0 ? (
                                        selectedFiles["health-card"].map((file, index) => (
                                            <div key={index} className="file-item custom-input">{file.name}</div>
                                        ))
                                    ) : (
                                        <div className="file-item custom-input"></div>
                                    )}
                                </div>
                            </td>

                            {/* Uploaded Column */}
                            <td>
                                {selectedFiles["health-card"] && selectedFiles["health-card"].length > 0 ? (
                                    <>
                                        {new Date().toLocaleDateString('en-CA')} <br />
                                        {new Date().toLocaleTimeString('en-CA', { hour12: false })}.{new Date().getMilliseconds()}
                                    </>
                                ) : '---'}
                            </td>

                            {/* Status Column */}
                            <td>
                                {selectedFiles["health-card"] && selectedFiles["health-card"].length > 0 ? 
                                    docStatus["health-card"] : 
                                    '---'
                                }
                            </td>

                            {/* Note Column */}
                            <td>
                                {selectedFiles["health-card"] && selectedFiles["health-card"].length > 0 ? 
                                    docNote["health-card"] : 
                                    '---'
                                }
                            </td>

                            {/* Expiry Column */}
                            <td>
                                <FormControlField
                                    type="date"
                                    disabled={!onUpdate} 
                                    value={docExpiry["health-card"]}
                                    onChange={(e) => setDocExpiry({
                                        ...docExpiry,
                                        "health-card": e.target.value
                                    })}
                                />
                            </td>
                        </tr>

                        {/* DOCUMENT CANADA PASSPORT **************************/}
                        <tr>
                            {/* ID Type Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={Images.DocIdCanadaPassport} alt="Passport" style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
                                    <div>
                                        <span>Passport</span>
                                    </div>
                                </div>
                            </td>

                            {/* Document Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor="file-passport" className="btn btn-link">
                                        {selectedFiles["passport"] && selectedFiles["passport"].length > 0 ? 'Update' : 'Choose'}
                                    </label>
                                    <input 
                                        type="file" 
                                        id="file-passport" 
                                        name="promoDoc" 
                                        accept=".pdf,.jpg,.jpeg,.png,.webp" 
                                        multiple 
                                        style={{ display: 'none' }} 
                                        onChange={(e) => handleFileChange(e, "passport")}
                                    />
                                    {selectedFiles["passport"] && selectedFiles["passport"].length > 0 && (
                                        <button 
                                            className="btn btn-link text-danger ms-2"
                                            onClick={() => handleRemoveFile("passport")}
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                                <div className="file-list custom-input">
                                    {selectedFiles["passport"] && selectedFiles["passport"].length > 0 ? (
                                        selectedFiles["passport"].map((file, index) => (
                                            <div key={index} className="file-item custom-input">{file.name}</div>
                                        ))
                                    ) : (
                                        <div className="file-item custom-input"></div>
                                    )}
                                </div>
                            </td>

                            {/* Uploaded Column */}
                            <td>
                                {selectedFiles["passport"] && selectedFiles["passport"].length > 0 ? (
                                    <>
                                        {new Date().toLocaleDateString('en-CA')} <br />
                                        {new Date().toLocaleTimeString('en-CA', { hour12: false })}.{new Date().getMilliseconds()}
                                    </>
                                ) : '---'}
                            </td>

                            {/* Status Column */}
                            <td>
                                {selectedFiles["passport"] && selectedFiles["passport"].length > 0 ? 
                                    docStatus["passport"] : 
                                    '---'
                                }
                            </td>

                            {/* Note Column */}
                            <td>
                            {selectedFiles["passport"] && selectedFiles["passport"].length > 0 ? 
                                    docNote["passport"] : 
                                    '---'
                                }
                            </td>

                            {/* Expiry Column */}
                            <td>
                                <FormControlField
                                    type="date"
                                    disabled={!onUpdate} 
                                    value={docExpiry["passport"]}
                                    onChange={(e) => setDocExpiry({
                                        ...docExpiry,
                                        "passport": e.target.value
                                    })}
                                />
                            </td>
                        </tr>

                        {/* DOCUMENT CANADA PERMANENT RESIDENT *************************/}
                        <tr>
                            {/* ID Type Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={Images.DocIdCanadaPermResident} alt="Residence Permit" style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
                                    <div>
                                        <span>Residence Permit</span>
                                    </div>
                                </div>
                            </td>

                            {/* Document Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor="file-residence-permit" className="btn btn-link">
                                        {selectedFiles["residence-permit"] && selectedFiles["residence-permit"].length > 0 ? 'Update' : 'Choose'}
                                    </label>
                                    <input 
                                        type="file" 
                                        id="file-residence-permit" 
                                        name="promoDoc" 
                                        accept=".pdf,.jpg,.jpeg,.png,.webp" 
                                        multiple 
                                        style={{ display: 'none' }} 
                                        onChange={(e) => handleFileChange(e, "residence-permit")}
                                    />
                                    {selectedFiles["residence-permit"] && selectedFiles["residence-permit"].length > 0 && (
                                        <button 
                                            className="btn btn-link text-danger ms-2"
                                            onClick={() => handleRemoveFile("residence-permit")}
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                                <div className="file-list custom-input">
                                    {selectedFiles["residence-permit"] && selectedFiles["residence-permit"].length > 0 ? (
                                        selectedFiles["residence-permit"].map((file, index) => (
                                            <div key={index} className="file-item custom-input">{file.name}</div>
                                        ))
                                    ) : (
                                        <div className="file-item custom-input"></div>
                                    )}
                                </div>
                            </td>

                            {/* Uploaded Column */}
                            <td>
                                {selectedFiles["residence-permit"] && selectedFiles["residence-permit"].length > 0 ? (
                                    <>
                                        {new Date().toLocaleDateString('en-CA')} <br />
                                        {new Date().toLocaleTimeString('en-CA', { hour12: false })}.{new Date().getMilliseconds()}
                                    </>
                                ) : '---'}
                            </td>

                            {/* Status Column */}
                            <td>
                                {selectedFiles["residence-permit"] && selectedFiles["residence-permit"].length > 0 ? 
                                    docStatus["residence-permit"] : 
                                    '---'
                                }
                            </td>

                            {/* Note Column */}
                            <td>
                            {selectedFiles["residence-permit"] && selectedFiles["residence-permit"].length > 0 ? 
                                    docNote["residence-permit"] : 
                                    '---'
                                }
                            </td>

                            {/* Expiry Column */}
                            <td>
                                <FormControlField
                                    type="date"
                                    disabled={!onUpdate} 
                                    value={docExpiry["residence-permit"]}
                                    onChange={(e) => setDocExpiry({
                                        ...docExpiry,
                                        "residence-permit": e.target.value
                                    })}
                                />
                            </td>
                        </tr>

                        {/* DOCUMENT CANADA NATIONAL ID *************************/}
                        <tr>
                            {/* ID Type Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={Images.DocIdCanadaNationalId} alt="National ID Card" style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
                                    <div>
                                        <span>Government Issued</span> <br />
                                        <span>National ID Card</span>
                                    </div>
                                </div>
                            </td>

                            {/* Document Column */}
                            <td>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor="file-national-id" className="btn btn-link">
                                        {selectedFiles["national-id"] && selectedFiles["national-id"].length > 0 ? 'Update' : 'Choose'}
                                    </label>
                                    <input 
                                        type="file" 
                                        id="file-national-id" 
                                        name="promoDoc" 
                                        accept=".pdf,.jpg,.jpeg,.png,.webp" 
                                        multiple 
                                        style={{ display: 'none' }} 
                                        onChange={(e) => handleFileChange(e, "national-id")}
                                    />
                                    {selectedFiles["national-id"] && selectedFiles["national-id"].length > 0 && (
                                        <button 
                                            className="btn btn-link text-danger ms-2"
                                            onClick={() => handleRemoveFile("national-id")}
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                                <div className="file-list custom-input">
                                    {selectedFiles["national-id"] && selectedFiles["national-id"].length > 0 ? (
                                        selectedFiles["national-id"].map((file, index) => (
                                            <div key={index} className="file-item custom-input">{file.name}</div>
                                        ))
                                    ) : (
                                        <div className="file-item custom-input"></div>
                                    )}
                                </div>
                            </td>

                            {/* Uploaded Column */}
                            <td>
                                {selectedFiles["national-id"] && selectedFiles["national-id"].length > 0 ? (
                                    <>
                                        {new Date().toLocaleDateString('en-CA')} <br />
                                        {new Date().toLocaleTimeString('en-CA', { hour12: false })}.{new Date().getMilliseconds()}
                                    </>
                                ) : '---'}
                            </td>

                            {/* Status Column */}
                            <td>
                                {selectedFiles["national-id"] && selectedFiles["national-id"].length > 0 ? 
                                    docStatus["national-id"] : 
                                    '---'
                                }
                            </td>

                            {/* Note Column */}
                            <td>
                            {selectedFiles["national-id"] && selectedFiles["national-id"].length > 0 ? 
                                    docNote["national-id"] : 
                                    '---'
                                }
                            </td>

                            {/* Expiry Column */}
                            <td>
                                <FormControlField
                                    type="date"
                                    disabled={!onUpdate} 
                                    value={docExpiry["national-id"]}
                                    onChange={(e) => setDocExpiry({
                                        ...docExpiry,
                                        "national-id": e.target.value
                                    })}
                                />
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div> {/* Table Section */}

            <br /> 
            <br /> 
            <br /> 
            <h6 className="mb-0 pb-0" style={{ color: 'black' }}>{t('descriptions.security_note')}</h6>
            <br /> 
            <br /> 

        </div>

        {/* MODAL : CONFIRM REMOVE DOCUMENT */}
        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'blue' }}>{`${fileToRemove} ${t('titles.file_removal')}`}</Modal.Title>  
            </Modal.Header>

            <Modal.Body>
                {t('messages.confirm_file_removal', { file: selectedFiles[fileToRemove]?.[0]?.name })}
            </Modal.Body>  

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
                    {t('buttons.cancel')}
                </Button>
                <Button variant="danger" onClick={confirmRemoveFile}>
                    {t('buttons.confirm')}
                </Button>
            </Modal.Footer>
        </Modal>

    </TabContentContainer>

    

    );
};

export default ModuleDocumentInfo;