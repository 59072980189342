/* File: /src/services/svcInternetApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const svcInternetApi = createApi({
    reducerPath: "svcInternetApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/svcInternets",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["Internets"],
    endpoints: builder => ({
        internetListPackages:   builder.query({    query: ({ source })           => ({ url: "/",      method: "GET",    params: { source }, }),         providesTags: ["Internets"] }),
        internetListById:       builder.query({    query: ({ id, source })       => ({ url: `/${id}`, method: "GET",    params: { source } }),          providesTags: ["Internets"] }),
        internetAddPackage:     builder.mutation({ query: ({ data, source })     => ({ url: "/",      method: "POST",   body: { ...data, source } }),   invalidatesTags: ["Internets"] }),
        internetUpdatePackage:  builder.mutation({ query: ({ id, data, source }) => ({ url: `/${id}`, method: "PATCH",  body: { ...data, source } }),   invalidatesTags: ["Internets"] }),
        internetDeletePackage:  builder.mutation({ query: ({ id, source })       => ({ url: `/${id}`, method: "DELETE", params: { source } }),          invalidatesTags: ["Internets"] }),

        healthCheck:            builder.query   ({ query: ({ source }) =>       ({ url: `/health`,          method: "GET", params: { source }, })        }),

    })
});

export const {
    useHealthCheckQuery,
    useInternetDeletePackageMutation,
    useInternetListPackagesQuery,
    useInternetListByIdQuery,
    useInternetUpdatePackageMutation,
    useInternetAddPackageMutation
} = svcInternetApi;
