/* REAL - HOME PAGE - SERVICE - LEARNING */
import { 
    Tooltip, 
    OverlayTrigger 
   }                                    from "react-bootstrap";
import { useNavigate }                  from "react-router-dom";
import { 
        useContext,
        useState,
        useEffect,
        }                               from "react";
import { UserContext }                  from "../../../../App";
import { CoursesBox }                   from "./technologyCoursesStyle";
import CoursePlan                       from "./CoursePlan";
import { 
        BoldContent, 
        TabContentContainer, 
        TabTitle, 
        TextContent,
        OrderButton,
         }                               from "../../../Tabs/tabContentStyle";
import { 
        useGetSubscriptionsMutation,
          }                             from "../../../../services/userUserStoreApi";
import { useGetAllCoursesQuery }        from "../../../../services/svcLearningApi";
import CardSlider                       from "../../../../components/UI/CardSlider";
import SvcLearningModal                 from "../../../Modal/SvcLearningModal";



const SvcLearning = ({ tabLabel, sectionID }) => {
    const Source_Name           = "HOME PAGE - SERVICE - LEARNING";
    const navigator = useNavigate();
    const { user } = useContext(UserContext);
    const { data: learningLists } = useGetAllCoursesQuery({ source: Source_Name });
    const [UserStore_GetSubscriptions]                  = useGetSubscriptionsMutation();
    const [subscriptionData, setSubscriptionData]       = useState(null);
    const [showModal,       setShowModal]               = useState(false);

    useEffect(() => {
        if (user?._id) {
            const data = {
                userIds: [user._id],  // List of user IDs
              };
              
            UserStore_GetSubscriptions({ data, source: Source_Name }).then(response => {
                setSubscriptionData(response.data);
                console.log("response", response.data);
            });
        }
    }, [user, UserStore_GetSubscriptions]);

    const settings = {
        className: "slider center variable-width",
        centerMode: true,
        infinite: true,
        centerPadding: "20%",
        slidesToShow: 1,
        speed: 500,
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    centerPadding: "10%"
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: "5%"
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerPadding: "0%"
                }
            }
        ]
    };

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <TabContentContainer id={sectionID}>
                <TabTitle className="text-center">{tabLabel}</TabTitle>
                <BoldContent>
                    <p>
                        Looking to use Microsoft Word for a basic document or to enhance the appearance of your documents, letters, and
                        presentations? Our advisors will guide you step-by-step.
                    </p>
                </BoldContent>
                <TextContent>
                    <p>
                        Does Excel seem like a puzzle to you? Look no further! We will start from the simplest form and grow with you at every
                        step to create professional and efficient spreadsheets. Internet technologies are highly imaged and can sometimes be
                        confusing. No more hassle as our advisors will help you differentiate and use popular cloud platforms.
                    </p>
                    <p>
                        Our services are tailored to your level and we offer professional consultation services to meet your specific individual
                        or business needs.
                    </p>
                </TextContent>
                <CoursesBox>
                    {learningLists?.data?.length > 0 && (
                        <CardSlider sliderConfig={settings}>
                            {learningLists?.data?.map(course => {
                                return <CoursePlan key={course._id} courseData={course} />;
                            })}
                        </CardSlider>
                    )}
                </CoursesBox>


                <OverlayTrigger 
                        placement="top" 
                        overlay={
                            <Tooltip id="tooltip-save">
                                {user?._id ? 
                                    (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.learning) && user.subscriptions.learning.length > 0) 
                                        ? "My Learning Program" 
                                        : "Request a Package")  
                                    : "Sign-In or Login to Request"
                                }
                            </Tooltip>
                        }
                    >
                        <div className="d-flex justify-content-center">
                            <OrderButton
                                variant="secondary"
                                
                                onClick={() => {
                                    console.log("subscriptionData:", subscriptionData);
                                    if (!user?._id) {
                                        navigator("/signin"); // Navigate to sign-in page
                                    } else if (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.learning) && user.subscriptions.learning.length > 0)) {
                                        navigator("/subscriptions?tab=learning"); // Navigate to subscriptions page
                                    } else {
                                        handleShowModal(); // Show modal for requesting a package
                                    }
                                }}
                            >
                                {user?._id ?
                                    (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.learning) && user.subscriptions.learning.length > 0) 
                                        ? "My Learning Program" 
                                        : "Request a Package") 
                                    : "Sign-In or Login to Request"
                                }
                            </OrderButton>
                        </div>
                    </OverlayTrigger>

            </TabContentContainer>

            <SvcLearningModal 
                showModal={showModal} 
                handleCloseModal={handleCloseModal} 
                user={user} 
                data={learningLists} // Updated line
            />

        </>
    );
};

export default SvcLearning;
