/* REAL - HOME PAGE - MENU BAR AT THE TOP OF THE HOME PAGE */
/* file: src/pages/Header/SiteNavBar/index.jsx */
import { Dropdown } from "react-bootstrap"; // Add this if Button is not imported
import i18n from "../../../i18n"; // Import your i18n instance if not already imported
import { useTranslation 
        }                                   from 'react-i18next';
import React, 
        { useState, 
          useEffect, 
          useRef, 
          useContext, 
          useCallback,
        }                                   from "react";
import { 
        Link, 
        useNavigate 
        }                                   from "react-router-dom";
import { BiCommentDetail }                  from "react-icons/bi";
import { LoginButton, 
        SiteNav 
        }                                   from "./siteNavStyle";
// import ServicesDropDown                     from "../ServicesDropDown";
import ProfileDropDown                      from "../ProfileDropDown";
import { UserContext }                      from "../../../App";
// import { SVGImages }                        from "../../../config/images";


const NavItem = React.memo(({ sectionId, children, onMenuItemSelect, activeSection }) => (  // Modified line

    <Link
        className={sectionId === activeSection ? "active nav-link" : "nav-link"} // Modified line
        to={["videos", "dashboard"].includes(sectionId) ? `/${sectionId}` : `/#${sectionId}`}
        onClick={() => onMenuItemSelect(sectionId)}>
        {children}
    </Link>
));

const SiteNavBar = ({ styleClass, onHide = () => {} }) => {
    const { t } = useTranslation('homeTopBar');
    const [activeSection,   setActiveSection]           = useState("banner");
    const { user }                                      = useContext(UserContext);
    const sectionsRef                                   = useRef(null);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    
    useEffect(() => {
        // This ensures that the displayed language updates if the language changes
        const handleLanguageChange = (lng) => setCurrentLanguage(lng);
        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

    const handleScroll = useCallback(() => {
        const pageYOffset = window.scrollY;
        if (sectionsRef.current) { // Modified line
            const newActiveSection = Array.from(sectionsRef.current).find(
                section => pageYOffset >= section.offsetTop && pageYOffset < section.offsetTop + section.offsetHeight
            )?.id;

            if (newActiveSection && newActiveSection !== activeSection) {
                setActiveSection(newActiveSection);
            }
        } // Modified line
    }, [activeSection]);

    
    useEffect(() => {
        sectionsRef.current = document.querySelectorAll("section");
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);


    const onMenuItemSelect = useCallback(
        sectionId => {
            onHide();
            const element = document.getElementById(sectionId);
            if (element) {
                const headerOffset = 60;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.scrollY - headerOffset;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        },
        [onHide]
    );

    return (
        <SiteNav className={`ms-auto my-2 my-lg-0 ${styleClass}`} navbarScroll>
            <NavItem sectionId="banner"     onMenuItemSelect={onMenuItemSelect}>            {t('titles.home')}         </NavItem>
            {user?._id && user.role !== "affiliate" && (
                <NavItem sectionId="videos" onMenuItemSelect={onMenuItemSelect}>            {t('titles.my_promo')}     </NavItem>
            )}
            <NavItem sectionId="services"   onMenuItemSelect={onMenuItemSelect}>            {t('titles.services')}     </NavItem>
            <NavItem sectionId="mission"    onMenuItemSelect={onMenuItemSelect}>            {t('titles.mission')}      </NavItem>
            <NavItem sectionId="privacy"    onMenuItemSelect={onMenuItemSelect}>            {t('titles.privacy')}      </NavItem>
            <NavItem sectionId="about"      onMenuItemSelect={onMenuItemSelect}>            {t('titles.about')}        </NavItem>
            <NavItem sectionId="contact"    onMenuItemSelect={onMenuItemSelect}>            {t('titles.contact')}      </NavItem>
            
 
            {/* Language Dropdown placed after 'Contact' */}
            <Dropdown>
                <Dropdown.Toggle
                    variant="link"
                    id="language-dropdown"
                    style={{ color: '#007bff', fontWeight: 'bold' }}  // Custom style for the toggle button
                >
                    {currentLanguage === 'en' ? 'English' : currentLanguage === 'fr' ? 'Français' : currentLanguage === 'es' ? 'Español' : 'Kreyol'}  {/* Show current language */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {currentLanguage !== 'ht' && (
                        <Dropdown.Item
                            onClick={() => i18n.changeLanguage("ht")}
                            style={{ color: 'blue', fontWeight: '500' }}  // Green option for Kreyol
                        >
                            Kreyol
                        </Dropdown.Item>
                    )}
                    {currentLanguage !== 'en' && (
                        <Dropdown.Item
                            onClick={() => i18n.changeLanguage("en")}
                            style={{ color: 'green', fontWeight: '500' }}  // Blue option for English
                        >
                            English
                        </Dropdown.Item>
                    )}
                    {currentLanguage !== 'es' && (
                        <Dropdown.Item
                            onClick={() => i18n.changeLanguage("es")}
                            style={{ color: 'orange', fontWeight: '500' }}  // Green option for Spanish
                        >
                            Español
                        </Dropdown.Item>
                    )}
                    {currentLanguage !== 'fr' && (
                        <Dropdown.Item
                            onClick={() => i18n.changeLanguage("fr")}
                            style={{ color: 'red', fontWeight: '500' }}  // Red option for French
                        >
                            Français
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>




            {["admin", "power", "support", "audit", "affiliate"].includes(user?.role) && (
                <NavItem sectionId="dashboard" onMenuItemSelect={onMenuItemSelect}>         {t('titles.dashboard')}    </NavItem>
            )}


            {user?._id  && (
                <NavItem sectionId="messages" onMenuItemSelect={onMenuItemSelect}>      <BiCommentDetail />     </NavItem>  )}

            <UserDropdownBox isScrolled={styleClass} onHide={onHide} />
        </SiteNav>
    );
};

const UserDropdownBox = ({ isScrolled, onHide }) => {
    const { t } = useTranslation('homeTopBar');
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    return user?._id ? (
        <ProfileDropDown styleClass={isScrolled} />
    ) : (
        <LoginButton
            variant="primary"
            onClick={() => {
                navigate("/signin");
                onHide(); // Close the menu when Login2 button is clicked
            }}>
            <span>{t('buttons.login')}</span>
        </LoginButton>
    );
};

export default SiteNavBar;
