/* File: /src/services/orderVehiclePartsOrderApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const orderVehiclePartsOrderApi = createApi({
    reducerPath: "orderVehiclePartsOrderApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/vehiclePartsOrder",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["carRequests"],
    endpoints: builder => ({
        getAllVehicleRequests:      builder.query({    query: ({ source }) =>       ({ url: "/",                method: "GET", params: { source }, }),                       providesTags: ["carRequests"] }),
        getVehicleRequestsByUserId: builder.query({    query: id =>       ({ url: `/user/${id}`,      method: "GET" }),                       providesTags: ["carRequests"] }),
        
        requestQuote:               builder.mutation({ query: data =>     ({ url: `/requestForQuote`, method: "POST", body: data }),          invalidatesTags: ["carRequests"] }),
        
        updateVehiclePartsOrder:    builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,   method: "PATCH", body: payload.data }), invalidatesTags: ["carRequests"] }),
        deleteVehiclePart:          builder.mutation({ query: payload =>  ({ url: `/${payload.orderId}/part/${payload.partId}`, method: "PATCH", body: payload.data }), invalidatesTags: ["carRequests"] }),

        deleteOrder:                builder.mutation({ query: payload => ({ url: `/${payload.orderId}`, method: "DELETE", body: payload.data }), invalidatesTags: ["carRequests"] }),


        healthCheck:                builder.query   ({ query: ({ source }) =>       ({ url: `/health`,          method: "GET", params: { source }, })        }),

    })
});

export const { 
    useHealthCheckQuery,
    useGetAllVehicleRequestsQuery, 
    useGetVehicleRequestsByUserIdQuery, 
    useRequestQuoteMutation,
    useUpdateVehiclePartsOrderMutation,
    useDeleteVehiclePartMutation,
    useDeleteOrderMutation,
} = orderVehiclePartsOrderApi;
