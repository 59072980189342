import React, { useState } from 'react';
import './TableStyles.css';

const generateRandomData = () => {
  const names = ["John", "Jane", "Alice", "Bob", "Charlie", "Diana", "Eve", "Frank", "Grace", "Henry"];
  const genders = ["Male", "Female"];
  
  return Array.from({ length: 10 }, (_, id) => ({
    id: id + 1,
    name: names[Math.floor(Math.random() * names.length)],
    age: Math.floor(Math.random() * 50) + 18, // Random age between 18-67
    gender: genders[Math.floor(Math.random() * genders.length)],
    inputText: '',
    isChecked: false,
  }));
};

export const Table = () => {
  const [data, setData] = useState(generateRandomData());
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 5;

  // Calculate the total number of pages
  const filteredData = data.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleInputChange = (id, value) => {
    setData(prevData =>
      prevData.map(item =>
        item.id === id ? { ...item, inputText: value } : item
      )
    );
  };

  const handleCheckboxChange = (id) => {
    setData(prevData =>
      prevData.map(item =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  // Pagination logic
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  return (
    <div className="table-container">
           {/* Search Bar */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>

      <table className="responsive-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Input</th>
            <th>Checkbox</th>
            <th>Button 1</th>
            <th>Button 2</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.age}</td>
              <td>{item.gender}</td>
              <td>
                <input
                  type="text"
                  value={item.inputText}
                  onChange={(e) => handleInputChange(item.id, e.target.value)}
                />
              </td>
              <td>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={item.isChecked}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                  <span className="checkmark"></span>
                </label>
              </td>
              <td>
                <button className="button1">Button 1</button>
              </td>
              <td>
                <button className="button2">Button 2</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

   
      {/* Pagination Controls */}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};
