/* File: /src/services/svcTranscriptApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const svcTranscriptApi = createApi({
    reducerPath: "svcTranscriptApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/svcTranscripts",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["Transcripts"],
    endpoints: builder => ({
        getAllTranscripts:  builder.query({    query: ({ source }) =>        ({ url: "/",                               method: "GET", params: { source } }),         providesTags: ["Transcripts"] }),
        getTranscriptById:  builder.query({    query: ({ id, source }) =>    ({ url: `/${id}`,                          method: "GET", params: { source } }),         providesTags: ["Transcripts"] }),
       
        
        
        getTranscriptionStatus: builder.mutation({ query: ({ s3Key, source }) => ({ url: "/transcription-status",  method: "POST", body: { s3Key, source }  }), invalidatesTags: ["Transcripts"] }),

        uploadTranscript:   builder.mutation({ query: ({ data, source }) =>  ({ url: "/",                method: "POST", body: { ...data, source } }), invalidatesTags: ["Transcripts"] }),
     
        updateTranscript:   builder.mutation({ query: ({ payload, source }) => ({ url: `/${payload.id}`, method: "PATCH", body: { ...payload.data, source } }), invalidatesTags: ["Transcripts"] }),
        
        deleteTranscript:   builder.mutation({ query: id =>                  ({ url: `/${id}`,           method: "DELETE" }),                          invalidatesTags: ["Transcripts"] }),
        healthCheck:        builder.query   ({ query: ({ source }) =>        ({ url: `/health`,          method: "GET", params: { source }, })  }),
        //getTranscriptById:   builder.query({    query: id =>       ({ url: `/${id}`,           method: "GET" }),                        providesTags: ["Transcripts"] }),
        //   updateTranscript:    builder.mutation({ query: payload =>             ({ url: `/${payload.id}`,   method: "PATCH", body: payload.data }),       invalidatesTags: ["Transcripts"] }),

        transcribeAudioToText: builder.mutation({
            query: ({ s3Key, source, selectedLanguage }) => {
                const formData = new FormData();
                formData.append("s3Key", s3Key);
                formData.append("source", source);
                formData.append("selectedLanguage", selectedLanguage);

                return {
                    url: "/transcribe",
                    method: "POST",
                    body: formData,
                };
            },
            invalidatesTags: ["Transcripts"]
        }),
        requestPresignedUrl: builder.mutation({ query: ({ fileName, fileType, source }) => ({ url: "/presigned-url", method: "POST", body: { fileName, fileType, source } }) }),

    })
});

export const { 
    useHealthCheckQuery,
    useDeleteTranscriptMutation,
    useGetAllTranscriptsQuery,
    useGetTranscriptByIdQuery,
    useGetTranscriptionStatusMutation,
    useUpdateTranscriptMutation,
    useUploadTranscriptMutation,
    useTranscribeAudioToTextMutation,
    useRequestPresignedUrlMutation,
} = svcTranscriptApi;
