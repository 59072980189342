import React from "react";
import "./styles.css";

const DeletePopup = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-box">
        <h4>{message}</h4>
        <div className="popup-buttons">
          <button onClick={onConfirm} className="popup-button confirm">
            Yes
          </button>
          <button onClick={onCancel} className="popup-button cancel">
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
