import styled from 'styled-components';
import FONTS from '../../config/fonts';
import { Colors } from "../../config/colors";
export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

export const PrivacyContent = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
  margin: 40px 0;
  color: ${Colors.grayColor};
  font-family: ${FONTS.lexend};
  line-height : 25px;
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  p {
    margin-bottom: 16px;
  }

  .italic {
    color: #718096;
    font-style: italic;
  }
`;
