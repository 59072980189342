/* REAL - MY SUBSCRIPTIONS - PROMO LIST */
/* File: /src/pages/Subscruptions/Promo/index.jsx  */
import { useNavigate }                  from "react-router-dom";
import React, 
        { 
        useContext, 
        useState, 
        useEffect 
        }                               from "react";
import { Button,
        }                               from "react-bootstrap";
import { 
        TabContentContainer,
        TabSubTitle,
        TableContainer 
        }                               from "../../Tabs/tabContentStyle";
import { 
        staggerVariants, 
        titleVariants 
        }                               from "../../../utils/FmVariants";
import TableComponent                   from "../../../components/UI/TableComponent";
import { 
        useGetVideosByUserQuery 
        }                               from "../../../services/listVideoApi";
import {
        useGetInterestsQuery
        }                               from "../../../services/userUserStoreApi";
import { 
        UserContext 
        }                               from "../../../App";

const SubsPromo = () => {
    const navigator                                     = useNavigate();
    const { user }                                      = useContext(UserContext);
    const { data, isLoading }                           = useGetVideosByUserQuery(user?._id);
    const { data: user_Interests, 
            isLoading: isLoadingInterests }             = useGetInterestsQuery(user?._id);
    const [videoLists,          setVideoLists]          = useState([]);
    const [videoError,          setVideoError]          = useState(false);
    const [videoErrorMessage,   setVideoErrorMessage]   = useState("");

    /* Section 01.0 - useEffect - [data, user] */
    useEffect(() => {
        if (data && user?.role === "affiliate") {
            const videoList = data?.data.filter(item => item.affiliates === user._id);
            setVideoLists(videoList);
        } else if (user?.role === "user") {
            const interests = user_Interests?.data || [];
            if (interests.length > 0) {
                const matchedObjects = data?.data?.filter(obj => obj.interests.some(item => interests.includes(item)));
                if (matchedObjects && matchedObjects.length > 0) {
                    setVideoLists(matchedObjects);
                } else {
                    setVideoError(true);
                    setVideoErrorMessage("No videos available for your selected interest.");
                }
            } else {
                setVideoError(true);
                setVideoErrorMessage("Please select at least one interest.");
            }
        } else {
            const videoList = data?.data;
            setVideoLists(videoList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, user, user_Interests]);

    
    /* Section 02.0 - getInterestCount - Function to get full name */
    const fullname = user => {
        if (!user || user.length === 0) return "";
        return user[0].firstName + " " + user[0].lastName;
    };

    if (isLoading || !data) {
        return <p>Loading Videos...</p>;
    }
    if (isLoadingInterests ) {
        return <p>Loading Interests...</p>;
    }

    return (
        <TabContentContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
            <TableContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TabSubTitle>List of Promotions</TabSubTitle>
                    <Button variant="primary" className="ms-2" onClick={() => navigator("/videos")}>
                        My Promo
                    </Button>
                </div>
                {videoError ? (
                    <div className="">{videoErrorMessage}</div>
                ) : (
                    <TableComponent variants={titleVariants} isDataTable={+false}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Affiliate</th>
                                <th width={"40%"}>Description</th>
                                <th>Recent Impressions</th>
                                <th>My Rating</th>
                            </tr>
                        </thead>
                        <tbody>
                            {videoLists?.map((data, i) => (
                                <tr key={i + 1}>
                                    <td>{i < 100 ? `00${i + 1}` : `${i}`}</td>
                                    <td>{fullname(data?.user)}</td>
                                    <td>{data?.description}</td>
                                    <td>{data?.comments[0]?.comment ? `${data?.comments[0]?.comment}` : "Not Commented"}</td>
                                    <td>{data?.comments[0]?.rating ? `${data?.comments[0]?.rating}/5` : "Not Rated"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </TableComponent>
                )}
            </TableContainer>
        </TabContentContainer>
    );
};

export default SubsPromo;
