import React, 
        { useState, useEffect }               from "react";
import { 
        useTranslation 
        }                                     from 'react-i18next';
import {
        AiOutlineArrowLeft,
        AiOutlineDelete,
        AiOutlineEdit,
        }                                     from "react-icons/ai";
import { 
        toast 
        }                                     from "react-toastify";
import Select                                 from 'react-select'; 
import                                              "./styles.css";
import DeletePopup                            from "./deletePopup";

import { 
        useGetBeneficiaryMutation, 
        useSaveBeneficiaryMutation,
        useDeleteBeneficiaryMutation,
        }                                     from "../../../../services/userUserStoreApi";
import { 
      SVGImages 
        }                                     from "../../../../config/images";

// Map the countries to their respective currencies
const countryCurrencyMap = {
  "Bahamas":              { flag: SVGImages.FlagBahamas,              currencies: ["USD"] },
  "Canada":               { flag: SVGImages.FlagCanada,               currencies: ["CAD"] },
  "Dominican Republic":   { flag: SVGImages.FlagDominicanRepublic,    currencies: ["DOP", "USD"] },
  "Haiti":                { flag: SVGImages.FlagHaiti,                currencies: ["HTG", "USD"] },
  "Turks and Caicos":     { flag: SVGImages.FlagTurksAndCaicos,       currencies: ["USD"] },
  "United States":        { flag: SVGImages.FlagUnitedStates,         currencies: ["USD"] },
};

const countryOptions = Object.keys(countryCurrencyMap).map(country => ({
  value: country,
  label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={countryCurrencyMap[country].flag} alt={`${country} flag`} style={{ width: '20px', marginRight: '10px' }} />
          {country}
      </div>
  )
}));

const AddBeneficiaryBox = ({ user, onBackClick }) => {
  const { t }                 = useTranslation("subsRemittance");
  const Source_Name           = "MY SUBSCRIPTIONS - REMITTANCE";

  // Form state for adding/editing beneficiaries
  const [userBeneficiaryData,         setUserBeneficiaryData]   = useState({ data: [] });
  const [firstName,                   setFirstName]             = useState("");
  const [lastName,                    setLastName]              = useState("");
  const [city,                        setCity]                  = useState("");
  const [address,                     setAddress]               = useState("");
  const [phone,                       setPhone]                 = useState("");
  const [country,                     setCountry]               = useState("");
  const [currency,                    setCurrency]              = useState("");
  const [errors,                      setErrors]                = useState({});
  const [isPopupVisible,              setIsPopupVisible]        = useState(false);
  const [selectedBeneficiaryId,       setSelectedBeneficiaryId] = useState(null);
  const [selectedBeneficiary,         setSelectedBeneficiary]   = useState(null);
  const [availableCurrencies,         setAvailableCurrencies]   = useState([]);
  const [UserStore_getBeneficiary]                              = useGetBeneficiaryMutation();
  const [userStore_Save]                                        = useSaveBeneficiaryMutation();
  const [userStore_deleteBeneficiary]                           = useDeleteBeneficiaryMutation();

  useEffect(() => {
    if (user?._id) {
      const fetchBeneficiaryData = async () => {
        try {
          const data_getBeneficiary = { userId: user._id, };
          const result = await UserStore_getBeneficiary({ data: data_getBeneficiary,  source: Source_Name,  });
          console.log("User Beneficiary Data in State:", result);
          console.log("Number of Beneficiaries:", result?.data?.data?.length);

  
          setUserBeneficiaryData(result);
          
        } catch (error) {
          console.error("Error fetching user beneficiaries:", error);
          setUserBeneficiaryData({ data: [] });
        }
      };
  
      fetchBeneficiaryData();  // Call the async function
    } else { 
      console.error("NOOOO user");
    }
  }, [UserStore_getBeneficiary, user?._id, Source_Name]);
  
  // Update the available currencies whenever the country changes
  useEffect(() => {
    if (country && countryCurrencyMap[country]) {
      setAvailableCurrencies(countryCurrencyMap[country].currencies);
      setCurrency(''); // Reset currency selection when country changes
    } else {
      setAvailableCurrencies([]);
      setCurrency(''); // Reset currency if no country is selected
    }
  }, [country]);

  const handleDeleteBeneficiary = async (beneficiaryId) => {
    try {
      const response = await userStore_deleteBeneficiary(beneficiaryId).unwrap();
  
      if (response?.status) {
        toast.success(response.message);
  
        // Fetch the updated list of beneficiaries after deleting
        const fetchBeneficiaryData = async () => {
          const data_getBeneficiary = { userId: user._id, };
          const result = await UserStore_getBeneficiary({ data: data_getBeneficiary, source: Source_Name });
            
          setUserBeneficiaryData({ data: result?.data || [] });
        };
  
        await fetchBeneficiaryData(); // Call to refresh the list after deletion
  
        setIsPopupVisible(false);
      } else {
        toast.error(response?.message || "Failed to delete beneficiary.");
      }
  
    } catch (error) {
      console.error("Error deleting beneficiary:", error);
      toast.error("Failed to delete beneficiary.");
    }
  };
  
  const handleDeleteIconClick = (beneficiaryId) => {
    setSelectedBeneficiaryId(beneficiaryId);
    setIsPopupVisible(true);
  };

  const confirmDelete = () => {
    if (selectedBeneficiaryId) {
      handleDeleteBeneficiary(selectedBeneficiaryId); // Call delete function after confirmation
    }
    setIsPopupVisible(false);
    setSelectedBeneficiaryId(null);
  };
  
  const cancelDelete = () => {
    setIsPopupVisible(false);
    setSelectedBeneficiaryId(null);
  };

  const handlePhoneChange = (e) => {
    const phoneValue = e.target.value;
    if (/^\d{0,10}$/.test(phoneValue)) {
      setPhone(phoneValue);
    }
  };

  const handleEditClick = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setFirstName(beneficiary.firstName);
    setLastName(beneficiary.lastName);
    setCity(beneficiary.city);
    setAddress(beneficiary.address);
    setPhone(beneficiary.phone);
    setCountry(beneficiary.country);
    setCurrency(beneficiary.currency);
    setErrors({});
  };
      
  const validateFields = () => {
    let formErrors = {};
    if (!firstName) formErrors.firstName  = "First Name is required";
    if (!lastName)  formErrors.lastName   = "Last Name is required";
    if (!city)      formErrors.city       = "City is required";
    if (!address)   formErrors.address    = "Address is required";
    if (!currency)  formErrors.currency   = "Currency is required";
    if (!country)   formErrors.country    = "Country is required";
    if (!phone)     formErrors.phone      = "Phone number is required";
    else if (phone.length !== 10)
                    formErrors.phone      = "Phone number must be 10 digits";
    
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleAddBeneficiary = async (e) => {
    e.preventDefault();
  
    if (!validateFields()) {
      return;
    }
  
    // Construct the new beneficiary object
    const newBeneficiary = {
      firstName,
      lastName,
      city,
      address,
      phone,
      country,
      currency,
      userId: user?._id,
    };
  
    try {
      // Save the new beneficiary to the backend
      const res_SaveBeneficiary = await userStore_Save(newBeneficiary);
      if (res_SaveBeneficiary.data?.status) {
        toast.success(res_SaveBeneficiary?.data?.message);
  
        // Clear form fields after adding beneficiary
        setFirstName("");
        setLastName("");
        setCity("");
        setAddress("");
        setPhone("");
        setCountry("");
        setCurrency("");
        setErrors({});
  
        // Fetch updated list of beneficiaries after adding a new one
        const fetchBeneficiaryData = async () => {
          const data_getBeneficiary = { userId: user._id, };
          const result = await UserStore_getBeneficiary({ data: data_getBeneficiary, source: Source_Name });
          setUserBeneficiaryData({ data: result?.data || [] }); // Update the state with the new list
        };
  
        await fetchBeneficiaryData(); // Call the function to update the list
  
      } else {
        toast.error(res_SaveBeneficiary?.data?.message);
      }
    } catch (error) {
      toast.error("Error saving beneficiary. Please try again.");
      console.error("Error saving beneficiary:", error);
    }
  };
        
  useEffect(() => {
    if (selectedBeneficiary) {
      // Set the currency based on the selected beneficiary's country or default currency
      const beneficiaryCurrency = selectedBeneficiary.currency || availableCurrencies[0];
      setCurrency(beneficiaryCurrency);
    } else if (availableCurrencies.length === 1) {
      // Automatically set the currency if only one is available
      setCurrency(availableCurrencies[0]);
    }
  }, [selectedBeneficiary, availableCurrencies, setCurrency]);
  


  return (
    <div className="add-beneficiary-box">
      <button className="back-button" onClick={onBackClick}>
        <AiOutlineArrowLeft style={{ fontSize: '24px' }}/> Back
      </button>

      <h3>{t('Beneficiary.title_AddBeneficiary')}</h3>

      <div className="beneficiary-container">
        <div className="beneficiary-form-container">


          <form onSubmit={handleAddBeneficiary} className="beneficiary-form">
            <div className="form-fields">

              <div className="form-row">
                {/* First Name  */}
                <div>
                  <label>First Name:</label>
                  <input      type="text"     value={firstName}     onChange={(e) => setFirstName(e.target.value)}    />
                  {errors.firstName && (  <p  className="error-message">{errors.firstName}</p> )}
                </div>

                {/* Last Name  */} 
                <div>
                  <label>Last Name:</label>
                  <input      type="text"     value={lastName}      onChange={(e) => setLastName(e.target.value)}    />
                  {errors.lastName &&  (  <p  className="error-message">{errors.lastName}</p>  )}
                </div>

              </div>

              <div className="form-row">
                {/* City  */}
                <div>
                  <label>City:</label>
                  <input      type="text"     value={city}          onChange={(e) => setCity(e.target.value)}        />
                  {errors.city &&      (  <p  className="error-message">{errors.city}</p>                        )}
                </div>

                {/* Country  */}
                <div>
                  <label>Country:</label>
                  <Select
                    options={countryOptions}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption.value);
                      const selectedCountry = selectedOption.value;
                      const currencies      = countryCurrencyMap[selectedCountry].currencies || [];
                      setAvailableCurrencies(currencies);
                      setCurrency(''); // Reset currency when country changes
                    }}
                    value={countryOptions.find(option => option.value === country)}
                  />
                  {errors.country && <p className="error-message">{errors.country}</p>}
                </div>
              </div>

              {/* Address  */}
              <div>
                <label>Address:</label>
                <input    type="text"         value={address}       onChange={(e) => setAddress(e.target.value)}   />
                {errors.address &&    (    <p className="error-message">{errors.address}</p>                      )}
              </div>

              <div className="form-row">
                {/* Phone  */}
                <div>
                  <label>Phone:</label>
                  <input    type="tel"          value={phone}         onChange={handlePhoneChange}    placeholder="Enter 10-digit phone number" />
                  {errors.phone &&      (    <p className="error-message">{errors.phone}</p>                      )}
                </div>

                {/* Currency  */}
                <div>
                  <label>Currency:</label>
                  <select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    disabled={availableCurrencies.length <= 1} // Disable if there's only one option
                  >
                    {/* Automatically select the currency if only one option is available */}
                    {availableCurrencies.length > 1 ? (
                      <>
                        <option value="" disabled>
                          Select Currency
                        </option>
                        {availableCurrencies.map((currencyOption) => (
                          <option key={currencyOption} value={currencyOption}>
                            {currencyOption}
                          </option>
                        ))}
                      </>
                    ) : (
                      availableCurrencies.length === 1 && (
                        <option key={availableCurrencies[0]} value={availableCurrencies[0]}>
                          {availableCurrencies[0]}
                        </option>
                      )
                    )}
                  </select>
                  {errors.currency && <p className="error-message">{errors.currency}</p>}
                </div>
              </div>

              {/* BUTTON: Add Beneficiary  */}
              <button type="submit" className="add-button">
                Save Beneficiary
              </button>
            </div>
          </form>

        </div>

        {/* BENEFICIARY LIST  */}
        <div className="beneficiary-list-container">
          <div className="beneficiary-list-header"> <h4>Beneficiary List</h4>    </div>

          <ul className="beneficiary-list">
            {userBeneficiaryData?.data?.data?.map((item) => (
              <li key={item._id}    className="beneficiary-item"  onClick={() => handleEditClick(item)} >
                {item.firstName} {item.lastName} - {item.city}, {item.address}, {" "}  {item.phone} [{item.currency}] ({item.country})
                <AiOutlineEdit    style={{ fontSize: '24px' }} className="edit-icon"   onClick={(e) => { e.stopPropagation();  handleEditClick(item);             }} />
                <AiOutlineDelete  style={{ fontSize: '24px' }} className="delete-icon" onClick={(e) => { e.stopPropagation();  handleDeleteIconClick(item._id);   }} />
              </li>
            ))}
          </ul>
        </div>

      </div>

      {/* DeletePopup  */}
      {isPopupVisible && (
        <DeletePopup
          message="Are you sure you want to delete this beneficiary?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </div>
  );
};

export default AddBeneficiaryBox;