/* REAL - DASH - MANAGE - DOCUMENTS */
/* File: /src/pages/Dashboard/DashboardPages/Services-UserDocuments/ManageUserDocuments.js  */
import { useTranslation 
}                                               from 'react-i18next';
import React, 
        { 
        useEffect, 
        useState }                          from "react";
import { Modal, 
         Button, 
         Form, 
         Table }                            from "react-bootstrap";
import { toast }                            from "react-toastify";
import { BiSolidPencil }                    from "react-icons/bi";
import moment                               from "moment";
import { useGetAllDocumentsQuery, 
         useUpdateDocumentStatusMutation,
         useGetDocumentByIdQuery,
         useDeleteDocumentMutation,
        }                           from "../../../../services/userDocumentApi";
import { useGetAllUsersQuery }          from "../../../../services/userUserApi";

const ManageDocuments = () => {
    const { t } = useTranslation('manageUserDocuments');
    const Source_Name           = "MANAGE - DOCUMENTS";

    // State to handle documents and modals
    const [selectedDoc,         setSelectedDoc]         = useState(null);
    const [showModal,           setShowModal]           = useState(false);
    const [docStatus,           setDocStatus]           = useState("");
    const [docNote,             setDocNote]             = useState("");
   // const [docExpiry,           setDocExpiry]           = useState("");
    const [originalDocExpiry,   setOriginalDocExpiry]   = useState("");
    const [docExpiryDisplay,    setDocExpiryDisplay]    = useState(""); // Displayed in the calendar input
    const [isDateTouched,       setIsDateTouched]       = useState(false);

    const [pdfUrl,              setPdfUrl]              = useState(null);
    const [fileName,            setFileName]            = useState('');
    const [selectedUserId,      setSelectedUserId]      = useState('');
    const [selectedFirstName,   setSelectedFirstName]   = useState('');
    const [selectedLastName,    setSelectedLastName]    = useState('');

    const [showFileModal,       setShowFileModal]       = useState(false);
    const [fileContent,         setFileContent]         = useState(null);
    const [fileType,            setFileType]            = useState('');

    const [showDeleteModal,     setShowDeleteModal]     = useState(false);
    const [docIdToDelete,       setDocIdToDelete]       = useState(null);


    const [selectedFileId, setSelectedFileId] = useState(null); // State to store the file ID

    const { data: documentsData, isLoading: isDocumentsLoading, refetch } = useGetAllDocumentsQuery({ source: Source_Name });
    const { data: userLists }                           = useGetAllUsersQuery({ source: Source_Name });
    const [updateDocumentStatus]                        = useUpdateDocumentStatusMutation();
    const [Document_deleteDocument]                     = useDeleteDocumentMutation();

    // Fetch document by selected ID using useGetDocumentByIdQuery
    // const { data: documentData } = useGetDocumentByIdQuery(selectedFileId, { skip: !selectedFileId });
    const { data: documentData } = useGetDocumentByIdQuery({ id: selectedFileId, source: Source_Name }, { skip: !selectedFileId });


    useEffect(() => {
        if (documentData) {
            const { blob, contentType } = documentData;
              if (blob instanceof Blob) {
                // Create a URL for the file
                const url = URL.createObjectURL(blob);
                setPdfUrl(url);
                setFileType(contentType);
    
                // Assuming you can get the filename and user name from documentData
                setFileName(`promoFile.${contentType.split("/")[1]}`); // Example filename

                /*
                // Automatically download the file or view it
                const link = document.createElement('a');
                link.href = url;
                link.download = `promoFile.${contentType.split("/")[1]}`; // You can set the file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                */

                setFileContent(url);
                setShowFileModal(true);

            } else {
                console.error("Invalid Blob object");
            }
        }
    }, [documentData, selectedFileId]);
    
    useEffect(() => {
        if (selectedDoc) {
            setDocStatus(selectedDoc.docStatus); // Set the selected document's status in the form
            setDocNote(selectedDoc.docNote);
            // setDocExpiry(selectedDoc.docExpiry ? moment(selectedDoc.docExpiry).format('YYYY-MM-DD') : ""); // Format the docExpiry
            setOriginalDocExpiry(selectedDoc.docExpiry);
            setIsDateTouched(false);  // Reset interaction flag when opening modal
            setShowModal(true);
        }
    }, [selectedDoc]);

    const handleSelectDoc = (doc, userId) => {
        setSelectedUserId(userId);
        setSelectedDoc(doc);
        setDocStatus(doc.docStatus);
        setDocNote(doc.docNote); 

        // Set both the original and displayed expiry values
        const formattedExpiry = doc.docExpiry ? moment(doc.docExpiry).format('YYYY-MM-DD') : "";
        // setDocExpiry(doc.docExpiry);
        setDocExpiryDisplay(formattedExpiry);
        setOriginalDocExpiry(doc.docExpiry);  // Store original expiry value
        setIsDateTouched(false);              // Reset touch status
        setShowModal(true);
    };

    // Function to handle viewing the file
    const handleViewFile = (doc, userId) => {
        const fileId = doc._id; // Use the document ID as the file ID
        setSelectedUserId(userId);
        setSelectedFirstName(getFirstName(userId));
        setSelectedLastName(getLastName(userId));
        setSelectedFileId(fileId); // Trigger the useGetDocumentByIdQuery
    };

    // Clean up Blob URL when component unmounts
    useEffect(() => {
        return () => {
            if (fileContent) {
                URL.revokeObjectURL(fileContent);
                setFileContent(null);
            }
        };
    }, [fileContent]);

    const getFullName = (userId) => {
        const user = userLists?.data?.find(user => user._id === userId);
        return user ? `${user.firstName} ${user.lastName}` : '-Non Existent-';
    };

    const getFirstName = (userId) => {
        const user = userLists?.data?.find(user => user._id === userId);
        return user ? user.firstName : '-Non Existent-';
    };
    
    const getLastName = (userId) => {
        const user = userLists?.data?.find(user => user._id === userId);
        return user ? user.lastName : '-Non Existent-';
    };
    

    const handleUpdateDocStatus = async () => {
        if (!docStatus) {
            toast.error(t('messages.select_status'));
            return;
        }
    
        const data = {
            docId: selectedDoc._id,
            docStatus,
            docNote,
            docExpiry: docExpiryDisplay,  // Use the displayed expiry value
        };
    
        const source = 'MANAGE - DOCUMENTS';  // Define source separately
        
        try {
            const result = await updateDocumentStatus({ ...data, source });
            if (result.data.status) {
                toast.success(t('messages.document_updated_success'));
                setShowModal(false);
                refetch(); 
            } else {
                toast.error(t('messages.document_updated_failure'));
            }
        } catch (error) {
            toast.error(t('messages.error_updating_document'));
        }
    };
    
    const openDeleteConfirmation = (docId) => {
        setDocIdToDelete(docId);
        setShowDeleteModal(true);
    };
    

    const handleDeleteDocument = () => {
        if (docIdToDelete) {
            Document_deleteDocument({ data: { id: docIdToDelete, source: Source_Name } }).then(response => {
                if (response?.data?.status) {
                    toast.success(t('messages.document_deleted_success'));
                    refetch();
                } else {
                    toast.error(t('messages.document_deleted_failure'));
                }
            }).catch(error => {
                toast.error(t('messages.error_deleting_document'));
            }).finally(() => {
                setShowDeleteModal(false);
            });
        }
    };
    
    


    


    return (
        <div className="manage-documents">
            <h2>{t('title.manage_documents')}</h2>

            {isDocumentsLoading ? (
                <p>{t('labels.loading_documents')}</p>
            ) : (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>{t('labels.no')}</th>
                            <th>{t('labels.member')}</th>
                            <th>{t('labels.document_type')}</th>
                            <th>{t('labels.document_note')}</th>
                            <th>{t('labels.status')}</th>
                            <th>{t('labels.expiry_date')}</th>
                            <th>{t('labels.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentsData?.documents?.map((docData, index) =>
                            docData.userIdDoc.map((doc, docIndex) => (
                                <tr key={doc._id}>
                                    <td>{index + 1}.{docIndex + 1}</td>
                                    <td>{getFullName(docData.userId)}</td>
                                    <td>{doc.docType}</td>
                                    <td>{doc.docNote}</td>
                                    <td>{doc.docStatus}</td>
                                    <td>{doc.docExpiry ? moment(doc.docExpiry).format('YYYY-MM-DD') : 'N/A'}</td> 
                                    <td>
                                        {/* Pencil icon to edit document */}
                                        <BiSolidPencil
                                            className="action-icon"
                                            onClick={() => handleSelectDoc(doc, docData.userId)}
                                            style={{ cursor: "pointer", color: "blue" }}
                                        />
                                        {/* View button */}
                                        <Button
                                            variant="link"
                                            onClick={() => handleViewFile(doc, docData.userId )}
                                            style={{ cursor: "pointer", color: "green" }}
                                        >
                                            {t('buttons.view')}
                                        </Button>
                                        {/* DEL button */}
                                        <Button
                                            variant="danger"
                                            onClick={() => openDeleteConfirmation(doc._id)}
                                            style={{ cursor: "pointer", marginLeft: "10px" }}
                                        >
                                            {t('buttons.delete')}
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            )}

            {/* Modal to update document status */}
            <Modal 
                    show={showModal} 
                    onHide={() => {
                        if (!isDateTouched) {
                            setDocExpiryDisplay(originalDocExpiry ? moment(originalDocExpiry).format('YYYY-MM-DD') : "");  // Revert to original display value
                        }
                        setIsDateTouched(false);  
                        setShowModal(false);
                    }} 
                    backdrop="static" 
                    keyboard={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>{t('title.update_document', { name: getFullName(selectedUserId) })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* New Form Group for Expiry Date */}
                    <Form.Group controlId="formDocExpiry">
                        <Form.Label>{t('labels.document_expiry_date', { type: selectedDoc?.docType })}</Form.Label>
                        <Form.Control
                            type="date"
                            value={docExpiryDisplay}
                            onChange={(e) => {
                                setDocExpiryDisplay(e.target.value);
                                setIsDateTouched(true);
                            }}
                        />
                    </Form.Group>


                    <Form.Group controlId="formDocStatus">
                        <Form.Label>{t('labels.document_status', { type: selectedDoc?.docType })}</Form.Label>
                        <Form.Control
                            as="select"
                            value={docStatus}
                            onChange={(e) => setDocStatus(e.target.value)}
                        >
                            <option value="">{t('messages.select_status')}</option>
                            <option value="Submitted">Submitted</option>
                            <option value="Processing">Processing</option>
                            <option value="Verified">Verified</option>
                            <option value="Rejected">Rejected</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formDocNote">
                        <Form.Label>{t('labels.document_note_field')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={docNote}
                            onChange={(e) => setDocNote(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        {t('buttons.close')}
                    </Button>

                    <Button 
                        variant={docStatus === 'Verified' && !docExpiryDisplay ? "warning" : "primary"}  // Check docExpiryDisplay instead of docExpiry
                        onClick={handleUpdateDocStatus}
                        disabled={docStatus === 'Verified' && !docExpiryDisplay}  // Check docExpiryDisplay instead of docExpiry
                    >
                        {docStatus === 'Verified' && !docExpiryDisplay ? t('buttons.set_expiry_date') : t('buttons.update_status')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to display the file */}
            <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t('title.document_verification', { id: selectedUserId.slice(-7) })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <p>{t('labels.file_name')}: {fileName}</p>
                        <p><strong>{t('labels.first_name')}:</strong> {selectedFirstName}</p>
                        <p><strong>{t('labels.last_name')}:</strong> {selectedLastName}</p>
                        
                        {fileType.startsWith('image/') ? (
                            <img src={pdfUrl} alt="Document" style={{ width: '100%' }} />
                        ) : fileType === 'application/pdf' ? (
                            <iframe
                                src={fileContent} title="PDF Document" style={{ width: '100%', height: '500px' }}
                            />
                        ) : (
                            <p>{t('labels.unsupported_file_type')}</p>
                        )}
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowFileModal(false)}>
                        {t('buttons.close_modal')}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('title.confirm_document_removal')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('messages.delete_confirmation')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        {t('buttons.cancel_delete')}
                    </Button>
                    <Button variant="danger" onClick={handleDeleteDocument}>
                        {t('buttons.confirm_delete')}
                    </Button>
                </Modal.Footer>
            </Modal>




        </div>
    );
};

export default ManageDocuments;
