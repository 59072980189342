import React                            from "react";
import                                        "./styles.css";
import { 
  toast 
   }                                    from "react-toastify";
import { 
        useCreateTransactionMutation,
        }                               from "../../../../../services/orderTransactionApi";



const SuccessPopup = ({
          onClose,
          userId,
          userEmail,
          requestNumber,
          firstName,
          lastName,
          phone,
          address,
          city,
          country,
          currency,
          sendAmount,
          receiveAmount,
          feesAmount,
          messageNote,
        }) => {

  const totalAmount = Number(sendAmount) + Number(feesAmount);
  const [createTransaction_OrderTransaction]                  = useCreateTransactionMutation();

  /* Section 00.0 - createAuditAction   */
  const createAuditAction = (actionDescription) => ({
    agentId: userId,
    actionDescription,
    timestamp: new Date()
  });


  /* Section 07.0 - handle_PlaceOrder   */
  const handle_PlaceOrder = () => { 
    toast.success("Processing..."); 
    createTransaction_OrderTransaction({
        userEmail: userEmail,
        userId: userId,
        transactionDetails: {
            firstName,
            lastName,
            address,
            city,
            country,
            phone,
            requestNumber,
            sendAmount,
            receiveAmount,
            feesAmount,
            currency,
            totalAmount,
            messageNote,
            timestamp: new Date()
        },
        auditActions: createAuditAction("User Requested Transaction")
    }).then(res => {
        console.log("Create transaction response:", res);
        if (res?.data?.status) {
            toast.success(res?.data?.message, {position: "top-center"} );
        } else {
            toast.error(res?.data?.message);
        }

    }).catch(error => {
        console.error("Error creating transaction:", error);
        toast.error("Failed to create transaction.");
    });
  };


  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Review Sending Request</h2>
        <hr />
        <div className="popup-details">
          <DetailRow label="Request#:"  value={requestNumber} />
          <DetailRow label="Send to:"   value={firstName} />
          <DetailRow label="Phone:"     value={phone} />
          <DetailRow label="Sending:"   value={`${sendAmount} CAD`} />
          <DetailRow label="Receiving:" value={`${receiveAmount}`} />
          <DetailRow label="Total:"     value={`${totalAmount} CAD`} />
          <DetailRow label="Message:"   value={messageNote} />
        </div>
        <div className="terms-and-conditions">
          <p>By accepting the transaction you will:</p>
          <ul>
            <li>Be able to Download the invoice immediately</li>
            <li>Agree to pay {totalAmount} before proceeding</li>
          </ul>
          <p>
            Email my request at <strong>support@ata2go.com</strong>
          </p>
          <strong>
            <p className="red-text">
              Send the payment by Interact to initiate the transfer
            </p>
          </strong>
        </div>
        <hr />
        <div className="popup-buttons">
          <button className="popup-button"                  onClick={onClose}>Back          </button>
          <button className="popup-button confirm-button"   onClick={handle_PlaceOrder}>Confirm Order</button>
        </div>
      </div>
    </div>
  );
};

const DetailRow = ({ label, value }) => (
  <div className="detail-row">
    <span className="detail-label">{label}</span>
    <span className="detail-value">{value}</span>
  </div>
);

export default SuccessPopup;
