/* File: /src/services/listCategoryApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const listCategoryApi = createApi({
    reducerPath: "listCategoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/listCategories",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["Categories", "Rating"],
    endpoints: builder => ({
        getAllCategories:    builder.query({    query: ({ source }) =>       ({ url: "/",                 method: "GET", params: { source }, }),                       providesTags: ["Categories", "Rating"] }),
        getCategoryById:     builder.query({    query: id =>       ({ url: `/${id}`,            method: "GET" }),                       providesTags: ["Categories"] }),
        
        addCategory:         builder.mutation({ query: data =>     ({ url: "/",                 method: "POST", body: data }),          invalidatesTags: ["Categories"] }),
        updateCategory:      builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,    method: "PATCH", body: payload.data }), invalidatesTags: ["Categories"] }),
        
        deleteCategory:      builder.mutation({ query: id =>       ({ url: `/${id}`,            method: "DELETE" }),                    invalidatesTags: ["Categories"] }),
        healthCheck:         builder.query   ({ query: ({ source }) =>       ({ url: `/health`,           method: "GET", params: { source }, })        }),
    })
});

export const {
    useHealthCheckQuery,
    useAddCategoryMutation,
    useDeleteCategoryMutation,
    useGetAllCategoriesQuery,
    useGetCategoryByIdQuery,
    useUpdateCategoryMutation
} = listCategoryApi;
