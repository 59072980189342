/* File: /src/services/listVehiclesApi.js */
import { createApi, fetchBaseQuery }    from "@reduxjs/toolkit/query/react";
import { baseUrl }                      from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const listVehiclesApi = createApi({
    reducerPath: "listVehiclesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/listVehicles",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            console.log("token", token); 
            if (token) {    
                headers.set("Authorization", `Bearer ${token}`)
                console.log("====> SET headers <====", headers); 
                    // Manually log the entries to ensure it is set correctly
                    for (let pair of headers.entries()) {
                        console.log(`here ${pair[0]}: ${pair[1]}`);
                    }
                console.log("====> token: listVehicleApi <====");   
            } else { console.log("====> Could NOT get token: listVehicleApi <====");}
            
            console.log("====> headers <====", headers);
            return headers;
        }
    }),
    tagTypes: ["Cars"],
    endpoints: builder => ({
        getAllVehicles: builder.query({     query: ({ source }) =>        ({ url: "/",      method: "GET", params: { source }, }),  providesTags:    ["Cars"] }),
        getVehicleById: builder.query({     query: id =>        ({ url: `/${id}`,           method: "GET" }),                       providesTags:    ["Cars"] }),
        
        addVehicle:     builder.mutation({  query: data =>      ({ url: "/",                method: "POST", body: data }),          invalidatesTags: ["Cars"] }),
        requestQuote:   builder.mutation({  query: data =>      ({ url: `/requestForQuote`, method: "POST", body: data }),          invalidatesTags: ["Cars"] }),
        
        updateVehicles: builder.mutation({  query: payload =>   ({ url: `/${payload.id}`,   method: "PATCH", body: payload.data }), invalidatesTags: ["Cars"] }),
        setPublishState: builder.mutation({ query: ({ data }) =>       ({ url: `/setPublishState`,         method: 'PATCH', body: data, }),        invalidatesTags: ['Cars'] }),

        deleteVehicle:  builder.mutation({  query: id =>        ({ url: `/${id}`,           method: "DELETE" }),                    invalidatesTags: ["Cars"] }),
        healthCheck:    builder.query   ({  query: ({ source }) =>        ({ url: `/health`,          method: "GET", params: { source }, })                                                  }),
    })
});

export const {
    useHealthCheckQuery,
    useAddVehicleMutation,
    useDeleteVehicleMutation,
    useGetAllVehiclesQuery,
    useGetVehicleByIdQuery,
    useRequestQuoteMutation,
    useSetPublishStateMutation,
    useUpdateVehiclesMutation
} = listVehiclesApi;
