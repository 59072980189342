/* REAL - MODAL - Remittance Request Package */ 
/* File:  /src/pages/Modal/SvcRemittanceModal.js */ 
import React, 
        { 
        useState 
        }                                       from "react";
import { 
        Modal, 
        Button, 
        Form 
        }                                       from "react-bootstrap";
import { 
        toast 
        }                                       from "react-toastify";
import { 
        Images 
        }                                       from "../../config/images";
import { 
        useSendSupportMailMutation 
        }                                       from "../../services/userUserApi";
import { 
        useUpdateSubscriptionsMutation,
        }                                       from "../../services/userUserStoreApi";
import                                          "./ModalStyle.css";

const SvcRemittanceModal = ({ showModal, handleCloseModal, user, allRemittance }) => {
    const Source_Name                           = "MODAL - Remittance Request Package"; 
    const [contactMethod,   setContactMethod]   = useState("email");
    // eslint-disable-next-line no-unused-vars
    const [selectedPackage, setSelectedPackage] = useState("remittances");

    const [SendRemittanceRequest]               = useSendSupportMailMutation();
    const [UserStore_UpdateSubscriptions]       = useUpdateSubscriptionsMutation();
    

    const handleContactMethodChange             = (e) => setContactMethod(e.target.value);

    // eslint-disable-next-line no-unused-vars
    const handlePackageChange                   = (e) => { setSelectedPackage(e.target.value);
                                                            console.log("selectedPackage", selectedPackage);
    };

    const createAuditAction = (actionDescription) => ({
        agentId: user?._id,
        actionDescription,
        timestamp: new Date()
    });

    const handleSubscribeRemittance = (selectedPackageDetails) => {
        const serviceType   = "remittances";      
        const serviceStatus = "requested";
        console.log("selectedPackageDetails", selectedPackageDetails);
        const serviceCode = selectedPackageDetails.serviceCode;
        
        UserStore_UpdateSubscriptions({ 
            ids: Array.isArray(user?._id) ? user?._id : [user?._id],
            data: { 
                subscriptions: serviceType, 
                serviceStatus, 
                serviceCode,
                auditActions: createAuditAction(`Requesting Package: ${serviceCode}`)  // Wrap in an array
            },
            source: Source_Name, 
        }).then(res => {
            if (res?.data?.status) {
        //        setRefetch(refetch + 1);
               // toast.success(res?.data?.message);
            } else {
                toast.error(res?.data?.message);
            }
        //    setModal(false);
        });
       
      //  setSelectedRemittanceServiceCode('');
      //  fetchSubscriptions(); 
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();

        const selectedPackageDetails = allRemittance?.data?.find(pkg => pkg.serviceCode === selectedPackage);
        console.log("selectedPackage here ", selectedPackage);
        console.log("selectedPackageDetails", selectedPackageDetails);
        handleSubscribeRemittance(selectedPackageDetails);

        const data = {
            source: Source_Name,
            name: `${user?.lastName}, ${user?.firstName}`,
            email: user?.email,
            subject: 'Remittance Service Request',
            text: `Remittance Package:
            <br/> ${selectedPackageDetails ? `Price: ${selectedPackageDetails.price} $` : "Not Provided"}
            <br/><br/>User's phone number:
            <br/> ${user?.phoneNo || "Not Provided"}`,
        };

        handleCloseModal();
        try {
            console.log("emailData", data);
            SendRemittanceRequest(data).then((res) => {
                console.log("res", res);
                if (res?.data?.status) {
                    toast.success(res?.data?.message);
                    setContactMethod("email");
                } else {
                    toast.warning("SEND FAILED: Please send an email to support@ata2go.com");
                }
            });
        } catch (error) {
            console.log(error.message);
        }
    };



    const modalContentStyle = {
      //  backgroundImage: `url(${Images.wwwInternet})`,
      color: '#000', // Ensure the text is black, which contrasts well with a light background
      backgroundColor: '#fff', // Optional: Set a white background for good contrast
    };


    return (
        <Modal 
        show={showModal} 
        onHide={handleCloseModal} 
        backdrop="static" 
        keyboard={false}
    >

        <div className="modal-content modal-content-background" style={modalContentStyle}>
            <Modal.Header closeButton>
                <img src={Images.wwwInternet} alt="Remittance Logo" className="header-image" />
                <Modal.Title>Remittance Service Request</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{user?.firstName}, thank you for your Interests!</p>
                <p>
                    To better serve you, a representative will contact for information the 
                    Money service Providers and Locations for the funds can be picked up. 
                    <br /> <br />
                    <span style={{ color: 'blue' }}>
                        Note: Government issued Identification documents must be uploaded, be 
                        valid, and verified before you can perform transfers.
                    </span>
                </p>
                <Form>

                    {/* Dropdown for selecting Remittance package 
                    <Form.Group controlId="remittancePackage">
                        <Form.Label>We service those countries:</Form.Label>
                        <Form.Control 
                            as="select" 
                            className="form-select" 
                            value={selectedPackage} // Bind to the state variable
                            onChange={handlePackageChange}
                        >
                            <option value="SelectPackage">Send/Receive</option> 
                            {allRemittance?.data?.map((remitPack) => (
                                <option key={remitPack._id} value={remitPack._id}>
                                    {`\u00A0\u00A0 ${remitPack.countryName}`}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    */} 

                    <Form.Group controlId="contactMethod">
                        <br />
                        <Form.Label>For this request, contact me by:</Form.Label>
                        <Form.Check
                            type="radio"
                            name="contactMethod"
                            checked={contactMethod === "email"}
                            value="email"
                            onChange={handleContactMethodChange}
                            disabled={!user?.email}
                            label={`Email: ${user?.email || "Not Provided"}`}
                        />
                        <Form.Check
                            type="radio"
                            name="contactMethod"
                            checked={contactMethod === "phone"}
                            value="phone"
                            onChange={handleContactMethodChange}
                            disabled={!user?.phoneNo}
                            label={`Phone: ${user?.phoneNo || "Not Provided"}`}
                        />
                        <Form.Label>Adjust your Contact Details in Settings if the above is incorrect.</Form.Label>
                    </Form.Group>
                </Form>
                <br />
                <p>We will follow up with you shortly.</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}                                                  >Cancel</Button>
                <Button variant="primary"   onClick={handleSendMessage} disabled={selectedPackage === "Select Package"}  >Request</Button>
            </Modal.Footer>
            </div>
        </Modal>
    );
};

export default SvcRemittanceModal;
