/* REAL - LETS UPDATE SUDO - */
import React, { useEffect }                 from "react";
import { Container }                        from "react-bootstrap";
import { useParams }                        from "react-router-dom";
import { PageHeading, ProfileSection }      from "../../../Settings/profileStyle";
import SettingsTabs                         from "../../../Settings/ProfileTabComponent/SettingsTabs";
import SubscriptionsTabs                    from "../../../Subscriptions/_SubscriptionTab/SubscriptionsTabs";
import PageHeader                           from "../../../../components/UI/PageHeader";
import { useGetUserByIdQuery }              from "../../../../services/userUserApi";
import { settingsData, subscriptionsData }  from "../../../../utils/constants";

const UpdateProfile = () => {
    const { id } = useParams();
    const { data, isLoading, error } = useGetUserByIdQuery(id);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Handle loading and error states
    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading user data.</p>;
    }

    const user = data?.result;

    if (!user) {
        return <p>User not found.</p>;
    }

    return (
        <>
            <PageHeader title={`Let's Update User: ${user.firstName} ${user.lastName}`} />
            <ProfileSection>
                <Container>
                    <PageHeading>Settings</PageHeading>
                    <SettingsTabs user={user} tabs={settingsData} />
                </Container>
            </ProfileSection>
            <ProfileSection>
                <Container>
                    <PageHeading>Subscriptions</PageHeading>
                    <SubscriptionsTabs user={user} tabs={subscriptionsData} />
                </Container>
            </ProfileSection>
        </>
    );
};

export default UpdateProfile;
