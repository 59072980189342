import React                    from "react";
import { Modal, 
         Button, 
         Tooltip, 
         OverlayTrigger 
        }                       from "react-bootstrap";
import { GrDocumentDownload }   from "react-icons/gr";
import { Images }               from "../../config/images";
import moment                   from "moment";



const BillingVehiclePartsModal = ({ show, handleClose, billDetail, userId, downloadPdf }) => {
    const STG_Date_Format = "YYYY-MM-DD";
    const hasHold = billDetail?.items?.some(item => item.hold);

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>Billing Invoice</Modal.Header>
            <Modal.Body>
                    
                    <div id="container" className="invoice">
                    
                        <div className="d-flex align-items-center justify-content-between modal-header">
                            <img src={Images.ata2goLogo} alt="Header Background" className="header-image" />
                            <h4 className="mb-0">Vehicle Parts Invoice</h4>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-save">Download Invoice</Tooltip>} >
                                <Button variant="primary" className="ms-2" onClick={downloadPdf}>   <GrDocumentDownload />  </Button>
                            </OverlayTrigger>
                        </div>
                        <div className="invoice-details" style={{ fontSize: '12px' }}>
                        
                            {/* <p className="text-right bold-text">Detail: {JSON.stringify(billDetail)}</p> */}
                            <p className="text-right">Invoice #: {billDetail?.invoiceNumber}</p>
                            <p className="text-right bold-text">Billing Date: {moment(billDetail?.date).format(STG_Date_Format)}</p>
                            <p className="text-right bold-text">Member Id: {userId}</p>
                            <br />
                            <div className="invoice-info">
                                <div className="info-row">
                                    <span className="title">Member Name:</span>
                                    <span className="value">{billDetail?.lastName}, {billDetail?.firstName}</span>
                                </div>
                                {billDetail?.companyName && (
                                    <div className="info-row">
                                        <span className="title">Business Name:</span>
                                        <span className="value">{billDetail?.companyName}</span>
                                    </div>
                                )}
                                <div className="info-row">
                                    <span className="title">Address:</span>
                                    <span className="value">{billDetail?.userAddress}, {billDetail?.userCity}, {billDetail?.userPostCode}</span>
                                </div>
                            </div>
                        </div>
                        <br />
                        

                        <table className="invoice-table" style={{ fontSize: '12px' }}>
                            <thead>
                                <tr>
                                    <th>Vehicle</th>
                                    <th>Part Name</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    {hasHold && <th>Hold</th>}
                                    <th>Sub+Tx</th>
                                </tr>
                            </thead>
                            <tbody>
                                {billDetail?.items?.map((item, i) => (
                                    <tr key={i + 1}>
                                        <td>{item.year} {item.brand} {item.model}</td>
                                        <td>{item.service}</td>
                                        <td>${item?.price}</td>
                                        <td>{item.quantity}</td>
                                        {hasHold && <td>{item.hold ? item.hold : '-'}</td>}
                                        <td>${item?.subTotal}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="invoice-total">
                            <div className="label-with-image">
                                <img src={Images.interact} alt="Interact logo" className="payment-image" />
                                <div>
                                    <div className="label">TPS : <span>{billDetail?.taxTPS?.toFixed(2)} $</span></div>
                                    <div className="label">TVQ : <span>{billDetail?.taxTVQ?.toFixed(2)} $</span></div>
                                    <div className="label">Total : <span>{billDetail?.Total?.toFixed(2)} $</span></div>
                                    <p>Interact Payment: FINANCE@ATA2GO.COM</p>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <div className="invoice-info" style={{ fontSize: '12px' }}>
                            <div><span><h6>ATA2GO</h6></span></div>
                            <div><span>www.ata2go.com</span></div>
                            <div><span>Tel: 514-867-5523 email: support@ata2go.com</span></div>
                            <div><span>TPS: 797533528-RT0001 TVQ: 1223428670TQ0001</span></div>
                        </div>
                        <br />
                        <div className="invoice-info"><p style={{ color: 'blue' }}>Thank you for your business!</p></div>

                    </div>

            </Modal.Body>
        </Modal>
    );
};

export default BillingVehiclePartsModal;
