/* CONFIG - STORE */
/* File: /src/store/store.js */

/* Various */
import { configureStore }               from "@reduxjs/toolkit";
import { setupListeners }               from "@reduxjs/toolkit/query";

/* Order */
import { orderBillingApi }              from "../services/orderBillingApi";
import { orderTransactionApi }          from "../services/orderTransactionApi";
import { orderVehiclePartsOrderApi }    from "../services/orderVehiclePartsOrderApi";

/* Lists */
import { listCategoryApi }              from "../services/listCategoryApi";
import { listVehiclesApi }              from "../services/listVehiclesApi";
import { listVehiclePartsApi }          from "../services/listVehiclePartsApi";
import { listVideoApi }                 from "../services/listVideoApi";

/* Marketing */
import { mktgVideoRatingApi }           from "../services/mktgVideoRatingApi";

/* Services */
import { svcInternetApi }               from "../services/svcInternetApi";
import { svcLearningApi }               from "../services/svcLearningApi";
import { svcMarketingApi }              from "../services/svcMarketingApi";
import { svcRemittanceApi }             from "../services/svcRemittanceApi";
import { svcServiceStoreApi }           from "../services/svcServiceStoreApi";
import { svcTranscriptApi }             from "../services/svcTranscriptApi";
import { svcVehiclePartsApi }            from "../services/svcVehiclePartsApi";

/* User */
import { userDocumentApi }              from "../services/userDocumentApi";
import { userNotificationApi }          from "../services/userNotificationApi";
import { userUserApi }                  from "../services/userUserApi";
import { userUserStoreApi }             from "../services/userUserStoreApi";
import { userAffiliateApi }             from "../services/userAffiliateApi";



export const store = configureStore({
    reducer: {
        
        [orderBillingApi.reducerPath]:              orderBillingApi.reducer,
        [orderVehiclePartsOrderApi.reducerPath]:    orderVehiclePartsOrderApi.reducer,
        [orderTransactionApi.reducerPath]:          orderTransactionApi.reducer,

        [listCategoryApi.reducerPath]:              listCategoryApi.reducer,
        [listVehiclesApi.reducerPath]:              listVehiclesApi.reducer,
        [listVehiclePartsApi.reducerPath]:          listVehiclePartsApi.reducer,
        [listVideoApi.reducerPath]:                 listVideoApi.reducer,
        
        [mktgVideoRatingApi.reducerPath]:           mktgVideoRatingApi.reducer,

        [svcInternetApi.reducerPath]:               svcInternetApi.reducer,
        [svcLearningApi.reducerPath]:               svcLearningApi.reducer,
        [svcMarketingApi.reducerPath]:              svcMarketingApi.reducer,
        [svcRemittanceApi.reducerPath]:             svcRemittanceApi.reducer,
        [svcServiceStoreApi.reducerPath]:           svcServiceStoreApi.reducer,
        [svcTranscriptApi.reducerPath]:             svcTranscriptApi.reducer,
        [svcVehiclePartsApi.reducerPath]:            svcVehiclePartsApi.reducer,

        [userNotificationApi.reducerPath]:          userNotificationApi.reducer,
        [userDocumentApi.reducerPath]:              userDocumentApi.reducer,
        [userUserApi.reducerPath]:                  userUserApi.reducer,
        [userUserStoreApi.reducerPath]:             userUserStoreApi.reducer,
        [userAffiliateApi.reducerPath]:             userAffiliateApi.reducer,

    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(orderBillingApi.middleware)
            .concat(orderVehiclePartsOrderApi.middleware)
            .concat(orderTransactionApi.middleware)

            .concat(listCategoryApi.middleware)
            .concat(listVehiclesApi.middleware)
            .concat(listVehiclePartsApi.middleware)
            .concat(listVideoApi.middleware)
            
            .concat(mktgVideoRatingApi.middleware)

            .concat(svcInternetApi.middleware)
            .concat(svcLearningApi.middleware)
            .concat(svcMarketingApi.middleware)
            .concat(svcRemittanceApi.middleware)
            .concat(svcServiceStoreApi.middleware)
            .concat(svcTranscriptApi.middleware)
            .concat(svcVehiclePartsApi.middleware)

            .concat(userNotificationApi.middleware)
            .concat(userDocumentApi.middleware)
            .concat(userUserApi.middleware)
            .concat(userUserStoreApi.middleware)
            .concat(userAffiliateApi.middleware)
});
setupListeners(store.dispatch);
