/* REAL - DASH - SERVICES - TRANSCRIPTS */
/* File: /src/pages/Dashboard/DashboardPages/Services-Transcripts/Transcripts.js */
import                                      "../ManageStyle.css";
import { UserContext }                      from "../../../../App";

import { useContext }                       from "react";
import React, 
        { useState, 
          useEffect
        }                                   from "react";
import { 
        Button, 
        Table, 
        Modal,
        }                                   from "react-bootstrap";
import { toast }                            from "react-toastify";
import { BiSolidPencil }                    from "react-icons/bi";
import { 
        MdAddChart,
        MdDelete 
        }                                   from "react-icons/md";
import { 
        useDeleteTranscriptMutation,
        useGetAllTranscriptsQuery,
        useGetTranscriptByIdQuery,
        useUpdateTranscriptMutation,
        useUploadTranscriptMutation
        }                                   from "../../../../services/svcTranscriptApi";
import { 
        useGetSubscriptionsByTypeMutation,
        }                                   from "../../../../services/userUserStoreApi";

import {
        useListServicesQuery,
        useUpdateServiceMutation,
        }                                   from "../../../../services/svcServiceStoreApi";

import { 
        useGetUserInfoMutation
        }                                   from "../../../../services/userUserApi";

import DeleteModal                          from "../../../../components/Modal/DeleteModal";


const ManageTranscript = () => {
    const Source_Name           = "MANAGE - SERVICES - TRANSCRIPT";
    const [serviceCode,           setServiceCode]           = useState("");
    const [serviceStatus,         setServiceStatus]         = useState(null);
    const [price,                 setPrice]                 = useState("");
    const [description,           setDescription]           = useState("");
    const [selectedId,            setSelectedId]            = useState("");
    const [deleteModal,           setDeleteModal]           = useState(false);
    const [selectedItem,          setSelectedItem]          = useState("");
    const [showModal,             setShowModal]             = useState(false);
    const [isNewPromotion,        setIsNewPromotion]        = useState(false);
    const [subscriptionCounts,    setSubscriptionCounts]    = useState({});
    const [showReqModal,          setShowReqModal]          = useState(false);
    const [selectedServiceCode,   setSelectedServiceCode]   = useState("");
    const [selectedUserInfos,     setSelectedUserInfos]     = useState([]);
    const [userData,              setUserData]              = useState([]);

    const { user }                                      = useContext(UserContext);
    const [uploadTranscript]                            = useUploadTranscriptMutation();
    const [deleteTranscript]                            = useDeleteTranscriptMutation();
    const { data }                                      = useGetAllTranscriptsQuery({ source: Source_Name });
    const { data: transcriptData }                      = useGetTranscriptByIdQuery({ id: selectedId, source: Source_Name });
    const { data: servicesList}                         = useListServicesQuery({ source: Source_Name });
    const [updateService]                               = useUpdateServiceMutation();
    const [updateTranscript]                            = useUpdateTranscriptMutation();
    const [getSubscriptionsByType]                      = useGetSubscriptionsByTypeMutation();
    const [getUserInfo]                                     = useGetUserInfoMutation();

    const STG_Course_TypeLabel      = "transcripts"


    /* Section 01.00 - initializeValues  */
    const initializeValues = () => {
        setServiceCode("");
        setPrice("");
        setDescription([]);
    }

    /* Section 02.00 - handle_Transcript  */
    const handle_Transcript = () => {
        const data = {
            serviceCode,
            price,
            description
        };
        uploadTranscript({ data, source: Source_Name }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                initializeValues();
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 03.00 - handle_DeletePackage  */
    const handle_DeletePackage = id => {
        deleteTranscript(id).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setDeleteModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 04.00 - handle_NewPackage  - Function to handle opening the modal */
    const handle_NewPackage = () => {
        initializeValues();
        setIsNewPromotion(true);
        setShowModal(true);
        
    };

    /* Section 05.00 - useEffect - transcriptData */
    useEffect(() => {
        if (transcriptData) {
            setServiceCode(transcriptData?.result?.serviceCode);
            setPrice(transcriptData?.result?.price);
            setDescription(transcriptData?.result?.description);
        }
    }, [transcriptData]);

    /* Section 06.00 - handle_Update  */
    const handle_Update = () => {
        updateTranscript({
            id: selectedId,
            data: { serviceCode: serviceCode, 
                    price: price, 
                    description: description },
            source: Source_Name
        }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setShowModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 07.00 - handle_DeleteClick  */
    const handle_DeleteClick = (item) => {
        setSelectedItem(item); // set the entire item, not just id
        setDeleteModal(true);
    };

    /* Section 08.00 - handle_EditClick  */
    const handle_EditClick = (item_id) => {
        setSelectedId(item_id);
        setShowModal(true);
    };

    /* Section 06.00 - handle_Publish */
    const handle_Publish = async () => {
        try {
            // Log the services list data
            console.log("SERVICES LIST RAW DATA:", servicesList);
    
            if (serviceStatus) {  // Use serviceStatus instead of finding it again
                const updatedService = {
                    id: serviceStatus._id,
                    data: { 
                        published: !serviceStatus.published  // Toggle the published status
                    }
                };
                console.log("updatedService:", updatedService);
    
                const result = await updateService({ payload: updatedService, source: Source_Name }).unwrap();
                if (result?.status) {
                    toast.success(`Service "transcripts" has been ${!serviceStatus.published ? 'published' : 'unpublished'} successfully.`);
                    setServiceStatus(prevState => ({ ...prevState, published: !prevState.published }));  // Update the state after success
                } else {
                    toast.error(result?.message || 'Failed to update the service.');
                }
            } else {
                toast.error('Service "transcripts" not found.');
            }
        } catch (error) {
            console.error('Error in handle_Publish:', error);
            toast.error('An error occurred while updating the service.');
        }
    };

    /* Section 10.00 - Function to handle clicking on the "Req" column */
    const handleReqClick = async (serviceCode, usersData) => {
        const userIds = usersData
            .filter(user => user.services.some(service => service.serviceCode === serviceCode && service.status === "requested"))
            .map(user => user.userId);

        setSelectedServiceCode(serviceCode);

        const userInfos = await Promise.all(
            userIds.map(async (userId) => {
                try {
                    const res = await getUserInfo(userId).unwrap(); // Call the mutation and unwrap the result
                    return {
                        userId: userId.slice(-7),
                        email: res?.result?.email || "N/A", // Email from the API response
                        firstName: res?.result?.firstName || "N/A", // First name from the API response
                        lastName: res?.result?.lastName || "N/A", // Last name from the API response
                    };
                } catch (error) {
                    console.error("Error fetching user info:", error);
                    return {
                        userId: userId.slice(-7),
                        email: "N/A",
                        firstName: "N/A",
                        lastName: "N/A",
                    };
                }
            })
        );
        

        setSelectedUserInfos(userInfos); // Store the user info data
        setShowReqModal(true);
    };

    /* Section 09.00 - useEffect - getSubscriptionsByType  */
    useEffect(() => {
        const fetchSubscriptionCounts = async () => {
            try {
                const response = await getSubscriptionsByType({ type: STG_Course_TypeLabel }).unwrap();
                const counts = response.data.reduce((acc, user) => {
                    user.services.forEach(service => {
                        // Log the service details for debugging
                        console.log("====service.status", service.status);
                        
                        // Count non-requested services
                        if (service.status !== "requested") {
                            acc.subscriptions[service.serviceCode] = (acc.subscriptions[service.serviceCode] || 0) + 1;
                        }

                        // Count requested services
                        if (service.status === "requested") {
                            acc.requests[service.serviceCode] = (acc.requests[service.serviceCode] || 0) + 1;
                        }
                    });
                    return acc;
                }, { subscriptions: {}, requests: {} });

                // Log the counts to verify computation
                console.log('Computed counts:', counts);
                setSubscriptionCounts(counts);
                setUserData(response.data); 
            } catch (error) {
                console.error('Error fetching subscription counts:', error);
            }
        };

        fetchSubscriptionCounts();
    }, [getSubscriptionsByType]);


    /* Section 10.00 - useEffect - servicesList  */
    useEffect(() => {
        if (servicesList && Array.isArray(servicesList.data)) {
            const service = servicesList.data.find(service => service.serviceName === "transcripts");
            setServiceStatus(service);  // Store the service in the state variable
            console.log("service FIND:", service);
        }
    }, [servicesList]);

    return (
        <div className="manage-video-page">
            <div className="video-table-container">
                <h2 className="video-page-title">TRANSCRIPT PACKAGES</h2>
                <Button variant="primary"   style={{ marginRight: '10px' }}         disabled={!(user?.role === "power" || user?.role === "admin")} 
                        onClick={() => handle_NewPackage()}>      <MdAddChart />      </Button>

                <Button variant="success"   style={{ marginRight: '10px' }}         disabled={!(user?.role === "power" || user?.role === "admin")} 
                        onClick={       handle_Publish}     >       {serviceStatus?.published ? 'UnPublish' : 'Make Available'}
                </Button>

                <div className="table-data">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Service Code</th>
                                <th>Price per month</th>
                                <th>Package Description</th>
                                <th>Actions</th>
                                <th>Subs</th>
                                <th>Req</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data?.data?.map((item, i) => (
                                <tr key={i++}>
                                    <td>{i + 1}</td>
                                    <td>{item.serviceCode}</td>
                                    <td>${item?.price}/m</td>
                                    <td>{item?.description}</td>

                                    <td>
                                        <div className="d-flex align-items-center justify-content-center gx-4">
                                            {user?.role === "power" || user?.role === "admin" || user?.role === "support" ? (
                                                <span   className="action-icon update"      onClick={() => handle_EditClick(item?._id)} > <BiSolidPencil /> </span>
                                            ) : (
                                                <span   className="action-icon update"      style={{ background: "grey" }}              > <BiSolidPencil /> </span>
                                            )}
                                            {user?.role === "admin" ? (
                                                <span   className="action-icon delete"      onClick={() => handle_DeleteClick(item)}    > <MdDelete />      </span>
                                            ) : (
                                                <span   className="action-icon delete"      style={{ background: "grey" }}              > <MdDelete />      </span>
                                            )}
                                        </div>
                                    </td>
                                    <td>{subscriptionCounts[item.serviceCode] || 0}</td> {/* Display subscription count */}
                                    <td 
                                        style={{ cursor: subscriptionCounts.requests?.[item.serviceCode] > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if (subscriptionCounts.requests?.[item.serviceCode] > 0) {
                                                handleReqClick(item.serviceCode, userData);  // Pass the response data here
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.requests?.[item.serviceCode] || 0}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>


            <Modal show={showReqModal} onHide={() => setShowReqModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Requests for Service: {selectedServiceCode}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {selectedUserInfos.map((userInfo, index) => (
                            <li key={index}>
                                {userInfo.userId}, {userInfo.firstName} {userInfo.lastName}, {userInfo.email}
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowReqModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{isNewPromotion ? "Register New Transcript Package" : "Update Transcript Package"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-form">
                        <div className="video-input-box-modal">

                            <div className="input-wrapper">
                                <label  htmlFor="title">Service Code</label>
                                <input  type="text"     id="serviceCode"    placeholder="Service Code"      value={serviceCode}
                                        onChange={e => setServiceCode(e.target.value)}
                                />
                            </div>

                            <div className="input-wrapper">
                                <label  htmlFor="title">Monthly Price($)</label>
                                <input  type="text"     id="price"          placeholder="Price"             value={price}
                                        onChange={e => setPrice(e.target.value)}
                                />
                            </div>

                            <div className="input-wrapper">
                                <label  htmlFor="title">Description</label>
                                <input  type="text"     id="description"    placeholder="Description"       value={description}
                                        onChange={e => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {isNewPromotion ? ( <Button onClick={handle_Transcript}    variant="primary">   Add Package </Button>
                    ) : (               <Button onClick={handle_Update}      variant="primary">   Update      </Button>
                    )}

                    <Button variant="secondary" onClick={() => setShowModal(false)}>    Close   </Button>
                </Modal.Footer>
            </Modal>


            <DeleteModal
                question            ={`Will delete Transcript Package '${selectedItem?.serviceCode}'?`}
                heading             ={"Package Deletion"}
                deleteModal         ={deleteModal}
                setDeleteModal      ={setDeleteModal}
                handleDeleteItem    ={handle_DeletePackage}
                id                  ={selectedItem}
            />
        </div>
    );
};

export default ManageTranscript;
