/* File: /src/services/mktgVideoRatingApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const mktgVideoRatingApi = createApi({
    reducerPath: "mktgVideoRatingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/rating",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["Rating"],
    endpoints: builder => ({
        addRating:              builder.mutation({ query: data =>     ({ url: `/rating`,          method: "POST", body: data }),            invalidatesTags: ["Rating"] }),
        getStoppedAt:           builder.query   ({ query: ({ source, userId, videoId }) => ({ url: `/getStoppedAt`,   method: "GET",  params: { source, userId, videoId } }) }),
        updateStoppedAt:        builder.mutation({ query: data =>     ({ url: `/updateStoppedAt`, method: "POST", body: data }),            invalidatesTags: ["Rating"] }),
        healthCheck:            builder.query   ({ query: ({ source }) =>       ({ url: `/health`,          method: "GET", params: { source }, })        }),
    })
});

export const {
    useHealthCheckQuery,
    useAddRatingMutation,
    useGetStoppedAtQuery,
    useUpdateStoppedAtMutation,
} = mktgVideoRatingApi;




