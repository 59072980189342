/* REAL - MY SUBSCRIPTIONS - PARTS ORDER   */
/* File:  /src/pages/Subscriptions/PartsOrders/index.jsx */
// import "./carPartsSubsStyle.css"
import { useTranslation 
        }                                               from 'react-i18next';
import React, { useCallback, useEffect, useState }      from "react";
import { toast }                                        from "react-toastify";
import { Button, 
         Form, 
         Modal,
         Tooltip, 
         OverlayTrigger, 
       }                                                from "react-bootstrap";
import { IoIosSave }                                    from "react-icons/io";
import { MdOutlineFilterAltOff }                        from "react-icons/md";
import { MdDelete }                                     from "react-icons/md";
import { IoCarSport }                                   from "react-icons/io5";
import { BiSearchAlt }                                  from "react-icons/bi";
import { RxReset }                                      from "react-icons/rx";
import { useNavigate }                                  from "react-router-dom";
import { TabContentContainer }                          from "../../Tabs/tabContentStyle";
import { useGetAllVehiclePartsQuery }                   from "../../../services/listVehiclePartsApi";
import { useGetAllVehiclesQuery, 
         useRequestQuoteMutation 
        }                                               from "../../../services/listVehiclesApi";
import { useGetVehicleQuery, 
         useSaveVehicleMutation,
         useDeleteVehicleMutation, 
       }                                                from "../../../services/userUserStoreApi";
import { staggerVariants }                              from "../../../utils/FmVariants";


const SubsPartsOrders = ({ user }) => {
    const { t } = useTranslation('subsPartsOrders');
    const Source_Name           = "MY SUBSCRIPTIONS - PARTS ORDER";
    const navigate                                              = useNavigate();
    const DFLT_Year         = t("labels.year");
    const DFLT_Brand        = t("labels.brand");
    const DFLT_Model        = t("labels.model");
    const DFLT_System       = t("labels.system");
    const DFLT_SubSystem    = t("labels.sub_system");
    const DFLT_VehicleParts = t("labels.part");

    const STG_PartsOldestYear = 1990;

    /* Vehicle Information: */
    const currentYear                                           = new Date().getFullYear();
    const years                                                 = Array.from({ length: currentYear - STG_PartsOldestYear + 1 }, (_, index) => STG_PartsOldestYear + index);
    const [selectedYear,              setSelectedYear]          = useState(currentYear);
    const [selectedVehicle,           setSelectedVehicle]       = useState(null);
    const [brand,                     setBrand]                 = useState(DFLT_Brand);
    const [vehicleObj,                setVehicleObj]            = useState({});
    const [model,                     setModel]                 = useState(DFLT_Model);
    const [additional,                setAdditional]            = useState("");
    const [vin,                       setVin]                   = useState("");
    const [savedVehicleUsed,          setSavedVehicleUsed]      = useState(false);

    /* Select Vehicle Part: */
    const [searchQuery,               setSearchQuery]           = useState("");
    const [subSystems,                setSubSystems]            = useState([]);
    const [selectedSystem,            setSelectedSystem]        = useState({ system: DFLT_System });
    
    const [selectedSubSystem,         setSelectedSubSystem]     = useState(DFLT_SubSystem);
    const [selectedItem,              setSelectedItem]          = useState({ partName: DFLT_VehicleParts });
    const [selectedItemString,        setSelectedItemString]    = useState("");
    const [selectedParts,             setSelectedParts]         = useState([]);
    const [vehiclePartsCheck,             setVehiclePartsCheck]         = useState([]);

    /* Parts Selected: */
    const [items,                     setItems]                 = useState([]);
    const [selected,                  setSelected]              = useState("");
    const [showQuoteModal,            setShowQuoteModal]        = useState(false);


    /* Data Handling: */
    const { data }                                              = useGetAllVehiclePartsQuery({ source: Source_Name });
    const { data: VehiclesData }                                = useGetAllVehiclesQuery({ source: Source_Name });
    const [requestQuote]                                        = useRequestQuoteMutation();
    const [userStore_Save]                                      = useSaveVehicleMutation();
    const [deleteVehicle]                                       = useDeleteVehicleMutation();
    const { data: userVehicleData }                             = useGetVehicleQuery(user?._id);
    const [filteredData,              setFilteredData]          = useState(data?.data);




    /* Handle 01 */
    const handleYearChange = event => {
        setSelectedYear(parseInt(event.target.value));
    };

    /* Handle 02 */
    const handle_BrandChange = (e) => {
        const selectedOption        = e.target.options[e.target.selectedIndex];
        const vehicleEntryString    = selectedOption.getAttribute('data-vehicle');
        const vehicleBrand          = selectedOption.getAttribute('value');
        const vehicleEntry          = JSON.parse(vehicleEntryString);

        // <option value="Cadillac" 
        // data-vehicle="{"_id":"64ec1bc245308cc5c846e68c","brand":"Cadillac","models":["CT4","CT5","CT6","Escalade","XT4","XT5","XT6","XT7","XT9"],
        // "createdAt":"2023-08-28T04:00:02.286Z","updatedAt":"2024-06-25T04:46:29.133Z","__v":0,"published":true}">Cadillac
        // </option>

        setVehicleObj( vehicleEntry);
        setBrand( vehicleBrand );
        setModel( DFLT_Model );
    };
        
    /* Handle 03 */
    const handleModelChange = e => {
        const value = e.target.value;
        setModel(value);
    };

    /* Handle 04 */
    const handle_SaveVehicle = async () => {
        if (!selectedYear || !brand || !model || (model === DFLT_Model )) {
            toast.error(t("messages.require_year_brand_model"));
            return;
        } 
        const data_Vehicle = {
            year:           selectedYear,
            brand:          brand,
            model:          model,
            additionalInfo: additional,
            vin:            vin,
            userId:         user?._id
        };
    
        const res_SaveVehicle = await userStore_Save(data_Vehicle);
        if (res_SaveVehicle.data?.status) {
            toast.success(t("messages.vehicle_saved_success"));
        } else {
            toast.error(res_SaveVehicle?.data?.message);
        }
    };

    /* Handle 05 */
    const handle_UseVehicle = () => {
        if (selectedVehicle) {
            console.log("SELECTED ==> ", selectedVehicle);          
            setVehicleObj(  selectedVehicle   );                      
            setSelectedYear(selectedVehicle.year);                  // 2016
            setBrand(selectedVehicle.brand);                        // "Honda"
            setModel(selectedVehicle.model);                        // "CR-V"
            setAdditional(selectedVehicle.additionalInfo || "");    // "Touring Edition"
            setVin(selectedVehicle.vin || "");                      // "66843bc11049ee270ecbd689"
            setSavedVehicleUsed(true); // Set the state to true
        }
    };

    /* Handle 06 */
    const handle_ClearVehicle = () => {
        setSelectedVehicle(null);
        setSelectedYear(currentYear);
        setBrand(DFLT_Brand);
        setVehicleObj(JSON.stringify({ car: DFLT_Brand, models: DFLT_Model }));
        setModel(DFLT_Model);
        setAdditional("");
        setVin("");
        setSavedVehicleUsed(false); // Reset the state
    };

    /* Handle 06 */
    const handle_DeleteVehicle = async () => {
        if (selectedVehicle) {
            const confirmDelete = window.confirm(t("messages.confirm_delete"));
            if (confirmDelete) {
                try {
                    const response = await deleteVehicle(selectedVehicle._id).unwrap();
                    if (response.status) {
                        toast.success(t("messages.vehicle_deleted_success"));
                        if (savedVehicleUsed) { 
                            setSelectedVehicle(null);
                            setSelectedYear(currentYear);
                            setBrand(DFLT_Brand);
                            setVehicleObj(JSON.stringify({ car: DFLT_Brand, models: DFLT_Model }));
                            setModel(DFLT_Model);
                            setAdditional("");
                            setVin("");
                            setSavedVehicleUsed(false); // Reset the state
                            // Optionally update the local state or refetch data
                            // You can use refetch or another query hook to update the vehicle list
                        }
                    } else {
                        toast.error(response.message);
                    }
                } catch (error) {
                    toast.error("Error deleting vehicle");
                }
            }
        }
    };
    
    /* Handle 07 */
    const handleViewCategory = e => {
        const value = e.target.value;
        setSelectedSystem(JSON.parse(value));
        setSelected(value);
        const filteredSystem = data?.data?.filter(d => d?.system === JSON.parse(value)?.system);

        // Extract sub-systems
        const keysArray = filteredSystem[0]?.subSystems?.map(subSys => {
            const validKeys = Object.keys(subSys).filter(key => key !== 'published');  // Filter out 'published'
            return validKeys[0];  // Return the first valid sub-system name
        });
        setSubSystems(keysArray);
        setSelectedSubSystem(DFLT_SubSystem);
        setSelectedItemString("");

        // Reinitialize items list taking into account selected parts
        const allItems = filteredSystem[0]?.subSystems.reduce((acc, subSys) => {
            const subSystemName = Object.keys(subSys)[0];

            // Ensure that subSys[subSystemName] is an array before using filter
            const parts = Array.isArray(subSys[subSystemName]) ? subSys[subSystemName].filter(part =>
                !selectedParts.some(selectedPart => selectedPart.partName === part.partName)
            ) : []; // If not an array, return an empty array

            acc[subSystemName] = parts;
            return acc;
        }, {});

        setItems(allItems[DFLT_SubSystem] || []);
    };


    /* Handle 08 */
    const handleShowItems = useCallback((e) => {
        const value = e.target.value;
        setSelectedSubSystem(value);
        if (value !== DFLT_SubSystem) {
            setItems(selectedSystem?.subSystems[subSystems.indexOf(value)][value].filter(part =>
                !selectedParts.some(selectedPart => selectedPart.partName === part.partName)
            ));
        }
        setSelectedItemString("");
        setSelectedItem({
            partName: DFLT_VehicleParts
        });
    }, [selectedSystem, subSystems, selectedParts, DFLT_SubSystem, DFLT_VehicleParts]);

    /* Handle 09 */
    const handleSelectedItem = e => {
        const value = e.target.value;
        setSelectedItem(JSON.parse(value));
        setSelectedItemString(value);
    };

    /* Handle 10 */
    const handleSelectVehicleParts = item => {
        const data = {
            system:     selectedSystem?.system,
            subSystem:  selectedSubSystem,
            partName:   item.partName,
            model:      model,
            brand:      brand,
            year:       selectedYear,
            vin:        vin
        };

        const foundObject = selectedParts.find(
            obj =>
                obj.system ===      data.system &&
                obj.subSystem ===   data.subSystem &&
                obj.partName ===    data.partName &&
                obj.brand ===       data.brand &&
                obj.model ===       data.model &&
                obj.year ===        data.year &&
                obj.vin ===         data.vin
        );

        if (foundObject) {
            toast.error(t("messages.part_already_added"));
        } else {
            setVehiclePartsCheck([...vehiclePartsCheck, data]);
            setSelectedParts([
                ...selectedParts,
                {
                    ...data,
                    quantity: 1,
                    additional: additional,
                    partImage: item?.partImage
                }
            ]);
            setItems(prevItems => prevItems.filter(i => i.partName !== item.partName));
        }
        setSelectedItem({ partName: DFLT_VehicleParts });
        setSelectedItemString("");
    };

    /* Handle 11 */
    const handleRemoveVehicleParts = data => {
        const filtered = selectedParts?.filter(obj => obj !== data);
        setSelectedParts(filtered);
        // Add the removed item back to the items list
        setItems(prevItems => [...prevItems, data]);
    };

    /* Handle 12 */
    const handleValue = (index, value) => {
        selectedParts[index].quantity = value;
        setSelectedParts([...selectedParts]);
    };

    /* Handle 13 */
    const handleRequestQuote = () => {
        if (brand === "" || brand === DFLT_Brand) {
            toast.error(t("messages.require_brand"));
        } else {
            if (model === "" || model === DFLT_Model) {
                toast.error(t("messages.require_model"));
            } else {
                if (
                    selectedSystem.system   !== DFLT_System &&
                    selectedSubSystem       !== DFLT_SubSystem &&
                    selectedParts.length > 0 &&
                    selectedParts[0]?.quantity > 0
                ) {
                    setShowQuoteModal(true);
                } else {
                    toast.error(t("messages.require_part_selection"));
                }
            }
        }
    };
    
    /* Handle 14 */
    const handle_Search = () => {
        if (searchQuery.trim() === "") {
            setFilteredData(data?.data);
        } else {
            const filtered = data?.data?.filter(d =>
                d.system.toLowerCase().includes(searchQuery.toLowerCase()) ||
                d.subSystems.some(sub =>
                    Object.keys(sub)[0].toLowerCase().includes(searchQuery.toLowerCase()) ||
                    sub[Object.keys(sub)[0]].some(part => part.partName.toLowerCase().includes(searchQuery.toLowerCase()))
                )
            );
            setFilteredData(filtered);
        }
        setSelectedSystem({ system: DFLT_System });
        setSelectedSubSystem(DFLT_SubSystem);
        setSelectedItemString("");
    };

    /* Handle 15 */
    const handleReset = () => {
        setSearchQuery("");
        setFilteredData(data?.data);
        setSelectedSystem({ system: DFLT_System });
        setSelectedSubSystem(DFLT_SubSystem);
        setSelectedItemString("");
    };



    /* useEffect 01 - data                          */
    useEffect(() => {
        console.log("Fetched vehicle parts data:", data);
        setFilteredData(data?.data);
    }, [data]);

    /* useEffect 02 - VehiclesData                  */
    useEffect(() => {
        const currentDate = new Date();
        const dateTimeString = currentDate.toLocaleString();
        console.log(`Fetched All Vehicles data at ${dateTimeString}:`, VehiclesData);
    }, [VehiclesData]);

    /* useEffect 03 - userVehicleData               */
    useEffect(() => {
        console.log("Fetched user vehicles:", userVehicleData);
    }, [userVehicleData]);

    /* useEffect 04 - subSystems, handleShowItems   */
    useEffect(() => {
        if (subSystems.length === 1) {
            setSelectedSubSystem(subSystems[0]);
            handleShowItems({ target: { value: subSystems[0] } });
        }
    }, [subSystems, handleShowItems]);

    /* useEffect 05 - items                         */
    useEffect(() => {
        if (items.length === 1) {
            handleSelectedItem({ target: { value: JSON.stringify(items[0]) } });
        }
    }, [items]);

    /* useEffect 06 - brand                         */
    useEffect(() => {
        console.log("brand state updated:", brand);
    }, [brand]);

        
        return (
            <TabContentContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>

            {/* Review Parts to be Ordered */}
            <Modal show={showQuoteModal} onHide={() => setShowQuoteModal(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>                    <Modal.Title>{t("modals.review_parts_ordered")}</Modal.Title>                </Modal.Header>
            <Modal.Body>
                    <div className="mt-3">
                        <table className="table table-bordered">
                            <tbody>
                                {Object.entries(selectedParts.reduce((acc, part) => {
                                    const key = `${part.year} - ${part.brand} - ${part.model} - ${part.additionalInfo || ""}`;
                                    if (!acc[key]) {
                                        acc[key] = [];
                                    }
                                    acc[key].push(part);
                                    return acc;
                                }, {})).map(([vehicle, parts], index) => (
                                    <React.Fragment key={index}>
                                        <tr style={{ color: 'green' }}>
                                            <th colSpan="2">{vehicle}</th>
                                        </tr>

                                        {parts.map((part, idx) => (
                                            <tr key={idx}>
                                                <td>{part.subSystem} - {part.partName}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowQuoteModal(false)}>{t("buttons.back")}</Button>
                    <Button variant="primary" onClick={() => {
                        const data_requestQuote = {
                            userEmail: user?.email,      // Ensure email is correctly set
                            userId: user?._id,           // Ensure userId is correctly set
                            carInfo: selectedParts,      // Contains parts information
                            source: Source_Name
                        };
                        requestQuote({ data: data_requestQuote }).then(res => {
                            if (res?.data?.status) {
                                toast.success(res?.data?.message);
                                setSelectedItemString("");
                                setSelectedItem({ partName: DFLT_VehicleParts });
                                setSelectedSystem({ system: DFLT_System });
                                setSelectedSubSystem(DFLT_SubSystem);
                                setVehiclePartsCheck([]);
                                setSelectedParts([]);
                                setBrand(DFLT_Brand);
                                setShowQuoteModal(false);
                            } else {
                                toast.error(res?.data?.message);
                            }
                        });
                    }}>{t("buttons.place_order")}</Button>
                </Modal.Footer>
            </Modal>



                <div className="video-input-box">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="mt-4">
                            <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t("titles.vehicle_information")}</h5>
                        </div>
                        <div className="d-flex">
                            <button onClick={handleRequestQuote} className="btn btn-primary d-block" disabled={selectedParts.length === 0}>   {t("buttons.send_request")}   </button>
                            <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">{t("buttons.view_quotes")}</Tooltip>}     >
                                <Button variant="primary" className="ms-2" onClick={() => navigate("/subscriptions?tab=PartsQuotes")}>
                                    {t("buttons.view_quotes")}
                                </Button>
                            </OverlayTrigger>
                        </div>

                    </div>
                    <hr className="mt-1" />
        
                    <div className="row">
                        {/*  YEAR  */}
                        <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-4">
                        <Form.Label htmlFor="year">{t("labels.year")}</Form.Label>
                        {savedVehicleUsed ? (
                                <Form.Control id="year" type="text" value={selectedYear} readOnly aria-describedby="year" />
                            ) : (
                                <Form.Select id="year" className="shadow-none" name="year" value={selectedYear} onChange={handleYearChange} 
                                             aria-label={`Default ${DFLT_Year}`} aria-describedby="year">
                                    <option value={DFLT_Year}>{DFLT_Year}</option>
                                    {years.map(year => (
                                        <option     key={year}  value={year}>   {year}  </option>   ))}
                                </Form.Select>
                            )}
                        </div>

                    {/*  BRAND  */} {/* value={JSON.stringify(Vehicle)}   to  value={Vehicle.car} */}
                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-4">
                        <Form.Label htmlFor="brand">{t("labels.brand")}</Form.Label>
                        {savedVehicleUsed ? (
                            <Form.Control id="brand" type="text" value={brand} readOnly aria-describedby="brand" />
                        ) : (
                            <Form.Select id="brand" className="shadow-none" name="brand" value={brand} onChange={(e) => handle_BrandChange(e)}
                                         aria-label={`Default ${DFLT_Brand}`} aria-describedby="brand"
                            >


                                <option value={DFLT_Brand} data-vehicle={JSON.stringify({ brand: DFLT_Brand })}>{DFLT_Brand}</option>
                                {(VehiclesData?.data || [])
                                    .filter(Vehicle => Vehicle.published) // Filter only published vehicles
                                    .map(Vehicle => (
                                        <option key={Vehicle?._id} value={Vehicle.brand} data-vehicle={JSON.stringify(Vehicle)}>
                                            {Vehicle.brand}
                                        </option>
                                ))}

                            </Form.Select>
                            
                            
                        )}
                    </div>

                    {/*  MODEL  */}
                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-4">
                        <Form.Label htmlFor="model">{t("labels.model")}</Form.Label>
                        {savedVehicleUsed ? (
                            <Form.Control id="model" type="text" value={model} readOnly aria-describedby="model" />
                        ) : (
                            <Form.Select id="model" className="shadow-none" name="model" value={model} onChange={handleModelChange} 
                                         aria-label={`Default ${DFLT_Model}`} aria-describedby="model" disabled={!brand || brand === DFLT_Brand}
                            >
                                <option value={DFLT_Model}>{DFLT_Model}</option>
                                {vehicleObj?.models?.slice().sort().map(model => (
                                    <option key={model} value={model}>
                                        {model}
                                    </option>
                                ))}
                            </Form.Select>
                        )}
                    </div>
    
                    {/*  ADDITIONAL INFO  */}
                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        <Form.Label htmlFor="additional">{t("labels.additional_info")}</Form.Label>
                        <Form.Control id="additional" type="text" value={additional} onChange={e => setAdditional(e.target.value)} aria-describedby="additional" />
                    </div>
    
                    {/*  VEHICLE VIN  */}
                    <div className="input-wrapper mb-3 col-12 col-md-6 col-lg-6">
                        <Form.Label htmlFor="vin">{t("labels.vehicle_vin")}</Form.Label>
                        <Form.Control id="vin" type="text" value={vin} onChange={e => setVin(e.target.value)} aria-describedby="vin" />
                    </div>

                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t("titles.my_vehicles")}</h5>
                    <br /> 

                    <div className="d-flex justify-content-between align-items-center">
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">{t("tooltips.save_vehicle")}</Tooltip>}       >
                            <Button variant="primary" className="ms-2"              onClick={handle_SaveVehicle}>        <IoIosSave />             </Button>
                        </OverlayTrigger>

                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-reset">{t("tooltips.use_vehicle")}</Tooltip>}        >
                            <Button variant="primary" className="ms-2"              onClick={handle_UseVehicle}>         <IoCarSport />            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-reset">{t("tooltips.clear_vehicle")}</Tooltip>}        >
                            <Button variant="secondary" className="ms-2"            onClick={handle_ClearVehicle}>     <MdOutlineFilterAltOff />   </Button>
                        </OverlayTrigger>

                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-reset">{t("tooltips.delete_vehicle")}</Tooltip>}        >
                            <Button variant="danger" className="action-icon delete" onClick={handle_DeleteVehicle}>     <MdDelete />               </Button>
                        </OverlayTrigger>


                        


    
                        <Form.Select className="ms-2" value={selectedVehicle?._id || ""} onChange={(e) => setSelectedVehicle(userVehicleData?.data?.find(v => v._id === e.target.value))} 
                                     aria-label="Select Saved Vehicle" aria-describedby="select-saved-vehicle">
                            <option value="">{t("labels.select_saved_vehicle")}</option>
                            {userVehicleData?.data?.map(vehicle => (
                                <option key={vehicle._id} value={vehicle._id}>
                                    {`${vehicle.year} ${vehicle.brand} ${vehicle.model}`}
                                </option>
                            ))}
                        </Form.Select>
    
                        
                    </div>
                </div>

                <br /> <br /> 
                <div className="mt-4">
                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>{t("titles.select_vehicle_part")}</h5>
                    <Form.Control
                        type="text"
                        placeholder={t("placeholders.search")}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handle_Search();
                            }
                        }}
                    />
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-search">{t("tooltips.search")}</Tooltip>}     >
                        <Button id="searchButton"   onClick={handle_Search}  className="ms-2"    disabled={ model === "" || model === DFLT_Model }>  <BiSearchAlt /> </Button>
                    </OverlayTrigger>
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-search">{t("tooltips.remove_search_filter")}</Tooltip>}     >
                        <Button id="resetButton"    onClick={handleReset}   className="ms-2"    disabled={ model === "" || model === DFLT_Model || searchQuery === ""}>  <RxReset /> </Button>
                    </OverlayTrigger>
                </div>

                <hr />
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-8">
                        <div className="input-wrapper mb-3">
                        <label className="mt-3" htmlFor="text">{filteredData && filteredData.length !== data?.data?.length ? `${t("labels.system")} (${t("labels.filter")}: "${searchQuery}")` : t("labels.system")}:</label>

                            <Form.Select
                                onChange={handleViewCategory}
                                value={selected}
                                name="selectedSystem"
                                aria-label={`Default ${DFLT_System}`}
                                className="shadow-none"
                                disabled={brand === "" || brand === DFLT_Brand || model === "" || model === DFLT_Model}
                                default={DFLT_System}
                            >
                                <option value={JSON.stringify({ system: DFLT_System })}>{DFLT_System}</option>
                                {filteredData ? [...filteredData]
                                    .filter(d => d.published) // Only include systems with published true
                                    .sort((a, b) => a.system.localeCompare(b.system))
                                    .map(d => (
                                        <option key={d?._id} value={JSON.stringify(d)}>{d?.system}</option>
                                    )) : null}
                            </Form.Select>

                            <label className="mt-3" htmlFor="text">{t("labels.sub_system")}:</label>
                            <Form.Select
                                onChange={handleShowItems}
                                value={selectedSubSystem}
                                name="selectedSystem"
                                aria-label={`Default ${DFLT_SubSystem}`}
                                className="shadow-none"
                                disabled={selectedSystem.system === DFLT_System}
                            >
                                {subSystems.length > 1 ? (
                                    <>
                                        <option value={DFLT_SubSystem}>{DFLT_SubSystem}</option>
                                        {[...subSystems].sort((a, b) => a.localeCompare(b)).map(d => (
                                            <option key={d} value={d}>{d}</option>
                                        ))}
                                    </>
                                ) : (
                                    subSystems.length === 1 && <option value={subSystems[0]}>{subSystems[0]}</option>
                                )}
                            </Form.Select>

                            <label className="mt-3" htmlFor="text">{t("labels.part")}:</label>
                            <Form.Select
                                onChange={handleSelectedItem}
                                value={selectedItemString}
                                name="selectedSystem"
                                aria-label={`Default ${DFLT_VehicleParts}`}
                                className="shadow-none"
                                disabled={selectedSubSystem === DFLT_SubSystem}
                            >
                                {items.length > 1 ? (
                                    <>
                                        <option value={JSON.stringify({ partName: DFLT_VehicleParts })}>{DFLT_VehicleParts}</option>
                                        {[...items].sort((a, b) => a.partName.localeCompare(b.partName)).map(d => (
                                            <option key={d.partName} value={JSON.stringify(d)}>{d.partName}</option>
                                        ))}
                                    </>
                                ) : (
                                    items.length === 1 && (
                                        <>
                                            <option value={JSON.stringify(items[0])}>{items[0].partName}</option>
                                        </>
                                    )
                                )}
                            </Form.Select>
                        </div>
                    </div>


                    {selectedItemString && (
                        <div className="col-12 col-md-4 col-lg-4 d-flex flex-column justify-content-center">
                            <button onClick={() => handleSelectVehicleParts(selectedItem)} className="btn btn-primary mt-3">
                                {t("buttons.add_part")}
                            </button>
                            <strong className="text-center">{`${selectedYear}-${brand}-${model}`}</strong>
                            <div className="sub-cat-div w-100 mt-3">
                                {selectedItem?.partName !== DFLT_VehicleParts ? (
                                    <>
                                        <div className="vehicle-part-style w-100">
                                            {selectedItem?.partImage && (
                                                <img className="vehicle-part-img" src={selectedItem?.partImage} height={"100px"} width={"auto"} alt="part" />
                                            )}
                                            <div className="p-2 pb-0">
                                                <p className="carParts-name">{selectedItem?.partName}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <p className="text-danger">
                                            <small>{t("messages.no_vehicle_part_selected")}</small>
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <br /> 
                <div className="mt-4">
                    <h5 className="mb-0 pb-0" style={{ color: 'blue' }}>
                        {t("titles.parts_selected")}: {selectedParts.length >= 1 && `: ${selectedParts.length} ${t("titles.selected")}`}
                    </h5>
                </div>

                <hr />

                {/* selectedParts  =>>
                {"system":"BRAKE & ROTORS","subSystem":"Brake Pad","partName":"Front Brake Pad",
                "model":"Continental GT","brand":"Bentley","year":2024,"vin":"","quantity":1,"additional":"","partImage":""} */}
                <div className="row">
                    {selectedParts?.map((s, i) => (
                        <div key={i} className="col-12 col-md-5 col-lg-5 mx-2 my-2 card-style-part">
                            <div className="d-flex justify-content between align-items-center gap-2">
                                <div className="">
                                    <p className="mb-0 fw-bold">{`${t("labels.part_number")} ${i + 1}`}</p>
                                    <p className="partName-style">{s.partName}</p>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold">{t("labels.quantity")}</p>
                                    <input className="quantity-style" value={s.quantity} placeholder="Qt" onChange={e => handleValue(i, e.target.value)} />
                                </div>
                            </div>
                            <div className="vehicle-part-style w-100">
                                <div>{s?.partImage && <img className="selected-part-img" height={"170px"} src={s?.partImage} alt="part" />}</div>
                            </div>
                            <Button className="danger-btn d-block mx-auto mt-2 mb-2" variant="danger" onClick={() => handleRemoveVehicleParts(s)}>
                                {t("buttons.delete")}
                            </Button>
                            <div className="text-center mt-2">
                                <strong>{`${s.year}-${s.brand}-${s.model}`}</strong>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </TabContentContainer>
    );
};

export default SubsPartsOrders;
