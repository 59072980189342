/* REAL - DASH - MANAGE - MKTG-VIDEOS - PROMO VIDEOS */
/* File: /src/pages/Dashboard/DashboardPages/Services-VideoPromos/ManageVideoPromos.js  */

import moment                           from 'moment';
import React, 
        { useContext, 
        useEffect, 
        useMemo,
        useState }                      from "react";
import DatePicker                       from 'react-datepicker';
import                                       'react-datepicker/dist/react-datepicker.css';
import { Button, Image, Modal,
         Form,
         Row, Col, 
         Container,
         Tooltip, 
         OverlayTrigger
        }                               from "react-bootstrap";
import BootstrapTable                   from "react-bootstrap-table-next";
import filterFactory, 
        { textFilter }                  from "react-bootstrap-table2-filter";
import paginationFactory                from "react-bootstrap-table2-paginator";
import { BiSolidPencil }                from "react-icons/bi";
import { MdCampaign, 
         MdDelete,
         MdAddChart,
        }                               from "react-icons/md";
import { FaRegEye, 
         FaRegEyeSlash
        }                               from "react-icons/fa";
import { LuPenSquare }                  from "react-icons/lu";
import { FcViewDetails }                from "react-icons/fc";


import Select                           from "react-select";
import { toast }                        from "react-toastify";
import { useNavigate }                  from "react-router-dom";

import {
        useDeleteVideoMutation,
        useGetAllVideosWithRatingQuery,
        useGetVideoByIdQuery,
        useUploadVideoMutation
        }                               from "../../../../services/listVideoApi";
import { useGetAllAffiliatesQuery }     from "../../../../services/userAffiliateApi";
import { useGetAllCategoriesQuery }     from "../../../../services/listCategoryApi";
import { 
        useGetAllMarketingsQuery,
        }                               from "../../../../services/svcMarketingApi";
import DeleteModal                      from "../../../../components/Modal/DeleteModal";
import { UserContext }                  from "../../../../App";
import { Images }                       from "../../../../config/images";

import                                  "../ManageStyle.css";

const Source_Name           = "MANAGE - MKTG-VIDEOS - PROMO VIDEOS";
const STG_Date_Format       = "YYYY-MM-DD";
const STG_DateTime_Format   = "YYYY-MM-DD, HH:mm:ss";

const ManageVideos = ({ page = 1, sizePerPage = 25, lastPageText = ">>", firstPageText = "<<", nextPageText = ">", prePageText = "<" }) => {
    const navigate                              = useNavigate();
    // **  Constants section
    const [showModal,           setShowModal]           = useState(false);
    const [showComments,        setShowComments]        = useState(false);
    const [showDescription,     setShowDescription]     = useState(false);
    const [showDetails,         setShowDetails]         = useState(false);
    const [showCampaign,        setShowCampaign]        = useState(false);
    const [deleteModal,         setDeleteModal]         = useState(false);
    const [isPublished,         setIsPublished]         = useState(false);
    const [loadThumbnail,       setLoadThumbnail]       = useState(true);
    const [isNewPromotion,      setIsNewPromotion]      = useState(false);
    const [showAffiliatesModal, setShowAffiliatesModal] = useState(false);
 
    const [affiliates,          setAffiliates]          = useState([]);
    const [serviceCode,         setServiceCode]         = useState("");
    const [couponCode,          setCouponCode]          = useState("");
    const [campaignName,        setCampaignName]        = useState("");
    const [categories,          setCategories]          = useState([]);

    const [title,               setTitle]               = useState("");
    const [description,         setDescription]         = useState("");
    const [validFromDate,       setValidFromDate]       = useState("");
    const [validUntilDate,      setValidUntilDate]      = useState("");

    const [viewComments,        setViewComments]        = useState([]);
    const [viewRatings,         setViewRatings]         = useState([]);
    const [videoUrl,            setVideoUrl]            = useState("");
    const [displayUrl,          setDisplayUrl]          = useState(''); // New state variable for display
    const [isImageReady,        setIsImageReady]        = useState(true); // New state
    const [thumbnailUrl, setThumbnailUrl] = useState('');



    const [selectedId,          setSelectedId]          = useState("");
    const [selectedVideoUrl,    setSelectedVideoUrl]    = useState("");
    const [selectedItem,        setSelectedItem]        = useState("");
    const [videoLists,          setVideoLists]          = useState([]);
    const [selectedFiles,       setSelectedFiles]       = useState([]);
    const [marketingOptions,    setMarketingOptions]    = useState([]);
    
    const [subcategoryOptionsList,  setSubcategoryOptionsList]  = useState([]);
    const [selectedCategory,        setSelectedCategory]        = useState(null);
    const [selectedSubCategory,     setSelectedSubCategory]     = useState(null);
    const [selectedAffiliate,       setSelectedAffiliate]       = useState(null);
    const [selectedAffiliateName,   setSelectedAffiliateName]   = useState("");


    const { user }                       = useContext(UserContext);
    // const { data: Video_GetById }        = useGetVideoByIdQuery(selectedId);
    const { data: Video_GetById }        = useGetVideoByIdQuery({ id: selectedId, source: Source_Name });
    const { data: Videos_ListAll  }      = useGetAllVideosWithRatingQuery({ source: Source_Name });
    const { data: Category_ListAll }     = useGetAllCategoriesQuery({ source: Source_Name });
    const { data: Affiliate_UserList }   = useGetAllAffiliatesQuery({ source: Source_Name });
    const { data: Marketing_ListAll }    = useGetAllMarketingsQuery({ source: Source_Name });

    const [deleteVideo]                  = useDeleteVideoMutation();
    const [uploadVideo]                  = useUploadVideoMutation();

    const headerStyle           = { backgroundColor: 'blue', color: 'white', verticalAlign: 'top' };
    const headerStatStyle       = { backgroundColor: 'blue', color: 'white', verticalAlign: 'top', textAlign: 'right' };
    

    const handleFileChange      = (e) => {   setSelectedFiles(Array.from(e.target.files));    };
    
    /* Section 01.0 - findCategoryAndSubcategory */
    const findCategoryAndSubcategory = (categories, subcategoryNames) => {
        let categoryObj = null;
        console.log("-FIND-CATEGORY-AND-SUBCAT");
        //console.log("categories",categories);
        console.log("subcategoryNames",subcategoryNames); 
        categories.forEach(cat => {
            cat.subCategories.forEach(sub => {
                subcategoryNames.forEach(subCatName => {
                    const [catName, subName] = subCatName.split('|');
                    
                    if ((cat.categoryName === catName && sub === subName) || subcategoryNames.includes(sub)) {
                        categoryObj = cat;
                    }
                });
            });
        });

        if (!categoryObj) { 
            categoryObj = { subCategories: [] }; // Provide a default object with an empty subCategories array
        }
        return { categoryObj };
    };

    /* Section 02.0 - handlePublishChange */
    const handlePublishChange = () => {
        setIsPublished(prev => !prev);
    };

    /* Section 02.5 - handleThumbnailChange */
    const handleThumbnailChange = () => {
        setLoadThumbnail(prev => !prev);
        if (loadThumbnail && displayUrl) {
            let videoIdMatch = displayUrl;
    
            // Check if displayUrl is a full URL or already a video ID
            if (displayUrl.includes("youtube.com") || displayUrl.includes("youtu.be")) {
                videoIdMatch = displayUrl.match(/(?:\?v=|\/embed\/|\.be\/|\/v\/|\/e\/|\/u\/\w\/|embed\?clip_id=|clip_id=)([^#&?]*)(?:&.*)?$/);
                videoIdMatch = videoIdMatch ? videoIdMatch[1] : null;
            }
    
            console.log("videoIdMatch=", videoIdMatch);
    
            if (videoIdMatch) {
                const imageUrl = `https://img.youtube.com/vi/${videoIdMatch}/maxresdefault.jpg`;
                setThumbnailUrl(imageUrl);  // Set the thumbnail URL directly
                setIsImageReady(true);      // Mark image as ready
            } else {
                setIsImageReady(false);     // Reset if URL or videoId is not valid
            }
        } else {
            setIsImageReady(false);         // Reset when Thumbnail is toggled off
            setThumbnailUrl('');            // Clear thumbnail URL
        }
    };
    
    
    

    /* Section 03.0 - handleDeleteVideo */
    const handleDeleteVideo = id => {
        deleteVideo(id).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setDeleteModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 04.0 - getFullInterests */
    const getFullInterests = (selectedCategory, selectedSubCategory) => {
        console.log("GET-FULL-INTERESTS");
        console.log("selectedCategory",selectedCategory);
        console.log("selectedSubCategory",selectedSubCategory);
        if (!selectedCategory || !selectedSubCategory) return [];
        return selectedSubCategory.map(option => 
            option.label.includes('|') ? option.label : `${selectedCategory.label}|${option.label}`
        );
    };
    
    /* Section 05.0 - handleUploadVideo */
    const handleUploadVideo = async () => {
        try {
            // Validate dates and set to today's date if invalid
            const validatedFromDate = moment(validFromDate).isValid() ? moment(validFromDate).format(STG_Date_Format) : moment().format(STG_Date_Format);
            const validatedUntilDate = moment(validUntilDate).isValid() ? moment(validUntilDate).format(STG_Date_Format) : moment().format(STG_Date_Format);
    
            const fullInterests = getFullInterests(selectedCategory, selectedSubCategory);

            const data = new FormData();
            data.append("title", title);
            data.append("description", description);
            data.append("serviceCode", serviceCode);
            data.append("couponCode", couponCode);
            data.append("videoUrl", videoUrl);
            data.append("campaignName", campaignName);
            data.append("validFromDate", validatedFromDate);
            data.append("validUntilDate", validatedUntilDate);
            data.append("interests", fullInterests.join(','));
            data.append("affiliates", selectedAffiliate.value);
            data.append("postedBy", user?._id);
            data.append("isPublic", user?.role === "admin");

            selectedFiles.forEach(file => {
                data.append("promoDocs", file);
            });
    
            const res = await uploadVideo(data);
    
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setTitle("");
                setVideoUrl("");
                setDescription("");
                setServiceCode("");
                setCouponCode("");
                setCampaignName("");
                setValidFromDate(moment().format('YYYY-MM-DD'));
                setValidUntilDate(moment().format('YYYY-MM-DD'));
                setSelectedAffiliate(null);
                setSelectedCategory(null);
                setSelectedSubCategory(null);
            } else {
                toast.error(res?.data?.message);
            }
            setShowModal(false);
        } catch (error) {
            console.error('Error uploading video:', error);
            toast.error('An error occurred while uploading the video.');
        }
    };

    /* Section 06.0 - formDataToObject */
    const formDataToObject = (formData) => {
        const obj = {};
        formData.forEach((value, key) => {
            obj[key] = value;
        });
        return obj;
    };

    /* Section 07.0 - handleUpdate */
    const handleUpdate = async () => {
        if (!selectedCategory || !selectedSubCategory) {
            toast.error("Please select a category and subcategory.");
            return;
        }
    
        const formattedValidFromDate = moment(validFromDate).format(STG_Date_Format);
        const formattedValidUntilDate = moment(validUntilDate).format(STG_Date_Format);
        const fullInterests = getFullInterests(selectedCategory, selectedSubCategory);
    
        const data = new FormData();
        data.append("title", title);
        data.append("description", description);
        data.append("serviceCode", serviceCode);
        data.append("couponCode", couponCode);
        data.append("videoUrl", videoUrl);
        data.append("campaignName", campaignName);
        data.append("validFromDate", formattedValidFromDate);
        data.append("validUntilDate", formattedValidUntilDate);
        data.append("interests", JSON.stringify(fullInterests));
        data.append("affiliates", selectedAffiliate ? selectedAffiliate.value : null);
        data.append("isPublic", isPublished);
    
        selectedFiles.forEach(file => {
            data.append("promoDoc", file);
        });
    
        // Log the contents of the Form Data object for debugging
        for (const [key, value] of data.entries()) {
            console.log(`${key}:`, value);
        }
    
    // Convert FormData to a plain object
    const payloadData = formDataToObject(data);
    console.log("payloadData", payloadData);

    try {
        const response = await fetch(`http://localhost:5000/api/v1/listVideos/${selectedId}`, {
            method: 'PATCH',
            body: data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        });

        const result = await response.json();

        if (result.status) {
            toast.success(result.message);
            setShowModal(false);
            setSelectedFiles([]);
        } else {
            toast.error("HERE");
            toast.error(result.message);
        }
    } catch (error) {
        console.error("Update video error:", error);
        toast.error("An error occurred while updating the video.");
    }
    };
    
    /* Section 08.0 - handleCategoryChange */
    const handleCategoryChange = selectedCategory => {
        setSelectedCategory(selectedCategory);
        setSelectedSubCategory(null);
    
        const category = Category_ListAll?.data?.find(category => category._id === selectedCategory.value);
    
        if (category) {
            const sortedSubcategoryOptions = category.subCategories
                .map(subcategory_item => ({
                    value: subcategory_item,
                    label: subcategory_item,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)); // Sort by label in ascending order
    
            setSubcategoryOptionsList(sortedSubcategoryOptions);
        } else {
            setSubcategoryOptionsList([]);
        }
    };

    /* Section 09.0 - calculateAverageRating */
    const calculateAverageRating = ratings => {
        if (ratings.length === 0) return "---";
        const totalRating = ratings.reduce((acc, rating) => acc + rating, 0);
        const averageRating = totalRating / ratings.length;
        return averageRating.toFixed(1); // Round to one decimal place
    };

    /* Section 10.0 - fullname */
    const fullname = user => {
        if (!user) {
            return "";
        } else {
            return `${user.firstName} ${user.lastName}`;
        }
    };

    /* Section 11.0 - updatedVideoLists = useMemo(() => */
    const updatedVideoLists = useMemo(() => {
        if (videoLists.length > 0 && Affiliate_UserList && Affiliate_UserList.data && Category_ListAll && Category_ListAll.data) {
            console.log("-UPDATED-VIDEO-LISTS", videoLists.length);

            return videoLists.map(video => {
                const affiliate = Affiliate_UserList.data.find(user => user._id === video.affiliates[0]?._id);
                
                // Find the category based on the concatenated string in video.interests
                const category = Category_ListAll.data.find(cat =>
                    cat.subCategories.some(sub => 
                        video.interests.some(interest => {
                            const [catName, subName] = interest.split('|');
                            return cat.categoryName === catName && sub === subName;
                        })
                    )
                );
                // console.log("category",category);
    
                // Format the interests to display only the part after the '|'
                const formattedInterests = video.interests.map(interest => {
                    const parts = interest.split('|');
                    return parts.length > 1 ? parts[1] : interest;
                }).join(', ');
                // console.log("formattedInterests",formattedInterests); 
    
                return {
                    ...video,
                    fullname:                   fullname(affiliate),
                    categoryName:               category ? category.categoryName : 'Unknown',
                    campaignName:               video.campaignName,
                    serviceCode:                video.serviceCode,
                    couponCode:                 video.couponCode,
                    formattedValidFromDate:     moment(video.validFromDate).format(STG_Date_Format),
                    formattedValidUntilDate:    moment(video.validUntilDate).format(STG_Date_Format),
                    days:                       moment(video.validUntilDate).diff(moment(video.validFromDate), 'days') + 1,
                    formattedCreatedAt:         moment(video.createdAt).format(STG_DateTime_Format),
                    formattedInterests 
                };
            });
        }
        return videoLists;
    }, [videoLists, Affiliate_UserList, Category_ListAll]);
    
    /* Section 12.0 - headerFormatter = (text) => (column, colIndex, { sortElement, filterElement }) => */
    const headerFormatter = (text) => (column, colIndex, { sortElement, filterElement }) => {
        const lines = text.split(' ');
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                <div>
                    {lines.map((line, index) => (
                        <div key={index}>{line}{sortElement}</div>
                    ))}
                    
                    {filterElement}
                </div>

            </div>
        );
    };

    /* Section 13.0 - columns */
    const columns = [
        {   dataField: "id",              text: "No.",                 headerStyle: headerStyle,                                         formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {   dataField: "fullname",        text: "Affiliate Name",      headerStyle: headerStyle,   sort: true,   filter: textFilter(),   formatter: (cell, row) => row.fullname,
        },
        showCampaign && {
            dataField: "serviceCode",     text: "SvcCode",             headerStyle: headerStyle,   sort: true,   filter: textFilter(),
        },
        showCampaign && {
            dataField: "couponCode",      text: "Coupon",              headerStyle: headerStyle,   sort: true,   filter: textFilter(),
        },
        showCampaign && {
            dataField: "campaignName",    text: "Campaign",            headerStyle: headerStyle,   sort: true,   filter: textFilter(),
        },
        {   dataField: "categoryName",    text: "Category",            headerStyle: headerStyle,   sort: true,   filter: textFilter(),
        },
        {   dataField: "interests",       text: "Interests",           headerStyle: headerStyle,   sort: true,   filter: textFilter(),   formatter: cell => cell.map(interest => interest.split('|').pop()).join(', '),
        },
        {   dataField: "title",           text: "Title",               headerStyle: headerStyle,   sort: true,   filter: textFilter(),
        },
        showDescription && {
            dataField: "description",     text: "Description",         headerStyle: headerStyle,   sort: true,   filter: textFilter(),
        },
        {   dataField: "formattedValidFromDate",  text: "Valid From",  headerStyle: headerStyle,   sort: true,   filter: textFilter(), headerFormatter: headerFormatter("Valid From"),
        },
        showDetails && {
            dataField: "formattedValidUntilDate", text: "Valid Until", headerStyle: headerStyle,   sort: true,   filter: textFilter(), headerFormatter: headerFormatter("Valid Until"),
        },
        {   dataField: "days",            text: "Valid Days",          headerStyle: headerStyle,   sort: true,   filter: textFilter(), headerFormatter: headerFormatter("Valid Days"),
        },
        showDetails && {
            dataField: "ratings",         text: "Rating AVG",          headerStyle: headerStyle,   sort: true,   filter: textFilter(),   formatter: cell => calculateAverageRating(cell),
        },
        showDetails && {
            dataField: "comments",        text: "Comm Nb.",            headerStyle: headerStatStyle, sort: true,                         formatter: (cell, row) => (
                <div className={`commentCount ${cell.length > 0 ? "commentBox" : "commentCount noLink"}`}
                     onClick={() => {    setViewComments(row.comments);  setViewRatings(row.ratings); setShowComments(true);  }}>
                     {cell.length > 0 ? `${cell.length}` : "---"}
                </div>
            ),
        },
        showDetails && {
            dataField: "formattedCreatedAt", text: "Date Created", headerStyle: headerStyle, sort: true, filter: textFilter(),
        },
        {   dataField: "isPublic",      text: "Publish",              headerStyle: headerStyle,     sort: true,                          formatter: cell => (cell ? "Yes" : "No"),
        },
    ].filter(Boolean);

    /* Section 14.0 - defaultSorted */
    const defaultSorted = [     {   dataField: "title", order: "asc",   },  ];

    /* Section 15.0 - paginationOptions */
    const paginationOptions = {
        page,
        sizePerPage,
        lastPageText,
        firstPageText,
        nextPageText,
        prePageText,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange:           function (page, sizePerPage) { console.log("page", page);   console.log("size per page", sizePerPage);  },
        onSizePerPageChange:    function (page, sizePerPage) { console.log("page", page);   console.log("size per page", sizePerPage);  },
    };

    /* Section 16.0 - getFormattedSubCategories = (selectedSubCategories) => */
    const getFormattedSubCategories = (selectedSubCategories) => {
        if (!selectedSubCategories || selectedSubCategories.length === 0) return [];
    
        const seenLabels = new Set();
        return selectedSubCategories
            .map(subCategory => {
                const parts = subCategory.label.split('|');
                const label = parts.length > 1 ? parts[1] : subCategory.label;
                return {
                    ...subCategory,
                    label,
                };
            })
            .filter(subCategory => {
                if (seenLabels.has(subCategory.label)) {
                    return false;
                } else {
                    seenLabels.add(subCategory.label);
                    return true;
                }
            });
    };
    
    /* Section 17.0 - getFilteredSubcategoryOptions = (selectedSubCategories, subcategoryOptionsList) => */
    const getFilteredSubcategoryOptions = (selectedSubCategories, subcategoryOptionsList) => {
        console.log("FILTERING", selectedSubCategories );
        console.log("FILTERED", subcategoryOptionsList );
        if (!selectedSubCategories || selectedSubCategories.length === 0) return subcategoryOptionsList;
        
        const selectedValues = new Set(selectedSubCategories.map(subCategory => {
            const parts = subCategory.value.split('|');
            return parts[parts.length - 1];
        }));
    
        return subcategoryOptionsList.filter(option => !selectedValues.has(option.value));
    };

    /* Section 18.0 - handle_UrlChange */
    const handle_UrlChange = (e) => {
        const url = e.target.value;
        setVideoUrl(url);

        const videoIdMatch = url.match(/(?:\?v=|\/embed\/|\.be\/|\/v\/|\/e\/|\/u\/\w\/|embed\?clip_id=|clip_id=)([^#&?]*)(?:&.*)?$/);
        if (videoIdMatch && videoIdMatch[1]) {
            setDisplayUrl(videoIdMatch[1]); // Set the display URL to the video ID
        } else {
            setDisplayUrl(url); // Set display URL to the full URL if not a valid YouTube URL
        }
    };

    /* Section 19.0 -  affiliateCounts - Calculate the count of videos for each affiliate */
    const affiliateCounts = useMemo(() => {
        if (!Videos_ListAll || !Videos_ListAll.data) return {};

        return Videos_ListAll.data.reduce((acc, video) => {
            video.affiliates.forEach(affiliate => {
                if (acc[affiliate._id]) {
                    acc[affiliate._id]++;
                } else {
                    acc[affiliate._id] = 1;
                }
            });
            return acc;
        }, {});
    }, [Videos_ListAll]);

    const [selectedAffiliates, setSelectedAffiliates] = useState([]);

    /* Section 20.0 -  useEffect - [Affiliate_UserList, affiliateCounts] */
    useEffect(() => {
        if (Affiliate_UserList?.data) {
            const filteredAffiliates = Affiliate_UserList.data
                .filter(affiliate => affiliateCounts[affiliate._id] < 50) // Only select affiliates with a count below 50
                .map(affiliate => affiliate._id);
            
            setSelectedAffiliates(filteredAffiliates);
        }
    }, [Affiliate_UserList, affiliateCounts]);
        
    /* Section 21.0 - handleOpenAffiliatesModal */
    const handleOpenAffiliatesModal = () => {
        if (Affiliate_UserList?.data) {
            const filteredAffiliates = Affiliate_UserList.data
                .filter(affiliate => affiliateCounts[affiliate._id] < 50) // Only select affiliates with a count below 50
                .map(affiliate => affiliate._id);
            
            setSelectedAffiliates(filteredAffiliates); // Pre-select only the filtered affiliates
        }
        setShowAffiliatesModal(true);
    };

    /* Section 22.0 -  handleAffiliateSelection */
    const handleAffiliateSelection = (affiliateId) => {
        setSelectedAffiliates(prevSelectedAffiliates => {
            if (prevSelectedAffiliates.includes(affiliateId)) {
                // Deselect the affiliate
                return prevSelectedAffiliates.filter(id => id !== affiliateId);
            } else {
                // Select the affiliate
                return [...prevSelectedAffiliates, affiliateId];
            }
        });
    };

    /* Section 23.0 - useEffect - Category_ListAll - Populate categories when Category_ListAll changes */
    useEffect(() => {
        if (Category_ListAll && Category_ListAll.data && Category_ListAll.data.length) {
            const sortedCategories = [...Category_ListAll.data]
                .map(item => ({
                    value: item._id,
                    label: item.categoryName,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)); // Sort by label in ascending order

            setCategories(sortedCategories);
        }
    }, [Category_ListAll]);

    /* Section 24.0 - useEffect - Affiliate_UserList - Populate affiliates when Affiliate_UserList changes */
    useEffect(() => {
        if (Affiliate_UserList && Affiliate_UserList.data && Affiliate_UserList.data.length) {
            const sortedAffiliates = Affiliate_UserList.data
                .map(item => ({
                    value: item._id,
                    label: fullname(item),  // Using the fullname function here
                }))
                .sort((a, b) => a.label.localeCompare(b.label)); // Sort by label in ascending order
    
            setAffiliates(sortedAffiliates);
        }
    }, [Affiliate_UserList]);

    /* Section 25.0 - useEffect - Video_GetById, affiliates, Category_ListAll, categories - Update video details when Video_GetById changes */
    useEffect(() => {
        if (Video_GetById && Video_GetById.result) {
            setTitle(Video_GetById.result.title);
            setVideoUrl(Video_GetById.result.videoUrl);
            setDisplayUrl(Video_GetById.result.videoUrl.match(/(?:\?v=|\/embed\/|\.be\/|\/v\/|\/e\/|\/u\/\w\/|embed\?clip_id=|clip_id=)([^#&?]*)(?:&.*)?$/)?.[1] || Video_GetById.result.videoUrl); // Set display URL

            setDescription(Video_GetById.result.description);
            setServiceCode(Video_GetById.result.serviceCode);
            setCouponCode(Video_GetById.result.couponCode);
            setCampaignName(Video_GetById.result.campaignName);
            setValidFromDate(Video_GetById.result.validFromDate);
            setValidUntilDate(Video_GetById.result.validUntilDate);

            setIsPublished(Video_GetById.result.isPublic);
            if (Video_GetById.result.affiliates) {
                const user = affiliates.find(user => user.value === Video_GetById.result.affiliates);
                setSelectedAffiliate(user);
            } else {
                setSelectedAffiliate(null);
            }
            if (Video_GetById.result.interests.length > 0) {
                console.log("-MATCHING CATEGORY AND SUBCATEGORY-");
                console.log("Video_GetById.result.interests", Video_GetById.result.interests);
                const subcategory_item = Video_GetById.result.interests;
                //returns Ex: ["Electronics|Security Systems"]
                
                const { categoryObj } = findCategoryAndSubcategory(Category_ListAll.data, subcategory_item); 
                //* Returns Ex: {_id: "64cef7226fb997d0fdd072b6", categoryName: "Electronics", subCategories: ["General Electronics", "Security Systems"], …} 
                // {_id: "64cef7226fb997d0fdd072b6", categoryName: "Electronics", subCategories: ["General Electronics", "Security Systems"], createdAt: "2023-08-06T01:28:02.885Z", updatedAt: "2023-08-06T01:28:02.885Z", …}
                
                console.log("categoryObj:", categoryObj);
                console.log("subcategory_item", subcategory_item);
                const cate = categories.find(cate => cate.value === categoryObj._id); 
                // returns Ex: {value: "64cef7226fb997d0fdd072b6", label: "Electronics"}
                
                console.log("cate", cate);
                setSelectedCategory(cate);
                const subcategoryOptions = categoryObj.subCategories.map(subcategory_item => ({
                    value: subcategory_item,
                    label: subcategory_item,
                }));
                console.log("subcategoryOptions", subcategoryOptions);

                const selectedSubCategory = Video_GetById.result.interests.map(interest => ({
                    value: interest,
                    label: interest,
                }));
                console.log("selectedSubCategory", selectedSubCategory);


                setSubcategoryOptionsList(subcategoryOptions);
                setSelectedSubCategory(selectedSubCategory);
            } else {
                setSelectedCategory(null);
                setSelectedSubCategory(null);
            }

            // Set selectedFiles if promoDoc is available
            if (Video_GetById.result.promoDoc && Video_GetById.result.promoDoc.length > 0) {
                const files = Video_GetById.result.promoDoc.map(doc => ({
                    name: doc.filename,
                    size: doc.length,
                    type: doc.contentType,
                    lastModified: doc.uploadDate,
                }));
                console.log("FILESSSS===", files);
                setSelectedFiles(files);
            } else {
                setSelectedFiles([]);
            }

        }
    }, [Video_GetById, affiliates, Category_ListAll, categories]);

    /* Section 26.0 - useEffect - Filter Video List Based on Selected Affiliates and User Role */
    useEffect(() => {
        if (Videos_ListAll && Videos_ListAll.data) {
            let filteredVideos = Videos_ListAll.data;

            // Filter based on selected affiliates (those with counts below 50)
            if (selectedAffiliates.length > 0) {
                filteredVideos = filteredVideos.filter(video => 
                    video.affiliates.some(affiliate => selectedAffiliates.includes(affiliate._id))
                );
            }

            // Further filter based on user role if the user is an affiliate
            if (user.role === "affiliate") {
                filteredVideos = filteredVideos.filter(item => 
                    item.affiliates.some(affiliate => affiliate._id === user._id)
                );
            }

            setVideoLists(filteredVideos);
        }
    }, [Videos_ListAll, selectedAffiliates, user.role, user._id, affiliateCounts]);

    /* Section 27.0 - useEffect - [Marketing_ListAll] // Add this new useEffect section after other useEffect hooks */ 
    useEffect(() => {
        if (Marketing_ListAll && Marketing_ListAll.data) {
            const options = Marketing_ListAll.data.map(item => ({
                value: item.serviceCode,
                label: item.serviceCode,
            }));
            setMarketingOptions(options);
        }
    }, [Marketing_ListAll]);

    /* Section 28.0 - openModal - Function to handle opening the modal */
    const openModal = (isNewPromotion) => {
        setShowModal(true);
        setIsNewPromotion(isNewPromotion);
        setSelectedFiles([]);
    };

    /* Section 29.0 - closeModal - Function to handle closing the modal */
    const closeModal = () => {
        setShowModal(false);
        setIsNewPromotion(false);
        setSelectedFiles([]);
    };

    const selectRow = {
        mode: "radio", // Ensures that only one row can be selected at a time
        clickToSelect: true,
        style: { backgroundColor: '#c8e6c9' }, // Highlight the selected row with a background color
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelectedId(row._id); // Set the selected row's ID
                setSelectedAffiliateName(row.fullname);
                setSelectedVideoUrl(row.videoUrl);
            } else {
                setSelectedId(''); // Clear the selection if unselected
            }
        },
    };
    
    return (
        <div className="video-table-container">
            <h2 className="video-page-title">PROMO VIDEOS ({updatedVideoLists.length})</h2>

            {/* New Promo */}
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-new-promo">New Promo</Tooltip>}>
                <Button variant="primary" style={{ marginRight: '10px' }} onClick={() => openModal(true)}>
                    <MdAddChart />
                </Button>
            </OverlayTrigger>

            {/* Show Description */}
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-show-description">Show Description</Tooltip>}>
                <Button variant="primary" style={{ marginRight: '10px' }} onClick={() => setShowDescription(prev => !prev)}>
                    {showDescription ? (<span><FaRegEyeSlash /><LuPenSquare /></span>)
                                    : (<span><FaRegEye /><LuPenSquare /></span>)}
                </Button>
            </OverlayTrigger>

            {/* Show Campaign */}
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-show-campaign">Show Campaign</Tooltip>}>
                <Button variant="primary" style={{ marginRight: '10px' }} onClick={() => setShowCampaign(prev => !prev)}>
                    {showCampaign ? (<span><FaRegEyeSlash /><MdCampaign /></span>)
                                : (<span><FaRegEye /><MdCampaign /></span>)}
                </Button>
            </OverlayTrigger>

            {/* Show Details */}
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-details">Toggle Details</Tooltip>}>
                <Button variant="primary" style={{ marginRight: '10px' }} onClick={() => setShowDetails(prev => !prev)}>
                {showDetails ? (<span><FaRegEyeSlash /><FcViewDetails /></span>)
                            : (<span><FaRegEye /><FcViewDetails /></span>)}
                </Button>
            </OverlayTrigger>

            {/* Go to My Subscriptions */}
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-mysubs">Go to My Subscriptions</Tooltip>}>
                <Button variant="primary" onClick={() => navigate('/subscriptions')}>
                    MySubs
                </Button>
            </OverlayTrigger>

            <div className="d-flex align-items-center justify-content-center gx-4">
                {user?.role === "power" || user?.role === "admin" || user?.role === "support" || user?.role === "affiliate" ? (
                    <>
                        <span className="action-icon aff" onClick={handleOpenAffiliatesModal}>
                            Aff
                        </span>
                        <span className="selected-affiliate-name ms-2">
                            {selectedAffiliateName || "No Affiliate Selected"}
                        </span>
                        <a 
                            target="_blank" 
                            href={selectedVideoUrl || "#"}
                            rel="noreferrer" 
                            className="action-icon view"
                            onClick={(e) => {
                                if (!selectedId) {
                                    e.preventDefault(); // Prevent the default action if `selectedId` is not set
                                }
                            }}
                        >
                            <FaRegEye />
                        </a>
                        <span className="action-icon update" onClick={() => {   if (selectedId) { setIsNewPromotion(false); setShowModal(true); }   }}>
                            <BiSolidPencil />
                        </span>

                        <span className="action-icon delete" onClick={() => {   if (selectedId) { setSelectedItem(selectedId);  setDeleteModal(true);   }   }}>
                            <MdDelete />
                        </span>
                    </>
                ) : (
                    <>
                        <span style={{ background: "grey" }} className="action-icon view">
                            <FaRegEye />
                        </span>
                        <span style={{ background: "grey" }} className="action-icon update">
                            <BiSolidPencil />
                        </span>
                        <span style={{ background: "grey" }} className="action-icon delete">
                            <MdDelete />
                        </span>
                    </>
                )}
            </div>

            
            {/* Table Description */}
            <div className="table-responsive">
                <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={updatedVideoLists}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    striped
                    hover
                    condensed
                    responsive
                    scrollable
                    pagination={paginationFactory(paginationOptions)}
                    noDataIndication="No data found!"
                    filter={filterFactory()}
                    filterPosition="top"
                    selectRow={selectRow} // Add rowEvents to make the row clickable
                    rowStyle={(row, rowIndex) => ({
                        backgroundColor: selectedId === row._id ? '#c8e6c9' : 'white' // Highlight the selected row
                    })}
                />
            </div>


            <Modal 
                show={showModal} 
                onHide={closeModal}  
                backdrop="static" 
                keyboard={false} 
                dialogClassName="custom-modal-width"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isNewPromotion ? "Register New Promo" : "Update Promotion Details"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="manage-video-page">
                        <div className="video-upload-container">
                            <div className="video-input-box 2">
                                <h2 className="video-page-title">PROMO INFORMATION</h2>
                                <Container>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="title">*Title</label>
                                                <input  type="text"     id="title"    placeholder="Title"             value={title}           onChange={e => setTitle(e.target.value)}          />
                                            </div>
                                            <div className="input-wrapper">
                                                <label htmlFor="title">*Youtube link (URL)</label>
                                                <input  type="text"     id="title"    placeholder="https://"          value={displayUrl}        onChange={handle_UrlChange}       />
                                            </div>
                                            <div className="input-wrapper">
                                                <label htmlFor="title">Campaign Name</label>
                                                <input type="text"      id="title"    placeholder="Campaign Name"     value={campaignName}    onChange={e => setCampaignName(e.target.value)}   />
                                            </div>
                                            <div className="input-wrapper text-area">
                                                <label htmlFor="textarea">*Description</label>
                                                <textarea
                                                    name=""     id="textarea"   cols="30"   rows="8"
                                                    placeholder="Video Description"     value={description}     onChange={e => setDescription(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <div className="input-wrapper custom-input">
                                                <label htmlFor="title">*Affiliate Name</label>
                                                <Select  className="mb-2"    value={selectedAffiliate}   options={affiliates}              onChange={setSelectedAffiliate}      isDisabled={user?.role === "affiliate"}  />
                                            </div>
                                            <div className="input-wrapper custom-input">
                                                <label htmlFor="title">*Interest Category</label>
                                                <Select  className="mb-2"    value={selectedCategory}    options={categories}              onChange={handleCategoryChange}      isDisabled={!selectedAffiliate}          />
                                            </div>
                                            <div className="input-wrapper">
                                                <label htmlFor="title">*Sub Category</label>
                                                <Select  className="mb-2"    value={getFormattedSubCategories(selectedSubCategory)} 
                                                options={getFilteredSubcategoryOptions(selectedSubCategory, subcategoryOptionsList)}  onChange={setSelectedSubCategory}    isDisabled={!selectedCategory} isMulti   />
                                            </div>

                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <div className="input-wrapper custom-input">
                                                        <label htmlFor="dueDate" className=" form-label">*Valid From</label>
                                                        <DatePicker
                                                            selected={validFromDate ? new Date(validFromDate) : null}
                                                            onChange={date => {
                                                                const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                                                                setValidFromDate(moment(adjustedDate).format('YYYY-MM-DD'));
                                                            }}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            minDate={new Date()}
                                                            placeholderText="Valid From"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="input-wrapper custom-input">
                                                        <label htmlFor="dueDate" className=" form-label">Valid Until</label>
                                                        <DatePicker
                                                            selected={validUntilDate ? new Date(validUntilDate) : null}
                                                            onChange={date => {
                                                                const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                                                                setValidUntilDate(moment(adjustedDate).format('YYYY-MM-DD'));
                                                            }}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            minDate={new Date(validFromDate)}
                                                            placeholderText="Valid Until"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <div className="input-wrapper  custom-input">
                                                        <label htmlFor="title" className="custom-input">*Service Code</label>
                                                        <Select 
                                                            className="mb-2 custom-input" 
                                                            value={serviceCode ? { value: serviceCode, label: serviceCode } : null} 
                                                            options={marketingOptions} 
                                                            onChange={option => setServiceCode(option.value)} 
                                                            isDisabled={!selectedAffiliate} 
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="input-wrapper custom-input">
                                                        <label htmlFor="title">Coupon Code</label>
                                                        <input  type="text"     id="title"      placeholder="Coupon Code"       value={couponCode}      onChange={e => setCouponCode(e.target.value)}   />
                                                    </div>
                                                </Col>
                                            </Row>
                                            {user?.role !== "affiliate" && (
                                                <div className="form-check form-switch ps-0" style={{ display: "flex" }}>
                                                    <label className="form-check-label dark" htmlFor="flexSwitchCheckChecked">Publish Promo: </label>
                                                    <input
                                                        className="form-check-input mx-2"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        checked={isPublished}
                                                        onChange={handlePublishChange}
                                                    />
                                                    <label className="form-check-label dark" htmlFor="flexSwitchCheckChecked">{isPublished ? 'On' : 'Off'}</label>
                                                </div>
                                            )}
                                                <div className="form-check form-switch ps-0" style={{ display: "flex" }}>
                                                    <label className="form-check-label dark" htmlFor="flexSwitchCheckChecked">Thumbnail: </label>
                                                    <input
                                                        className="form-check-input mx-2"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="loadThumbnail"
                                                        checked={loadThumbnail}
                                                        onChange={handleThumbnailChange}
                                                    />
                                                    <label className="form-check-label dark" htmlFor="flexSwitchCheckChecked">{loadThumbnail ? 'On' : 'Off'}</label>
                                                    {isImageReady && (
                                                        <div>
                                                            <img src={thumbnailUrl} alt="Video Thumbnail" style={{ width: '100px', height: 'auto' }} />
                                                        </div>
                                                    )}



                                                </div>
                                        </Col>
                                        <div className="input-wrapper custom-input">
                                            <label htmlFor="file">File (pdf, image, audio)</label>
                                            <input 
                                                type="file" 
                                                id="file" 
                                                name="promoDoc" // Ensure the field name matches the backend expectation
                                                accept=".pdf,.jpg,.jpeg,.png,.mp3,.wav" 
                                                multiple 
                                                onChange={handleFileChange}
                                            />
                                            <div className="file-list custom-input">
                                                {selectedFiles.map((file, index) => (
                                                <div key={index} className="file-item custom-input">{file.name}</div>
                                                ))}
                                            </div>
                                        </div>


                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={isNewPromotion ? handleUploadVideo : handleUpdate}>
                        {isNewPromotion ? "Register Now" : "Update Promo"}
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Modal for selecting affiliates */}
            <Modal show={showAffiliatesModal} onHide={() => setShowAffiliatesModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Affiliates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {Affiliate_UserList && Affiliate_UserList.data
                            .slice() // Create a shallow copy of the array to avoid mutating the original array
                            .sort((a, b) => fullname(a).localeCompare(fullname(b))) // Sort affiliates by full name in ascending order
                            .map(affiliate => (
                                <Form.Check 
                                    key={affiliate._id}
                                    type="checkbox"
                                    id={affiliate._id}
                                    label={`${(affiliateCounts[affiliate._id] || 0).toString().padStart(4, '0')} - ${fullname(affiliate)} `}
                                    checked={
                                        selectedAffiliates.includes(affiliate._id) 
                                        ? true // If the affiliate is in the selected list, keep it checked
                                        : affiliateCounts[affiliate._id] < 50 // Uncheck if count is 50 or more by default
                                    }
                                    onChange={() => handleAffiliateSelection(affiliate._id)}
                                />
                            ))}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAffiliatesModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>







            <DeleteModal
                question={"Do you really want to delete the Video?"}
                heading={"Delete Video"}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                handleDeleteItem={handleDeleteVideo}
                id={selectedItem}
            />

            <Modal show={showComments} onHide={() => setShowComments(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>User Ratings and Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="video-comment-list">
                        {viewComments.map((comment, index) => (
                            <li key={index}>
                                <div className="comment">
                                    <div className="user-imge">
                                        <Image src={Images.userProfileComment} />
                                    </div>
                                    <div>
    <p className="comment-text">{viewRatings[index] || 'No Rating'} - {comment}</p>
</div>

                                </div>
                            </li>
                        ))}
                    </ul>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowComments(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};
export default ManageVideos;
