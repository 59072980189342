/* REAL - SETTINGS - Security */
/* File: /src/pages/Settings/Security/index.jsx  */
import moment from                                  "moment";
import { 
        useTranslation 
        }                                           from 'react-i18next';
import { 
        FaChevronDown, 
        FaChevronUp 
        }                                           from 'react-icons/fa';
import React, 
        { useState }                                from "react";
import { 
        Button, 
        Col, 
        Form, 
        Modal, 
        Row 
        }                                           from "react-bootstrap";
import { 
        toast 
        }                                           from "react-toastify";
import                                              "./securityStyle.css";
import { 
        FormButton,            
        FormControlField, 
        FormLabelText,         
        SecurityFormContainer, 
        ShowHidePassField 
        }                                           from "./securityStyle";

import {
        TabContentContainer,   
        TabSubTitle, 
        TableContainer,        
        TextContent 
        }                                           from "../../Tabs/tabContentStyle";

import TableComponent                               from "../../../components/UI/TableComponent";
import { 
        staggerVariants,       
        titleVariants 
        }                                           from "../../../utils/FmVariants";
import {
        Images,                
        SVGImages 
        }                                           from "../../../config/images";
import {
        useGetLastLoginQuery,
        useSendTwoFactorMutation,
        useUpdatePasswordMutation,
        useVerifyTwoFaCodeMutation,
        useVerifyTwoFaPhoneCodeMutation,
        useSigninMutation,
        useSendTwoFaPhoneCodeMutation,
        useTwoFaAuthGenerateMutation,
        useTwoFaAuthVerifyMutation,
        useDeactivateTwoFaMutation,
        }                                           from "../../../services/userUserApi";


const STG_Date_Format                       = "YYYY-MM-DD";
const STG_Time_Format                       = "HH:mm:ss";
const STG_Security_Nb_LastLogin             = 10;
const STG_passwordRegex                     = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
const STG_KW_TwoFaAuth                      = "twoFaAuth";
const STG_KW_TwoFaEmail                     = "twoFaEmail";
const STG_KW_TwoFaPhone                     = "twoFaPhone";
// const STG_KW_TwoFaMethodNone                = "None";


const SetSecurity = ({ user, refetch, setRefetch }) => {
    const { t }                             = useTranslation('settingsSecurity');
    const Source_Name                       = "Settings-SetSecurity";
    const { data: dataLogins }                              = useGetLastLoginQuery({ id: user?._id, source: Source_Name });
    const [showPasswordSection,     setShowPasswordSection] = useState(false);
    const [show2FASection,          setShow2FASection]      = useState(false); 
    const [showSignInSection,       setShowSignInSection]   = useState(false);



    return (
        <TabContentContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
            {/* ==================================================== */}
            {/* Change Password Section with Chevron */}
            <TabSubTitle className="mb-2" onClick={() => setShowPasswordSection(!showPasswordSection)} style={{ cursor: 'pointer' }}>
                <span className="blue-text">{t('change_password_title')}</span> 
                {showPasswordSection ? <FaChevronUp className="green-icon" /> : <FaChevronDown className="green-icon" />}
            </TabSubTitle>

            <br />
            {showPasswordSection && (
                <SecurityForm user={user} />
            )}


            {/* ==================================================== */}
            {/* Two Factor Authentication (2FA) Section with Chevron */}
            <TabSubTitle
                className="mb-2"
                onClick={() => setShow2FASection(!show2FASection)}
                style={{ cursor: 'pointer', marginBottom: show2FASection ? '0.5rem' : '1.5rem' }}
            >
                <span className="blue-text">{t('2fa_title')}</span> 
                {show2FASection ? <FaChevronUp  className="green-icon" /> : <FaChevronDown  className="green-icon" />}
            </TabSubTitle>

            <br />
            <div style={{ display: show2FASection ? 'block' : 'none' }}>
            <TextContent className="full">
                <p className="text-start">
                    {t('2fa_description')}
                </p>
            </TextContent>

            {/* Render the form */}
            <Auth2FAForm user={user} refetch={refetch} setRefetch={setRefetch} />
            </div>


            {/* ==================================================== */}
            {/* Last 10 Sign In Connections Section with Chevron */}
            <TabSubTitle
                className="mb-2"
                onClick={() => setShowSignInSection(!showSignInSection)}
                style={{ cursor: 'pointer', marginBottom: showSignInSection ? '0.5rem' : '1.5rem' }}
            >
                <span className="blue-text">{t('last_signin_title', { number: STG_Security_Nb_LastLogin })}</span>
                {showSignInSection ? <FaChevronUp className="green-icon" /> : <FaChevronDown className="green-icon" />}
            </TabSubTitle>

            {/* Always render the table, but hide it when the section is collapsed */}
            <br />
            <div style={{ display: showSignInSection ? 'block' : 'none' }}>
                <TableContainer>
                    <TableComponent variants={titleVariants} isDataTable={+false}>
                    <thead>
                        <tr>
                        <th>{t('signin_date')}</th>
                        <th>{t('signin_time')}</th>
                        <th>{t('signin_ip_address')}</th>
                        <th>{t('signin_device')}</th>
                        <th>{t('signin_model')}</th>
                        <th>{t('signin_browser')}</th>
                        <th>{t('signin_location')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {dataLogins?.data?.map(l => (
                        <tr key={l?._id}>
                            <td>{moment(l.lastLoginTime).format(STG_Date_Format)}</td>
                            <td>{moment(l.lastLoginTime).format(STG_Time_Format)}</td>
                            <td>{l?.ip}</td>
                            <td>{l?.device}</td>
                            <td>{l?.model}</td>
                            <td>{l?.browser?.name}</td>
                            <td>{l?.location ? l?.location : "Not Available"}</td>
                        </tr>
                        ))}
                    </tbody>

                    </TableComponent>
                </TableContainer>
            </div>
        </TabContentContainer>
    );
};

const SecurityForm = ({ user }) => {
    const Source_Name                       = "Auth-Settings-Security-TurnOnTwoFaAuth";
    const { t }                             = useTranslation('settingsSecurity');
    const [showPassword, setShowPassword]   = useState(false);
    const [showConfPass, setShowConfPass]   = useState(false);
    const [error,        setError]          = useState(false);
    const [newPassword,  setNewPassword]    = useState("");
    const [oldPassword,  setOldPassword]    = useState("");
    const [User_UpdatePassword]             = useUpdatePasswordMutation();
    
    
    

    const handleChangePassword = async () => {
        if (!(newPassword && oldPassword)) {    
            toast.error(t('error_missing_fields')); 
            return;  } 
        if (!STG_passwordRegex.test(newPassword)) { setError(true); } 

        const data_User_UpdatePassword = { ids:    [user?._id], task:   "updatePassword",  password: newPassword, oldPass: oldPassword };
        const res_User_UpdatePassword = await User_UpdatePassword({ 
            data:   data_User_UpdatePassword, 
            source: Source_Name 
         });
        if (res_User_UpdatePassword.data.status) { 
            toast.success(t('Success_Password_Change'));
            setNewPassword("");
            setOldPassword("");
            setError(false);
        } else {
            toast.error(t('Failed_Password_Change'));
            setError(false);
        }
    };

    return (
        <SecurityFormContainer variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
            {/* Current Password*   */} 
            <Row>
                <Col md={6} lg={6} xl={4}>                                  {/* Current Password* */} 
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                        <FormLabelText variants={titleVariants}>{t('current_password_label')}</FormLabelText>
                        <ShowHidePassField variants={titleVariants}>
                            <FormControlField
                                required    placeholder="***"   type={showPassword ? "text" : "password"}       value={oldPassword}
                                aria-describedby="basic-addon2"     onChange={e => setOldPassword(e.target.value)}
                            />
                            <Button variant="outline-secondary" id="button-showPass-current" onClick={() => setShowPassword(!showPassword)}>
                                {" "}   {showPassword ? <SVGImages.EyeOnIcon /> : <SVGImages.EyeOffIcon />}{" "}
                            </Button>
                        </ShowHidePassField>
                    </Form.Group>
                </Col>
            </Row>

            {/* New Password*   &  Update Password  */} 
            <Row>
                <Col md={6} lg={6} xl={4}>                                  
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                        <FormLabelText variants={titleVariants}>{t('new_password_label')}</FormLabelText>
                        <ShowHidePassField variants={titleVariants}>
                            <FormControlField
                                required    placeholder="***"   type={showConfPass ? "text" : "password"}   value={newPassword}
                                aria-describedby="basic-addon2"     onChange={e => setNewPassword(e.target.value)}
                            />
                            <Button variant="outline-secondary" id="button-showPass-new" onClick={() => setShowConfPass(!showConfPass)}>
                                {" "}   {showConfPass ? <SVGImages.EyeOnIcon /> : <SVGImages.EyeOffIcon />}{" "}
                            </Button>
                        </ShowHidePassField>
                    </Form.Group>
                </Col>

                {/* Update Password */} 
                <Col md={6} lg={6} xl={4} className="text-start">           
                    <Form.Group className="mb-3">
                        <FormLabelText variants={titleVariants} className="d-block">
                            {" "}
                            &nbsp;{" "}
                        </FormLabelText>
                        <FormButton onClick={handleChangePassword} variants={titleVariants} variant="primary">
                            {" "}
                            <span>{t('update_password_button')}</span>{" "}
                        </FormButton>
                    </Form.Group>
                </Col>
                {error && (
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <p className="error-message mb-0">
                            {" "}
                            <b>{t('password_requirements_title')}</b>{" "}
                        </p>
                        <ul className="pass-err-list">
                            <li className="pass-error">{t('password_requirements_list.requirement_1')}</li>
                            <li className="pass-error">{t('password_requirements_list.requirement_2')}</li>
                            <li className="pass-error">{t('password_requirements_list.requirement_3')}</li>
                            <li className="pass-error">{t('password_requirements_list.requirement_4')}</li>
                        </ul>
                    </div>

                )}

            </Row>
        </SecurityFormContainer>
    );
};



const Auth2FAForm = ({ user, email, refetch, setRefetch }) => {

    const { t }                                         = useTranslation('settingsSecurity');
    const Source_Name                                   = "Settings-Security-ModifyTwoFaAuth";
    const [code,                setCode]                = useState("");
    const [twoFaEmail,          setTwoFaEmail]          = useState(user.email);
    const [twoFaAuthON,         setTwoFaAuthON]         = useState(user?.twoFaAuthEnabled);
    const [twoFaEmailCodeSent,  setTwoFaEmailCodeSent]  = useState(false);
    const [reverifyEmailCode,   setReverifyEmailCode]   = useState(false);
    const [reverifyPhoneCode,   setReverifyPhoneCode]   = useState(false);
    const [email_Modal,         setEmail_Modal]         = useState(false);
    const [off2Fa_Modal,        setOff2Fa_Modal]        = useState(false);
    const [authQrCode,          setAuthQrCode]          = useState("");
    const [authSecret,          setAuthSecret]          = useState("");
    const [authCode,            setAuthCode]            = useState('');
    const [authError,           setAuthError]           = useState('');
    const [smsModal,            setSmsModal]            = useState(false);
    const [twoFaPhone,          setTwoFaPhone]          = useState(user?.twoFaPhone || "");
    const [smsCodeSent,         setSmsCodeSent]         = useState(false);
    const [smsCode,             setSmsCode]             = useState("");
    const [authConfirm_Modal,   setAuthConfirm_Modal]   = useState(false);
    const [authConfirm,         setAuthConfirm]         = useState(false);
    const [User_sendTwoFaPhoneCode]                     = useSendTwoFaPhoneCodeMutation();
    const [User_sendTwoFactor]                          = useSendTwoFactorMutation();
    const [User_verifyTwoFaCode]                        = useVerifyTwoFaCodeMutation();
    const [User_verifyTwoFaPhoneCode]                   = useVerifyTwoFaPhoneCodeMutation();
    const [User_signIn]                                 = useSigninMutation();
    // const [User_updateUserInfo]                         = useUpdateUserInfoMutation();
    const [User_useTwoFaAuthGenerate]                   = useTwoFaAuthGenerateMutation();
    const [User_useTwoFaAuthVerify]                     = useTwoFaAuthVerifyMutation();
    const [User_deactivateTwoFa]                        = useDeactivateTwoFaMutation();
    


    const handle_TurnOnTwoFaAuth = async () => {
        if (!twoFaEmail) {
            toast.error(t('User_Email_Not_Loaded'));
            return;
        }
 
        if (twoFaAuthON) { 
            setAuthConfirm_Modal(false);
            toast.success("should Turn OFF"); 
            if (authConfirm) { toast.success("continuing"); }
        } else { 
            toast.success("should Turn on"); 
        }

        const data_User_signIn = {  
            email:  user?.email,    
            task:   "getId"  
        };
        const res_User_signIn = await User_signIn({  data: data_User_signIn,  source: Source_Name });
        if (!res_User_signIn?.data?.status) {   
            toast.error(t('Failed_Getting_User_Info') );            
            return; }

        try {
            if (res_User_signIn?.data?.twoFaAuthEnabled) { 
                setTwoFaAuthON(!twoFaAuthON);
                const data_User_deactivateTwoFa = { 
                    ids:            [res_User_signIn.data.id], 
                    authFactor:     STG_KW_TwoFaAuth 
                };
                const res_User_deactivateTwoFa = await User_deactivateTwoFa({ data:   data_User_deactivateTwoFa, source: Source_Name });

                if (res_User_deactivateTwoFa.data.status) { 
                    toast.success(t('User_Success_Deactivate'))
                } else {
                    toast.error(t('Failed_Deactivate_2FA_Auth'));
                }
                return; 
            };
            
            toast.success(t('Activating... 2FA Auth'))
            const data_useTwoFaAuthGenerate = {
                user: twoFaEmail
            };
            const response_useTwoFaAuthGenerate = await User_useTwoFaAuthGenerate({ data: data_useTwoFaAuthGenerate,  source: Source_Name});
            setAuthQrCode(response_useTwoFaAuthGenerate.data.qrCode);
            setAuthSecret(response_useTwoFaAuthGenerate.data.secret); 
          
            
        } catch (error) {
            toast.error(t('User_Failed_Fetch'), error);
        }
    };

    const handle_ValidateTwoFaAuthCode = async () => {
        try {
            const data_useTwoFaAuthVerify = { 
                email: twoFaEmail, 
                token: authCode 
            };
            const response_useTwoFaAuthVerify = await User_useTwoFaAuthVerify({  data: data_useTwoFaAuthVerify, source: Source_Name});
        
            if (response_useTwoFaAuthVerify.data.status) {
                toast.success(t('User_Success_Activate'));
                setAuthError('Good code.');
                setTwoFaAuthON(true);
                setAuthQrCode();
            } else {
                toast.error(t('User_error_wrong_code'));
                setAuthError(t('User_error_wrong_code'));
            }
        } catch (error) {
            setAuthError(t('User_error_general'));
        }
        
    };

    const handle_CancelTwoFaAuth = async () => {
        setTwoFaAuthON(false);
        setAuthQrCode();
    };

    const handle_CopySecret = async () => {
        try {
            await navigator.clipboard.writeText(authSecret);
            toast.success(t('success_copy_secret'));
        } catch (err) {
            toast.error(t('error_copy_secret'));
        }
    };



    const handle_SendTwoFaEmailCode = async () => {
        if (!twoFaEmail) {  
            toast.error(t('Error_Missing_2FA_Email'));    
            return; }
        try {
            const data_sendTwoFactor = { 
                userId:     user?._id, 
                email:      twoFaEmail, 
                userEmail:  user?.email 
            };
            const res_sendTwoFactor = await User_sendTwoFactor({ data:   data_sendTwoFactor,  source: Source_Name});
            if (res_sendTwoFactor?.data?.status) {
                toast.success(t('Success_Sending_2FA_Email_Code'));
                setTwoFaEmailCodeSent(true);
            } else {
                toast.error(t('Failed_Sending_2FA_Email_Code'));
            }
        } catch (error) {
            toast.error(t('Error_Sending_2FA_Email_Code'));
        }
    };

    const handle_User_verifyTwoFaEmailCode = async () => { 
        if (!code) {
            toast.error(t('Failed_Missing_Code'));
            return;
        }
        try {
            const data_verifyTwoFaCode = { 
                email: user?.email,   
                code
            };
            const res_User_verifyTwoFaCode = await User_verifyTwoFaCode({ data: data_verifyTwoFaCode, source: Source_Name} );
            setRefetch(refetch + 1);
            if (res_User_verifyTwoFaCode?.data?.status) {
                toast.success(t('Success_Verif_2FA_Email_Code'));
                setEmail_Modal(false);
                setReverifyEmailCode(false);
                setCode("");
            } else {
                if (res_User_verifyTwoFaCode?.data?.message === "Verification code has Expired!") {
                    toast.error(t('Failed_2FA_Email_Code_Expired'));
                    setCode("");
                    setReverifyEmailCode(true);
                } else {
                    toast.error(t('Failed_Verif_2FA_Email_Code'));
                }
            }
        } catch (error) {
            toast.error(t('Error_Verifying_Code'));
        }
    };

    const handle_TurnOffEmail = async () => {
        const userConfirmed = window.confirm(t('Q_Deactivated_2FA_Email'));
        if (!userConfirmed) {
            return;
        }

        try {
            const data_User_deactivateTwoFa = { 
                ids:            [user?._id], 
                authFactor:     STG_KW_TwoFaEmail 
            };
            const res_delete_Email_signIn = await User_deactivateTwoFa({ data:   data_User_deactivateTwoFa, source: Source_Name });

            if (res_delete_Email_signIn.data.status) {
                toast.success(t('Success_Deactivate_2FA_Email'));
                setRefetch(refetch + 1);
            } else {
                toast.error(t('Failed_Deactivate_2FA_Email'));
            }
        } catch (error) {
            toast.error(t('Error_Update_User_Settings'));
        }
    };



    const handle_SendTwoFaPhoneCode = async () => {
        if (!twoFaPhone) {
            toast.error(t('Error_Missing_Phone_Number'));
            return;
        }

        try {
            const data_User_sendTwoFaPhoneCode = { 
                email:        twoFaEmail, 
                userEmail:    user?.email, 
                userId:       user?._id, 
                twoFaPhone:   twoFaPhone 
            };
            const res_sendTwoFaPhoneCode = await User_sendTwoFaPhoneCode({ data: data_User_sendTwoFaPhoneCode,  source: Source_Name });
            
            if (res_sendTwoFaPhoneCode?.data?.status) {
                // toast.success(t('Success_SMS_Code_Sent'));
                toast.success(res_sendTwoFaPhoneCode?.data?.message);
                setSmsCodeSent(true);
            } else {
                toast.error(t('Failed_SMS_Code_Sent'));
            }
        } catch (error) {
            toast.error(t('Error_Sending_Phone_Code'));
        }
    };

    const handle_User_verifyTwoFaPhoneCode = async () => { 
        if (!smsCode) {
            toast.error(t('Error_Enter_SMS_Code'));
            return;
        }
        
        try {
            const data_User_verifyTwoFaPhoneCode = { 
                email:      user?.email, 
                smsCode, 
                twoFaPhone:    twoFaPhone 
            };
            const res_User_verifyTwoFaPhoneCode = await User_verifyTwoFaPhoneCode({ data: data_User_verifyTwoFaPhoneCode,  source: Source_Name });

            setRefetch(refetch + 1);
            if (res_User_verifyTwoFaPhoneCode?.data?.status) {
                toast.success(t('Success_SMS_Code_Valid'));
                setSmsModal(false);
                setReverifyPhoneCode(false);
                setSmsCode("");
            } else {
                
                if (res_User_verifyTwoFaPhoneCode?.data?.message === "Expired!") {
                    toast.error(t('Failed_SMS_Code_Expired'));
                    setSmsCode("");
                    setReverifyPhoneCode(true);
                } else {
                    toast.error(t('Failed_SMS_Code_Invalid'));
                }
            }
        } catch (error) {
            toast.error(t('Error_Verifying_Code'));
        }
    };

    const handle_TurnOffPhone = async () => {
        const userConfirmed = window.confirm(t('Q_Deactivated_2FA_Phone'));
        if (!userConfirmed) {
            return;
        }
        try {
            const data_User_deactivateTwoFa = { 
                ids:            [user?._id], 
                authFactor:     STG_KW_TwoFaPhone 
            };
            const res_delete_Phone_signIn = await User_deactivateTwoFa({  data: data_User_deactivateTwoFa,  source: Source_Name });

            if (res_delete_Phone_signIn.data.status) {
                toast.success(t('Success_Deactivate_2FA_Phone'));
                setRefetch(refetch + 1);
            } else {
                toast.error(t('Failed_Deactivate_2FA_Phone'));
            }
        } catch (error) {
            toast.error(t('Error_Update_User_Settings'));
        }
    };
    




    return (
        <>
            <SecurityFormContainer
                variants={staggerVariants}      initial="offscreen"     whileInView="onscreen"      viewport={{ once: true, amount: 0.1 }}>


                {/* ==================================================== */}
                {/* Authenticator Configuration */}
                <Row>
                    <Col md={6} lg={6} xl={4}>
                        <FormLabelText variants={titleVariants}>{t('2fa_auth_app_label')} </FormLabelText>
                        {twoFaAuthON ? (
                            <FormControlField   variants={titleVariants} disabled  required   type="email"   
                                value={t('2fa_auth_enabled')}   placeholder={t('2fa_auth_placeHolder_Config')}   />
                        ) : (
                            <FormControlField   variants={titleVariants} disabled  required   type="email"   
                                value={t('2fa_auth_disabled')}   placeholder={t('2fa_auth_placeHolder_Config')}   />
                        )}
                    </Col>

                    <Col md={6} lg={6} xl={4} className="text-start">
                        <Form.Group className="mb-3">
                            <FormLabelText variants={titleVariants} className="d-block">    {" "}   &nbsp;{" "}     </FormLabelText>
                            <FormButton 
                                onClick={() => {
                                    setAuthConfirm_Modal(true); // Call the first function
                                    
                                }} 
                                variants={titleVariants} 
                                variant="primary"
                            >
                                <span>
                                    {twoFaAuthON ? t('2fa_auth_button', { action: 'OFF' }) : t('2fa_auth_button', { action: 'ON' })}
                                </span>
                            </FormButton>

                        </Form.Group>
                    </Col>
                </Row>

                {/* Authenticator QR Code */}
                <Row>
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            {authQrCode && (
                                <>
                                    <FormLabelText variants={titleVariants}>{t('qr_code_label')}</FormLabelText>
                                    <div>
                                        <img src={authQrCode} alt={t('qr_code_alt_text')} />
                                        <p>{t('scan_qr_instruction')}</p>
                                        <p>{t('copy_paste_key_instruction', { authSecret })}</p>
                                        <Form.Control 
                                            type="text" 
                                            className="shadow-none" 
                                            value={authCode} 
                                            placeholder={t('User_code_placeholder')} 
                                            onChange={(e) => setAuthCode(e.target.value)} 
                                        />
                                        {authError && <p style={{ color: 'red' }}>{authError}</p>}
                                        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                            <Button className="btn btn-primary" onClick={handle_ValidateTwoFaAuthCode}>{t('validate_button')}</Button>
                                            <Button className="btn btn-primary" onClick={handle_CopySecret}>{t('copy_secret_button')}</Button>
                                            <Button className="btn btn-primary" onClick={handle_CancelTwoFaAuth}>{t('cancel_button')}</Button>
                                        </div>
                                    </div>

                                </>
                            )}
                        </Form.Group>
                    </Col>

                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            {authQrCode && (
                                <>
                                    <div>
                                        <p>{t('authenticator_help_label')}</p>
                                        <Button 
                                            className="d-block"     onClick={() => {}}  
                                            style={{ display: 'flex', alignItems: 'left', width: '50%', height: '60px', border: '2px solid black', background: 'none', padding: 0, marginBottom: '10px' }}
                                        >
                                            <img src={Images.AuthenticatorAuthy}     alt="Authenticator Authy" style={{ width: '60%', marginRight: '8px' }} />
                                        </Button>
                                        <Button 
                                            className="d-block"     onClick={() => {}} 
                                            style={{ border: '2px solid black', background: 'none', width: '50%', height: '60px', padding: 0, marginBottom: '10px' }}
                                        >
                                            <img src={Images.AuthenticatorGoogle}    alt="Authenticator Google"  style={{ width: '85%' }} />
                                        </Button>
                                        <Button 
                                            className="d-block"     onClick={() => {}} 
                                            style={{ border: '2px solid black', background: 'none', width: '50%', height: '60px',padding: 0 }}
                                        >
                                            <img src={Images.AuthenticatorMicrosoft} alt="Authenticator Microsoft" style={{ width: '85%' }} />
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form.Group>
                    </Col>

                </Row>


                {/* ==================================================== */}
                {/* 2FA Email Configuration */}
                <Row>
                    {user?.twoFaEmailEnabled ? (                    
                        <>
                            <Col md={6} lg={6} xl={4}>                         
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                    <FormLabelText      variants={titleVariants}>{t('label_2FA_Email_Config')}</FormLabelText>
                                    <FormControlField   variants={titleVariants} disabled  required     type="email"   
                                        value={user?.twoFaEmail}   placeholder={t('placeHolder_2FA_Email_Config')}   />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={6} xl={4} className="text-start">
                                <Form.Group className="mb-3">
                                    <FormLabelText variants={titleVariants} className="d-block">  {" "}   &nbsp;{" "} </FormLabelText>
                                    <FormButton    variants={titleVariants} onClick={handle_TurnOffEmail}  variant="primary"> {" "}   
                                        <span> {t('2fa_email_button', { action: 'OFF' } )}</span>  {" "}   </FormButton>
                                </Form.Group>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col md={6} lg={6} xl={4}>                         
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                    <FormLabelText      variants={titleVariants}>{t('2fa_email_label')}</FormLabelText>
                                    <FormControlField   variants={titleVariants} disabled  required     type="email"   
                                        value={t('default_2FA_Email_Config')}   placeholder={t('placeHolder_2FA_Email_Config')}   />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={6} xl={4} className="text-start">
                                <Form.Group className="mb-3">
                                    <FormLabelText variants={titleVariants} className="d-block"> {" "}   &nbsp;{" "}  </FormLabelText>
                                    <FormButton    variants={titleVariants} onClick={() => setEmail_Modal(true)}  variant="primary">   {" "}   
                                        <span> {t('2fa_email_button', { action: 'ON' } )}</span>{" "}    </FormButton>
                                </Form.Group>
                            </Col>
                        </>
                    )}
                </Row>


                {/* ==================================================== */}
                {/* 2FA Phone SMS Configuration */}
                <Row>
                    {user?.twoFaPhoneEnabled ? (
                        <>
                            <Col md={6} lg={6} xl={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                    <FormLabelText variants={titleVariants}>{t('label_2FA_Phone_Config')}</FormLabelText>
                                    <FormControlField variants={titleVariants} disabled required type="text" 
                                        value={user?.twoFaPhone} placeholder={t('placeHolder_2FA_Phone_Config')} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={6} xl={4} className="text-start">
                                <Form.Group className="mb-3">
                                    <FormLabelText variants={titleVariants} className="d-block">{" "}&nbsp;{" "}</FormLabelText>
                                    <FormButton variants={titleVariants} onClick={handle_TurnOffPhone} variant="primary">{" "}
                                        <span>{t('2fa_phone_button', { action: 'OFF' } )}</span>{" "}</FormButton>
                                </Form.Group>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col md={6} lg={6} xl={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                    <FormLabelText variants={titleVariants}>{t('2fa_phone_label')}</FormLabelText>
                                    <FormControlField variants={titleVariants} disabled required type="text" 
                                        value={t('default_2FA_Number_Config')} placeholder={t('placeHolder_2FA_Phone_Config')} />
                                </Form.Group>
                            </Col>

                            <Col md={6} lg={6} xl={4} className="text-start">
                                <Form.Group className="mb-3">
                                    <FormLabelText variants={titleVariants} className="d-block">{" "}&nbsp;{" "}</FormLabelText>
                                    <FormButton variants={titleVariants} onClick={() => setSmsModal(true)} variant="primary">{" "}
                                        <span>{t('2fa_phone_button', { action: 'ON' } )}</span>{" "}</FormButton>
                                </Form.Group>
                            </Col>
                        </>
                    )}
                </Row>


            </SecurityFormContainer>


            {/* authConfirm_Modal *******************************************/}
            <Modal show={authConfirm_Modal} onHide={() => setAuthConfirm_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>{t('Auth_Q_Deactivate')}</Modal.Header>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setAuthConfirm_Modal(false)}>
                        {t('modal_cancel_button')}
                    </Button>
                    <Button className="success-button" onClick={() => {setAuthConfirm(true);  handle_TurnOnTwoFaAuth();  }}>
                        {t('modal_disable_2fa_auth_button')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* sms_Modal *******************************************/}
            <Modal show={smsModal} onHide={() => setSmsModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>{t('header_Turn_On_SMS_2FA')}</Modal.Header>
                <Modal.Body>
                    <div className="input-wrapper-modal">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t('label_Turn_On_SMS_2FA')}</Form.Label>
                            <Form.Control
                                type="tel"  // Set type to "tel"
                                pattern="[0-9]*"  // Restrict input to numbers only
                                minLength="10" 
                                className="shadow-none"
                                value={twoFaPhone}
                                placeholder={t('placeHolder_SMS_phone')}
                                onChange={e => {
                                    // Allow only numeric values
                                    const numericValue = e.target.value.replace(/\D/g, '');
                        
                                    // Set the phone number only if it has at least 10 digits
                                    if (numericValue.length <= 10) {
                                        setTwoFaPhone(numericValue);
                                    }
                                }}
                            />
                        </Form.Group>

                        {smsCodeSent && !reverifyPhoneCode && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>{t('label_SMS_code')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="shadow-none"
                                    value={smsCode}
                                    placeholder={t('placeHolder_SMS_code')}
                                    onChange={e => setSmsCode(e.target.value)}
                                />
                            </Form.Group>
                        )}
                    </div>


                    <div className="d-flex justify-content-center align-items-center">
                        <Button 
                            variant="secondary" 
                            className="d-block me-3 w-50" 
                            onClick={() => setSmsModal(false)}>
                            {t('close_button')}
                        </Button>

                        {!smsCodeSent && !reverifyPhoneCode ? (
                            <Button 
                                onClick={handle_SendTwoFaPhoneCode} 
                                variant="primary" 
                                className="d-block ms-3 w-50" 
                                disabled={twoFaPhone.length < 10}  // Disable if length is less than 10
                            >
                                {t('send_code_button')}
                            </Button>
                        ) : !reverifyPhoneCode ? (
                            <Button 
                                onClick={handle_User_verifyTwoFaPhoneCode} 
                                className="d-block btn btn-success ms-3 w-50" 
                                disabled={twoFaPhone.length < 10}  // Disable if length is less than 10
                            >
                                {t('verify_code_button')}
                            </Button>
                        ) : null}

                        {reverifyPhoneCode && (
                            <Button 
                                onClick={() => {
                                    handle_SendTwoFaPhoneCode();  // Call the function to send the code
                                    setReverifyPhoneCode(false);  // Reset the reverifyPhoneCode state
                                }} 
                                className="d-block btn btn-success ms-3 w-50">
                                {t('resend_code_button')}
                            </Button>
                        )}
                    </div>






                </Modal.Body>
            </Modal>

            {/* email_Modal *******************************************/}
            <Modal show={email_Modal} onHide={() => setEmail_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>{t('header_Turn_On_EMAIL_2FA')}</Modal.Header>
                <Modal.Body>
                    <div className="input-wrapper-modal">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t('label_Turn_On_EMAIL_2FA')}</Form.Label>
                            <Form.Control
                                type="email"        className="shadow-none"     value={twoFaEmail}   
                                placeholder={t('placeHolder_Email')}  onChange={e => setTwoFaEmail(e.target.value)}
                            />
                        </Form.Group>

                        {twoFaEmailCodeSent && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('label_Verification_code')}</Form.Label>
                                <Form.Control
                                    type="text"     className="shadow-none"     value={code}         
                                    placeholder={t('placeHolder_Verification_code')}  onChange={e => setCode(e.target.value)}
                                />
                            </Form.Group>
                        )}
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <Button variant="secondary" className="d-block me-3 w-50" onClick={() => setEmail_Modal(false)}>
                            {t('close_button')}
                        </Button>
                        {!twoFaEmailCodeSent && !reverifyEmailCode ? (
                            <Button onClick={handle_SendTwoFaEmailCode} variant="primary" className="d-block ms-3 w-50">
                                {t('send_code_button')}
                            </Button>
                        ) : (
                            <Button onClick={handle_User_verifyTwoFaEmailCode} className="d-block btn btn-success ms-3 w-50">
                                {t('verify_code_button')}
                            </Button>
                        )}

                        {reverifyEmailCode && (
                            <Button onClick={handle_SendTwoFaEmailCode} className="d-block btn btn-success ms-3 w-50">
                                {t('resend_code_button')}
                            </Button>
                        )}
                    </div>

                </Modal.Body>
            </Modal>

            {/* Off2FaAuth modal *******************************************/}
            <Modal show={off2Fa_Modal} onHide={() => setOff2Fa_Modal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('turn_off_2fa_Auth_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <div className="modal-form">
                        <h5>{t('confirm_disable_2fa_auth')}</h5>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-btn" variant="danger" onClick={() => setOff2Fa_Modal(false)}>
                        {t('modal_cancel_button')}
                    </Button>
                    <Button className="success-button" onClick={() => {}}>
                        {t('modal_disable_2fa_auth_button')}
                    </Button>
                </Modal.Footer>
            </Modal> {/* Off2FaAuth modal */}


        </>
    );
};

export default SetSecurity;
