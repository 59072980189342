import                            "./styles.css";
import React, 
      { useState }              from  "react";
import SubscriptionsTab         from  "./SubscriptionsTab";
import RemittancePageTab        from  "./RemittancePageTab/RemittancePageTab";
import InternetPageTab          from  "./InternetPageTab/InternetPageTab";
import PartsOrdersPageTab       from  "./PartsOrdersPageTab/PartsOrdersPageTab";
import PartsQuotesPageTab       from  "./PartsQuotesPageTab/PartsQuotesPageTab";
import TranscriptPageTab        from  "./TranscriptPageTab/TranscriptPageTab";
import LearningPageTab          from  "./LearningPageTab/LearningPageTab";
import InterestsPageTab         from  "./InterestsPageTab/InterestsPageTab";
import PromoPageTab             from  "./PromoPageTab/PromoPageTab";




const SubscriptionPage = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Remittances"); // New state for active tab

  const toggleSidebar = () => { setIsOpen(!isOpen); };

  const renderContent = () => {
    switch (activeTab) {
      case "Remittances":     return <RemittancePageTab   user={user}/>;
      case "Internet":        return <InternetPageTab     user={user}/>;
      case "PartsOrder":      return <PartsOrdersPageTab  user={user}/>;
      case "PartsQuotes":     return <PartsQuotesPageTab  user={user}/>;
      case "Transcript":      return <TranscriptPageTab   user={user}/>;
      case "Learning":        return <LearningPageTab     user={user}/>;
      case "MyInterests":     return <InterestsPageTab    user={user}/>;
      case "PromoList":       return <PromoPageTab        user={user}/>;
      default:                return <RemittancePageTab   user={user}/>;
    }
  };

  return (
    <div className="main">
      <SubscriptionsTab isOpen={isOpen} toggleSidebar={toggleSidebar} setActiveTab={setActiveTab} />

      <div className={`content ${isOpen ? "sidebar-open" : ""}`}>
        <h1 className={`subscriptions-title-main ${isOpen ? "expanded" : "collapsed"}`}>Subscriptions</h1>

        {/* Render the content based on activeTab */}
        {renderContent()}
      </div>
    </div>
  );
};

export default SubscriptionPage;
