import React, { useState }          from "react";
import { Modal, Button, Form }      from "react-bootstrap";
import { toast }                    from "react-toastify";
import { Images }                   from "../../config/images";
import { useSendSupportMailMutation }   from "../../services/userUserApi";

const SvcInternetModal = ({ showModal, handleCloseModal, user, data }) => {
    const [contactMethod, setContactMethod] = useState("email");
    // const [selectedPackage, setSelectedPackage] = useState("");

    const [SendInternetRequest] = useSendSupportMailMutation();

    const handleContactMethodChange = (e) => setContactMethod(e.target.value);

    const handleSendMessage = async (e) => {
        e.preventDefault();

        // const selectedPackageDetails = data?.data?.find(pkg => pkg._id === selectedPackage);
        // console.log("selectedPackageDetails", selectedPackageDetails);

        const emailData = {
            name: `${user?.lastName}, ${user?.firstName}`,
            email: user?.email,
            subject: 'Vehicle Service Subscription',
            text: `
            Request Auto Parts Service
            <br/><br/>

            My Postal Code is: ${user?.postCode || "Not Provided"}

            <br/><br/>Member phone number:
            <br/> ${user?.phoneNo || "Not Provided"}`,
        };

        handleCloseModal();
        try {
            console.log("emailData", emailData);
            SendInternetRequest(emailData).then((res) => {
                console.log("res", res);
                if (res?.data?.status) {
                    toast.success(res?.data?.message);
                    setContactMethod("email");
                } else {
                    toast.warning("SEND FAILED: Please send an email to support@ata2go.com");
                }
            });
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <img src={Images.vehicleParts} alt="Internet Logo" className="header-image" />
                <Modal.Title>Member Vehicle Parts Service</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{user?.firstName}, thank you for your Interests.</p>
                <p>
                    To better serve you, a representative will contact you to discuss your needs for Vehicle parts.
                </p>
                <Form>
                    <Form.Group controlId="postalCode">
                        <Form.Label>Please confirm your postal code to verify the service availability.</Form.Label>
                        <Form.Control 
                            type="text" 
                            defaultValue={user?.postCode || ""} 
                            placeholder={!user?.postCode ? "Enter your postal code" : ""}
                        />
                    </Form.Group>


                    <Form.Group controlId="contactMethod">
                        <br />
                        <Form.Label>For this request, contact me by:</Form.Label>
                        <Form.Check
                            type="radio"
                            name="contactMethod"
                            checked={contactMethod === "email"}
                            value="email"
                            onChange={handleContactMethodChange}
                            disabled={!user?.email}
                            label={`Email: ${user?.email || "Not Provided"}`}
                        />
                        <Form.Check
                            type="radio"
                            name="contactMethod"
                            checked={contactMethod === "phone"}
                            value="phone"
                            onChange={handleContactMethodChange}
                            disabled={!user?.phoneNo}
                            label={`Phone: ${user?.phoneNo || "Not Provided"}`}
                        />
                        <Form.Label>Adjust your Contact Details in Settings if the above is incorrect.</Form.Label>
                    </Form.Group>
                </Form>
                <br />
                <p>We will follow up with you within 24 hours.</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                <Button variant="primary" onClick={handleSendMessage}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SvcInternetModal;
