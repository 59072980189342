/* REAL - DASH - SERVICES - LEARNING */
/* File: /src/pages/Dashboard/DashboardPages/Services-Learning/Learning.js */ 
import                                      "../ManageStyle.css";
import { UserContext }                      from "../../../../App";

import { useContext }                       from "react";
import React, 
        { 
          useEffect, 
          useState 
        }                                   from "react";
import { 
        Button, 
        Form, 
        Modal, 
        Table 
        }                                   from "react-bootstrap";
import { toast }                            from "react-toastify";
import { BiSolidPencil }                    from "react-icons/bi";
import { 
        MdAddChart,
        MdDelete 
        }                                   from "react-icons/md";
import {
        useDeleteCourseMutation,
        useGetAllCoursesQuery,
        useGetCourseByIdQuery,
        useUpdateCourseMutation,
        useUploadCourseMutation
        }                                   from "../../../../services/svcLearningApi";
import { 
        useGetSubscriptionsByTypeMutation,
        }                                   from "../../../../services/userUserStoreApi";
import {
        useListServicesQuery,
        useUpdateServiceMutation,
        }                                   from "../../../../services/svcServiceStoreApi";
import { 
        useGetUserInfoMutation
        }                                   from "../../../../services/userUserApi";

import DeleteModal                          from "../../../../components/Modal/DeleteModal";

const ManageLearning = () => {
    const Source_Name           = "MANAGE - SERVICES - COURSES";
    const [formData, setFormData] = useState({
        serviceCode:    "",
        courseName:     "",
        courseLevel:    "",
        price:          "",
        project:        "",
        session:        "",
        description:    ""
    });
    
   // const [serviceCode,           setServiceCode]           = useState("");
    const [serviceStatus,         setServiceStatus]         = useState(null);
    const [showModal,               setShowModal]           = useState(false);
    const [isNewPromotion,          setIsNewPromotion]      = useState(false);
    const [selectedId,              setSelectedId]          = useState("");
    const [deleteModal,             setDeleteModal]         = useState(false);
    const [selectedItem,            setSelectedItem]        = useState("");
    const [subscriptionCounts,      setSubscriptionCounts]  = useState({});
    const [showReqModal,          setShowReqModal]          = useState(false);
    const [selectedServiceCode,   setSelectedServiceCode]   = useState("");
    const [selectedUserInfos,     setSelectedUserInfos]     = useState([]);
    const [userData,              setUserData]              = useState([]);


    const [uploadCourse]                            = useUploadCourseMutation();
    const { data }                                  = useGetAllCoursesQuery({ source: Source_Name });
    const { user }                                  = useContext(UserContext);
    const { data: courseData }                      = useGetCourseByIdQuery(selectedId);
    const { data: servicesList}                     = useListServicesQuery({ source: Source_Name });
    const [updateService]                           = useUpdateServiceMutation();
    const [deleteCourse]                            = useDeleteCourseMutation();
    const [updateCourses]                           = useUpdateCourseMutation();
    const [getSubscriptionsByType]                  = useGetSubscriptionsByTypeMutation();
    const [getUserInfo]                                     = useGetUserInfoMutation();

    
    const canEdit                   = ['power', 'admin', 'support'].includes(user?.role);
    const actionStyle               = canEdit ? {} : { background: "grey" };
    const STG_Course_TypeLabel      = "courses"



    /* Section 01.00 - initializeValues  */
    const initializeValues = () => {
        setFormData({
            serviceCode: "",
            courseName: "",
            courseLevel: "",
            price: "",
            project: "",
            session: "",
            description: ""
        });
    }

    /* Section 02.00 - handleInputChange  */
    const handleInputChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    /* Section 03.00 - handle_CourseData  */
    const handle_CourseData = () => {
        setShowModal(false);
        if (formData && (formData?.session || formData?.project)) {
            uploadCourse(formData).then(res => {
                if (res?.data?.status) {
                    toast.success(res?.data?.message);
                    setFormData({
                        serviceCode: "",
                        courseName: "",
                        courseLevel: "",
                        price: "",
                        project: "",
                        session: "",
                        description: ""
                    });
                } else {
                    toast.error(res?.data?.message);
                }
            });
        } else {
            toast.error("Please fill all the info!");
        }
    };

    /* Section 04.00 - useEffect - courseData  */
    useEffect(() => {
        setFormData({
            serviceCode:    courseData?.result?.serviceCode,
            courseName:     courseData?.result?.courseName,
            courseLevel:    courseData?.result?.courseLevel,
            price:          courseData?.result?.price,
            project:        courseData?.result?.project,
            session:        courseData?.result?.session,
            description:    courseData?.result?.description
        });
    }, [courseData]);

    /* Section 05.00 - handle_NewPackage  - Function to handle opening the modal */
    const handle_NewPackage = () => {
        initializeValues();
        setIsNewPromotion(true);
        setShowModal(true);
        
    };

    /* Section 06.00 - handleDeleteCourse  */
    const handleDeleteCourse = id => {
        deleteCourse(id).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setDeleteModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 07.00 - handle_Update  */
    const handle_Update = () => {
        updateCourses({
            id: selectedId,
            data: formData
        }).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setShowModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    /* Section 08.00 - handle_DeleteClick  */
    const handle_DeleteClick = (item) => {
        setSelectedItem(item); // set the entire item, not just id
        setDeleteModal(true);
    };

    /* Section 09.00 - handle_EditClick  */
    const handle_EditClick = (item_id) => {
        setSelectedId(item_id);
        setShowModal(true);
    };

    /* Section 10.00 - handle_CloseModal  */
    const handle_CloseModal = () => {
        setShowModal(false);
        setIsNewPromotion(false);
    };

    /* Section 11.00 - handle_Publish */
    const handle_Publish = async () => {
        try {
            // Log the services list data
            console.log("SERVICES LIST RAW DATA:", servicesList);
    
            if (serviceStatus) {  // Use serviceStatus instead of finding it again
                const updatedService = {
                    id: serviceStatus._id,
                    data: { 
                        published: !serviceStatus.published  // Toggle the published status
                    }
                };
                console.log("updatedService:", updatedService);
    
                const result = await updateService({ payload: updatedService, source: Source_Name }).unwrap();
                if (result?.status) {
                    toast.success(`Service "couses" has been ${!serviceStatus.published ? 'published' : 'unpublished'} successfully.`);
                    setServiceStatus(prevState => ({ ...prevState, published: !prevState.published }));  // Update the state after success
                } else {
                    toast.error(result?.message || 'Failed to update the service.');
                }
            } else {
                toast.error('Service "courses" not found.');
            }
        } catch (error) {
            console.error('Error in handle_Publish:', error);
            toast.error('An error occurred while updating the service.');
        }
    };

    /* Section 10.00 - Function to handle clicking on the "Req" column */
    const handleReqClick = async (serviceCode, usersData) => {
        const userIds = usersData
            .filter(user => user.services.some(service => service.serviceCode === serviceCode && service.status === "requested"))
            .map(user => user.userId);

        setSelectedServiceCode(serviceCode);

        const userInfos = await Promise.all(
            userIds.map(async (userId) => {
                try {
                    const res = await getUserInfo(userId).unwrap(); // Call the mutation and unwrap the result
                    return {
                        userId: userId.slice(-7),
                        email: res?.result?.email || "N/A", // Email from the API response
                        firstName: res?.result?.firstName || "N/A", // First name from the API response
                        lastName: res?.result?.lastName || "N/A", // Last name from the API response
                    };
                } catch (error) {
                    console.error("Error fetching user info:", error);
                    return {
                        userId: userId.slice(-7),
                        email: "N/A",
                        firstName: "N/A",
                        lastName: "N/A",
                    };
                }
            })
        );
        

        setSelectedUserInfos(userInfos); // Store the user info data
        setShowReqModal(true);
    };

    /* Section 12.00 - useEffect - getSubscriptionsByType  */
    useEffect(() => {
        const fetchSubscriptionCounts = async () => {
            try {
                const response = await getSubscriptionsByType({ type: STG_Course_TypeLabel }).unwrap();
                const counts = response.data.reduce((acc, user) => {
                    user.services.forEach(service => {
                        // Log the service details for debugging
                        console.log("====service.status", service.status);
                        
                        // Count non-requested services
                        if (service.status !== "requested") {
                            acc.subscriptions[service.serviceCode] = (acc.subscriptions[service.serviceCode] || 0) + 1;
                        }

                        // Count requested services
                        if (service.status === "requested") {
                            acc.requests[service.serviceCode] = (acc.requests[service.serviceCode] || 0) + 1;
                        }
                    });
                    return acc;
                }, { subscriptions: {}, requests: {} });

                // Log the counts to verify computation
                console.log('Computed counts:', counts);
                setSubscriptionCounts(counts);
                setUserData(response.data); 
            } catch (error) {
                console.error('Error fetching subscription counts:', error);
            }
        };

        fetchSubscriptionCounts();
    }, [getSubscriptionsByType]);

    /* Section 13.00 - useEffect - servicesList  */
    useEffect(() => {
        if (servicesList && Array.isArray(servicesList.data)) {
            const service = servicesList.data.find(service => service.serviceName === "learning");
            setServiceStatus(service);  // Store the service in the state variable
            console.log("service FIND:", service);
        }
    }, [servicesList]);
    
    return (
        <div className="manage-video-page">
            <div className="video-table-container">
                <h2 className="video-page-title">LEARNING PACKAGES</h2>
                <Button variant="primary"   style={{ marginRight: '10px' }}         disabled={!(user?.role === "power" || user?.role === "admin")} 
                    onClick={() => handle_NewPackage()}>      <MdAddChart />      </Button>
                
                <Button variant="success"   style={{ marginRight: '10px' }}         disabled={!(user?.role === "power" || user?.role === "admin")} 
                        onClick={       handle_Publish}     >       {serviceStatus?.published ? 'UnPublish' : 'Make Available'}
                </Button>

                {/* course table box */}
                <div className="table-data">
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Code</th>
                                <th>Course</th>
                                <th>Expertise</th>
                                <th>Price</th>
                                <th>Description</th>
                                <th>#Prj.</th>
                                <th>#Sess.</th>
                                <th>Actions</th>
                                <th>Subs</th>
                                <th>Req</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.map((item, i) => (
                                <tr key={i++}>
                                    <td>{i + 1}</td>
                                    <td>{item.serviceCode}</td>
                                    <td>{item.courseName}</td>
                                    <td>{item.courseLevel}</td>
                                    <td>${item.price}</td>
                                    <td>{item.description}</td>
                                    <td>{item?.project ? item?.project : "0"}</td>
                                    <td>{item?.session ? item?.session : "0"}</td>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-center gx-4">
                                            {user?.role === "power" || user?.role === "admin" || user?.role === "support" ? (
                                                <span   className="action-icon update"      style={actionStyle} onClick={ canEdit? () => { handle_EditClick(item?._id)} : null} > <BiSolidPencil /> </span>
                                            ) : (
                                                <span   className="action-icon update"      style={{ background: "grey" }}              > <BiSolidPencil /> </span>
                                            )}
                                            {user?.role === "admin" ? (
                                                <span   className="action-icon delete"      style={actionStyle} onClick={() => handle_DeleteClick(item)}    > <MdDelete />      </span>
                                            ) : (
                                                <span   className="action-icon delete"      style={{ background: "grey" }}              > <MdDelete />      </span>
                                            )}
                                        </div>
                                    </td>
                                    <td>{subscriptionCounts.subscriptions?.[item.serviceCode] || 0}</td> {/* Display subscription count */}
                                    <td 
                                        style={{ cursor: (subscriptionCounts.requests?.[item.serviceCode] || 0) > 0 ? 'pointer' : 'default' }} 
                                        onClick={() => {
                                            if ((subscriptionCounts.requests?.[item.serviceCode] || 0) > 0) {
                                                handleReqClick(item.serviceCode, userData);  // Pass the response data here
                                            }
                                        }}
                                    >
                                        {subscriptionCounts.requests?.[item.serviceCode] || 0}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>


                <Modal show={showReqModal} onHide={() => setShowReqModal(false)} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Requests for Service: {selectedServiceCode}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                            {selectedUserInfos.map((userInfo, index) => (
                                <li key={index}>
                                    {userInfo.userId}, {userInfo.firstName} {userInfo.lastName}, {userInfo.email}
                                </li>
                            ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowReqModal(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isNewPromotion ? "Register New Transcript Package" : "Update Course Package"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-form">
                            <div className="video-input-box-modal">

                                <div className="input-wrapper">
                                    <label  htmlFor="serviceCode">Service Code</label>
                                    <input  type="text"     id="serviceCode"    placeholder="Enter Course Code"   value={formData.serviceCode}
                                            name="serviceCode"   onChange={handleInputChange}
                                    />
                                </div>

                                <div className="input-wrapper">
                                    <label  htmlFor="course-name">Course Name</label>
                                    <input  type="text"     id="course-name"    placeholder="Enter All Course Name"   value={formData.courseName}
                                            name="courseName"   onChange={handleInputChange}
                                    />
                                </div>

                                <div className="input-wrapper mb-3">
                                    <label htmlFor="text">Course Level</label>
                                    <Form.Select
                                        onChange={handleInputChange}
                                        value={formData.courseLevel}
                                        name="courseLevel"
                                        aria-label="Default select example"
                                        className="shadow-none"
                                    >
                                        <option>Select Expertises</option>
                                        <option value="Beginner">Beginner</option>
                                        <option value="Advanced">Advanced</option>
                                        <option value="Professional">Professional</option>
                                    </Form.Select>
                                </div>

                                {['Beginner', 'Advanced'].includes(formData?.courseLevel) ? (
                                    <div className="input-wrapper">
                                        <label  htmlFor="session">Nb. of Sessions</label>
                                        <input  type="text"     id="session"    placeholder="Number of Sessions"   value={formData.session}
                                                name="session"   onChange={handleInputChange}
                                        />
                                    </div>
                                
                                ) : formData?.courseLevel === "Professional" ? (
                                    <div className="input-wrapper">
                                        <label  htmlFor="project">Nb of Projects</label>
                                        <input  type="text"     id="project"    placeholder="Contact for details"   value={formData.project}
                                                name="project"   onChange={handleInputChange}
                                        />
                                    </div>

                                ) : null}

                                <div className="input-wrapper">
                                    <label  htmlFor="project">Price($)</label>
                                    <input  type="text"     id="price"    placeholder="Price"   value={formData.price}
                                            name="price"   onChange={handleInputChange}
                                    />
                                </div>

                                <div className="input-wrapper">
                                    <label  htmlFor="text">Description</label>
                                    <input  type="text"     id="text"    placeholder="Description"   value={formData.description}
                                            name="description"   onChange={handleInputChange}
                                    />
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {isNewPromotion ? ( <Button onClick={handle_CourseData}    variant="primary">   Add Package </Button>
                        ) : (               <Button onClick={handle_Update}      variant="primary">   Update      </Button>
                        )}

                        <Button variant="secondary" onClick={() => handle_CloseModal()}>     Cancel  </Button>
                    </Modal.Footer>
                </Modal>
                <DeleteModal
                    question={"Do you really delete the package?"}
                    heading={"Delete Package"}
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    handleDeleteItem={handleDeleteCourse}
                    id={selectedItem}
                ></DeleteModal>

            </div>
        </div>
    );
};

export default ManageLearning;
