/* REAL - SETTINGS - Contact Info */
/* File: /src/pages/Settings/ContactInfo/index.jsx  */
import { useTranslation 
}                                               from 'react-i18next';
import { Button, Col, Form, Row }   from "react-bootstrap";
import { useContext, useEffect, 
         useState }                 from "react";
import { toast }                    from "react-toastify";
import { FormControlField, 
         FormLabelText, 
         LoginContactForm }         from "./ContactInfoStyle";
import { TabContentContainer }      from "../../Tabs/tabContentStyle";
import { useGetUserByIdQuery, 
         useUpdateUsersMutation,
   //      useUpdateMemberIDMutation
        }                           from "../../../services/userUserApi";
import { UserContext }              from "../../../App";
import { staggerVariants, 
         titleVariants }            from "../../../utils/FmVariants";

const SetContactInfo = ({ user }) => {
    const { t } = useTranslation('settingsContactInfo');
    const { refetch, setRefetch }                   = useContext(UserContext);
    console.log("[SetContactInfo] - Here", user);
    const { data }                                  = useGetUserByIdQuery(user?._id);
    const [memberId,        setMemberId]            = useState("");
    const [firstName,       setFirstName]           = useState("");
    const [lastName,        setLastName]            = useState("");
    const [phoneNo,         setPhoneNo]             = useState("");
    const [address,         setAddress]             = useState("");
    const [email,           setEmail]               = useState("");
    const [companyName,     setCompanyName]         = useState("");
    const [streetAddressOne, setStreetAddressOne]   = useState("");
    const [city,            setCity]                = useState("");
    const [state,           setState]               = useState("");
    const [postCode,        setPostCode]            = useState("");
    const [country,         setCountry]             = useState("");
    const [onUpdate,        setOnUpdate]            = useState(false);
    const [streetAddress,   setStreetAddress]       = useState("");

    const [Auth_updateUsers]                              = useUpdateUsersMutation();
   // const [UpdateMemberID]                                = useUpdateMemberIDMutation();

    useEffect(() => {
        setMemberId(        data?.result?.memberId);
        setFirstName(       data?.result?.firstName);
        setLastName(        data?.result?.lastName);
        setPhoneNo(         data?.result?.phoneNo);
        setAddress(         data?.result?.address);
        setEmail(           data?.result?.email);
        setCompanyName(     data?.result?.companyName);
        setStreetAddress(   data?.result?.streetAddress);
        setStreetAddressOne(data?.result?.streetAddressOne);
        setCity(            data?.result?.city);
        setState(           data?.result?.state);
        setPostCode(        data?.result?.postCode);
        setCountry(         data?.result?.country);
    }, [data]);

    const handleUpdate = () => {
        const data = {
            firstName,
            lastName,
            phoneNo,
            email,
            companyName,
            address,
            streetAddress,
            streetAddressOne,
            postCode,
            city,
            country,
            state,
        };
        if (data) {
            console.log("[handleUpdate] - data update:", data, "UserId",user._id );
            Auth_updateUsers({ ids: [user?._id], data: data, task: "updateContactInfo"}).then(res => {
                console.log("...Back from update...");
                if (res?.data?.status) {
                    toast.success(res?.data?.message);
                    setOnUpdate(false);
                    setRefetch(refetch + 1);
                } else {
                    toast.error(res?.data?.message);
                }
            });
        } else { console.log("[handleUpdate] - NOT Updating:", data);} 
    };

    /*
    const handle_UpdateMemberID = async () => {
        try {
            const res = await UpdateMemberID({ source: "SETTINGS - Contact Info" }).unwrap();
            if (res?.data?.status) {
                toast.success(res?.data?.message);
            } else {
                toast.error(res?.data?.message);
            }
        } catch (error) {
            console.error("Error UPDATING member ID", error);
        }
    };
    */

    return (
        <TabContentContainer>
            <LoginContactForm variants={staggerVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>

                {/* User ID    +  First Name      +   Last Name  */}
                <Row>
                    {/* Member ID */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <FormLabelText variants={titleVariants}>{t('member_id_label')}</FormLabelText>
                            <FormControlField
                                required disabled={true} type="text" variants={titleVariants}
                                value={memberId ? memberId.slice(0, 2).toUpperCase() + memberId.slice(2).replace(/^0+/, '') : ''} 
                                placeholder={t('member_id_placeholder')} 
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* First Name */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput2">
                            <FormLabelText variants={titleVariants}>{t('first_name_label')}</FormLabelText> 
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={firstName} placeholder={t('first_name_placeholder')} 
                                onChange={e => setFirstName(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* Last Name */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput3">
                            <FormLabelText variants={titleVariants}>{t('last_name_label')}</FormLabelText> 
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={lastName} placeholder={t('last_name_placeholder')} 
                                onChange={e => setLastName(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>
                </Row>


                {/* Phone No    +  Email      +   Company Name  */}
                <Row className="mb-4">
                    {/* Phone No */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput4">
                            <FormLabelText variants={titleVariants}>{t('phone_no_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="tel" variants={titleVariants}
                                value={phoneNo} placeholder={t('phone_no_placeholder')}
                                onChange={e => { setPhoneNo(e.target.value); console.log(e); }} 
                                pattern="/^-?\d+\.?\d[0-9]$/"
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* Email */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput5">
                            <FormLabelText variants={titleVariants}>{t('email_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="email" variants={titleVariants}
                                value={email} placeholder={t('email_placeholder')}
                                onChange={e => setEmail(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* Company Name */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput6">
                            <FormLabelText variants={titleVariants}>{t('company_name_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={companyName} placeholder={t('company_name_placeholder')}
                                onChange={e => setCompanyName(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>
                </Row>


                {/* Address    +  Street Address      +   Address Info  */}
                <Row>
                    {/* Address */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput7">
                            <FormLabelText variants={titleVariants}>{t('address_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={address} placeholder={t('address_placeholder')}
                                onChange={e => setAddress(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* Street Address */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput8">
                            <FormLabelText variants={titleVariants}>{t('street_address_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={streetAddress} placeholder={t('street_address_placeholder')}
                                onChange={e => setStreetAddress(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* Address Info */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput9">
                            <FormLabelText variants={titleVariants}>{t('address_info_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={streetAddressOne} placeholder={t('address_info_placeholder')}
                                onChange={e => setStreetAddressOne(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>
                </Row>

                
                {/* City    +  Province/State      +   Postal/Zip Code    +    Country  */}
                <Row>
                    {/* City */}
                    <Col md={6} lg={6} xl={4}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput10">
                            <FormLabelText variants={titleVariants}>{t('city_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={city} placeholder={t('city_placeholder')}
                                onChange={e => setCity(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* Province/State */}
                    <Col md={6} lg={6} xl={3}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput11">
                            <FormLabelText variants={titleVariants}>{t('province_state_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={state} placeholder={t('province_state_placeholder')}
                                onChange={e => setState(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* Postal/Zip Code */}
                    <Col md={6} lg={6} xl={2}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput12">
                            <FormLabelText variants={titleVariants}>{t('postal_zip_code_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={postCode} placeholder={t('postal_zip_code_placeholder')}
                                onChange={e => setPostCode(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>

                    {/* Country */}
                    <Col md={6} lg={6} xl={3}>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput13">
                            <FormLabelText variants={titleVariants}>{t('country_label')}</FormLabelText>
                            <FormControlField
                                required disabled={!onUpdate} type="text" variants={titleVariants}
                                value={country} placeholder={t('country_placeholder')}
                                onChange={e => setCountry(e.target.value)}
                            />{" "}
                        </Form.Group>{" "}
                    </Col>
                </Row>


                {/* BUTTONS */}
                <Row>
                    <Col md={6} lg={6} xl={4} className="text-end">
                        <FormLabelText variants={titleVariants}>&nbsp;</FormLabelText>
                        <div className="d-flex justify-content-center align-items-center">
                            {onUpdate ? (
                                <>
                                    <Button onClick={() => setOnUpdate(false)} className="btn btn-danger btn-md py-2 w-50 me-2">
                                        {" "}
                                        <span>{t('cancel_button')}</span>{" "}
                                    </Button>
                                    <Button onClick={handleUpdate} className="btn btn-info ms-2 w-50 py-2">
                                        {" "}
                                        <span>{t('save_button')}</span>{" "}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => setOnUpdate(true)} className="btn btn-info w-100 py-2">
                                        {" "}
                                        <span>{t('update_contact_button')}</span>{" "}
                                    </Button>
                                    {/* 
                                    <Button onClick={() => handle_UpdateMemberID()} className="btn btn-info w-100 py-2">
                                        {" "}
                                        <span>{t('update_member_id_button')}</span>{" "}
                                    </Button>
                                    */}
                                </>
                            )}
                        </div>
                    </Col>
                </Row>

            </LoginContactForm>
        </TabContentContainer>
    );
};

export default SetContactInfo;
