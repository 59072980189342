import styled from 'styled-components';
import FONTS from '../../config/fonts';
// import { Colors } from '../../config/colors';


export const TermsContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  font-family : ${FONTS.lexend};
  line-height : 35px ;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #2c3e50;
  font-family : ${FONTS.lexend};
`;

export const SectionTitle = styled.h2`
  font-size: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #34495e;
  font-family : ${FONTS.lexend};
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #555;
  font-family : ${FONTS.lexend};
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-left: 2rem;
  font-family : ${FONTS.lexend};
`;

export const ListItem = styled.li`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-family : ${FONTS.lexend};
`;
