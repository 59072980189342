/* REAL - HOME PAGE - FOOTER SECTION */
/* File: /src/pages/Home/FooterSection/Footer.js */
import { 
      useTranslation 
      }                   from 'react-i18next';
import React              from "react";
import { 
      FaFacebookF, 
      FaTwitter, 
      FaInstagram, 
      FaLinkedinIn 
      }                   from "react-icons/fa";
import { 
      FooterWrapper, 
      FooterContent, 
      FooterLogo, 
      FooterLinks, 
      SocialIcons, 
      Divider, 
      CopyRight 
      }                   from "./footerSectionStyle";
import { 
      Images 
      }                   from "../../../config/images";
import { 
      HashLink 
      }                   from "react-router-hash-link";
import { 
      Link 
      }                   from "react-router-dom";

const Footer = ({sectionId}) => {
  const { t } = useTranslation('homeFooterSection');
  return (
    <FooterWrapper>
      <FooterContent>
        {/* Logo Section */}
        <FooterLogo>
          <img src={Images.ata2goLogo} alt="Logo" />
        </FooterLogo>

        {/* Pages Section */}
        <FooterLinks>
          <div className="footer-column">
          <ul>
              <li><HashLink smooth to="/" className="nav-link js-scroll">{t('titles.home')}</HashLink></li>
              <li><HashLink smooth to="/#services" className="nav-link js-scroll">{t('titles.services')}</HashLink></li>
              <li><HashLink smooth to="/#about" className="nav-link js-scroll">{t('titles.about')}</HashLink></li>
              <li><HashLink smooth to="/#mission" className="nav-link js-scroll">{t('titles.mission')}</HashLink></li>
              <li><HashLink smooth to="/#testimonial" className="nav-link js-scroll">{t('titles.testimonial')}</HashLink></li>
            </ul>
          </div>
          <div className="footer-column">
            <ul>
              <li><HashLink smooth to="/#partnership" className="nav-link js-scroll">{t('titles.partnerships')}</HashLink></li>
              <li><Link to="/privacy">{t('titles.privacy_policy')}</Link></li>
              <li><Link to="/terms-services">{t('titles.terms_of_services')}</Link></li>
              <li><HashLink smooth to="/#contact" className="nav-link js-scroll">{t('titles.contact')}</HashLink></li>
            </ul>

            {/* Social Icons */}
            <SocialIcons>
              <FaFacebookF />
              <FaTwitter />
              <FaInstagram />
              <FaLinkedinIn />
            </SocialIcons>
          </div>
        </FooterLinks>
      </FooterContent>

      {/* Divider */}
      <Divider />

      {/* Copyright */}
      <CopyRight>{t('copyright.text')}</CopyRight>
    </FooterWrapper>
  );
};

export default Footer;
