/* REAL - MY SUBSCRIPTIONS -Select your Interests */
import { useContext, 
         useEffect, 
         useState,
         useCallback
         }                                  from "react";
import { IoIosSave }                        from "react-icons/io";
import { BiSearchAlt }                      from "react-icons/bi";
import { MdOutlineFilterAltOff }            from "react-icons/md";
import { // FaRegEye, FaRegEyeSlash,
         FaHandHoldingHeart
        }                                   from "react-icons/fa";
import { FaListCheck }                      from "react-icons/fa6";
import { MdOutlinePlaylistAddCheckCircle }  from "react-icons/md";
import { CiViewList }                       from "react-icons/ci";
import { CgListTree }                       from "react-icons/cg";
import { toast }                            from "react-toastify";
import { Button, Form,
         Tooltip, OverlayTrigger 
       }                                    from "react-bootstrap";
import { useNavigate }                      from "react-router-dom";
// import { Puff }                             from "react-loader-spinner";
import styled                               from 'styled-components';
import { CategoriesBox, 
         CategoryText, 
         EachCategory, 
         HeadingBox, 
         ProductsBox }                      from "./MyInterestsStyle";
import LabelCheckBox                        from "./LabelCheckBox";

import { TabContentContainer }              from "../../Tabs/tabContentStyle";
import { UserContext }                      from "../../../App";
import { useGetAllCategoriesQuery }         from "../../../services/listCategoryApi";
import { useUpdateInterestsMutation,
         useGetInterestsQuery
       }                                    from "../../../services/userUserStoreApi";
import {
         useGetInterestsCountQuery,
         useGetAllVideosQuery,
        }                                   from "../../../services/listVideoApi";
import Loading                              from '../../../components/Loading/Loading';



const ScrollableCategoriesBox = styled(CategoriesBox)`
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    `;

const StaticHeadingBox = styled(HeadingBox)`
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    padding-bottom: 10px;
    `;

const SubsInterests = ({ user }) => {
    const Source_Name           = "MY SUBSCRIPTIONS -Select your Interests";
    const navigate                                      = useNavigate();
    const { data, isLoading }                           = useGetAllCategoriesQuery({ source: Source_Name });
    const { data: data_Interests }                      = useGetInterestsCountQuery({ source: Source_Name });
    const { data: allVideos }                           = useGetAllVideosQuery({ source: Source_Name });
    const { data: user_Interests, 
            isLoading: isLoadingInterests }             = useGetInterestsQuery(user?._id);
    const [UserStore_UpdateInterests]                   = useUpdateInterestsMutation();
    const { refetch,            setRefetch }            = useContext(UserContext);
    const [selectedCat,         setSelectedCat]         = useState([]);
    const [showCheckedOnly,     setShowCheckedOnly]     = useState(false);
    const [showAvailableOnly,   setShowAvailableOnly]   = useState(true);
    const [showUnpublishedOnly, setShowUnpublishedOnly] = useState(false);
    const [searchTerm,          setSearchTerm]          = useState("");
    const [filteredCategories,  setFilteredCategories]  = useState([]);
    const [selectedCatCount,    setSelectedCatCount]    = useState(0);
    const [totalEntriesCount,   setTotalEntriesCount]   = useState(0);


    /* Section 01.0 - getInterestCount */
    const getInterestCount = useCallback((concatenatedProduct) => { 
        if (!data_Interests || !data_Interests.data || !Array.isArray(data_Interests.data)) {
            return 0; // Default count if data_Interests is not an array
        }
    
        const interest = data_Interests.data.find(item => item._id === concatenatedProduct);
        return interest ? interest.count : 0;
    }, [data_Interests]);
    
    /* Section 02.0 - updateCounts */
    const updateCounts = useCallback((newSelectedCat) => {
        setSelectedCatCount(newSelectedCat.length);
    
        const newTotalEntriesCount = newSelectedCat.reduce((acc, cat) => {
            const [categoryName, product] = cat.split('|');
            return acc + getInterestCount(`${categoryName}|${product}`);
        }, 0);
    
        setTotalEntriesCount(newTotalEntriesCount);
    }, [getInterestCount]);
    
    /* Section 03.0 - useEffect isLoadingInterests, user_Interests */
    useEffect(() => {
        if (!isLoadingInterests && user_Interests) {
            const initialSelectedCat = user_Interests.data;
            setSelectedCat(initialSelectedCat);

            // Update counts on initial load
            updateCounts(initialSelectedCat);
        }
    }, [isLoadingInterests, user_Interests, updateCounts]);

    /* Section 04.0 - useEffect data */
    useEffect(() => {
    if (data) {
        setFilteredCategories(data.data);
    }
    }, [data]);
    
    /* Section 05.0 - useEffect selectedCat */
    useEffect(() => {
        updateCounts(selectedCat);
    }, [selectedCat, updateCounts]);

    /* Section 06.0 - handle_SelectCategory */
    const handle_SelectCategory = s => {
        let newSelectedCat;
        if (selectedCat?.includes(s)) {
            newSelectedCat = selectedCat?.filter(d => d !== s);
        } else {
            newSelectedCat = [...selectedCat, s];
        }
        setSelectedCat(newSelectedCat);

        // Update counts
        updateCounts(newSelectedCat);
    };

    /* Section 07.0 - handle_UpdateInterests */
    const handle_UpdateInterests = async () => {
        const data_Interests = {    interests: selectedCat  };
        console.log("data_Interests",data_Interests);
        if (selectedCat?.length > 0) {

            const res_UpdateInterests = await UserStore_UpdateInterests({ ids: [user?._id] , data: data_Interests });

            if (res_UpdateInterests.data.status) { 
                toast.success(res_UpdateInterests?.data?.message);
                setRefetch(refetch + 1);
            } else {
                toast.error(res_UpdateInterests?.data?.message);
            }
        } else {
            toast.error("Please select at least one item!");
        }
    };

    /* Section 08.0 - toggleFilter */
    const toggleFilter = () => {
        setShowCheckedOnly(prevShowCheckedOnly => {
            const newShowCheckedOnly = !prevShowCheckedOnly;

            // Update counts if necessary
            if (newShowCheckedOnly) {
                updateCounts(selectedCat.filter(cat => {
                    const [categoryName] = cat.split('|');  // Only destructure the first part
                    return filteredCategories.some(category => category.categoryName === categoryName);
                }));
            } else {
                updateCounts(selectedCat);
            }

            return newShowCheckedOnly;
        });
    };

    /* Section 09.0 - handle_SelectCategory */
    const handle_AvailableChange = () => {
        setShowAvailableOnly(prev => !prev);
    };

    /* Section 10.0 - isCategoryVisible */
    const isCategoryVisible = (category) => {
        const userRoles = ["admin", "power", "support"];
            return category.published || userRoles.includes(user.role);
    };
    
    /* Section 11.0 - isCategoryVisible */
    const handleSearch = () => {
        if (searchTerm) {
            const filtered = data.data.filter(category => {
                const categoryMatch = category.categoryName.toLowerCase().includes(searchTerm.toLowerCase());
                const subCategoryMatch = category.subCategories.some(subCategory => subCategory.toLowerCase().includes(searchTerm.toLowerCase()));
                return categoryMatch || subCategoryMatch;
            });
            setFilteredCategories(filtered);
        } else {
            setFilteredCategories(data.data);
        }
    };

    /* Section 12.0 - resetSearch */
    const resetSearch = () => {
    setSearchTerm("");
    setFilteredCategories(data.data);
    };
 
    /* Section 13.0 - isPublicInterest = (category, product) */
    const isPublicInterest = (category, product) => {
        if (!allVideos?.data) {
            return false;
        }
        const concatenatedProduct = `${category}|${product}`;

        const video = allVideos.data.find(video => video.interests.includes(concatenatedProduct));
        return video ? video.isPublic : showAvailableOnly ? false :  true;

        /* return video ? (console.log("concatenatedProduct", concatenatedProduct),
        console.log("video.title", video.title), 
        console.log("video.isPublic", video.isPublic),
        video.isPublic) : showAvailableOnly ? false :  true ; */ 
    };

    /* Section 14.0 - resetSearch */
    const handleToggleUnpublished = () => {
        setShowUnpublishedOnly(prev => !prev);
    };
    
    if (isLoading) {
        return (
            <Loading />
          );
    }

    return (
    <>
        <StaticHeadingBox>
            <div className="d-flex flex-column">
            <h3 className="mb-0 pb-0">
            Select your Interests 
            <span style={{ fontSize: '0.8em' }}> ({selectedCatCount} Categories, {totalEntriesCount} Entries)</span>
        </h3>
                <div className="d-flex align-items-center mt-2">
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-filter">My Selections</Tooltip>}  >
                        <Button variant="primary" className="ms-2" onClick={toggleFilter}>
                            {showCheckedOnly ? <FaListCheck /> : <FaHandHoldingHeart />}
                        </Button>
                    </OverlayTrigger>
                    
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-available">Toggle Available</Tooltip>}    >
                        <Button variant="primary" className="ms-2" onClick={handle_AvailableChange}>
                            {showAvailableOnly ? (<CiViewList />) : (<span><MdOutlinePlaylistAddCheckCircle /></span>)}
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Save Interests</Tooltip>}       >
                        <Button variant="primary" className="ms-2" onClick={handle_UpdateInterests}>
                            <IoIosSave />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-mypromo">Go to MyPromo</Tooltip>}     >
                        <Button variant="primary" className="ms-2" onClick={() => navigate('/videos')}>
                            MyPromo
                        </Button>
                    </OverlayTrigger>

                    <Form.Control
                        type="text"         placeholder="Search"    value={searchTerm}      onChange={e => setSearchTerm(e.target.value)}
                        className="ms-2"    style={{ width: "200px" }}  onKeyPress={e => {if (e.key === 'Enter') {e.preventDefault();handleSearch();}  }}
                    />

                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-search">Search</Tooltip>}     >
                        <Button variant="primary" className="ms-2" onClick={handleSearch}>
                            <BiSearchAlt />
                        </Button>
                    </OverlayTrigger>
                    
                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-reset">Reset Search</Tooltip>}        >
                        <Button variant="secondary" className="ms-2" onClick={resetSearch}>
                            <MdOutlineFilterAltOff />
                        </Button>
                    </OverlayTrigger>

                    {["admin", "support", "power"].includes(user?.role) && (
                        <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-unpublished">Toggle Unpublished</Tooltip>}    >
                            <Button variant="primary" className="ms-2" onClick={handleToggleUnpublished}>
                                <CgListTree />
                            </Button>
                        </OverlayTrigger>
                    )}
                    
                </div>
            </div>
        </StaticHeadingBox>


        <TabContentContainer>
            <ScrollableCategoriesBox>

                {filteredCategories
                    ?.filter(category => showUnpublishedOnly ? !category.published : isCategoryVisible(category))

                    ?.slice() // Create a shallow copy of the categories array
                    ?.sort((a, b) => a.categoryName.localeCompare(b.categoryName)) // Sort categories
                    ?.map(category => {
                        const filteredSubCategories = showCheckedOnly
                            ? category?.subCategories?.filter(product => selectedCat?.includes(`${category?.categoryName}|${product}`))
                            : category?.subCategories;

                        if (showCheckedOnly) {
                        }

                        const availableSubCategories = filteredSubCategories?.filter(product => 
                            (!showAvailableOnly || getInterestCount(`${category?.categoryName}|${product}`) > 0) &&
                            isPublicInterest(category?.categoryName, product)
                        );

                        if (availableSubCategories?.length === 0) return null;

                        return (
                            <EachCategory key={category?._id}>
                                <h4>{category?.categoryName}{category?.published ? "" : " (Not Published)"}..</h4>
                                <ProductsBox>
                                    {availableSubCategories
                                        ?.slice() // Create a shallow copy of the subcategories array
                                        ?.sort((a, b) => a.localeCompare(b)) // Sort subcategories
                                        ?.map(product => {
                                            const concatenatedProduct = `${category?.categoryName}|${product}`;
                                            const count = getInterestCount(concatenatedProduct);

                                            return (
                                                <LabelCheckBox
                                                    key={concatenatedProduct}
                                                    isCategoryBox={true}
                                                    checked={selectedCat?.includes(concatenatedProduct)}
                                                    onChange={() => handle_SelectCategory(concatenatedProduct)}
                                                    id={concatenatedProduct} // Added id attribute
                                                    name={concatenatedProduct} // Added name attribute
                                                >
                                                    <CategoryText>
                                                        {product} {count > 0 ? `(${count})` : ''}
                                                    </CategoryText>
                                                </LabelCheckBox>
                                            );
                                        })}
                                </ProductsBox>
                            </EachCategory>
                        );
                    })
                }

            </ScrollableCategoriesBox>
        </TabContentContainer>


    </>
    );
};

export default SubsInterests;
