/* File: /src/services/listVehiclePartsApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const listVehiclePartsApi = createApi({
    reducerPath: "vehiclePartsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/listVehicleParts",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`); 
            console.log("====> token: vehiclePartsApi <====");   }
            else { console.log("====> Could NOT get token: vehiclePartsApi <====");}
            return headers;
        }
    }),
    tagTypes: ["VehicleParts"],
    endpoints: builder => ({
        getAllVehicleParts:       builder.query({    query: ({ source }) =>               ({ url: "/",                        method: "GET", params: { source }, }),                       providesTags: ["VehicleParts"] }),
        getVehiclePartById:       builder.query({    query: id =>               ({ url: `/${id}`,                   method: "GET" }),                       providesTags: ["VehicleParts"] }),
        
        addVehiclePart:           builder.mutation({ query: data =>             ({ url: "/",                        method: "POST", body: data }),          invalidatesTags: ["VehicleParts"] }),
       

        updateVehiclePart:        builder.mutation({ query: ({ id, data }) =>   ({   url: `/${id}`,                 method: 'PATCH', body: data, }),        invalidatesTags: ['VehicleParts'] }),
        setPublishState:          builder.mutation({ query: ({ data }) =>       ({ url: `/setPublishState`,         method: 'PATCH', body: data, }),        invalidatesTags: ['VehicleParts'] }),
        updateVehiclePartsById:   builder.mutation({ query: payload =>          ({ url: `/subSystem/${payload.id}`, method: "PATCH", body: payload.data }), invalidatesTags: ["VehicleParts"] }),
        
        deleteVehiclePart:        builder.mutation({ query: id =>               ({ url: `/${id}`,                   method: "DELETE" }),                    invalidatesTags: ["VehicleParts"] }),
        healthCheck:              builder.query   ({ query: ({ source }) =>               ({ url: `/health`,                  method: "GET", params: { source }, })        }),
    })
});

export const {
    useHealthCheckQuery,
    useSetPublishStateMutation,

    useAddVehiclePartMutation,          /* MANAGE - LISTS - PARTS */
    useGetAllVehiclePartsQuery,         /* MANAGE - LISTS - PARTS  &    MY SUBSCRIPTIONS - PARTS ORDER */
    useDeleteVehiclePartMutation,       /* MANAGE - LISTS - PARTS */
    
    useGetVehiclePartByIdQuery,         /*  --NULL-- */
    //useGetVehiclePartsByUserQuery,
    useUpdateVehiclePartMutation,       /* MANAGE - LISTS - PARTS */
    useUpdateVehiclePartsByIdMutation   /* MANAGE - LISTS - PARTS */
} = listVehiclePartsApi;
