// hooks/useTokenExpiryCheck.js
import { useEffect, useRef }    from "react";
import { jwtDecode }            from "jwt-decode";
import { toast }                from "react-toastify";
import moment                   from "moment";  
const STG_Time_Format = "HH:mm:ss";
const STG_TokenExpiry_CheckInterval_ms = 300000;
const STG_TokenExpiry_Warning_m = 15;


const useTokenExpiryCheck = () => {
  const warningIssued = useRef(false); // Track if warning has been issued

  useEffect(() => {
    const checkTokenExpiry = () => {
      const token = localStorage.getItem("token");      
      if (!token) return;

      const decodedToken = jwtDecode(token);
      // const currentTime = Date.now() / 1000; // Current time in seconds
      //const timeLeft = decodedToken.exp - currentTime; // Time left in seconds
      const timeLeft = (decodedToken.exp * 1000) - Date.now();
      const formattedTimeLeft = moment(timeLeft).format(STG_Time_Format)
      // const formattedTimeLeft = moment.duration(timeLeft, 'seconds').format(STG_Time_Format);
      console.log("Access Token exipres in:", formattedTimeLeft);


      if (timeLeft <= 0) {
        const minutesSinceExpiry = Math.abs(Math.floor(timeLeft / 60000)); // Convert ms to minutes and get absolute value
        alert(`Your session has expired ${minutesSinceExpiry} minute(s) ago. Please login again.`);
        localStorage.removeItem("token");
        window.location.reload(); // Reload the page to simulate logging out
        return;
      }
      

      // If there are less than 15 minutes left, warn the user immediately
      if (timeLeft <= STG_TokenExpiry_Warning_m * 60 * 1000 && !warningIssued.current) {
        toast.warn(`Your session will expire in less than ${STG_TokenExpiry_Warning_m} minutes. Please save your work.`);
        warningIssued.current = true;
        return;
      }

    };

    checkTokenExpiry();

    const intervalId = setInterval(() => {
      checkTokenExpiry();
    }, STG_TokenExpiry_CheckInterval_ms); 

    return () => clearInterval(intervalId); // Clear interval on cleanup
  }, []);

  return null;
};

export default useTokenExpiryCheck;
