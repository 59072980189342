import styled from "styled-components";
import { Images } from "../../../config/images";
import { Colors } from "../../../config/colors"; // import your color palette

export const FooterWrapper = styled.footer`
  background-image: url(${Images.bannerBgImage});
  color: #ffffff;
  padding: 40px 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 0px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px; /* Add padding to create space above links */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterLogo = styled.div`
  img {
    width: 280px;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  margin-bottom: 20px; /* Add this line to create space below the logo */
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 50px;

  .footer-column {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 10px;

        a {
          text-decoration: none;
          color: #ffffff;
          font-size: 14px;

          &:hover {
            color: ${Colors.primary};  // A primary color for hover effect
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    gap: 20px;
    text-align: center;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;

  svg {
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      color: ${Colors.primary};  // Hover effect for social icons
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #ffffff;
  margin: 20px 0;
  opacity: 0.2;
`;

export const CopyRight = styled.p`
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
  opacity: 0.6;
`;
