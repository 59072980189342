import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const SettingsPage = () => {
    const [stgCompanyName,                    setStgCompanyName]                    = useState('ATA2GO');
    const [stgDateFormat,                     setStgDateFormat]                     = useState('YYYY-MM-DD');
    const [stgTimeFormat,                     setStgTimeFormat]                     = useState('HH:mm:ss');
    const [stgDateTimeFormat,                 setStgDateTimeFormat]                 = useState('YYYY-MM-DD, HH:mm:ss');
    const [stgEmailVerificationCodeExpiry,    setStgEmailVerificationCodeExpiry]    = useState('180');
    const [stgPhoneVerificationCodeExpiry,    setStgPhoneVerificationCodeExpiry]    = useState('300');
    const [stgTokenName,                      setStgTokenName]                      = useState('token');
    const [stgCaptchaEnabled,                 setStgCaptchaEnabled]                 = useState(true);
    const [stgTokenExpiryWarning,             setStgTokenExpiryWarning]             = useState('15');
    const [stgTokenExpiryCheckInterval,       setStgTokenExpiryCheckInterval]       = useState('300000');
    const [stgMaxNbAuthMethod,                setStgMaxNbAuthMethod]                = useState('3');
    const [stgMinPasswordLength,              setStgMinPasswordLength]              = useState('8');
    const [stgSecurityNbLastLogin,            setStgSecurityNbLastLogin]            = useState('10');
    const [stgPasswordRegex,                  setStgPasswordRegex]                  = useState('/^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{8,}$/');
    const [stgPhoneNumberRegex,               setStgPhoneNumberRegex]               = useState('/[^+\\d\\s()-]/g');
    const [stgDefaultUserRole,                setStgDefaultUserRole]                = useState('user');
    const [stgUserAgentUndefinedTag,          setStgUserAgentUndefinedTag]          = useState('Unknown');
    const [stgTokenDurationExpiry,            setStgTokenDurationExpiry]            = useState('1d');
    const [stgMaxBadPassword,                 setStgMaxBadPassword]                 = useState('4');
    const [stgMaxBadPasswordRecord,           setStgMaxBadPasswordRecord]           = useState('10');
    const [stgGenSaltNbRounds,                setStgGenSaltNbRounds]                = useState('10');
    const [stgCodeNbDigit,                    setStgCodeNbDigit]                    = useState('6');
    const [stgLockDurationM,                  setStgLockDurationM]                  = useState('1');


/*
    const [stgPasswordRegex,                 setStgPasswordRegex]                 = useState('/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/');
    const [stgPhoneNumberRegex,              setStgPhoneNumberRegex]              = useState('/[^+\d\s()-]/g');
*/

    const [stgUpcoming,                      setStgUpcoming]                      = useState('');




  const handleSave = () => {
    // Save logic here
    console.log('Settings saved:', {
      stgCompanyName,
      stgDateFormat,
      stgTimeFormat,
      stgDateTimeFormat,
      stgEmailVerificationCodeExpiry,
      stgPhoneVerificationCodeExpiry,
      stgTokenName,
      stgCaptchaEnabled,
      stgTokenExpiryWarning,
      stgTokenExpiryCheckInterval,
      stgMaxNbAuthMethod,
      stgMinPasswordLength,
    });
  };


  return (
    <Container>
      <h2>Settings</h2>
      
      {/* Section 1: General Settings */}
      <h4 style={{ color: 'blue' }}>General Settings</h4>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <Form.Group className="mb-3" controlId="formStgCompanyName"><Form.Label>STG_CompanyName</Form.Label>
          <Form.Control type="text" value={stgCompanyName} onChange={(e) => setStgCompanyName(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgCompanyName"><Form.Label>STG_passwordRegex</Form.Label>
          <Form.Control type="text" value={stgPasswordRegex} onChange={(e) => setStgPasswordRegex(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgCompanyName"><Form.Label>STG_phoneNumberRegex</Form.Label>
          <Form.Control type="text" value={stgPhoneNumberRegex} onChange={(e) => setStgPhoneNumberRegex(e.target.value)} /></Form.Group>


        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-3" controlId="formStgDateFormat"><Form.Label>STG_Date_Format</Form.Label>
          <Form.Control type="text" value={stgDateFormat} onChange={(e) => setStgDateFormat(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgTimeFormat"><Form.Label>STG_Time_Format</Form.Label>
          <Form.Control type="text" value={stgTimeFormat} onChange={(e) => setStgTimeFormat(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgDateTimeFormat"><Form.Label>STG_DateTime_Format</Form.Label>
          <Form.Control type="text" value={stgDateTimeFormat} onChange={(e) => setStgDateTimeFormat(e.target.value)} /></Form.Group>
        </Col>
      </Row>

      {/* Section 2: Verification Settings */}
      <br /> <br /> 
      <h4 style={{ color: 'blue' }}>Verification Settings</h4>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <Form.Group className="mb-3" controlId="formStgEmailVerificationCodeExpiry"><Form.Label>STG_EmailVerificationCodeExpiry_s</Form.Label>
          <Form.Control type="text" value={stgEmailVerificationCodeExpiry} onChange={(e) => setStgEmailVerificationCodeExpiry(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgPhoneVerificationCodeExpiry"><Form.Label>STG_PhoneVerificationCodeExpiry_s</Form.Label>
          <Form.Control type="text" value={stgPhoneVerificationCodeExpiry} onChange={(e) => setStgPhoneVerificationCodeExpiry(e.target.value)} /></Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-3" controlId="formStgTokenName"><Form.Label>STG_TokenName</Form.Label>
          <Form.Control type="text" value={stgTokenName} onChange={(e) => setStgTokenName(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgCaptchaEnabled"><Form.Label>STG_CaptchaEnabled</Form.Label>
          <Form.Control type="text" value={stgCaptchaEnabled} onChange={(e) => setStgCaptchaEnabled(e.target.value)} /></Form.Group>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={12} md={6}>
          <Form.Group className="mb-3" controlId="formStgTokenExpiryWarning"><Form.Label>STG_TokenExpiry_Warning_m</Form.Label>
          <Form.Control type="text" value={stgTokenExpiryWarning} onChange={(e) => setStgTokenExpiryWarning(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgTokenExpiryCheckInterval"><Form.Label>STG_TokenExpiry_CheckInterval_ms</Form.Label>
          <Form.Control type="text" value={stgTokenExpiryCheckInterval} onChange={(e) => setStgTokenExpiryCheckInterval(e.target.value)} /></Form.Group>
        </Col>
      </Row>

      {/* Section 3: Security Settings */}
      <br /> <br /> 
      <h4 style={{ color: 'blue' }}>Security Settings</h4>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <Form.Group className="mb-3" controlId="formStgMaxNbAuthMethod"><Form.Label>STG_MaxNbAuthMethod</Form.Label>
          <Form.Control type="text" value={stgMaxNbAuthMethod} onChange={(e) => setStgMaxNbAuthMethod(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgMinPasswordLength"><Form.Label>STG_Min_Password_Length</Form.Label>
          <Form.Control type="text" value={stgMinPasswordLength} onChange={(e) => setStgMinPasswordLength(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgTokenName"><Form.Label>STG_Security_Nb_LastLogin</Form.Label>
          <Form.Control type="text" value={stgSecurityNbLastLogin} onChange={(e) => setStgSecurityNbLastLogin(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgCaptchaEnabled"><Form.Label>UPCOMING</Form.Label>
          <Form.Control type="text" value={stgUpcoming} onChange={(e) => setStgUpcoming(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgDefaultUserRole"><Form.Label>STG_DefaultUserRole</Form.Label>
          <Form.Control type="text" value={stgDefaultUserRole} onChange={(e) => setStgDefaultUserRole(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgUserAgentUndefinedTag"><Form.Label>STG_UserAgentUndefinedTag</Form.Label>
          <Form.Control type="text" value={stgUserAgentUndefinedTag} onChange={(e) => setStgUserAgentUndefinedTag(e.target.value)} /></Form.Group>
        </Col>

        <Col sm={12} md={6}>
          <Form.Group className="mb-3" controlId="formStgTokenDurationExpiry"><Form.Label>STG_TokenDurationExpiry</Form.Label>
          <Form.Control type="text" value={stgTokenDurationExpiry} onChange={(e) => setStgTokenDurationExpiry(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgMaxBadPassword"><Form.Label>STG_MaxBadPassword</Form.Label>
          <Form.Control type="text" value={stgMaxBadPassword} onChange={(e) => setStgMaxBadPassword(e.target.value)} /></Form.Group>        

          <Form.Group className="mb-3" controlId="formStgMaxBadPasswordRecord"><Form.Label>STG_MaxBadPasswordRecord</Form.Label>
          <Form.Control type="text" value={stgMaxBadPasswordRecord} onChange={(e) => setStgMaxBadPasswordRecord(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgGenSaltNbRounds"><Form.Label>STG_GenSalt_NbRounds</Form.Label>
          <Form.Control type="text" value={stgGenSaltNbRounds} onChange={(e) => setStgGenSaltNbRounds(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgCodeNbDigit"><Form.Label>STG_Code_NbDigit</Form.Label>
          <Form.Control type="text" value={stgCodeNbDigit} onChange={(e) => setStgCodeNbDigit(e.target.value)} /></Form.Group>

          <Form.Group className="mb-3" controlId="formStgLockDurationM"><Form.Label>STG_LockDuration_m</Form.Label>
          <Form.Control type="text" value={stgLockDurationM} onChange={(e) => setStgLockDurationM(e.target.value)} /></Form.Group>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col className="text-end">
          <Button className="danger-btn" variant="danger" onClick={handleSave}>Save</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingsPage;
