/* File: /src/services/listVideoApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const listVideoApi = createApi({
    reducerPath: "listVideoApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/listVideos",
    
        prepareHeaders: (headers, { getState }) => {
            const token = getTokenFromLocalStorage();
            if (token) {    
                headers.set("Authorization", `Bearer ${token}`);    
            }
            return headers;
        },
    }),
    

    tagTypes: ["Videos"], 
    endpoints: builder => ({
        getAllVideos:           builder.query({    query: ({ source }) =>       ({ url: "/",                method: "GET", params: { source }, }),                         providesTags: ["Videos"] }),
        getAllVideosWithRating: builder.query({    query: ({ source }) =>       ({ url: "/videoWithRating", method: "GET", params: { source }, }),                         providesTags: ["Videos"] }),
        getInterestsCount:      builder.query({    query: ({ source }) =>       ({ url: "/interestsCount",  method: "GET", params: { source }, }),                         providesTags: ["Videos"] }),
        getVideoById:           builder.query({    query: ({ id, source }) =>  ({ url: `/${id}`,           method: "GET", params: { source }, }),                         providesTags: ["Videos"] }),
        getVideosByUser:        builder.query({    query: ({ id, source }) =>  ({ url: `/userId/${id}`,    method: "GET", params: { source }, }),                         providesTags: ["Videos"] }),     
     // getVideoById:           builder.query({    query: id =>       ({ url: `/${id}`,           method: "GET" }),                         providesTags: ["Videos"] }),
     // getVideosByUser:        builder.query({    query: id =>       ({ url: `/userId/${id}`,    method: "GET" }),                         providesTags: ["Videos"] }),
        getVideosBySubCategory: builder.query({    query: subCategory => ({ url: `/subCategory/${subCategory}`, 
                                                                                                  method: "GET" }),                         providesTags: ["Videos"] }),
        getFileDetails:         builder.query({    query: id =>       ({ url: `/file-details/${id}`, method: "GET" }),                      providesTags: ["Videos"] }),
     /*   getPromoFile:           builder.query({    query: id =>       ({ url: `/promoFile/${id}`,  method: "GET", responseHandler: (response) => response.blob(),  }),
                                transformResponse: (response, meta, arg) => { return { blob: response, contentType: meta.response.headers.get("Content-Type"), }; },
                                providesTags: ["Videos"] }),
                            */
       getPromoFile:           builder.query({    query: ({ id, source}) => ({ url: `/promoFile/${id}`, method: "GET", params: { source }, responseHandler: (response) => response.blob(), }),
                                transformResponse: (response, meta) => { return { blob: response,    contentType: meta.response.headers.get("Content-Type"), }; },
                                providesTags: ["Videos"] }),

            getDocumentById: builder.query({ query: (id) => ({ url: `/${id}`, method: "GET" }), providesTags: ["Documents"] }),
                        
                                
                                

        uploadVideo:            builder.mutation({ query: data =>     ({ url: `/`,                method: "POST", body: data }),            invalidatesTags: ["Videos"] }),

        // updateVideo:            builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,   method: "PATCH", body: payload.data }),   invalidatesTags: ["Videos"] }),
        updateVideo:            builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,   method: "PATCH", body: payload.data,
                headers: (headers) => {
                    if (!(payload.data instanceof FormData)) { headers.set("Content-Type", "application/json"); }
                    return headers;
                }, 
            }),   
            invalidatesTags: ["Videos"] 
        }),
        
        deleteVideo:            builder.mutation({ query: id =>       ({ url: `/${id}`,           method: "DELETE" }),                      invalidatesTags: ["Videos"] }),
        healthCheck:            builder.query   ({ query: ({ source }) =>       ({ url: `/health`,          method: "GET" })        }),
    })
});

export const {
    useHealthCheckQuery,
    useDeleteVideoMutation,
    useGetAllVideosQuery,
    useGetAllVideosWithRatingQuery,
    useGetInterestsCountQuery,
    useGetVideoByIdQuery,
    useGetVideosByUserQuery,
    useGetFileDetailsQuery,
    useGetPromoFileQuery,
    useUpdateVideoMutation,
    useUploadVideoMutation,
    useGetVideosBySubCategoryQuery
} = listVideoApi;
