import React from 'react';
import PrivacyPageBanner from './privacyPageBanner/PrivacyPageBanner';
import Footer from '../Home/FooterSection/Footer';
// import SectionTitle from '../../components/SectionTitle';
import { Container, PrivacyContent } from './PrivacyPageStyles';
import { useEffect } from 'react';

const PrivacyPage = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    
    
  return (
    <>
      <PrivacyPageBanner />

      <Container>
        {/* <SectionTitle >Privacy Policy</SectionTitle> */}

        <PrivacyContent>
          <p>
            At [Your Company Name], we are committed to protecting your privacy and ensuring the security of your personal information. This privacy policy outlines the types of data we collect, how we use it, and your rights regarding your information.
          </p>

          <h2>Information We Collect</h2>
          <ul>
            <li>Personal identification information (Name, email address, phone number, etc.)</li>
            <li>Log data (IP address, browser type, etc.)</li>
            <li>Usage data (how you interact with our website)</li>
          </ul>

          <h2>How We Use Your Information</h2>
          <p>
            We use the information we collect for a variety of purposes, including to:
          </p>
          <ul>
            <li>Provide and maintain our services</li>
            <li>Improve our website's functionality and user experience</li>
            <li>Communicate with you regarding updates or promotional offers</li>
            <li>Comply with legal obligations</li>
          </ul>

          <h2>Data Security</h2>
          <p>
            We take appropriate measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.
          </p>

          <h2>Your Rights</h2>
          <p>
            You have the right to access, update, or delete your personal information at any time. To exercise these rights, please contact us at privacy@[yourcompany].com.
          </p>

          <h2>Changes to This Policy</h2>
          <p>
            We may update our privacy policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page, so please review it periodically.
          </p>

          <p className="italic">
            Last updated: [Date of the last update]
          </p>
        </PrivacyContent>
      </Container>

      <Footer />
    </>
  );
};

export default PrivacyPage;
