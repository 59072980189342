import axios                            from "axios";
import { 
        useTranslation 
        }                                     from 'react-i18next';
import React, 
      { useState, useEffect  }           from "react";
import SuccessPopup                     from "./successPopup";
import                                        "./styles.css";
import { 
        AiOutlineArrowLeft 
      }                                 from "react-icons/ai";
import { 
       toast 
        }                               from "react-toastify";
import { 
        useGetBeneficiaryMutation 
        }                               from "../../../../services/userUserStoreApi";
import { 
        useGetNewReqNbMutation, 
        }                               from "../../../../services/orderTransactionApi";
import { 
        SVGImages 
        }                               from "../../../../config/images";

// Map the countries to their respective currencies
const countryCurrencyMap = {
  "Bahamas":              { flag: SVGImages.FlagBahamas,              currencies: ["USD"] },
  "Canada":               { flag: SVGImages.FlagCanada,               currencies: ["CAD"] },
  "Dominican Republic":   { flag: SVGImages.FlagDominicanRepublic,    currencies: ["DOP", "USD"] },
  "Haiti":                { flag: SVGImages.FlagHaiti,                currencies: ["HTG", "USD"] },
  "Turks and Caicos":     { flag: SVGImages.FlagTurksAndCaicos,       currencies: ["USD"] },
  "United States":        { flag: SVGImages.FlagUnitedStates,         currencies: ["USD"] },
};


const SendRemittanceBox = ({ user, onBackClick }) => {
  const { t }                 = useTranslation("subsRemittance");
  const Source_Name                   = "MY SUBSCRIPTIONS - REMITTANCE";
  const [isPopupVisible,              setIsPopupVisible]      = useState(false);
  const [selectedBeneficiary,         setSelectedBeneficiary] = useState("");
  const [sendingAmount,               setSendingAmount]       = useState("");
  const [receivingAmount,             setReceivingAmount]     = useState("");
  const [feesAmount,                  setFeesAmount]          = useState("");
  const [totalAmount,                 setTotalAmount]         = useState("");
  const [message,                     setMessage]             = useState("");
  const [beneficiaries,               setBeneficiaries]       = useState([]);
  const [currencyOptions,             setCurrencyOptions]     = useState([]); // Dynamic currency options
  const [selectedCurrency,            setSelectedCurrency]    = useState(""); // Track selected currency
  const [requestNumber,               setRequestNumber]       = useState('');
  const [UserStore_getBeneficiary]                            = useGetBeneficiaryMutation(); // Fetch beneficiaries
  const [getNewReqNb_OrderTransaction]                        = useGetNewReqNbMutation();
  

  const selectedBeneficiaryData = beneficiaries.find(
    (b) => b._id === selectedBeneficiary
  );

  // Fetch beneficiaries on component load
  useEffect(() => {
    const fetchBeneficiaryData = async () => {
      if (user?._id) {
        try {
          const data_getBeneficiary = { userId: user._id };
          const result = await UserStore_getBeneficiary({ data: data_getBeneficiary,  source: "MY SUBSCRIPTIONS - REMITTANCE",  });

          if (result?.data?.data) {
            setBeneficiaries(result.data.data); // Update state with beneficiaries
          } else {
            setBeneficiaries([]); // Set empty if no beneficiaries
          }
        } catch (error) {
          console.error("Error fetching beneficiaries:", error);
          setBeneficiaries([]); // Handle error case
        }
      }
    };

    fetchBeneficiaryData();
  }, [UserStore_getBeneficiary, user?._id]);

  useEffect(() => {
    if (selectedBeneficiaryData) {
      const selectedCountry = selectedBeneficiaryData.country;
      const beneficiaryCurrency = selectedBeneficiaryData.currency; // Get the currency from the selected beneficiary
  
      if (selectedCountry && countryCurrencyMap[selectedCountry]) {
        // Update currency options based on the beneficiary's country
        setCurrencyOptions(countryCurrencyMap[selectedCountry].currencies);
  
        // Automatically set the selected currency if it's available in the options
        if (beneficiaryCurrency && countryCurrencyMap[selectedCountry].currencies.includes(beneficiaryCurrency)) {
          setSelectedCurrency(beneficiaryCurrency);
        } else {
          setSelectedCurrency(''); // Reset if the currency isn't available or not provided
        }
      } else {
        setCurrencyOptions([]); // Clear if no country found
        setSelectedCurrency(''); // Reset currency if no valid country
      }
    }
  }, [selectedBeneficiaryData]);

  const handle_CreateTransaction = async () => {
    if (!sendingAmount || !selectedCurrency) {
        toast.error("Please fill in all the fields.");
    } else {
        try {
            const result = await getNewReqNb_OrderTransaction({ id: user?._id, source: Source_Name }).unwrap();
            if (result?.data) { setRequestNumber(result?.data); }
            setIsPopupVisible(true);
        } catch (error) {
            toast.error("Failed to get request number. Please try again.");
        }
    }
};

  /* Section 08.0 - fetchExchangeRate   */
  const fetchExchangeRate = async (oriCurrency, destCurrency, amount) => {
    const options = {
        method: 'GET',
        url: 'https://currency-converter18.p.rapidapi.com/api/v1/convert',
        params: {
          from: `${oriCurrency}`,
          to: `${destCurrency}`,
          amount: `${amount}`
        },
        headers: {
          'x-rapidapi-key': '3183ae9d2bmshd63837079167294p121a90jsn3387d784707e',
          'x-rapidapi-host': 'currency-converter18.p.rapidapi.com'
        }
    };
      
    try {
        const response = await axios.request(options);
        return response.data.result.convertedAmount;
    } catch (error) {
        console.error(error);
    }
  };

// Debounce logic for both sendingAmount (Sends CAD) and receivingAmount (Receives)
useEffect(() => {
  let timeoutId;

  const convertAmount = async (fromCurrency, toCurrency, amount, isSendAmount) => {
    const convertedAmount = await fetchExchangeRate(fromCurrency, toCurrency, parseFloat(amount));
    if (convertedAmount) {
      if (isSendAmount) {
        // Update 'Receives' when 'Sends CAD' changes
        setReceivingAmount(convertedAmount.toFixed(2));
      } else {
        // Update 'Sends CAD' when 'Receives' changes
        setSendingAmount(convertedAmount.toFixed(2));

        // Calculate and update fees (6%) for Sends CAD
        const fees = (parseFloat(convertedAmount) * 0.06).toFixed(2);
        setFeesAmount(fees);

        // Calculate total as sendingAmount + feesAmount
        const total = (parseFloat(convertedAmount) + parseFloat(fees)).toFixed(2);
        setTotalAmount(isNaN(total) ? '---' : total);  // Update totalAmount
      }
    } else {
      if (isSendAmount) {
        setReceivingAmount(''); // Clear the field if conversion fails
      } else {
        setSendingAmount('');   // Clear the field if conversion fails
      }
    }
  };

  // Debounce for Sends CAD
  if (parseFloat(sendingAmount) >= 10 && selectedCurrency) {
    timeoutId = setTimeout(() => {
      convertAmount('CAD', selectedCurrency, sendingAmount, true); // Convert CAD -> Payout Currency
    }, 1000); // introduce a delay
  }

  // Debounce for Receives
  else if (parseFloat(receivingAmount) >= 10 && selectedCurrency) {
    timeoutId = setTimeout(() => {
      convertAmount(selectedCurrency, 'CAD', receivingAmount, false); // Convert Payout Currency -> CAD
    }, 1000); // introduce a delay
  }

  // Cleanup the timeout if the component unmounts or if values change rapidly
  return () => clearTimeout(timeoutId);

}, [sendingAmount, receivingAmount, selectedCurrency]);


  return (
    <div>
      <div className="send-remittance-box">
        <button className="back-button" onClick={onBackClick}>          <AiOutlineArrowLeft /> Back        </button>
        <h3 className="section-title">{t('PayDetails.title_PaymentDetails')}</h3>

        {/* Select Beneficiary */}
        <div className="input-group-2">
          <label>Select Beneficiary</label>
          <select
            className="input-field"
            value={selectedBeneficiary}
            onChange={(e) => setSelectedBeneficiary(e.target.value)}
          >
            <option value="" disabled>
              Select a beneficiary
            </option>
            {beneficiaries.map((beneficiary) => (
              <option key={beneficiary._id} value={beneficiary._id}>
                {beneficiary.firstName} {beneficiary.lastName} #{beneficiary.phone}, {beneficiary.city},  [{beneficiary.currency}] {beneficiary.country}
              </option>
            ))}
          </select>
        </div>

        <div className="payment-details">
          {/* Only show if a beneficiary is selected */}
          {selectedBeneficiaryData  && (
            <>
              {/* Sends CAD */}
              <div className="input-group">
                <label>{user.firstName} Sends CAD</label>
                <input
                  type="number"
                  className="input-field"
                  value={sendingAmount}
                  onChange={e => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.,]/g, '');    // Allow only numbers, periods, and commas
                    value = value.replace(/,/g, '.');         // Replace commas with periods to standardize the decimal format
                    setSendingAmount(value);                  // Only update the sendingAmount state
                    setReceivingAmount(".");                   // Clear the receivingAmount field when typing in Sends CAD


                    // Fees calculation is kept here because it should still be updated immediately
                    const fees = (parseFloat(value) * 0.06).toFixed(2);  // Calculate fees (6%) 
                    setFeesAmount(fees);

                    // Total amount should also update immediately
                    const total = (parseFloat(value) + parseFloat(fees)).toFixed(2);
                    setTotalAmount(isNaN(total) ? '---' : total);  // Update totalAmount, showing '---' if NaN
                  }}
                  min="0"
                  placeholder="Enter amount"
                />
              </div>

              {/* Currency Payout */}
              <div className="input-group">
                <label>Currency Payout</label>
                <select
                  className="input-field"
                  value={selectedCurrency}
                  onChange={async e => {
                    setSelectedCurrency(e.target.value);
                    // Only fetch the exchange rate if the value is >= 10
                    if (parseFloat(sendingAmount) >= 10) {
                      const convertedAmount = await fetchExchangeRate('CAD', e.target.value, parseFloat(sendingAmount));
                      if (convertedAmount) {
                          setReceivingAmount(convertedAmount.toFixed(2)); // Update with the converted amount
                      } else {
                          setReceivingAmount('');                         // Clear the field if conversion fails
                      }
                    } else {
                        setReceivingAmount('');                             // Clear the field if the amount is less than 10
                    }

                  }}
                  disabled={currencyOptions.length <= 1} // Disable if only one currency option
                >
                  {currencyOptions.length > 1 ? (
                    <>
                      <option value="" disabled>Select Currency</option>
                      {currencyOptions.map((currency) => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </>
                  ) : (
                    currencyOptions.length === 1 && (
                      <option key={currencyOptions[0]} value={currencyOptions[0]}>
                        {currencyOptions[0]}
                      </option>
                    )
                  )}
                </select>
              </div>

              {/* Receives */}
              <div className="input-group">
                <label>{selectedBeneficiaryData.firstName} Receives</label>
                <input
                  type="number"
                  className="input-field"
                  value={receivingAmount}
                  onChange={e => {
                    let value = e.target.value;
                    value     = value.replace(/[^\d.,]/g, '');    // Allow only numbers, periods, and commas
                    value     = value.replace(/,/g, '.');         // Replace commas with periods to standardize the decimal format
                    setReceivingAmount(value);
                    setSendingAmount(".");                     // Clear the sendingAmount field when typing in Receives

                  }}
                  min="0"
                  placeholder="Enter amount"
                />
              </div>


            </>
          )}
        </div>


        <div className="transfer-details">
          {/* Transfer Fees */}
          <div className="input-group">
            <label>Transfer Fees</label> 
            <input type="text" className="input-field greyed-out" value={feesAmount} readOnly />
          </div>

          {/* Total to pay CAD */}
          <div className="input-group">
            <label>Total to pay CAD</label> 
            <input type="number" min={0} className="input-field greyed-out" value={totalAmount} readOnly />
          </div>
        </div>

        {/* Message for recipient */}
        <div className="transfer-details">
          <div className="input-group">
            <label>Message for recipient</label>
            <textarea
              className="input-field"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>

        <button className="send-button" onClick={handle_CreateTransaction}>
          Send Remittance
        </button>

        {isPopupVisible && (
          <SuccessPopup
            onClose={() => setIsPopupVisible(false)}
            userId        ={user?._id}
            userEmail     ={user?.email}
            requestNumber ={requestNumber}
            firstName     ={selectedBeneficiaryData?.firstName} 
            lastName      ={selectedBeneficiaryData?.lastName}
            phone         ={selectedBeneficiaryData?.phone}
            address       ={selectedBeneficiaryData?.address}
            city          ={selectedBeneficiaryData?.city}
            country       ={selectedBeneficiaryData?.country}
            currency      ={selectedCurrency}
            sendAmount    ={sendingAmount}
            receiveAmount ={receivingAmount}
            feesAmount    ={feesAmount}
            messageNote   ={message}
          />
        )}

        {/* Terms of Remittance Service */}
        <div className="terms-conditions">
          <h3>Terms of Remittance Service:</h3>
          <ol className="terms-list">
            <li>
              ATA2GO INC nor subsequent Transfer company do not disclose any
              non-public personal information about you, except as permitted and
              required by law.
            </li>
            <li>
              ATA2GO INC Assumes no responsibility if (you) the sender has
              mistaken in identifying the destination address.
            </li>
            <li>
              This transaction will register with (FinTRAC) Financial
              Transactions and Reports Analysis Center of Canada.
            </li>
            <li>
              By using this service, you implicitly agree to these terms of
              service. If you disagree, then do not proceed to request this
              remittance service.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default SendRemittanceBox;
