/* File: /src/services/orderBillingApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const orderBillingApi = createApi({
  reducerPath: "orderBillingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "/billings",

    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Billings", "Courses", "Internets"],
  endpoints: builder => ({
    getBillsByUser: builder.query({    query: ({ userId, source }) =>   ({ url: `/getBillsByUser/${userId}`,  method: "GET", params: { source }, }),                       providesTags: ["Billings"] }),
    getAllBillings: builder.query({    query: ({ source }) =>       ({ url: "/",                          method: "GET", params: { source }, }),                       providesTags: ["Billings", "Courses", "internets"] }),
    createInvoice:  builder.mutation({ query: data =>     ({ url: "/",                          method: "POST", body: data }),          invalidatesTags: ["Billings"] }),
    updateInvoice:  builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,             method: "PATCH", body: payload.data }), invalidatesTags: ["Billings"] }),
    healthCheck:    builder.query   ({ query: ({ source }) =>       ({ url: `/health`,                    method: "GET", params: { source }, })        }),
})

});

export const { 
  useHealthCheckQuery,
  useGetBillsByUserQuery,
  useGetAllBillingsQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation, 
} = orderBillingApi;
