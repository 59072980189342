/* REAL - MY SUBSCRIPTIONS - TRANSACTIONS   */
/* File: /src/pages/Subcriptions/Transactions/index.jsx  */
import { useTranslation 
}                                               from 'react-i18next';
import React, { useState }       from "react";
import { Button, 
         Form, 
         Table,
         Tooltip, 
         OverlayTrigger, 
         Modal
        }                                   from "react-bootstrap";
import moment                               from "moment";
import { FaRegEye, 
        }                                   from "react-icons/fa";
import { useNavigate }                      from "react-router-dom";
import { toast }                            from "react-toastify";
import   BillingVehiclePartsModal           from "../../Modal/BillingVehiclePartsModal";
import { useGetTransactionsByUserQuery,
         useDeleteTransactionMutation,
        }                                   from "../../../services/orderTransactionApi";
import {
        useSaveBeneficiaryMutation,
        }                                   from "../../../services/userUserStoreApi";
import   generatePDF, 
        { Resolution, Margin }              from "react-to-pdf";

const SubsPartsQuotes = ({ user }) => {
    const { t } = useTranslation('subsTransactions');
    const Source_Name           = "MY SUBSCRIPTIONS - TRANSACTIONS";
    const navigate                                              = useNavigate();
    // eslint-disable-next-line
    const { data: transactionData , refetch }                   = useGetTransactionsByUserQuery({ id: user?._id, source: Source_Name });
    const transactionCount                                      = transactionData?.data?.[0]?.transactions?.length || 0;
    const [Request_DeleteTransaction]                           = useDeleteTransactionMutation();
    const [userStore_Save]                      = useSaveBeneficiaryMutation();
    const [selectedRow,             setSelectedRow]             = useState({});
    const [showInvoice,             setShowInvoice]             = useState(false);
    const [showDetails,             setShowDetails]             = useState(false);
    const [formArray,               setFormArray]               = useState([]);
    // const [isModified,           setIsModified]              = useState(false);
    const [showCancelQuoteModal,    setShowCancelQuoteModal]    = useState(false);
    const [cancelReason,            setCancelReason]            = useState("");
    const [billDetail,              setBillDetail]              = useState({});

    const STG_BILLING_FILE_NAME                                 = "ata2go-billing";
    // const STG_Date_Format = "YYYY-MM-DD";

    const options = {
        filename:           `${STG_BILLING_FILE_NAME}-${billDetail?.invoiceNumber}.pdf`,
        method:             "save",
        resolution:         Resolution.EXTREME,

        page: {
            margin:         Margin.SMALL,
            format:         "letter",
            orientation:    "portrait",
        },

        canvas: {
            mimeType:       "image/jpeg",
            qualityRatio:   1,
        },

        overrides: {
            pdf:            {  compress: true,  },
            canvas:         {   useCORS: true,  },
        },
    };

    const getTargetElement = () => document.getElementById("container");

    const handleViewStatus = (transaction) => {
        console.log('Selected transaction:', transaction); // Check the transaction data
        setSelectedRow(transaction);
        setFormArray([transaction]); // Set only the clicked transaction
        setShowDetails(true); // Ensure this is set correctly
    };
    


    /*
    const handleFormArrayChange = (id, field, value) => {
        const updatedFormArray = formArray.map(item =>
            item._id === id ? { ...item, [field]: value } : item
        );
        setFormArray(updatedFormArray);
        setIsModified(true);
    };
    */

    // eslint-disable-next-line
    const createAuditAction = (actionDescription) => ({
        agentId: user?._id,
        actionDescription,
        timestamp: new Date()
    });


    const handle_DeleteTransaction = async (id, cancelReason) => {
        try {
            toast.success(`${t('messages.transaction_deleted')}`);
            const res = await Request_DeleteTransaction({ transactionId: id, data: { cancelReason } });
            if (res?.data?.status) {
                toast.success(t('messages.transaction_deleted'));
            } else {
                toast.error(t('messages.transaction_delete_error'));
            }
        } catch (error) {
            toast.error(t('messages.transaction_delete_error'));
        }
        setShowCancelQuoteModal(false);
    };
    

    const getLastSevenDigits = id => id ? id.slice(-7) : "";

    const handle_ShowInvoicePDF = () => {
        const transactionItems = formArray.map(part => ({
            year:       part.year,
            brand:      part.brand,
            model:      part.model,  
            service:    part.partName,
            price:      part.price,
            quantity:   part.quantity,
            subTotal:   part.subTotal,
        }));

        const subTotal      = transactionItems.reduce((acc, part) => acc + parseFloat(part.subTotal), 0);
        const taxTPS        = subTotal * 0.05;
        const taxTVQ        = subTotal * 0.09975;
        const totalPrice    = subTotal + taxTPS + taxTVQ;

        setBillDetail({
            invoiceNumber:  "TRANS",
            date:           selectedRow?.createdAt,
            totalPrice:     totalPrice,
            userAddress:    user?.address,
            userCity:       user?.city,
            userPostCode:   user?.postCode,
            userState:      user?.state,
            firstName:      user?.firstName,
            lastName:       user?.lastName,
            items:          transactionItems,
            Total:          subTotal,
            taxTPS:         taxTPS,
            taxTVQ:         taxTVQ,
            
            companyName:    user?.companyName,

        });

        setSelectedRow({});
        setShowInvoice(true);
    };

    const downloadPdf = async () => {
        generatePDF(getTargetElement, options).then(() => {
            toast.success(`Transaction Invoice #: ${billDetail?.invoiceNumber} ready`);
        });
    };
    
    const adjustTimestampForTimezone = (timestamp) => {
        const date = new Date(timestamp);
        const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return adjustedDate;
    };

    /* Section 01.0 - handle_SaveBeneficiary   */
    const handle_SaveBeneficiary = async () => {
        const data_Beneficiary = {
            firstName:      selectedRow.firstName,
            lastName:       selectedRow.lastName,
            address:        selectedRow.address,
            city:           selectedRow.city,
            country:        selectedRow.country,
            phone:          selectedRow.phone,
            currency:       selectedRow.currency,
            userId:         user?._id
        };
    
        const res_SaveBeneficiary = await userStore_Save(data_Beneficiary);
        if (res_SaveBeneficiary.data?.status) {
            toast.success(res_SaveBeneficiary?.data?.message);
        } else {
            toast.error(res_SaveBeneficiary?.data?.message);
        }
    };

    return (
        <div className="manage-transaction-page">
            <div className="transaction-container">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mt-4">
                        <h4 className="mb-0 pb-0" style={{ color: 'blue' }}>
                            {t('titles.transaction_history')}: ({transactionCount})
                        </h4>
                    </div>
                    <div className="d-flex">
                        {/* BUTTON - BILLING */}
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-billing">{t('tooltips.billing')}</Tooltip>}>
                            <Button variant="primary" className="ms-2" onClick={() => navigate("/settings?tab=billing")}>
                                {t('titles.billing')}
                            </Button>
                        </OverlayTrigger>

                        {/* BUTTON - NEW REMITTANCE */}
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-new-remittance">{t('tooltips.new_remittance')}</Tooltip>}>
                            <Button variant="primary" className="ms-2" onClick={() => navigate("/subscriptions?tab=remittance")}>
                                {t('titles.new_remittance')}
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>



                <Table responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('table_headers.actions')}</th>
                            <th>{t('table_headers.req_nb')}</th>
                            <th>{t('table_headers.status')}</th>
                            <th>{t('table_headers.first_name')}</th>
                            <th>{t('table_headers.last_name')}</th>
                            <th>{t('table_headers.address')}</th>
                            <th>{t('table_headers.city')}</th>
                            <th>{t('table_headers.country')}</th>
                            <th>{t('table_headers.phone')}</th>
                            <th>{t('table_headers.send_amount')}</th>
                            <th>{t('table_headers.receive_amount')}</th>
                            <th>{t('table_headers.fees')}</th>
                            <th>{t('table_headers.total')}</th>
                        </tr>
                    </thead>
                        <tbody>
                            {transactionData?.data?.map((d, index) => (
                                <React.Fragment key={`${d._id}-${index}`}>
                                    {d?.transactions
                                        ?.slice() 
                                        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) 
                                        .map((transaction, transIndex) => (
                                            <tr key={`${transaction._id}-${transIndex}`}>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center gx-4">
                                                        <span className="action-icon update" onClick={() => handleViewStatus(transaction)}>
                                                            <span className="ms-2"> <FaRegEye /> </span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{transaction.requestNumber}</td>
                                                <td>{transaction.status}</td>
                                                <td>{transaction.firstName}</td>
                                                <td>{transaction.lastName}</td>
                                                <td>{transaction.address}</td>
                                                <td>{transaction.city}</td>
                                                <td>{transaction.country}</td>
                                                <td>{transaction.phone}</td>
                                                <td>{transaction.sendAmount?.toFixed(2)}</td>
                                                <td>{transaction.receiveAmount?.toFixed(2)} {transaction.currency}</td>
                                                <td>{transaction.feesAmount?.toFixed(2)}</td>
                                                <td>{transaction.totalAmount?.toFixed(2)}</td>
                                            </tr>
                                        ))}
                                </React.Fragment>
                            ))}
                        </tbody>

                </Table>

                {/* TRANSACTION DETAILS */}
                {showDetails && selectedRow?._id && (
                    <>
                        <h4 className="mt-5" style={{ color: 'blue' }}>
                            {t('titles.transaction_details')} - {selectedRow?.requestNumber}
                        </h4>
                    
                        <div key={selectedRow._id} className="form-array-border" style={{ marginBottom: "10px" }}>
                            <div className="row">
                                {/* TRANSACTION # */}
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor={`subSystem-partName-${selectedRow._id}`} className="form-label">
                                        {t('labels.receiver_name')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`subSystem-partName-${selectedRow._id}`}
                                        value={`${selectedRow.firstName} ${selectedRow.lastName}`}
                                        style={{ backgroundColor: "#f0f0f0", width: "100%" }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                {/* ADDRESS */}
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor={`address-${selectedRow._id}`} className="form-label">
                                        {t('labels.address')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`address-${selectedRow._id}`}
                                        value={`${selectedRow.address}, ${selectedRow.city}, ${selectedRow.country}`}
                                        style={{ backgroundColor: "#f0f0f0", width: "100%" }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                {/* SEND AMOUNT */}
                                <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                    <label htmlFor={`quantity-${selectedRow._id}`} className="form-label">
                                        {t('labels.send_amount')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`quantity-${selectedRow._id}`}
                                        value={selectedRow.sendAmount}
                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                    />
                                </div>

                                {/* RECEIVE AMOUNT */}
                                <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                    <label htmlFor={`price-${selectedRow._id}`} className="form-label">
                                        {t('labels.receive_amount')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`price-${selectedRow._id}`}
                                        value={selectedRow.receiveAmount}
                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                    />
                                </div>

                                {/* FEES */}
                                <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                    <label htmlFor={`qst-${selectedRow._id}`} className="form-label">
                                        {t('labels.fees')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`qst-${selectedRow._id}`}
                                        value={selectedRow.feesAmount}
                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                    />
                                </div>

                                {/* FX FEES */}
                                <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                    <label htmlFor={`gst-${selectedRow._id}`} className="form-label">
                                        {t('labels.fx_fees')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`gst-${selectedRow._id}`}
                                        value={selectedRow.fxFeesAmount}
                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                    />
                                </div>

                                {/* TOTAL */}
                                <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                    <label htmlFor={`totalAmount-${selectedRow._id}`} className="form-label">
                                        {t('labels.total')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`totalAmount-${selectedRow._id}`}
                                        value={selectedRow.totalAmount}
                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                {/* REQUESTED DATE */}
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor={`price-${selectedRow._id}`} className="form-label">
                                        {t('labels.requested_date')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`price-${selectedRow._id}`}
                                        value={moment(adjustTimestampForTimezone(selectedRow.createdAt)).format("YYYY-MM-DD, HH:mm:ss")}
                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                {/* UPDATED DATE */}
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor={`price-${selectedRow._id}`} className="form-label">
                                        {t('labels.updated_date')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`price-${selectedRow._id}`}
                                        value={moment(adjustTimestampForTimezone(selectedRow.timestamp)).format("YYYY-MM-DD, HH:mm:ss")}
                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                {/* STATUS */}
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor={`price-${selectedRow.status}`} className="form-label">
                                        {t('labels.status')}
                                    </label>
                                    <input
                                        disabled
                                        className="mb-2"
                                        type="text"
                                        id={`${selectedRow._id}-${selectedRow.status}`}
                                        value={selectedRow.status}
                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                    />
                                </div>
                            </div>
                        </div>

                      
           
                        <div className="d-flex justify-content-center align-items-center">
                            <Button className="btn btn-danger me-2" onClick={() => setSelectedRow({})}>
                                {t('buttons.close')}
                            </Button>
                            <Button className="btn btn-secondary ms-2" onClick={() => handle_SaveBeneficiary(true)}>
                                {t('buttons.save_beneficiary')}
                            </Button>
                            <Button className="btn btn-secondary ms-2" onClick={() => handle_ShowInvoicePDF("priceEmail")}>
                                {t('buttons.view_invoice')}
                            </Button>
                            <Button className="btn danger-btn ms-2" onClick={() => setShowCancelQuoteModal(true)}>
                                {t('buttons.cancel')}
                            </Button>
                        </div>

                    </>
                )}
                

                {/* Cancel Transaction Modal */}
                <Modal show={showCancelQuoteModal} onHide={() => setShowCancelQuoteModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('titles.cancel_transaction')}: {selectedRow?._id ? selectedRow._id.slice(-7) : ''}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="cancelReason">
                                <Form.Label>{t('modals.cancel_reason_prompt')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder={t('placeholders.enter_reason')}
                                    value={cancelReason}
                                    onChange={(e) => setCancelReason(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowCancelQuoteModal(false)}>
                            {t('modals.keep_transaction')}
                        </Button>
                        <Button
                            className="btn danger-btn ms-2"
                            disabled={!cancelReason.trim()}
                            onClick={() => handle_DeleteTransaction(selectedRow._id, cancelReason)}
                        >
                            {t('modals.delete_transaction')}
                        </Button>
                    </Modal.Footer>
                </Modal>                

                <BillingVehiclePartsModal
                    show={showInvoice}
                    handleClose={() => setShowInvoice(false)}
                    billDetail={billDetail}
                    userId={getLastSevenDigits(user?._id)}
                    downloadPdf={downloadPdf}
                />
      </div>
    </div>
  );
};

export default SubsPartsQuotes;
