import "./styles.css";
import React, { useState }  from "react";
import { FaInfoCircle }     from "react-icons/fa";
import { TiUserAdd }        from "react-icons/ti";
import { IoIosSend }        from "react-icons/io";
import { MdOutlineHistory } from "react-icons/md";
import { useNavigate }      from "react-router-dom";
import AddBeneficiaryBox    from "./AddBeneficiary";
import SendRemittanceBox    from "./SendRemittance";
import TransactionHistory   from "./TransactionHistory";

const RemittancePageTab = ({ user }) => {
  const navigate                                              = useNavigate();
  const [currentView, setCurrentView] = useState("main");
  const handleAddBeneficiaryClick     = () => setCurrentView("addBeneficiary");
  const handleSendRemittanceClick     = () => setCurrentView("sendRemittance");
  const handleTransactionHistoryClick = () => setCurrentView("transactionHistory");
  const handleBackClick               = () => setCurrentView("main");
  
  if (!user) {
    return <div>Please log in to access the remittance page.</div>;
  }

  return (
    <>
      {/* Add Title and Buttons */}
      <div className="header-container">
        <h1 className="subscriptions-title">Remittances</h1>
        <div className="button-group">
          <button className="action-button" onClick={() => navigate("/settings?tab=documents")} >Documents</button>
          <button className="action-button" onClick={() => navigate("/settings?tab=billing")} >Billing</button>
        </div>
      </div>

      <div className="subscription-page-tab">
        {currentView === "main" && (
          <>
            <div className="header">
              <span className={`online-indicator ${true ? "online" : "offline"}`}></span>

              <h2 className="heading">
                Verification Status:
                <span className="tooltip">
                  <FaInfoCircle className="info-icon" />
                  <span className="tooltip-content">  Choose from the options below. </span>
                </span>
              </h2>
            </div>

            <div className="button-container">
              <button className="big-button-1" onClick={handleAddBeneficiaryClick}>      Edit Beneficiary      <TiUserAdd className="icon" />
              </button>

              <button className="big-button-2" onClick={handleSendRemittanceClick}>      Send Remittance      <IoIosSend className="icon" />
              </button>

              <button className="big-button-3" onClick={handleTransactionHistoryClick}>  Transaction History  <MdOutlineHistory className="icon" />
              </button>
            </div>

            <div className="terms-conditions">
              <h3>Terms of Remittance Service:</h3>
              <ol className="terms-list">
                <li>
                  ATA2GO INC nor subsequent Transfer company do not disclose any
                  non-public personal information about you, except as permitted
                  and required by law.
                </li>
                <li>
                  ATA2GO INC assumes no responsibility if (you) the sender has
                  mistaken in identifying the destination address.
                </li>
                <li>
                  This transaction will register with (FinTRAC) Financial
                  Transactions and Reports Analysis Center of Canada.
                </li>
                <li>
                  By using this service, you implicitly agree to these terms of
                  service. If you disagree, then do not proceed to request this
                  remittance service.
                </li>
              </ol>
            </div>
          </>
        )}

        {currentView === "addBeneficiary" && (     <AddBeneficiaryBox   user={user} onBackClick={handleBackClick} />        )}
        {currentView === "sendRemittance" && (     <SendRemittanceBox   user={user} onBackClick={handleBackClick} />        )}
        {currentView === "transactionHistory" && ( <TransactionHistory  user={user} onBackClick={handleBackClick} />        )}
      </div>
    </>
  );
};

export default RemittancePageTab;
