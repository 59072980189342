import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const userDocumentApi = createApi({
    reducerPath: "userDocumentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/documents",

        prepareHeaders: (headers, { getState }) => {
            const token = getTokenFromLocalStorage();
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),

    tagTypes: ["Documents"],
    endpoints: builder => ({
        getDocumentById: builder.query({ query: ({ id, source }) => ({ url: `/${id}`,  method: "GET", params: { source }, responseHandler: (response) => response.blob(), }),
                        transformResponse: (response, meta) => {  return { blob: response,   contentType: meta.response.headers.get("Content-Type"), }; },
                        providesTags: ["Documents"], }),

    
        getAllDocuments: builder.query({ query: (params) => ({ url: `/getAllDocuments`, method: "GET", params }), providesTags: ["Documents"] }),

        updateDocumentStatus: builder.mutation({ query: ({ docId, source, ...data }) => ({ url: `/userDocuments/${docId}/status`, method: "PATCH", body: { ...data, source } }) }),
        
        retrieveDocumentListByUserId: builder.mutation({ query: (userId) => ({ url: `/list`, method: "POST", body: { userId } }), providesTags: ["Documents"] }),
    
        deleteDocument: builder.mutation({ query: (payload) => ({ url: `/`, method: "DELETE", body: payload }), invalidatesTags: ["Documents"] }),
        
        updateDocument: builder.mutation({ query: (payload) => ({ url: `/${payload.id}`, method: "PATCH", body: payload.data, headers: (headers) => { if (!(payload.data instanceof FormData)) { headers.set("Content-Type", "application/json"); } return headers; } }), invalidatesTags: ["Documents"] }),
        

    }),
});

export const {
    useGetDocumentByIdQuery,
    useUpdateDocumentMutation,
    useDeleteDocumentMutation,
    useRetrieveDocumentListByUserIdMutation,
    useGetAllDocumentsQuery,
    useUpdateDocumentStatusMutation,
} = userDocumentApi;
