/* REAL - MY SUBSCRIPTIONS - TRANSCRIPT */
/* Section 01.00 - Imports */
import                                          "./style.css";
import { 
       // useEffect,
        useState 
        }                                       from "react";
import { 
        Button 
        }                                       from "react-bootstrap";
import { 
        Images 
        }                                       from "../../../config/images";
import { 
        toast 
        }                                       from "react-toastify";

import { 
        handleTranslateClick 
        }                                       from './apiOpenaiCalls';

import { 
        useTranscribeAudioToTextMutation,
        useRequestPresignedUrlMutation, 
        useGetTranscriptionStatusMutation,
        }                                       from "../../../services/svcTranscriptApi";

import axios                                    from 'axios';
/* import { 
        skipToken 
        }                                       from '@reduxjs/toolkit/query/react';

*/
/* Section 02.00 - Main Component */
const SubsInternet = () => {
    const Source_Name                                   = "MY SUBSCRIPTIONS - TRANSCRIPT";
    const [transcriptionResult, setTranscriptionResult] = useState("");
    const [selectedLanguage,    setSelectedLanguage]    = useState("en"); // Default language: English
    const [file,                setFile]                = useState(null);
    const [jobIds,              setJobIds]              = useState([]);
//    const [selectedJobId,       setSelectedJobId]       = useState(null);
    // const [statusCheckTriggered, setStatusCheckTriggered] = useState(false); // NEW STATE


    const [requestPresignedUrl]                         = useRequestPresignedUrlMutation();
    const [transcribeAudioToText, { isLoading }]        = useTranscribeAudioToTextMutation();
    const [getTranscriptionStatus]                      = useGetTranscriptionStatusMutation();

     
    /* Section 02.01 - State Variables */
    const [fileInfo, setFileInfo] = useState({
        name: 'Select an audio file.',
        size: '',
        duration: '',
        status: '',
        cost: ''
    });

    
    
    
    
    


    /* Section 02.02 - Handle File Change */
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const fileSize = `${(selectedFile.size / 1024 / 1024).toFixed(2)} MB`; 
            const fileName = selectedFile.name;
            const fileReader = new FileReader();

            fileReader.onload = () => {
                const audio = new Audio(fileReader.result);
                audio.onloadedmetadata = () => {
                    const duration = new Date(audio.duration * 1000).toISOString().substr(11, 8);
                    setFileInfo({
                        name: fileName,
                        size: fileSize,
                        duration: duration,
                        status: 'Ready to Submit',
                        cost: '$0.001' // Cost calculation can be modified based on actual logic
                    });
                };
            };

            setFile(selectedFile); // Save the selected file to state
            fileReader.readAsDataURL(selectedFile);
        }
    };

    /* Section 02.03 - Transcription Handler */
    const handleTranscribe = async () => {
        if (!file) {
            toast.error("Please select an audio file first.");
            return;
        }
    
        try {
            // Step 1: Request Pre-signed URL from the backend
            const fileName              = file.name;
            const fileType              = file.type;
            const { uploadUrl, s3Key }  = await requestPresignedUrl({ fileName, fileType, source: Source_Name }).unwrap();
            
            // Step 2: Upload the file to S3 using the pre-signed URL with Axios
            await axios.put(uploadUrl, file, {    headers: {  'Content-Type': fileType    }   });
            toast.success("File uploaded successfully");
    
            // Step 3: Call the transcription API with the S3 key
            const result = await transcribeAudioToText({ s3Key, selectedLanguage, source: Source_Name }).unwrap();
 
    
            if (result?.status) {
                 // Step 4: Add s3Key to jobIds state instead of jobId
                setJobIds(prevJobIds => [...prevJobIds, { s3Key, status: 'Queued', transcriptionUrl: null }]);
                toast.success(`Transcription requested for ${file.name}`);


                // Set the transcription result in the state to display it in the textarea
                                  // setTranscriptionResult(transcriptionText);
            } else {
                toast.error(result?.message || `Failed to queue ${file.name}`);
            }
    
        } catch (error) {
            console.error("Error during file upload or transcription", error);
            toast.error("Failed to upload file or transcribe");
        }
    };


    
/* Section 02.04 - Check Transcription Status */
const checkStatus = async (s3Key) => {
    if (!s3Key) {
        toast.error("No s3Key provided for checking status");
        return;
    }

    try {
        const statusResponse = await getTranscriptionStatus({ s3Key, source: Source_Name }).unwrap();
        toast.success("Checking transcription status");

        // Update job status
        const updatedJobIds = jobIds.map(job =>
            job.s3Key === s3Key ? { ...job, status: statusResponse.status, transcriptionUrl: statusResponse.transcriptionUrl } : job
        );

        setJobIds(updatedJobIds);
    } catch (error) {
        console.error("Error fetching transcription status:", error);
        toast.error("Failed to check transcription status");
    }
};
    
    const handleViewTranscription = async (transcriptionUrl) => {
        try {
            const response          = await fetch(transcriptionUrl);
            const transcriptionText = await response.text();  // Fetching the content as text
            setTranscriptionResult(transcriptionText);        // Setting it in the state to display
        } catch (error) {
            console.error('Error fetching transcription text:', error);
            toast.error("Failed to load transcription");
        }
    };
    

    return (
        <>
            {/* Section 03.00 - Header */}
            <h1 className="title">
                <img src={Images.AudioLogo} alt="Audio Logo" className="audio-logo" /> 
                Transcriptor 0305
            </h1>

            {/* Section 04.00 - File Info */}
            <div className="info-section">
                <h2>Audio File Information</h2>
            </div>
            <div className="info-grid-file">
                {renderFileInfo("Processing File:", fileInfo.name)}
                {renderFileInfo("File Size:",       fileInfo.size)}
                {renderFileInfo("Audio Duration:",  fileInfo.duration)}
                {renderFileInfo("Status:",          fileInfo.status)}
                {renderFileInfo("Est. Cost:",       fileInfo.cost)}
            </div>

            {/* Section 05.00 - Form for File Input & Actions */}
            <form id="fileForm" style={{ marginTop: '20px' }}>
                <div className="button-container">
                    <Button type="button" className="blue-button button-space" 
                        onClick={() => document.getElementById('audioFile').click()} 
                        aria-label="Choose audio file for upload">
                        Choose Audio File
                    </Button>
                    <input type="file" id="audioFile" name="audioFile" 
                           style={{ display: "none" }} onChange={handleFileChange} required />
                    
                    {/* Language Selector */}
                    <select id="SelectLanguage" aria-label="Select original audio language" className="select-input" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                        <option value="fr">French</option>
                        <option value="ht">Haitian</option>
                        <option value="ja">Japanese</option>
                    </select>
                    
                    {/* Action Buttons */}
                    <Button id="transcribeButton" className="green-button button-space" 
                        aria-label="Transcribe audio file" onClick={handleTranscribe} disabled={isLoading}>
                        {isLoading ? "Transcribing..." : "Transcribe"}
                    </Button>
                    <Button id="translateButton" className="green-button button-space" 
                        aria-label="Translate audio file" onClick={handleTranslateClick}>
                        Translate to English
                    </Button>
                </div>
            </form>

            {/* Section 06.00 - Transcription Output */}
            <div className="info-section">
                <h2><span id="transcriptionLabel">Audio Transcription</span></h2>
            </div>
            <div className="transcription-and-info-wrapper">
                <div className="output-container">
                    <textarea   id="outputBox"  rows="8"    cols="110"  style={{ overflow: "auto" }} 
                                value={transcriptionResult}     aria-label="Transcription output"> 
                    </textarea>
                    
                    {/* Copy and Cancel Buttons */}
                    <div className="copy-button-container">
                        <Button id="copyButton" className="blue-button button-space" aria-label="Copy transcription text">
                            Copy
                        </Button>
                        <Button type="button" className="choose-file blue-button button-space" 
                                onClick={() => document.getElementById('textFile').click()} aria-label="Choose text file to speak">
                            LoadText
                        </Button>
                        <input type="file" id="textFile" name="textFile" style={{ display: "none" }} required />
                        <Button id="cancelButton" className="red-button button-space" aria-label="Cancel Request">
                            Cancel Request
                        </Button>
                    </div>

                    {/* Additional Info */}
                    <div className="info-grid">{renderTranscriptionInfo()}</div>
                </div>
            </div>

            {/* Section 07.00 - Additional Actions */}
            <div className="button-container">
                <Button id="checkTokenButton" className="green-button button-space" aria-label="Analyze number of token limit and Cost">Token</Button>
                <Button id="parseButton" className="green-button button-space" aria-label="Parse transcription text">Parse</Button>
                
                {/* Select Voice and Speak */}
                <select id="SelectVoice" aria-label="Select voice for speech synthesis">{renderVoiceOptions()}</select>
                <Button id="speakButton" className="green-button button-space" aria-label="Speak transcription text">Speak</Button>
                
                {/* Select Image Size and Imagize */}
                <select id="SelectImageSize" aria-label="Select image size for image generation">{renderImageSizeOptions()}</select>
                <Button id="imagizeButton" className="green-button button-space" aria-label="Convert transcription text to image">Imagize</Button>
                <Button id="downloadCaptionsButton" className="green-button button-space" aria-label="Download YouTube captions">YT Capt</Button>
                <Button id="logsButton" className="green-button button-space" aria-label="Download Logs">Logs</Button>
            </div>

            {/* Section 08.00 - Consumption & Results */}
            <div className="bottom-container">
                <div className="info-section">
                    <h2>Consumption & Results</h2>
                </div>
                <div className="results-container">
                    {jobIds.length > 0 ? (
                        <table cellPadding="10" cellSpacing="10"> {/* Adding padding and spacing */}
                            <thead>
                                <tr>
                                    <th style={{ padding: '10px' }}>Job#</th> {/* Centering the Job ID column */}
                                    <th style={{ padding: '10px' }}>Status</th>
                                    <th style={{ padding: '10px' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobIds.map((job) => (
                                    <tr key={job.s3Key}>  {/* Use s3Key as key */}
                                        <td style={{ padding: '10px', textAlign: 'center' }}>{job.s3Key}</td> {/* Display s3Key */}
                                        <td style={{ padding: '10px' }}>{job.status}</td>
                                        <td style={{ padding: '10px' }}> {/* Adding padding */}
                                            <Button onClick={() => checkStatus(job.s3Key)}>Check Status</Button> {/* Pass s3Key */}
                                            {job.status === 'completed' && job.transcriptionUrl && (
                                                <Button 
                                                    onClick={() => handleViewTranscription(job.transcriptionUrl)} 
                                                    style={{ marginLeft: '10px' }} // Adding space between the buttons
                                                >
                                                    View Transcription
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No jobs yet.</p>
                    )}
                </div>

            </div>


        </>
    );
};

/* Section 08.00 - Helper Functions */
const renderFileInfo = (label, value) => (
    <>
        <div className="info-label" style={{ textAlign: 'right' }}>{label}</div>
        <div className="info-value">{value}</div>
    </>
);

const renderTranscriptionInfo = () => (
    <>
        <div className="info-label">Characters:</div> 
        <div className="info-value" id="infoCharacters"></div>
        <div className="info-label2">Speak Cost:</div> 
        <div className="info-value2" id="infoSpeakCost"></div>
        {/* Add other information fields */}
    </>
);

const renderVoiceOptions = () => (
    <>
        <option value="alloy">Alloy</option>
        <option value="echo">Echo</option>
        <option value="fable">Fable</option>
        {/* Add more options */}
    </>
);

const renderImageSizeOptions = () => (
    <>
        <option value="1024x1024">1024x1024</option>
        <option value="512x512">512x512</option>
        <option value="256x256">256x256</option>
    </>
);

export default SubsInternet;
