import {React,useEffect} from 'react';
import TermsServicesBanner from './TermsServicePageBanner/TermsServiceBanner';
import {
    TermsContainer,
//    Title,
    SectionTitle,
    Paragraph,
    List,
    ListItem,
} from './TermsServicePageStyle'; // Importing styled components
import Footer from '../Home/FooterSection/Footer';
import { Table } from './Table';

const TermsServicePage = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    


    return (
        <>
        <TermsServicesBanner />
            
            <TermsContainer>

                <Paragraph>Last Updated: [2023 August 16]</Paragraph>
                <Paragraph>
                       These Terms of Service (&quot;Terms&quot;) constitute a legal agreement between you (&quot;User,&quot;
                    “Affiliate”, &quot;you,&quot; or &quot;your&quot;) and ATA2GO (&quot;ATA2GO,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), governing
                    your use of the ATA2GO website (&quot;Website&quot; or &quot;Service&quot;). The conditions of use (the
                    “Conditions”) govern the use of the ATA2GO website, as well as all transactions
                    conducted online.
                    Web browsing, and log-in with a password, when necessary, implies, on one hand, that
                    the user agrees to be bound by the Conditions, and on the other hand, that he consents
                    to the content of the Protection of personal information section, and any changes that
                    may be made from time to time. The refusal to consent implies that the user refrains
                    from using the ATA2GO’s website.
                    ATA2GO reserves the right to modify the Conditions and those set forth in its Protection
                    of personal information section at any time and without prior notice, by publishing the
                    relevant modifications directly on its website or by sending notifications to its users.
                    By accessing or using the ATA2GO website, you acknowledge and agree to comply
                    with these Terms. If you do not agree to these Terms, please refrain from using the
                    ATA2GO website.
                </Paragraph>

                <SectionTitle>1. Acceptance of Terms</SectionTitle>
                <Paragraph>
                    By using the ATA2GO website, you acknowledge that you have read, understood, and
                    agreed to the following Terms of Service, our Privacy Policy, and any other applicable
                    policies and guidelines. These Terms form a legally binding agreement between you
                    and ATA2GO. Please read them carefully before using our services.
                </Paragraph>

                <SectionTitle>2. User Conduct</SectionTitle>
                <Paragraph>
                    When using ATA2GO website, you are expected to adhere to a high standard of
                    conduct.
                    a) You agree to use the ATA2GO website only for lawful purposes and in compliance
                    with all applicable laws and regulations. You are expressly prohibited from engaging in
                    any activities or conduct that may hinder, compromise, disrupt, damage, overload, or
                    impair the functionality of the Website or interfere with other users&#39;, or affiliates’
                    enjoyment of the Service. Prohibited conduct includes, but is not limited to:
                </Paragraph>
                <List>
                    <ListItem>Posting or transmitting unauthorized or unsolicited promotional materials.</ListItem>
                    <ListItem>Impersonating another person or entity.</ListItem>
                    <ListItem>Uploading, posting, or distributing malware, viruses, or harmful software.</ListItem>
                    <ListItem>Distributing malware or destructive files.</ListItem>
                    <ListItem>Impersonating others.</ListItem>
                    <ListItem>Engaging in harassment.</ListItem>
                    <ListItem>interfere with the security of, or otherwise abuse these websites or any of its Services,
                        systems, servers or networks, or interfere with the security of affiliated, or linked
                        websites</ListItem>
                    <ListItem> use robots, spiders, automatic devices or manual processes to monitor or copy
                        webpages or the Content of this site, unless permitted by robots.txt file and solely to
                        index the websites in search engines</ListItem>
                    <ListItem> transmit spam, chain letters or any other type of unsolicited mass email</ListItem>
                    <ListItem> attempt to obtain unauthorized access to the sites or to restricted-access parts of the
                        site.</ListItem>
                    <ListItem> Any other such related behavior.</ListItem>
                </List>
                <Paragraph>The user agrees that he is solely liable for any actions and communications undertaken
                    or transmitted to or for his account and agrees to comply with all applicable laws.
                    ATA2GO cannot be held liable for any damage whatsoever sustained by anyone in this
                    respect.</Paragraph>

                <SectionTitle>3. Protection of Confidentiality of Your Password</SectionTitle>
                <Paragraph>
                    The user must choose a password that gives him secure access to Services provided
                    on the Website. The confidentiality of the password is essential to the protection and
                    security of personal information and to maintain the privacy of operations performed
                    online.
                    It is the responsibility of the user to protect the confidentiality of his password. The user
                    must not allow anyone to use it and must take all necessary measures to ensure
                    maximum security conditions. Moreover, it is the responsibility of the user to inform
                    ATA2GO of any unauthorized use of his password.
                    ATA2GO cannot, under any circumstances, be held liable for any unauthorized use of a
                    password and for any damage whatsoever resulting from failure to comply with any of
                    the obligations set forth in this paragraph.
                    We aim to maintain a safe and respectful environment for all users.
                </Paragraph>

                <SectionTitle>4. Intellectual Property</SectionTitle>
                <Paragraph>Unless otherwise indicated, the entire content, including logos, text, graphics, images,
                    all trademarks, service trademarks, brand names used on the site, software and
                    materials displayed on the ATA2GO website, are protected by copyright, trademark and
                    other intellectual property laws, and patents owned by ATA2GO or granted to it.
                    Company names and logos used on ATA2GO’s website may be subject to trademarks
                    (including registered trademarks), owned by ATA2GO. They may not be copied,
                    imitated or used, in whole or in part, otherwise than in compliance with the standards
                    set out in the Guide of Standards. Other product and company names mentioned on
                    ATA2GO’s website may also be trademarks of their respective owners.
                    You are granted a limited, non-exclusive license to access and use these materials
                    solely for personal, non-commercial purposes, provided that all such copies or

                    reproductions includes copyright notices or other notice of ownership of the Content and
                    are not altered in any way.
                    You may not reproduce, distribute, modify, create derivative works of, publicly display,
                    or use any materials from the ATA2GO website without explicit permission.</Paragraph>

                <SectionTitle>5. Privacy</SectionTitle>
                <Paragraph>Protecting your privacy and personal information is of utmost importance to us. This
                    information, whether collected or provided, Our Privacy Policy outlines how we collect,
                    use, retain, discard, process, protect and safeguard your personal information, in
                    accordance with applicable law. By using ATA2GO, you agree to the terms outlined in
                    the Privacy Policy and understand how your data is handled when you interact with our
                    services.</Paragraph>

                <SectionTitle>6. Termination</SectionTitle>
                <Paragraph>
                    ATA2GO reserves the right, at any time, to modify, to suspend, restrict, interrupt or
                    terminate your access to the website, temporarily or permanently, in whole or in part, at
                    our sole discretion and without notice, for any reason, including, but not limited to, a
                    breach of these Terms or any applicable law, non-compliance with one or more of the
                    Conditions or engaging in any conduct that we deem harmful to our community or the
                    website.
                    We will make reasonable efforts to provide notice before taking such action, but we may
                    do so without prior warning if necessary, to protect the integrity, quality and standards of
                    our platform, or any other reasons.
                    ATA2GO cannot be held liable to any person for any damage whatsoever that may
                    result from termination of access to the Services.
                </Paragraph>

                <SectionTitle>7. Disclaimer of Warranty</SectionTitle>
                <Paragraph>The ATA2GO website and its contents are provided &quot;as is&quot; and &quot;as available,&quot; without
                    any representations, warranties and conditions of any kind, whether express, implied OR
                    ARISING FROM THE LAW OR COMMERCIAL USAGE TO THE EXTENT PERMITTED BY LAW.
                    ATA2GO undertakes to take all necessary measures so that the Content of the
                    websites is as reliable as possible. However, the Content may contain inaccuracies or
                    typographical errors.
                    We make no warranties or representations regarding the accuracy, reliability, relevance
                    or completeness of the content, the Services, or the information available on the website, or of
                    the information it receives or that is received by users and affiliates as part of an online transaction.
                    ATA2GO reserves the right, in its absolute discretion, to correct any error or omission found on its
                    websites.
                    Your use of ATA2GO is at your own risk, and we disclaim any and all warranties,
                    including but not limited to implied warranties of merchantability, fitness for a particular
                    purpose, and non-infringement.</Paragraph>

                <SectionTitle>8. Links to third party websites and Content</SectionTitle>
                <Paragraph>This website may, from time to time, contain documents, data or information provided,
                    posted or offered by third parties. ATA2GO does not take responsibility for these
                    documents, data or information.
                    In addition, it is not liable for the Content and information found on other websites made
                    available to users via hyperlinks. Those other websites are governed by their own
                    conditions of use and the user assumes the risks related to their use.
                    ATA2GO cannot be held liable to any person for any damage whatsoever regarding
                    third party documents, data, or information.</Paragraph>

                <SectionTitle>9. Online transactions</SectionTitle>
                <Paragraph>The user who accesses the Services in order to conduct an online transaction may be
                    governed by directives specific to certain Services, which would then be communicated.
                    ATA2GO reserves the right to modify such directives at any time without prior notice. It
                    is therefore recommended to consult them on a regular basis.
                    Legal effect: To the extent applicable, every transaction conducted online using a
                    password and a personal user or affiliate number or other unique identifier constitutes
                    the user, or affiliate, signature and has the same legal value and effect as if the
                    transaction had been executed on paper and personally signed.</Paragraph>


                <SectionTitle>10. Limitation of Liability</SectionTitle>
                <Paragraph>Under no event or circumstances shall ATA2GO or its affiliates be liable for any indirect,
                    consequential, incidental, special, punitive, or exemplary damages, arising from your
                    use of the website. This includes but not limited to loss of profits, losses incurred due to
                    service interruptions, errors, or data loss, or goodwill, arising out of or in connection with
                    your use of the ATA2GO website.</Paragraph>

                <SectionTitle>11. Governing Law and Jurisdiction</SectionTitle>
                <Paragraph>These Terms of Service are governed by and construed in accordance with the laws of
                    Canada, specifically the Province of Quebec. Any disputes arising from or related to
                    these Terms shall be subject to the exclusive jurisdiction of the courts located in
                    Quebec. You agree to submit to the personal jurisdiction of these courts.</Paragraph>

                <SectionTitle>12. Changes to Terms</SectionTitle>
                <Paragraph>ATA2GO reserves the right to modify or update these Terms at any time. We will make
                    reasonable efforts to notify you of significant changes. Continued use of the ATA2GO
                    website after changes to the Terms constitutes your acceptance of the revised Terms.</Paragraph>

                <SectionTitle>13. Entire Agreement</SectionTitle>
                <Paragraph>These Terms of Service represent the entire agreement between you and ATA2GO
                    regarding your use of the services and the content of this website and supersede any
                    prior agreements or understandings. This document may be updated periodically, it is
                    important to regularly review this document for updates or changes. Thank you for
                    choosing ATA2GO as your service provider.</Paragraph>

                <SectionTitle>14. Contact Us</SectionTitle>
                <Paragraph> If you have any questions, concerns, or feedback regarding these Terms or the
                    ATA2GO website, please don&#39;t hesitate to contact our dedicated support team
                    at <a href="mailto:support@ata2go.com">support@ata2go.com</a>. We are here to assist you and address any inquiries you may
                    have.</Paragraph>

                    
            </TermsContainer> 

            <Table />
            <Footer /> 
        </>
    );
};

export default TermsServicePage;
