/* REAL - HOME PAGE - SERVICE - INTERNET */
import "../servicesStyle.css";
import { 
    useTranslation 
    }                       from 'react-i18next';
import { 
        Tooltip, 
        OverlayTrigger 
       }                                                    from "react-bootstrap";
import { useNavigate }                                      from "react-router-dom";
import { useContext,
         useState,
         useEffect,
         startTransition,
       }                                                    from "react";
// import { toast }                                            from "react-toastify";
import { UserContext }                                      from "../../../../App";
import { FeatureList, 
         FeatureListItem, 
         InternetPlanBox 
       }                                                    from "./homeInternetStyle";
import InternetPlan                                         from "./InternetPlan";
import { 
         SVGImages 
       }                                                    from "../../../../config/images";
// import { useSendSupportMailMutation }                           from "../../../../services/userUserApi";
import { useInternetListPackagesQuery }                          from "../../../../services/svcInternetApi";
import { 
         useGetSubscriptionsMutation,
       }                                                    from "../../../../services/userUserStoreApi";
import CardSlider                                           from "../../../../components/UI/CardSlider";

import { BoldContent, TabContentContainer, TabTitle,
         OrderButton,
       }                                                   from "../../../Tabs/tabContentStyle";
import SvcInternetModal                                     from "../../../Modal/SvcInternetModal";


// const features = ["Unlimited downloads", "Free service installation", "Free modem rental and included Wifi*"];

const SvcInternet = ({ tabLabel, sectionID }) => {
    const { t } = useTranslation('homeServicesInternet');
    const Source_Name           = "HOME PAGE - SERVICE - INTERNET";
    const navigator                                     = useNavigate();
    const [showModal,       setShowModal]               = useState(false);
    const { user }                                      = useContext(UserContext);
    const { data: internetLists, isLoading }            = useInternetListPackagesQuery({ source: Source_Name }); // Updated line
    const [UserStore_GetSubscriptions]                  = useGetSubscriptionsMutation();
    const [subscriptionData, setSubscriptionData]       = useState(null);
    

    useEffect(() => {
        if (user?._id) {
            const data = {
                userIds: [user._id],  // List of user IDs
              };
            startTransition(() => {  
                UserStore_GetSubscriptions({ data, source: Source_Name }).then(response => {
                    setSubscriptionData(response.data);
                    console.log("response", response.data);
                });
            });
        }
    }, [user, UserStore_GetSubscriptions]);

    const settings = {
        className:      "slider center variable-width",
        centerMode:     true,
        infinite:       true,
        centerPadding:  "20%",
        slidesToShow:   1,
        dots:           true,
        speed:          500,
        autoplay:       true,         // Enable auto-scrolling
        autoplaySpeed:  2200,         
        responsive: [
            {   breakpoint: 1400,   settings: {  centerPadding: "10%"  }   },
            {   breakpoint: 1200,   settings: {  centerPadding: "5%"   }   },
            {   breakpoint: 600,    settings: {  centerPadding: "0%"   }   }
        ]
    };

    if (isLoading) { 
        return <p>Loading</p>; 
    }

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => setShowModal(false);


    return (
        <>
            <TabContentContainer id={sectionID}>
                <TabTitle className="text-center">{tabLabel}</TabTitle>
                <BoldContent>
                    <p>
                        {t('content.intro')}
                    </p>
                </BoldContent>
                <FeatureList>
                    {[
                        t('content.features.unlimited_downloads'), 
                        t('content.features.free_installation'), 
                        t('content.features.free_modem_wifi')
                    ].map((feature, i) => {
                        return (
                            <FeatureListItem key={i}>   
                                <SVGImages.CheckCircleIcon />   
                                <span>{feature}</span>  
                            </FeatureListItem>
                        );
                    })}
                </FeatureList>
                <InternetPlanBox>
                    {internetLists?.data?.length > 0 && (
                        <CardSlider sliderConfig={settings}>
                            {internetLists?.data
                                ?.filter(plan => plan.published) // Filter for only published plans
                                ?.slice() // Create a shallow copy of the array
                                .sort((a, b) => {
                                    // Extract numeric values from downloadSpeed strings and compare them
                                    const speedA = parseInt(a.downloadSpeed.replace('Mbps', ''), 10);
                                    const speedB = parseInt(b.downloadSpeed.replace('Mbps', ''), 10);
                                    return speedA - speedB; // Sort in ascending order
                                })
                                .map(plan => {
                                    return <InternetPlan key={plan._id} planData={plan} />;
                                })}
                        </CardSlider>
                    )}
                </InternetPlanBox>

                <OverlayTrigger 
                    placement="top" 
                    overlay={
                        <Tooltip id="tooltip-save">
                            {user?._id ? 
                                (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.internet) && user.subscriptions.internet.length > 0) 
                                    ? t('tooltips.view_my_package') 
                                    : t('tooltips.request_package'))  
                                : t('tooltips.signin_or_login')
                            }
                        </Tooltip>
                    }
                >
                    <div className="d-flex justify-content-center">
                        <OrderButton
                            variant="secondary"
                            
                            onClick={() => {
                                console.log("subscriptionData:", subscriptionData);
                                if (!user?._id) {
                                    navigator("/signin"); // Navigate to sign-in page
                                } else if (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.internet) && user.subscriptions.internet.length > 0)) {
                                    navigator("/subscriptions?tab=internet"); // Navigate to subscriptions page
                                } else {
                                    handleShowModal(); // Show modal for requesting a package
                                }
                            }}
                        >
                            {user?._id ?
                                (subscriptionData?.data?.some(user => Array.isArray(user.subscriptions?.internet) && user.subscriptions.internet.length > 0) 
                                    ? t('buttons.view_my_package') 
                                    : t('buttons.request_package')) 
                                : t('buttons.signin_or_login')
                            }
                        </OrderButton>
                    </div>
                </OverlayTrigger>
                
            </TabContentContainer>

            <SvcInternetModal 
                showModal={showModal} 
                handleCloseModal={handleCloseModal} 
                user={user} 
                data={internetLists} // Updated line
            />
        </>
    );
};

export default SvcInternet;
