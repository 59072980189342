/* File: /src/utils/constants.js */ 
import { SVGImages }            from "../config/images";

import SetBilling               from "../pages/Settings/Billing";
import SetContactInfo           from "../pages/Settings/ContactInfo";
import SetNotifications         from "../pages/Settings/Notifications";
import SetSecurity              from "../pages/Settings/Security";


import SvcInternet              from "../pages/Home/ServicesSection/Internet";
import SvcLearning              from "../pages/Home/ServicesSection/Learning";
import SvcMarketing             from "../pages/Home/ServicesSection/Marketing";
import SvcPartsAuto             from "../pages/Home/ServicesSection/PartsAuto";
import SvcRemittance            from "../pages/Home/ServicesSection/Remittance";
import SvcTranscript            from "../pages/Home/ServicesSection/Transcript";


import SubsInternet             from "../pages/Subscriptions/Internet";
import SubsLearning             from "../pages/Subscriptions/Learning";
import SubsInterests            from "../pages/Subscriptions/Interests";
import SubsPromo                from "../pages/Subscriptions/Promo";
import SubsPartsOrders          from "../pages/Subscriptions/PartsOrders";
import SubsPartsQuotes          from "../pages/Subscriptions/PartsQuotes";
import SubsRemittance           from "../pages/Subscriptions/Remittance";
import SubsTranscript           from "../pages/Subscriptions/Transcript";


export const captchaKey = "6LeIMQEmAAAAALtXaLybRpVj5XnzXZrOXpuLu21m";
// export const captchaKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

export const settingsData = [
    // Order in which the services will appear on the "Settings" section
    { id: 1, label: "ContactInfo",      content: SetContactInfo },
    { id: 2, label: "Security",         content: SetSecurity },
    { id: 3, label: "Notifications",    content: SetNotifications },
    { id: 4, label: "Billing",          content: SetBilling },
];

export const serviceTabs = [
    // Order in which the services will appear on the "Our Services" section
    { id: "SvcMarketing",       icon: SVGImages.MarketingIcon,      label: "Marketing",     serviceName: "marketings",  content: SvcMarketing },
    { id: "SvcRemittance",      icon: SVGImages.MarketingIcon,      label: "Remittance",    serviceName: "remittances",  content: SvcRemittance },
    { id: "SvcInternet",        icon: SVGImages.HomeInternetIcon,   label: "Internet",      serviceName: "internet",  content: SvcInternet },
    { id: "SvcLearning",        icon: SVGImages.CourseIcon,         label: "Learning",      serviceName: "learning",  content: SvcLearning },
    { id: "SvcTranscript",      icon: SVGImages.MarketingIcon,      label: "Transcript",    serviceName: "transcripts",  content: SvcTranscript },
    { id: "SvcPartsAuto",       icon: SVGImages.CarIcon,            label: "Parts Order",   serviceName: "vehicleParts",  content: SvcPartsAuto },
    
];

export const subscriptionsData = [
    // Order in which the services will appear on the "My Subscriptions" section
    { id: 1, label: `My Interests`, content: SubsInterests },
    { id: 2, label: `My Promo`,     content: SubsPromo },
    { id: 3, label: `Internet`,     content: SubsInternet },
    { id: 4, label: `Learning`,     content: SubsLearning },
    { id: 5, label: `Parts Order`,  content: SubsPartsOrders },
    { id: 6, label: `Parts Quotes`, content: SubsPartsQuotes },
    { id: 7, label: `Transcript`,   content: SubsTranscript },
    { id: 8, label: `Remittance`,   content: SubsRemittance },
];



