/* File: /src/services/svcVehiclePartsApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const svcVehiclePartsApi = createApi({
    reducerPath: "vehiclePartsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/svcVehicleParts",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["VehicleParts"],
    endpoints: builder => ({
        getAllVehicleParts:   builder.query({    query: ({ source }) =>       ({ url: "/",                method: "GET", params: { source }, }),                       providesTags: ["VehicleParts"] }),
        getVehiclePartById:   builder.query({    query: id =>       ({ url: `/${id}`,           method: "GET" }),                       providesTags: ["VehicleParts"] }),
        
        uploadVehiclePart:    builder.mutation({ query: data =>     ({ url: "/",                method: "POST", body: data }),          invalidatesTags: ["VehicleParts"] }),
        
        updateVehiclePart:    builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,   method: "PATCH", body: payload.data }), invalidatesTags: ["VehicleParts"] }),
        
        deleteVehiclePart:    builder.mutation({ query: id =>       ({ url: `/${id}`,           method: "DELETE" }),                    invalidatesTags: ["VehicleParts"] }),
        healthCheck:          builder.query   ({ query: ({ source }) =>       ({ url: `/health`,          method: "GET", params: { source }, })        }),
    })
});

export const {
    useHealthCheckQuery,
    useDeleteVehiclePartMutation,
    useGetAllVehiclePartsQuery,
    useGetVehiclePartByIdQuery,
    useUpdateVehiclePartMutation,
    useUploadVehiclePartMutation
} = svcVehiclePartsApi;
