/* File: /src/services/svcRemittanceApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const svcRemittanceApi = createApi({
    reducerPath: "svcRemittanceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/svcRemittances",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["Remittances"],
    endpoints: builder => ({
        getAllRemittances:   builder.query({    query: ({ source }) =>       ({ url: "/",      method: "GET", params: { source }, }),                        providesTags: ["Remittances"] }),
        getRemittanceById:   builder.query({    query: id =>       ({ url: `/${id}`,           method: "GET" }),                        providesTags: ["Remittances"] }),
        
        uploadRemittance:    builder.mutation({ query: data =>     ({ url: "/",                method: "POST", body: data }),           invalidatesTags: ["Remittances"] }),
        
        updateRemittance:    builder.mutation({ query: payload =>  ({ url: `/${payload.id}`,   method: "PATCH", body: payload.data }),  invalidatesTags: ["Remittances"] }),
        
        deleteRemittance:    builder.mutation({ query: id =>       ({ url: `/${id}`,           method: "DELETE" }),                     invalidatesTags: ["Remittances"] }),
        healthCheck:         builder.query   ({ query: ({ source }) =>       ({ url: `/health`,          method: "GET", params: { source }, })        }),
    })
});

export const { 
    useHealthCheckQuery,
    useDeleteRemittanceMutation,
    useGetAllRemittancesQuery,
    useGetRemittanceByIdQuery,
    useUpdateRemittanceMutation,
    useUploadRemittanceMutation
} = svcRemittanceApi;
