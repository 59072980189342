import React                                  from "react";
import { AiOutlineArrowLeft }                 from "react-icons/ai";
import                                              "./styles.css";
import { 
        useGetTransactionsByUserQuery,
        }                                     from "../../../../services/orderTransactionApi";


const TransactionHistory = ({ user, onBackClick }) => {
  const Source_Name                   = "MY SUBSCRIPTIONS - Transactions";
  const { data: transactionData }     = useGetTransactionsByUserQuery({ id: user?._id, source: Source_Name });
  const transactionCount              = transactionData?.data?.[0]?.transactions?.length || 0;
  const transactions                  = transactionData?.data?.[0]?.transactions || []; // Get the list of transactions


  return (
    <div className="transaction-history-box">
      <button className="back-button" onClick={onBackClick}>
        <AiOutlineArrowLeft /> Back
      </button>
      <h3>Transaction History ({transactionCount})</h3>
      <div className="table-container">
        <table className="transaction-table">
          <thead>
            <tr>
              <th>Actions</th>
              <th>ReqNb</th>
              <th>Status</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Address</th>
              <th>City</th>
              <th>Country</th>
              <th>Phone</th>
              <th>Send Amount</th>
              <th>Receive Amount</th>
              <th>Fees</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={transaction._id}>
                <td>Edit/Delete</td> {/* You can add real action handlers for edit/delete */}
                <td>{transaction.requestNumber}</td>
                <td>{transaction.status}</td>
                <td>{transaction.firstName}</td>
                <td>{transaction.lastName}</td>
                <td>{transaction.address}</td>
                <td>{transaction.city}</td>
                <td>{transaction.country}</td>
                <td>{transaction.phone}</td>
                <td>{transaction.sendAmount?.toFixed(2)}</td>
                <td>{transaction.receiveAmount?.toFixed(2)}</td>
                <td>{transaction.feesAmount?.toFixed(2)}</td>
                <td>{transaction.totalAmount?.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
     
    </div>
  );
};

export default TransactionHistory;