/* File: /src/services/svcServiceStoreApi.js */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl }                   from "./api";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const svcServiceStoreApi = createApi({
    reducerPath: "svcServiceStoreApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + "/svcServiceStore",

        prepareHeaders: headers => {
            const token = getTokenFromLocalStorage();
            if (token) {    headers.set("Authorization", `Bearer ${token}`);    }
            return headers;
        }
    }),
    tagTypes: ["serviceStore"],
    endpoints: builder => ({
        listServices:   builder.query({    query: ({source}) =>            ({ url: `/listServices`,         method: "GET", params: { source }}),    providesTags:    ["serviceStore"] }),
        statusServices: builder.query({    query: ({source}) =>            ({ url: `/statusServices`,       method: "GET", params: { source }}),    providesTags:    ["serviceStore"] }),
        updateService:  builder.mutation({ query: ({ payload, source }) => ({ url: `/updateService`,        method: "PATCH", body: { ...payload, source } }), invalidatesTags: ["serviceStore"] }),
        
        createService:  builder.mutation({ query: data =>                  ({ url: "/createService",        method: "POST",   body: data }),        invalidatesTags: ["serviceStore"] }),
        deleteService:  builder.mutation({ query: id =>                    ({ url: `/deleteService/${id}`,  method: "DELETE" }),                    invalidatesTags: ["serviceStore"] }),
    })
});

export const { 
    useListServicesQuery,
    useStatusServicesQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation
} = svcServiceStoreApi;

/* EXAMPLE output useListServicesQuery
  {
    "_id": "66c4ff1d4447411e585e35e6",
    "serviceName": "internet",
    "published": true,
    "providers": [
      {
        "providerName": "Bravo Telecom",
        "contactInfo": "support@bravotelecom.com",
        "startDate": "2024-08-20T00:00:00.000Z",
        "rating": 0,
        "_id": "66c4ff1d4447411e585e35e7"
      }
    ]
  }
    */