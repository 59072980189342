/* REAL - DASH - MANAGE - PARTS-REQUESTS    //Dashboard/Parts-Requests */
/* File: /src/pages/Dashboard/DashboardPages/Services-PartsRequests/ManagePartsRequests.js  */
// Good form.
import                                      './ManagePartsRequests.css';

import React, 
       { 
        useContext, 
        useState,
       }                                from "react";
import { Button, 
        Form, 
        Modal,
        Tooltip, OverlayTrigger,
        }                               from "react-bootstrap";
import { 
        useGetAllVehicleRequestsQuery, 
        useUpdateVehiclePartsOrderMutation 
        }                               from "../../../../services/orderVehiclePartsOrderApi";
import { useGetAllUsersQuery }          from "../../../../services/userUserApi";
import { UserContext }                  from "../../../../App";
import { FaRegEye, 
         FaRegEyeSlash, 
        }                               from "react-icons/fa";
import { FcViewDetails }                from "react-icons/fc";
import { toast }                        from "react-toastify";
import moment                           from "moment";
import BootstrapTable                   from "react-bootstrap-table-next";
import filterFactory, 
       { textFilter }                   from "react-bootstrap-table2-filter";



const ManagePartsRequests = () => {
    const Source_Name           = "MANAGE - PARTS-REQUESTS";

    const { data: requestList }                 = useGetAllVehicleRequestsQuery({ source: Source_Name });
    const { data: userLists }                   = useGetAllUsersQuery({ source: Source_Name });
    const [carRequest_UpdateRequest]            = useUpdateVehiclePartsOrderMutation();
    const { user }                              = useContext(UserContext);
    
    const [show,                setShow]                = useState(false);
    const [selectedRow,         setSelectedRow]         = useState({});
    const [price,               setPrice]               = useState(0);
    const [notes,               setNotes]               = useState("");
    const [qst,                 setQst]                 = useState(0);
    const [gst,                 setGst]                 = useState(0);
    const [hold,                setHold]                = useState(0);
    const [showAdvanced,        setShowAdvanced]        = useState(false);
    const [statusModal,         setStatusModal]         = useState(false);
    const [selectedStatus,      setSelectedStatus]      = useState("");
    const [selectedPart,        setSelectedPart]        = useState({});
    const [showDetails,         setShowDetails]         = useState(false);
    const [currentPage,         setCurrentPage]         = useState(1);
    const [sizePerPage,         setSizePerPage]         = useState(5);

    const [formArray,           setFormArray]           = useState([]);
    const [originalFormArray,   setOriginalFormArray]   = useState([]); 
    const [isModified,          setIsModified]          = useState(false);

    

    const STG_DateTime_Format   = "YYYY-MM-DD, HH:mm:ss";

    const sliceIdFormatter      = cell => <span>{cell?.toString().slice(cell.length - 7)}</span>;

    const momentFormatter = (cell, format = STG_DateTime_Format) => {
        const formattedDate = moment(cell).format(format);
        const [datePart, timePart] = formattedDate.split(", ");
        return (
            <span>  {datePart}  <br />  {timePart}  </span>
        );
    };
    
    // Table Columns
    const columns = [
        { dataField: "_id",         text: "OrderId",            sort: true, formatter: sliceIdFormatter },
        ...(showAdvanced ?
       [{ dataField: "userEmail",   text: "Requested By",       sort: true, filter: textFilter() }] : []),

        { dataField: "fullname",    text: "Member Full Name",   sort: true, filter: textFilter() },
        { dataField: "brandModel",  text: "Brand & Model",      sort: true, filter: textFilter(), formatter: (cell, row) => {
            const uniqueBrandModels = [...new Set(row.carParts.map(part => `${part.brand} ${part.model}`))];
            return (
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {uniqueBrandModels.join("\n")}
                </pre>
            );
        }},
        { dataField: "partNames",   text: "Part Names",         sort: true, filter: textFilter(), formatter: (cell, row) => {
            return (
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {row.carParts.map(part => part.partName).join(" |\n")}
                </pre>
            );
        }},
        { dataField: "carParts",    text: "iCt",                sort: true, formatter: (cell, row) => Array.isArray(row.carParts) ? row.carParts.length : 0 },
        { dataField: "pcs",         text: "Pcs",                sort: true, formatter: (cell, row) => {
            return row.carParts.reduce((total, part) => total + parseInt(part.quantity, 10), 0); // Ensure quantity is treated as a number
        }},
        { dataField: "total",       text: "Tot",                sort: true, formatter: (cell) => cell ? `${cell} $` : '---' },
        { dataField: "status",      text: "Status",             sort: true },
        { dataField: "createdAt",   text: "Created",            sort: true, formatter: cell => momentFormatter(cell, STG_DateTime_Format) },
        
        ...(showAdvanced ? 
       [{ dataField: "updatedAt",   text: "Updated",            sort: true, formatter: cell => momentFormatter(cell, STG_DateTime_Format) }] : []),
    ];

    // Define the initial form structure
    const initialFormStructure = (part, id) => ({
        id:         id || "", 
        system:     part?.system || "",
        subSystem:  part?.subSystem || "",
        partName:   part?.partName || "",
        brand:      part?.brand || "",
        model:      part?.model || "",
        year:       part?.year || "",
        vin:        part?.vin || "",
        quantity:   part?.quantity || "",
        additional: part?.additional || "",
        price:      part?.price ? parseFloat(part.price) : "",
        qst:        part?.qst ? parseFloat(part.qst) : "", 
        gst:        part?.gst ? parseFloat(part.gst) : "", 
        hold:       part?.hold ? parseFloat(part.hold) : "", 
        notes:      part?.notes || "",
        subTotal:   part?.subTotal || ""
    });
    
    

    // _id : ObjectId('668549a55621f702de902884')
   //  669765efd18d1cddc55fd1ea
   // 66787a3534f7e304cc4b7762
    
    const handle_UpdatePrice = () => {
        if (price && hold && qst && gst) {
            const updatedArr = [];
            // eslint-disable-next-line no-unsafe-optional-chaining

            setSelectedPart(1);  // BOGUS LINE
            for (let i of selectedRow?.carParts) {
                if (i === selectedPart) {
                    const subTotal = parseInt(i.quantity) * parseInt(price) + parseInt(qst) + parseInt(gst) + parseInt(hold);
                    updatedArr.push({
                        ...i,
                        price: price,
                        subTotal: isNaN(subTotal) ? '' : subTotal,
                        notes: notes,
                        qst: qst,
                        gst: gst,
                        hold: hold,
                    });
                } else {
                    updatedArr.push(i);
                }
            }
            setSelectedRow({ ...selectedRow, carParts: updatedArr });
            setPrice("");
            setNotes("");
            setGst("");
            setQst("");
            setHold("");
            setShow(false);
        } else {
            toast.error("Please fill all the fields!");
        }
    };

    const handle_UpdateRequest = task => {
        const handle_Response_UpdateRequest = res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setShow(false);
                setStatusModal(false);
                setSelectedRow({});
            } else {
                toast.error(res?.data?.message);
            }
        };

        const createAuditAction = (actionDescription) => ({
            agentId: user?._id,
            actionDescription,
            timestamp: new Date()
        });
    
        if (task === "priceEmail" || task === "priceSave") {
            const updatedFormArray = formArray.map(part => {
                const price = parseFloat(part.price) || 0;
                const qst = price * 0.09975;
                const gst = price * 0.05;
                const hold = parseFloat(part.hold) || 0;
                const quantity = parseInt(part.quantity, 10) || 0;
                const subTotal = parseFloat((quantity * (price + qst + gst) + hold).toFixed(2)); // Calculate subTotal with precision
    
                return {
                    ...part,
                    qst: qst.toFixed(2),
                    gst: gst.toFixed(2),
                    subTotal,
                };
            });
    
            const totalPrice = updatedFormArray.reduce((total, part) => total + part.subTotal, 0).toFixed(2);
    
            carRequest_UpdateRequest({
                id: selectedRow?._id,
                data: {
                    task,
                    data: {
                        carParts: updatedFormArray,
                        total: parseFloat(totalPrice),
                        qst: updatedFormArray.reduce((acc, part) => acc + parseFloat(part.qst), 0).toFixed(2),
                        gst: updatedFormArray.reduce((acc, part) => acc + parseFloat(part.gst), 0).toFixed(2),
                        hold: updatedFormArray.reduce((acc, part) => acc + (part.hold ? parseFloat(part.hold) : 0), 0).toFixed(2),
                    },
                    auditActions: createAuditAction("update quote")
                },
            }).then(handle_Response_UpdateRequest);
        } else {
            carRequest_UpdateRequest({
                id: selectedRow?._id,
                data: {
                  task,
                  data: {
                    status: selectedStatus,
                  },
                  auditActions: createAuditAction("update status")
                },
              }).then(handle_Response_UpdateRequest);
        }
    };

    const handle_RowSelect = (row, isSelect, rowIndex, e) => {
        if (isSelect) {
            setSelectedRow(row);
            const updatedFormArray = row.carParts.map((part, index) => ({
                ...initialFormStructure(part),
                id: `${row._id}-${index}`
            }));
            setFormArray(updatedFormArray);
            setOriginalFormArray(JSON.parse(JSON.stringify(updatedFormArray))); // Save the original form array
            setShowDetails(true); // You can choose to show details if needed
        } else {
            setSelectedRow({});
            setFormArray([]);
            setOriginalFormArray([]);
            setIsModified(false);
            setShowDetails(false);
        }
    };
    
    // Handle form changes for each part
    const handleFormArrayChange = (id, field, value) => {
        const newFormArray = formArray.map((item) => {
            if (item.id === id) {
                const updatedItem = { ...item, [field]: value };
    
                const price = parseFloat(updatedItem.price || 0);
                const quantity = parseInt(updatedItem.quantity || 0, 10);
                const hold = parseFloat(updatedItem.hold || 0);
    
                if (field === "price" || field === "quantity" || field === "hold") {
                    const qst = price * 0.09975;
                    const gst = price * 0.05;
                    updatedItem.qst = qst.toFixed(2);  // Round to 2 decimal places
                    updatedItem.gst = gst.toFixed(2);  // Round to 2 decimal places
                    const subTotal = quantity * (price + qst + gst) + hold;
                    updatedItem.subTotal = subTotal.toFixed(2);  // Round to 2 decimal places
                }
    
                return updatedItem;
            }
            return item;
        });
    
        // Convert numeric fields to strings for comparison
        const convertToStringForComparison = (array) => {
            return array.map(item => ({
                ...item,
                price: item.price.toString(),
                quantity: item.quantity.toString(),
                hold: item.hold.toString(),
                qst: item.qst.toString(),
                gst: item.gst.toString(),
                subTotal: item.subTotal.toString()
            }));
        };

        const stringifiedNewFormArray = JSON.stringify(convertToStringForComparison(newFormArray));
        const stringifiedOriginalFormArray = JSON.stringify(convertToStringForComparison(originalFormArray));

        setFormArray(newFormArray);
        setIsModified(stringifiedNewFormArray !== stringifiedOriginalFormArray); // Compare with original array
        console.log("formArray", formArray);
    };
    
    const selectRow = {
        mode: "radio", // changed from  'checkbox'to radio:  Use radio if you want only one row to be selected at a time
        clickToSelect: true,
        style: { backgroundColor: '#c8e6c9' },
        onSelect: handle_RowSelect,
    };

    const CustomPagination = ({
        currentPage = 1,
        sizePerPage = 5,
        totalSize = 0,
        onPageChange,
        onSizePerPageChange,
    }) => {
        const totalPages = Math.ceil(totalSize / sizePerPage);
        const handlePrevPage = () => onPageChange(currentPage - 1, sizePerPage);
        const handleNextPage = () => onPageChange(currentPage + 1, sizePerPage);
        const handleFirstPage = () => onPageChange(1, sizePerPage);
        const handleLastPage = () => onPageChange(totalPages, sizePerPage);
    
        return (
            <div className="d-flex justify-content-between align-items-center mt-3">
                <button onClick={handleFirstPage}   disabled={currentPage === 1}         >   &lt;&lt;   </button>
                <button onClick={handlePrevPage}    disabled={currentPage === 1}         >   &lt;       </button>
                <span> Page {currentPage} of {totalPages} </span>
                <button onClick={handleNextPage}    disabled={currentPage === totalPages}>   &gt;       </button>
                <button onClick={handleLastPage}    disabled={currentPage === totalPages}>   &gt;&gt;    </button>
                <span>
                    Items per page:
                    <select value={sizePerPage}     onChange={(e) => onSizePerPageChange(currentPage, parseInt(e.target.value))}    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                    </select>
                </span>
            </div>
        );
    };

    const onPageChange = (page, sizePerPage) => {
        setCurrentPage(page);
        setSizePerPage(sizePerPage);
    };

    const onSizePerPageChange = (page, sizePerPage) => {
        setCurrentPage(page);
        setSizePerPage(sizePerPage);
    };

    const getFullName = (userId) => {
        const user = userLists?.data?.find(user => user._id === userId);
        return user ? `${user.firstName} ${user.lastName}` : '-Non Existent-';
    };

    // Preprocess data to add brandModel field
    const processedData = requestList?.data?.map(row => ({
        ...row,
        brandModel: [...new Set(row.carParts.map(part => `${part.brand} ${part.model}`))].join(", "),
        partNames: row.carParts.map(part => part.partName).join(" | "),
        fullname: getFullName(row.userId),
    }));

    // Slice the data to show only the current page's entries
    const paginatedData = processedData?.slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage);



    
 

    return (
        <div className="manage-video-page">
            <div className="video-upload-container">

                {/*  TITLE AND BUTTONS ROW  */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="mb-0">Requested Quotes ({requestList?.data?.length || 0})</h4>  {/* Add the total count here */}

                    <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Advanced Details</Tooltip>}       >
                        <Button onClick={() => setShowAdvanced(!showAdvanced)}>
                            {showAdvanced ? ( <span><FaRegEyeSlash /><FcViewDetails /></span> )
                                        : (<span><FaRegEye /><FcViewDetails /></span> )  }
                        </Button> 
                    </OverlayTrigger>

                    <div className="d-flex">
                        { (user?.role === "admin" || user?.role === "power") ? (
                            <>
                                <Button className="me-2" 
                                    onClick={() => { if (selectedRow?._id) { setStatusModal(true); setShowDetails(false); } }}
                                    disabled={!selectedRow?._id}    style={{ opacity: selectedRow?._id ? 1 : 0.5 }}             >
                                                                                                    Update Status   </Button>
                            </>
                        ) : (
                            <>
                                <Button disabled style={{ opacity: 0.5, marginRight: "0.5rem" }}>   Update Status   </Button>
                            </>
                        )}
                    </div>
                </div>


                {/* TABLE */}
                {paginatedData?.length > 0 && (
                    <div className="table-responsive">
                        <BootstrapTable
                            keyField="_id"
                            data={paginatedData}
                            columns={columns}
                            defaultSortDirection="asc"
                            striped
                            hover
                            condensed
                            responsive
                            scrollable
                            selectRow={selectRow}
                            filter={filterFactory()}  // Enable filtering
                            filterPosition="top"
                        />
                        <CustomPagination
                            currentPage={currentPage}
                            sizePerPage={sizePerPage}
                            totalSize={processedData?.length}
                            onPageChange={onPageChange}
                            onSizePerPageChange={onSizePerPageChange}
                        />
                    </div>
                )}

                {/* QUOTE DETAILS */}
                {selectedRow?.userEmail && showDetails && (
                    <>
                        <h4 className="mt-5">Quote Details - [{selectedRow?._id?.slice(-7)}] - {selectedRow?.userEmail}</h4>
                        {Object.entries(formArray.reduce((acc, item) => {
                            const key = `${item.year} - ${item.brand} - ${item.model} - ${item.vin}`;
                            if (!acc[key]) {    acc[key] = [];      }
                            acc[key].push(item);
                            return acc;
                        }, {})).map(([key, items]) => (
                            <div key={key} style={{ marginBottom: "20px" }}>

                                <h5 className="mt-4">{key}</h5>
                                <p  className="mb-4">Additional: {items[0].additional}</p>

                                {items.map((item) => (
                                    <div key={item.id} className="form-array-border" style={{ marginBottom: "10px" }}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                            <label htmlFor={`subSystem-partName-${item.id}`} className="form-label">Item #{String(parseInt(item.id.split('-').pop(), 10) + 1).padStart(2, '0')} - System & Part Name</label>

                                                <input  disabled         className="mb-2"    type="text"    id={`subSystem-partName-${item.id}`}    value={`${item.subSystem} - ${item.partName}`}
                                                        style={{ backgroundColor: "#f0f0f0", width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <label htmlFor={`notes-${item.id}`} className="form-label">ATA Parts Notes to Client</label>
                                                <input                  className="mb-2"    type="text"     id={`notes-${item.id}`}   value={item.notes}                                  
                                                        style={{ width: "100%" }}
                                                        onChange={e => handleFormArrayChange(item.id, "notes", e.target.value)}   
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                                <label htmlFor={`quantity-${item.id}`} className="form-label">Qty</label>
                                                <input  disabled        className="mb-2"    type="text"     id={`quantity-${item.id}`}     value={item.quantity}
                                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                                />
                                            </div>
                                            
                                            <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                                <label htmlFor={`price-${item.id}`} className="form-label">Price</label>
                                                <input                  className="mb-2"    type="text"     id={`price-${item.id}`}   value={item.price}
                                                        style={{ marginRight: "8px", width: "100%" }}
                                                        onChange={e => handleFormArrayChange(item.id, "price", e.target.value)}   
                                                />
                                            </div>

                                            <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                                <label htmlFor={`qst-${item.id}`} className="form-label">QST</label>
                                                <input  disabled        className="mb-2"    type="text"     id={`qst-${item.id}`}     value={item.qst}
                                                        style={{ backgroundColor: "#f0f0f0", marginRight: "8px", width: "100%" }}
                                                />
                                            </div>

                                            <div className="col-sm-12 col-md-1" style={{ paddingLeft: "0px" }}>
                                                <label htmlFor={`gst-${item.id}`} className="form-label">GST</label>
                                                <input  disabled        className="mb-2"    type="text"     id={`gst-${item.id}`}     value={item.gst}
                                                        style={{ backgroundColor: "#f0f0f0", marginLeft: "8px", width: "100%" }}
                                                />
                                            </div>

                                            <div className="col-sm-12 col-md-1" style={{ paddingRight: "0px" }}>
                                                <label htmlFor={`hold-${item.id}`} className="form-label">Hold</label>
                                                <input                  className="mb-2"    type="text"     id={`hold-${item.id}`}    value={item.hold}                                   
                                                        style={{ marginRight: "8px", width: "100%" }}
                                                        onChange={e => handleFormArrayChange(item.id, "hold", e.target.value)}   
                                                />
                                            </div>
                                            
                                            <div className="col-sm-12 col-md-1" style={{ paddingLeft: "0px" }}>
                                                <label htmlFor={`subTotal-${item.id}`} className="form-label">Sub-Total</label>
                                                <input  disabled        className="mb-2"    type="text"     id={`subTotal-${item.id}`} value={item.subTotal}
                                                        style={{ backgroundColor: "#f0f0f0",  marginLeft: "8px", width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        ))}
                        <div className="d-flex justify-content-center align-items-center">
                            <Button className="btn btn-danger me-2" onClick={() => setSelectedRow({})}>Cancel</Button>
                            <Button className="btn btn-primary ms-2" onClick={() => handle_UpdateRequest("priceSave")} disabled={!isModified} >Save Only</Button>
                            <Button className="btn btn-secondary ms-2" onClick={() => handle_UpdateRequest("priceEmail")}>Email Client</Button>
                        </div>
                    </>
                )}

            </div>

            {/* Modal: */}
            <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <div className="modal-form">
                        <div className="video-input-box-modal">

                            <div className="input-wrapper">
                                <label htmlFor="subTotal">Sub-Total</label>
                                <div className="d-flex justify-content-start align-items-center">
                                    <input
                                        type="text"     id="subTotal"   placeholder="Sub-Total"
                                        value={price * parseInt(selectedPart?.quantity) + parseInt(gst) + parseInt(qst) + parseInt(hold)}
                                    />
                                </div>
                            </div>
                            <div className="w-50 mx-auto d-flex justify-content-around align-items-center">
                                <Button variant="secondary me-3" onClick={() => setShow(false)}>    Close           </Button>
                                <Button variant="primary" onClick={handle_UpdatePrice}>             Update Quote    </Button> 
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal: status update */}
            <Modal show={statusModal} onHide={() => setStatusModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>                    <Modal.Title>Order Status</Modal.Title>                </Modal.Header>
                <Modal.Body>
                    <div className="modal-form">
                        <div>
                            <strong>User:</strong> {selectedRow?.userEmail || "N/A"}
                        </div>
                        <Form.Select
                            className="shadow-none"
                            aria-label="Default select example"
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}> 
                            <option value="CtReqParts">CtReqParts</option>
                            <option value="CtReqRefund">CtReqRefund</option>
                            <option value="CtReqCancel">CtReqCancel</option>
                            <option value="ReqCtInfo">ReqCtInfo</option>
                            <option value="PendingDelivery">PendingPayment</option>
                            <option value="PendingDelivery">PendingDelivery</option>
                            <option value="ClosedFulfilled">ClosedFulfilled</option>
                            <option value="AtaProcessing">AtaProcessing</option>
                            <option value="ReqCtQtApp">ReqCtQtApp</option>
                            <option value="ClosedInReview">ClosedInReview</option>
                            <option value="ClosedInfoOnly">ClosedInfoOnly</option>
                            <option value="AtaCancelled">AtaCancelled</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setStatusModal(false)}>                                  Close   </Button>
                    <Button variant="primary"   onClick={() => handle_UpdateRequest("status")} className="btn btn-primary">     Update Status  </Button>
                </Modal.Footer>
            </Modal>


        </div>
    );
};

export default ManagePartsRequests;
