/* REAL - DASH - LISTS - VEHICLE */
/* File:  /src/pages/Dashboard/DashboardPages/Services-Vehicle/ManageVehicle.js */
import "./ManageVehicle.css";
import React, 
        { useContext, useState }    from "react";
import { 
         Tooltip, OverlayTrigger ,
        }                           from "react-bootstrap";
        import { CgListTree }       from "react-icons/cg";
import { MdRemoveCircle,
         MdOutlineAddCard,
        }                           from "react-icons/md";
import { toast }                    from "react-toastify";
import { Button, 
        Form, 
        Modal 
        }                           from "react-bootstrap";
import { useAddVehicleMutation, 
        useDeleteVehicleMutation, 
        useGetAllVehiclesQuery, 
        useUpdateVehiclesMutation,
        useSetPublishStateMutation, 
        }                           from "../../../../services/listVehiclesApi";
import { UserContext }              from "../../../../App";
import DeleteModal                  from "../../../../components/Modal/DeleteModal";
const ManageVehicles = () => {
    const Source_Name           = "MANAGE - LIST - VEHICLE";
    const DFLT_Brand                            = "Select Brand";
    const [brand,           setBrand]           = useState("");
    const [updateBrand,     setUpdateBrand]     = useState("");
    const [updateModel,     setUpdateModel]     = useState([]);
    const [updateTemp,      setUpdateTemp]      = useState("");
    const [temp,            setTemp]            = useState("");
    const [models,          setModels]          = useState([]);
    const { user }                              = useContext(UserContext);
    const [addVehicle]                          = useAddVehicleMutation();
    const [selectedVehicle, setSelectedVehicle] = useState({ 
        brand: DFLT_Brand
    });
    const { data }                              = useGetAllVehiclesQuery({ source: Source_Name });
    const [show,            setShow]            = useState(false);
    const [selected,        setSelected]        = useState("");
    const [deleteVehicle]                       = useDeleteVehicleMutation();
    const [updateVehicles]                      = useUpdateVehiclesMutation();
    const [SetVehicle_Publish]                  = useSetPublishStateMutation();
    const [globalIsPublished,   setGlobalIsPublished]   = useState(true); // New state for global publish status
    const [deleteModal,     setDeleteModal]     = useState(false);
    const [selectedItem,    setSelectedItem]    = useState("");
    const handleModels                         = () => {
        setModels([...models, temp]);
        setTemp("");
    };

    const handleAddVehicle = () => {
        const data = {
            brand: brand,
            models: models
        };
        if (brand) {
            if (models.length > 0) {
                addVehicle(data).then(res => {
                    if (res?.data?.status) {
                        toast.success(res?.data?.message);
                        setBrand("");
                        setModels([]);
                        setTemp("");
                    } else {
                        toast.error(res?.data?.message);
                    }
                });
            } else {
                toast.error("Please add at least one sub-category!");
            }
        } else {
            toast.error("Please add the Vehicle Brand!");
        }
    };

    const handleDeleteModels = subCat => {
        const subCats = models?.filter(s => s !== subCat);
        setModels(subCats);
    };

    const handleViewBrandListModels = e => {
        const value = e.target.value;
        setSelectedVehicle(JSON.parse(value));
        setSelected(value);
    };
    const handleDeleteCat = id => {
        deleteVehicle(id).then(res => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setSelectedVehicle(JSON.stringify({ brand: DFLT_Brand }));
                setSelected("");
                setDeleteModal(false);
            } else {
                toast.error(res?.data?.message);
            }
        });
    };

    const handleAddModels = () => {
        setUpdateModel([...updateModel, updateTemp]);
        setUpdateTemp("");
    };

    const handleDeleteUpdateModels = model => {
        const filteredModels = updateModel?.filter(s => s !== model);
        setUpdateModel(filteredModels);
    };

    const handleUpdate = () => {
        const data = {
            brand: updateBrand,
            models: updateModel
        };
        if (updateBrand) {
            if (updateModel.length > 0) {
                updateVehicles({ id: selectedVehicle?._id, data: data }).then(res => {
                    if (res?.data?.status) {
                        toast.success(res?.data?.message);
                        setShow(false);
                        setUpdateTemp("");
                        setUpdateModel([]);
                        setSelectedVehicle(JSON.stringify({ brand: DFLT_Brand }));
                        setSelected(JSON.stringify({ brand: DFLT_Brand }));
                    } else {
                        toast.error(res?.data?.message);
                    }
                });
            } else {
                toast.error("Please add at least one model!");
            }
        } else {
            toast.error("Please add the Vehicle Brand!");
        }
    };

    const togglePublishStatus = () => {
        const newGlobalIsPublished = !globalIsPublished;
    
        // Update the publish status for all systems
        data?.data?.forEach(brand => {
            const brandData = {
                brand: brand.brand,
                published: newGlobalIsPublished // Update the published status
            };
            SetVehicle_Publish({ data: brandData });
        });
    };

    const handlePublishToggle = () => {
        const updatedVehicle = {
            brand: selectedVehicle?.brand,
            published: !selectedVehicle.published
        };
    
        SetVehicle_Publish({ data: updatedVehicle }).then(res => {
            if (res?.data?.status) {
                toast.success(`${updatedVehicle.published ? "Published" : "Unpublished"} ${selectedVehicle.brand}`);
               
                // Optionally, you can also update the data state to reflect the changes
                const updateVehicleNow = {
                    ...selectedVehicle,
                    published: !selectedVehicle.published
                };
                setSelectedVehicle(updateVehicleNow);
                
            } else {
                toast.error("Failed to update publish status");
            }
        });
    };
    


    return (
        <div className="manage-video-page">
            <div className="video-upload-container">
                <div className="video-input-box">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2 className="video-page-title text-center">Add New Vehicle Brand and Models</h2>
                            <div className="input-wrapper">
                                <label htmlFor="brand">Vehicle Brand</label>
                                <input type="text" id="brandName" placeholder="Vehicle Brand" value={brand} onChange={e => setBrand(e.target.value)} />
                            </div>

                            <div className="input-wrapper">
                                <label htmlFor="title">Add Vehicle Models</label>
                                <div className="d-flex justify-content-start align-items-center">
                                    <input type="text" id="models" placeholder="Models" value={temp} onChange={e => setTemp(e.target.value)} />
                                    <button onClick={handleModels}  className={`extra-btn ${!temp ? 'disabled-btn' : ''}`}  disabled={!temp}   >
                                        Add
                                    </button>
                                </div>
                                <div className="">
                                    <h5 className="mb-3 mt-3">Added Models</h5>
                                    {models?.length > 0 ? (
                                        <>
                                            {models?.map((e, i) => (
                                                <span key={i} className="sub-category-style">
                                                    {e}{" "}
                                                    <span onClick={() => handleDeleteModels(e)} className="sub-category-remove">
                                                        <MdRemoveCircle />
                                                    </span>
                                                </span>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-danger">     <small>No models Added!</small>     </p>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="input-button mt-3">
{/* 
                                        className={`extra-btn ${!brand || models.length === 0 ? 'disabled-btn' : ''}`}  */}

                                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">Add Vehicle Entry</Tooltip>}  >
                                    <Button className="success-button me-2"  variant={"success"}   disabled={!brand || models.length === 0} onClick={handleAddVehicle}   >   <MdOutlineAddCard />  </Button>
                                </OverlayTrigger>

                                <OverlayTrigger     placement="top"     overlay={<Tooltip id="tooltip-save">{globalIsPublished ? "Un-Publish" : "Publish"}</Tooltip>}       >
                                    <Button   
                                        className={globalIsPublished ? "danger-btn" : "success-button"}
                                        variant={globalIsPublished ? "danger" : "success"}
                                        onClick={() => {
                                            if (window.confirm(`About to ${globalIsPublished ? "Un-Publish" : "Publish"} all brands.`)) {
                                                const newGlobalIsPublished = !globalIsPublished;
                                                setGlobalIsPublished(newGlobalIsPublished);
                                                togglePublishStatus();
                                                toast.success(`${globalIsPublished ? "UnPublished" : "Published"} Completed`);
                                            }
                                        }}
                                    >
                                                                            <CgListTree /> 
                                    </Button>
                                </OverlayTrigger>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="video-upload-container mt-4">
                <div className="video-input-box">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="input-wrapper mb-3">
                                <label htmlFor="text">View/Modify Brand and Models</label>

                                <Form.Select
                                    onChange={handleViewBrandListModels}
                                    value={selected}
                                    name="selectedVehicle"
                                    aria-label="Default select example"
                                    className="shadow-none"
                                >
                                    <option value={JSON.stringify({ brand: DFLT_Brand })}>{DFLT_Brand}</option>
                                    {data?.data
                                        ?.slice() // Create a shallow copy of the array
                                        .sort((a, b) => (a.brand && b.brand ? a.brand.localeCompare(b.brand) : 0)) // Ensure 'brand' exists before calling localeCompare
                                        .map(d => (
                                            <option key={d?._id} value={JSON.stringify(d)}>
                                                {d?.brand}
                                            </option>
                                        ))}

                                </Form.Select>

                                {selectedVehicle?.brand !== DFLT_Brand && (
                                    <>
                                            <Button className="extra-btn me-2" variant={selectedVehicle.published ? "danger" : "success"}       onClick={handlePublishToggle}   >
                                                <span style={{ color: selectedVehicle.published ? "red" : "green" }}>
                                                    {selectedVehicle.published ? "UnPublish" : "Publish"}
                                                </span>
                                            </Button>
                                    </>
                                )}


                                <div className="sub-cat-div mt-3">
                                    {selectedVehicle &&
                                        selectedVehicle?.models?.map((e, i) => (
                                            <span key={i} className="sub-category-style pt-2">
                                                {e}
                                            </span>
                                        ))}
                                </div>
                                {selectedVehicle?.brand !== DFLT_Brand && (
                                    <>
                                        {user?.role === "power" || user?.role === "admin" || user?.role === "support" ? (
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        setShow(true);
                                                        setUpdateBrand(selectedVehicle?.brand);
                                                        setUpdateModel(selectedVehicle?.models);
                                                    }}
                                                    size="md"
                                                    disabled={!selectedVehicle?.brand} // Disable button if no brand is selected
                                                    style={!selectedVehicle?.brand ? { background: "grey", cursor: "not-allowed" } : {}}
                                                >
                                                    Modify
                                                </Button>
                                                <Button
                                                    className="danger-btn ms-3"
                                                    variant="danger"
                                                    onClick={() => {
                                                        setDeleteModal(true);
                                                        setSelectedItem(selectedVehicle?._id);
                                                    }}
                                                    disabled={!selectedVehicle?.brand} // Disable button if no brand is selected
                                                    style={!selectedVehicle?.brand ? { background: "grey", cursor: "not-allowed" } : {}}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        ) : (
                                            <div>
                                                <Button style={{ background: "grey" }} size="md">                                       Modify  </Button>
                                                <Button className="danger-btn ms-3" variant="danger" style={{ background: "grey" }}>    Delete  </Button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <div className="modal-form">
                        <div className="video-input-box-modal">
                            <h2 className="video-page-title text-center">Modify Entry</h2>
                            <div className="input-wrapper">
                                <label htmlFor="brand">Vehicle Brand</label>
                                <input
                                    type="text"     id="brand"      placeholder="Vehicle Brand"     value={updateBrand}
                                    onChange={e => setUpdateBrand(e.target.value)}
                                />
                            </div>

                            <div className="input-wrapper">
                                <label htmlFor="title">Add Model one at a time</label>
                                <div className="d-flex justify-content-start align-items-center">
                                    <input
                                        type="text"     id="models"     placeholder="Model"     value={updateTemp}
                                        onChange={e => setUpdateTemp(e.target.value)}
                                    />
                                    
                                    <button onClick={handleAddModels} className={`extra-btn ${!updateTemp ? 'disabled-btn' : ''}`} disabled={!updateTemp}>
                                        Add
                                    </button>
                                </div>
                                <div className="">
                                    <h5 className="mb-3 mt-3">Models for {updateBrand}</h5>
                                    {updateModel?.length > 0 ? (
                                        <>
                                            {updateModel?.map((e, i) => (
                                                <span key={i} className="sub-category-style">
                                                    {e}{" "}
                                                    <span onClick={() => handleDeleteUpdateModels(e)} className="sub-category-remove">
                                                        <MdRemoveCircle />
                                                    </span>
                                                </span>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-danger">     <small>No Models Added!</small>     </p>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="input-button mt-3">
                                <Button variant="secondary me-3 danger-btn" onClick={() => setShow(false)}>     Cancel  </Button>
                                <Button variant="primary" className="btn-primary ms-2" onClick={handleUpdate}>  Save    </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <DeleteModal
                question={`This will also delete all Models for: '${selectedVehicle.brand}'.`}
                heading={`Delete Vehicle Brand: '${selectedVehicle.brand}' `}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                handleDeleteItem={handleDeleteCat}
                id={selectedItem}
            ></DeleteModal>
        </div>
    );
};

export default ManageVehicles;
