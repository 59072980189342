import React, 
      { useEffect } from "react";
import { 
        useLocation, 
        useNavigate  
      } from "react-router-dom"; // To get URL and manage history

import                                "./styles.css";
import { 
      FaBars, 
      FaMoneyBillAlt, 
      FaCog, 
      FaInfoCircle 
      }                         from "react-icons/fa";
import {
      useListServicesQuery,
      }                         from "../../../services/svcServiceStoreApi";


// Mapping of tab IDs to service names
const tabMapping = {
  'Remittances':   { serviceName: "remittances" }, // good
  'Internet':      { serviceName: "internet" }, // good
  'PartsOrder':    { serviceName: "vehicleParts" }, // good
  'PartsQuotes':   { serviceName: "vehicleParts" }, // good
  'Transcript':    { serviceName: "transcripts" }, // good
  'Learning':      { serviceName: "courses" }, // good
  'MyInterests':   { serviceName: "marketings" }, // NOT
  'PromoList':     { serviceName: "marketings" } // NOT
};


const SubscriptionsTab = ({ isOpen, toggleSidebar, setActiveTab }) => {
  const Source_Name                       = "MY SUBSCRIPTIONS (BAR)";
  const { data: servicesList}             = useListServicesQuery({ source: Source_Name });

  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  // Set active tab based on URL param
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get('tab');
    if (tab && Object.keys(tabMapping).includes(tab)) {
      setActiveTab(tab); // Set the active tab from the URL
    }
  }, [location.search, setActiveTab]);

  // Update URL when a new tab is selected
  const handleTabChange = (tabName) => {
    navigate(`/subscriptions?tab=${tabName}`); // Update URL with the selected tab
    setActiveTab(tabName);
  };


  // Filter the tabs based on published status
  const filteredTabMapping = Object.keys(tabMapping).filter(tabKey => {
    const serviceName = tabMapping[tabKey].serviceName;
    if (!serviceName) return true; // Tabs without a serviceName are always included
    const service = servicesList?.data?.find(service => service.serviceName === serviceName);
    return service?.published;
  });


  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <FaBars />
      </div>
      <ul className={`menu-items ${isOpen ? "clickable" : ""}`}>

        {filteredTabMapping.includes('Remittances') && (
          <li className={!isOpen ? "disabled" : ""} onClick={() => handleTabChange("Remittances")}>
            <FaMoneyBillAlt className="icon" /> {isOpen && <span>Remittances</span>}
          </li>
        )}

        {filteredTabMapping.includes('Internet') && (
          <li className={!isOpen ? "disabled" : ""} onClick={() => handleTabChange("Internet")}>
            <FaCog className="icon" /> {isOpen && <span>Internet</span>}
          </li>
        )}

        {filteredTabMapping.includes('PartsOrder') && (
          <li className={!isOpen ? "disabled" : ""} onClick={() => handleTabChange("PartsOrder")}>
            <FaInfoCircle className="icon" /> {isOpen && <span>Parts Order</span>}
          </li>
        )}

        {filteredTabMapping.includes('PartsQuotes') && (
          <li className={!isOpen ? "disabled" : ""} onClick={() => handleTabChange("PartsQuotes")}>
            <FaInfoCircle className="icon" /> {isOpen && <span>Parts Quotes</span>}
          </li>
        )}

        {filteredTabMapping.includes('Transcript') && (
          <li className={!isOpen ? "disabled" : ""} onClick={() => handleTabChange("Transcript")}>
            <FaInfoCircle className="icon" /> {isOpen && <span>Transcript</span>}
          </li>
        )}

        {filteredTabMapping.includes('Learning') && (
          <li className={!isOpen ? "disabled" : ""} onClick={() => handleTabChange("Learning")}>
            <FaInfoCircle className="icon" /> {isOpen && <span>Learning</span>}
          </li>
        )}

        {filteredTabMapping.includes('MyInterests') && (
          <li className={!isOpen ? "disabled" : ""} onClick={() => handleTabChange("MyInterests")}>
            <FaInfoCircle className="icon" /> {isOpen && <span>MyInterests</span>}
          </li>
        )}

        {filteredTabMapping.includes('PromoList') && (
          <li className={!isOpen ? "disabled" : ""} onClick={() => handleTabChange("PromoList")}>
            <FaInfoCircle className="icon" /> {isOpen && <span>PromoList</span>}
          </li>
        )}

      </ul>
    </div>
  );
};

export default SubscriptionsTab;
